import { Doctor as DoctorDal } from '../service-dal/model/doctor';
import { Identity as IdentityDal } from '../service-dal/model/identity';
import { Doctor } from '../model/doctor.model';

export function toDoctorDal(doctorsDal: DoctorDal[]): Doctor[] {
  const doctors: Doctor[] = [];
  doctorsDal.forEach((doctor) => {
    const doctorModel = new Doctor();
    doctorModel.id = doctor.Id;
    doctorModel.name = doctor.Name;
    doctorModel.userId = doctor.UserId;
    doctors.push(doctorModel);
  });
  return doctors;
}

export function userToDalDoctor(user: IdentityDal): DoctorDal {
  const dalDoctor: DoctorDal = {
    Name: user.name,
    UserId: user.id,
  };

  return dalDoctor;
}
