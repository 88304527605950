<div class="page-title">
  {{ 'Résultats anciens bilans' | translate }}
</div>
<div class="row">
  <div class="col-lg-9">
    <div class="page-sub-title searchBar">
      <patient-header
        showValue="true"
        value="{{ patientName }}"
        (search)="onSearchPatient($event)"
        [inputValue]="initSearch"
      >
      </patient-header>
    </div>
  </div>
</div>
