import { Component, Input } from '@angular/core';

@Component({
  selector: 'core-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {

  @Input() public visible = false;
  @Input() public visibleAnimate = false;
  @Input() public name: string;

  public show(event?: any): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(event?: any): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }
}
