import { Component, OnInit, ViewChildren, QueryList, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { take, filter, tap, takeUntil, map } from 'rxjs/operators';

import { ɵbo as Ng2SmartTableComponent } from 'ng2-smart-table';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

import { IPatient } from './patient.interface';
import { readOnlyViews } from './patient.readonlyviews.constant';

import { PatientCellApartmentComponent } from './patient-cellapartment/patient-cellapartment.component';
import { PatientCellValueComponent } from './patient-cellvalue/patient-cellvalue.component';
import { PatientCellDateComponent } from './patient-celldate/patient-celldate.component';
import { PatientViews } from './patientviews.enum';
import { PatientModalComponent } from './patient-modal/patient-modal.component';

import { LocalStorageObject, LocalStorageEx } from '../../tools/localstorageobject.enum';

import { Patient, PatientDataService, FlatDataService } from '../../modules/smartflat-data-access';
import { NotificationService } from '../../modules/core';

import { Utilities } from '../../tools/utilities';
import { ConsultationSavingImp } from '../../services/tempSaving/impl/consultationSavingImp.service';
import { COMPARE } from './sub/functions';

import { DiagnosticSavingImp } from '../../services/tempSaving/impl/diagnosticSavingImp.service';

import { PatientCellNameComponent } from './patient-cellname/patient-cellname.component';
import { AuthService } from 'app/modules/core/services/authentification/auth.service';
import { Roles } from 'app/modules/core/roles.enum';
import { ModalComponent } from 'app/modules/common';
import { AppComponent } from '../../app.component';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';
import { PatientDataSource } from './patient-data-source';
import { PatientFilterStateServiceService } from './patient-filter-state-service.service';
import { Compute } from 'app/tools/compute';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';

/**
 * Component PatientComponent
 *
 */

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  providers: [DiagnosticSavingImp],
})
export class PatientComponent implements OnInit, OnDestroy {
  @ViewChildren(Ng2SmartTableComponent)
  public ng2SmartTables: QueryList<Ng2SmartTableComponent>;

  /**
   * SFLAT-451
   */
  @ViewChild(PatientModalComponent, { static: true })
  public patientModal: PatientModalComponent;

  @ViewChild('ValidateChangeDoctor', { static: true })
  public validateChangeDoctor: ModalComponent;
  public readonly changeDoctorValidated = new Subject<boolean>();

  // Error displayed
  public errorMessage: string;

  /* Patients */
  public createdPatient: IPatient;
  public selectedPatient: IPatient;
  public patientSource: PatientDataSource;
  public patientSettings: any;

  /* allows to use the PatientView enum in template */
  public PatientViews = PatientViews;
  public viewSwitch: PatientViews;

  public isEditorShown: boolean = false;
  public isEditorReset: boolean = false;

  private patientDefaultSettings = {
    columns: {
      lastName: {
        title: 'Patient.Lastname',
        filter: false,
        type: 'custom',
        valuePrepareFunction: (cell, row) => this.patientCell(cell, row),
        compareFunction: COMPARE,
        renderComponent: PatientCellNameComponent,
      },
      firstName: {
        title: 'Patient.Firstname',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      birthdate: {
        title: 'Patient.BirthDate',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      lastConsultationDate: {
        title: 'Patient.LastConsultation',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      score: {
        title: 'Patient.Score',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      flatName: {
        title: 'Patient.Apartment',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      connected: {
        title: 'Patient.MainTable.Header.Online',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellApartmentComponent,
      },
      folderNumber: {
        title: 'Patient.FolderNumber',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
    },
    actions: {
      custom: [],
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: '',
    },
    edit: {
      editButtonContent: '',
      cancelButtonContent: 'Annuler',
      saveButtonContent: 'Sauvegarder',
      confirmSave: false,
    },
    delete: { deleteButtonContent: '', confirmDelete: true },
    attr: { id: 'patient_table', class: 'table table-striped table-condensed patient_table' },
    noDataMessage: '',
    mode: 'external',
    selectMode: 'single',
    pager: { perPage: 10 },
    hideSubHeader: true,
  };

  private unsubscribe = new Subject<void>();

  constructor(
    public router: Router,
    public rolesCheck: RolesCheckService,
    private filterState: PatientFilterStateServiceService,
    private translate: TranslateService,
    private patientDataService: PatientDataService,
    private flatDataService: FlatDataService,
    private consultationSavingService: ConsultationSavingImp,
    private notificationService: NotificationService,
    private authService: AuthService,
    private app: AppComponent,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.viewSwitch = this.PatientViews.Edit;
    this.patientSettings = Utilities.deepCopy(this.patientDefaultSettings);
  }

  public ngOnInit() {
    this.rolesCheck.hasManagePatient.pipe(takeUntil(this.unsubscribe)).subscribe((canDelete) => {
      this.patientSettings.actions.delete = canDelete;
    });
    combineLatest(this.rolesCheck.hasManagePatient, this.rolesCheck.hasSelectPatientReferrer)
      .pipe(
        takeUntil(this.unsubscribe),
        map(([x, y]) => x || y),
      )
      .subscribe((canEdit) => {
        this.patientSettings.actions.edit = canEdit;
      });
    combineLatest(this.rolesCheck.hasDelegationChekup, this.rolesCheck.hasDelegationResult)
      .pipe(
        takeUntil(this.unsubscribe),
        map(([x, y]) => x || y),
      )
      .subscribe((canDelegate) => {
        const customActions = canDelegate
          ? [
              {
                name: 'delegate',
              },
            ]
          : [];
        this.patientSettings.actions.custom = customActions;
      });

    this.translate.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
    this.onLangChange();
    this.loadPatients();

    LocalStorageEx.currentPatientSelected = null;
    LocalStorageEx.removeObject(LocalStorageObject.diagnostic_patient);
  }

  public ngOnDestroy() {
    // save filter and sort
    this.filterState.saveFilterState(this.patientSource);

    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public patientCell(cell: any, rowData: any) {
    // if (this.ng2SmartTables.first.isPagerDisplay) {
    if (this.selectedPatient === rowData) {
      console.log('patientCell patient:' + this.selectedPatient.patientId);
      setTimeout(
        function() {
          if (this.patientSource.recoverRowSelected(rowData)) {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              row.isSelected = true;
            }
          } else {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              if (this.patientSource.selectFirstRow(rowData, true)) {
                row.isSelected = true;
              } else {
                row.isSelected = false;
                this.ng2SmartTables.first.grid.dataSet.selectRow(row);
              }
            }
          }
        }.bind(this),
        1,
      );
    }
    // }
    return cell;
  }

  /**
   * About patient modal
   */
  public showModifiedPageModal(patient: IPatient) {
    if (patient && patient.isModified) {
      this.patientModal.headerLabel = 'Attention';
      this.patientModal.bodyText = 'Patient.editor.escape.page.error';
      this.patientModal.footerConfirmText = null;
      this.patientModal.footerCancelText = 'button.ok';
      this.patientModal.show(patient);
    }
  }

  public showModifiedLineModal(patient: IPatient) {
    if (patient && patient.isModified) {
      this.patientModal.headerLabel = 'Attention';
      this.patientModal.bodyText = 'Patient.editor.escape.line.error';
      this.patientModal.footerConfirmText = null;
      this.patientModal.footerCancelText = 'button.ok';
      this.patientModal.show(patient);
    }
  }

  public showDeleteModal(patient: IPatient) {
    if (patient) {
      this.patientModal.headerLabel = 'Suppression';
      this.patientModal.bodyText = 'Etes-vous sûr de vouloir supprimer ce patient ?';
      this.patientModal.footerConfirmText = 'Oui';
      this.patientModal.footerCancelText = 'Non';
      this.patientModal.show(patient);
    }
  }

  public get isPatientModified() {
    return this.selectedPatient && this.selectedPatient.isModified;
  }

  public set isPatientModified(modified: boolean) {
    if (this.selectedPatient) {
      this.patientSource.keepCurrentPage = modified;
      this.selectedPatient.isModified = modified;
    }
  }

  /**
   * This method will be called by AuthGuard service before page is unloaded.
   * SFLAT-451
   */
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.showModifiedPageModal(this.selectedPatient);
    return !this.isPatientModified;
  }

  /**
   * About Editor
   */
  public get isEditorReadOnly(): number {
    if (this.viewSwitch !== PatientViews.Plus) {
      return this.isEditorReset ? readOnlyViews.CANCELED : readOnlyViews.MODIFIED;
    } else {
      return readOnlyViews.EDITABLE;
    }
  }

  public onEditorShown(shown: boolean) {
    this.isEditorShown = shown;
  }

  public onSelectPatient(event) {
    if (this.isPatientModified) {
      if (this.selectedPatient !== event.data || !event.isSelected) {
        this.showModifiedLineModal(this.selectedPatient);
        const patientTable = this.ng2SmartTables.first;
        if (patientTable) {
          let row = patientTable.grid.dataSet.findRowByData(this.selectedPatient);
          if (!row) row = patientTable.grid.dataSet.findRowByData(event.data); // new patient not found
          patientTable.grid.dataSet.selectRow(row);
        }
      }
      return;
    }

    this.selectedPatient = event.isSelected ? event.data : null;
    if (!this.selectedPatient) this.viewSwitch = PatientViews.Edit;

    LocalStorageEx.currentPatientSelected = this.selectedPatient;
  }

  public onCreatePatient() {
    if (this.selectedPatient) {
      const patientTable = this.ng2SmartTables.first;
      if (patientTable) {
        if (this.patientSource.isFirstRowData(this.selectedPatient))
          this.patientSource.selectFirstRow(this.selectedPatient);
        const row = patientTable.grid.dataSet.findRowByData(this.selectedPatient);
        if (row) patientTable.grid.dataSet.selectRow(row); // new patient not found
      }
    }

    this.createdPatient = new Patient() as IPatient;
    if (!this.authService.hasRoleInstant(Roles.selectPatientReferrer)) {
      this.createdPatient.doctorId = parseInt(LocalStorageEx.currentDoctorId, 10);
    }
    this.selectedPatient = this.createdPatient;
    this.isEditorReset = false;
    this.viewSwitch = PatientViews.Plus;

    LocalStorageEx.currentPatientSelected = this.selectedPatient;
  }

  public onModifyPatient(event) {
    if (this.isPatientModified) {
      if (this.selectedPatient !== event.data) {
        this.showModifiedLineModal(this.selectedPatient);
        const patientTable = this.ng2SmartTables.first;
        if (patientTable) {
          let row = patientTable.grid.dataSet.findRowByData(this.selectedPatient);
          if (!row) row = patientTable.grid.dataSet.findRowByData(event.data); // new patient not found
          patientTable.grid.dataSet.selectRow(row);
        }
        return;
      }
    }
    // ADD by TBY to correct a bug, when we click directly on edit there is no flat.
    this.onSelectPatient(event);
    this.selectedPatient = event.data;
    this.isEditorReset = false;
    this.viewSwitch = PatientViews.Plus;

    /**
     * Keep current row selected.
     */
    this.patientSource.selectFirstRow(event.data, true);
    event.isSelected = false;
    if (event._dataSet) {
      event._dataSet.selectRow(event);
    }
  }

  public get isConsultInprogress(): boolean {
    return LocalStorageEx.checkObject(LocalStorageObject.ConsultationInProgress);
  }

  public onDeletePatient(event) {
    if (this.isConsultInprogress) {
      this.app.showWarningPatientModal();
    } else {
      if (this.isPatientModified && this.selectedPatient !== event.data) {
        this.showModifiedLineModal(this.selectedPatient);
        return;
      } else this.showDeleteModal(this.selectedPatient);

      this.selectedPatient = event.data;

      /**
       * Keep current row selected.
       */
      this.patientSource.selectFirstRow(event.data, true);
      event.isSelected = false;
      if (event._dataSet) {
        event._dataSet.selectRow(event);
      }
    }
  }

  public onCustomActionPatient(event) {
    if (event.action === 'delegate') {
      if (this.isPatientModified && this.selectedPatient !== event.data) {
        this.showModifiedLineModal(this.selectedPatient);
        return;
      }
      const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(event.data);

      if (row) {
        this.ng2SmartTables.first.grid.selectRow(row);
      }
      this.selectedPatient = event.data;
      this.patientSource.selectFirstRow(event.data, true);
      this.viewSwitch = PatientViews.Delegate;
      this.isPatientModified = true;
    }
  }

  public rowSelect(event) {
    this.onSelectPatient(event);

    // **** BEGIN HACK
    // The ng2-smart-table has a bug (or at least our use of it does)
    // When we first display our table or change pages, the isSelected is true
    // for the first row but it does not have the selected class on the row.
    // This means the row is not highlighted.
    // This is a hack that allows us to reinitialize the state.
    // If the ng2-smart-table component has been upgraded, try without this!
    this.ng2SmartTables.first.grid.getRows().forEach((row: Row) => {
      if (row.isSelected) {
        row.isSelected = false;
        this.changeDetector.detach();
        this.changeDetector.reattach();
        this.changeDetector.detectChanges();
        row.isSelected = true;
      }
    });
    // **** END HACK
  }

  public onDelegateEnd(event) {
    this.isPatientModified = false;
    this.viewSwitch = PatientViews.Edit;
  }

  public onPatientDeleteConfirmed() {
    // Note: async. TODO: what to do?
    if (LocalStorageEx.checkObject(LocalStorageObject.ConsultationInProgress)) {
      if (LocalStorageEx.currentPatient.patientId === LocalStorageEx.currentPatientSelected.patientId) {
        this.eraseConsultationInProgress();
      }
    }
    this.patientDataService.deletePatient(this.selectedPatient).then(() => {
      this.patientSource.remove(this.selectedPatient);
      this.patientSource.refresh();
      this.selectedPatient = null;
    });
  }

  public onInputPatient(event) {
    this.isPatientModified = true;
  }

  public async onUpdatePatient() {
    const formValidated = this.checkForm();
    if (!formValidated) {
      return;
    }

    this.changeDoctorValidated
      .pipe(
        tap(() => this.validateChangeDoctor.hide()),
        take(1),
        filter((isOk) => isOk),
      )
      .subscribe(async () => {
        if (this.selectedPatient.userId) {
          this.selectedPatient.modified = new Date();
          const patient = await this.patientDataService.updatePatient(this.selectedPatient);
          this.selectedPatient = patient as IPatient;
          this.isPatientModified = false;
          this.viewSwitch = PatientViews.Edit;

          this.selectedPatient.flatName = '';
          this.selectedPatient.currentFlat = null;
          if (this.selectedPatient.flatId) {
            const flat = await this.flatDataService.getFlatById(this.selectedPatient.flatId);
            if (flat) {
              this.selectedPatient.flatName = flat.Name;
              this.selectedPatient.currentFlat = flat;
            }
          }

          const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(this.selectedPatient);
          row.setData(this.selectedPatient);
        } else {
          // create new patient
          const patient = await this.patientDataService.createPatient(this.selectedPatient);
          this.selectedPatient = patient as IPatient;

          this.isPatientModified = false;
          this.patientSource.append(this.selectedPatient).catch((e) => console.error(e));
          this.createdPatient = null;
          this.viewSwitch = PatientViews.Edit;
        }
      });

    if (
      this.authService.hasRoleInstant(Roles.selectPatientReferrer) ||
      this.selectedPatient.doctorId === parseInt(LocalStorageEx.currentDoctorId, 10)
    ) {
      this.changeDoctorValidated.next(true);
    } else {
      this.validateChangeDoctor.show();
    }
  }

  public onCancelPatient() {
    this.isEditorReset = true;
    this.isPatientModified = false;
    this.viewSwitch = PatientViews.Edit;
    this.createdPatient = null;
  }

  public onSearchPatient(event) {
    this.selectedPatient = null;
  }

  public onLangChange() {
    for (const columnName of Object.keys(this.patientDefaultSettings.columns)) {
      this.patientSettings.columns[columnName].title = this.translate.instant(
        this.patientDefaultSettings.columns[columnName].title,
      );
    }
    /* When translated completely, reset smart table. */
    this.patientSettings = Object.assign({}, this.patientSettings);
  }

  private async loadPatients() {
    try {
      const currentPage = this.patientSource ? this.patientSource.currentPage : 1;
      const patients = (await this.patientDataService.getPatientForAdmin().toPromise()) as IPatient[];

      this.setScoreToPatients(patients);
      this.setFlatNameToPatients(patients);
      const dataSource = new PatientDataSource(patients, this.patientDefaultSettings.attr.id, currentPage);
      if (this.selectedPatient) {
        /**
         * When a patient is created, make sure the patient is shown on first line.
         */
        dataSource.setSort([{ field: 'created', direction: 'desc', compare: COMPARE }]);
      }
      this.patientSource = dataSource;

      this.filterState.restoreFilterState(this.patientSource);

      if (this.selectedPatient)
        this.selectedPatient = this.patientSource.rowDatas.find(
          (patient) => patient.patientId === this.selectedPatient.patientId,
        );
    } catch (e) {
      console.error('list error', e);
    }
  }

  /**
   * when user decides to cancel the consultation in progress
   */
  private eraseConsultationInProgress(): void {
    LocalStorageEx.currentPatient = null;
    this.consultationSavingService.cleanLocalConsultation();
  }

  private setScoreToPatients(patients) {
    patients.forEach((patient) => {
      if (patient.fragilityState !== undefined && patient.fragilityState !== null) {
        patient.score = patient.fragilityState.score;
      }
    });
  }

  private setFlatNameToPatients(patients) {
    patients.forEach((patient) => {
      if (patient.currentFlat !== undefined) {
        patient.flatName = patient.currentFlat.Name;
      }
    });
  }

  private checkForm(): boolean {
    const minAge = this.authService.establishment.Config.minimumAge;

    let thereIsNoError: boolean = true;
    let stringError: string;
    if (!this.selectedPatient.lastName) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdatePatientLastName');
    } else if (this.selectedPatient.lastName && this.selectedPatient.lastName.length > 26) {
      thereIsNoError = false;
      stringError = i18nKey('Patient.editor.error.length');
    } else if (!this.selectedPatient.firstName) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdatePatientFirstName');
    } else if (this.selectedPatient.firstName && this.selectedPatient.firstName.length > 26) {
      thereIsNoError = false;
      stringError = i18nKey('Patient.editor.error.length');
    } else if (!this.selectedPatient.gender) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdateGenderName');
    } else if (!this.selectedPatient.handedness) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdateHandedness');
    } else if (!this.selectedPatient.birthdate) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdateBirthDateName');
    } else if (!this.selectedPatient.doctorId) {
      thereIsNoError = false;
      stringError = i18nKey('errorUpdateDoctor');
    } else if (this.selectedPatient.folderNumber && this.selectedPatient.folderNumber.length > 26) {
      thereIsNoError = false;
      stringError = i18nKey('Patient.editor.error.length');
    } else if (minAge && Compute.age(new Date(this.selectedPatient.birthdate)) < minAge) {
      thereIsNoError = false;
      stringError = i18nKey('Patient.editor.error.tooYoung');
    } else if (this.selectedPatient.consent === undefined) {
      thereIsNoError = false;
      stringError = i18nKey('Patient.editor.error.missingconsent');
    }
    if (stringError) {
      this.translate
        .get(stringError)
        .toPromise()
        .then((value) => {
          this.notificationService.pushErrorNotifications(value);
        });
    }

    return thereIsNoError;
  }
}
