import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartPoint } from 'chart.js';

import { NavigationActionsService, NavigationActionLink } from '../../../core';
import { HistoVersusConsultationService } from '../../../consultation';
import { LocalDatePipe } from '../../../time/pipes/localdate.pipe';
import { PatientDataService, QuestionnaryDataService, ConsultationDataService } from '../../../smartflat-data-access';
import { LocalStorageEx } from '../../../../tools/localstorageobject.enum';
import { AppComponent } from '../../../../app.component';
import { Consultation } from 'app/modules/smartflat-data-access';
import { CheckupTitleService } from '../../services/checkup-title.service';

@Component({
  selector: 'checkup-walk-indicator',
  styleUrls: ['indicator.component.scss'],
  templateUrl: './indicator.component.html',
})
export class IndicatorComponent implements OnInit, OnDestroy {
  public score: number = null;

  @ViewChild('weight', { static: true })
  public weightRef: ElementRef;
  @ViewChild('locomotion', { static: true })
  public locomotionRef: ElementRef;
  @ViewChild('physical', { static: true })
  public physicalRef: ElementRef;
  @ViewChild('grip', { static: true })
  public gripRef: ElementRef;
  @ViewChild('energy', { static: true })
  public energyRef: ElementRef;
  public currentConsultation: Consultation;
  public unmeasurable: boolean = false;
  public physicalCriteria: boolean = null;
  public physicalDescription: { date: string; last: string };
  public speedCriteria: boolean = null;
  public speedDescription: { date: string; last: string };
  public strengthCriteria: boolean = null;
  public strengthDescription: { date: string; bmi: string; last: string };
  public vigorCriteria: boolean = null;
  public vigorDescription: { date: string; question7: string; question20: string };
  public weightCriteria: boolean = null;
  public weightDescription: { firstDate: string; lastDate: string; absWeight: string; relWeight: string };
  public actionNext: NavigationActionLink;
  public fragilityDatahistory;
  private unsubscribe = new Subject<void>();

  constructor(
    public app: AppComponent,
    private navigationActionService: NavigationActionsService,
    private histoVerusConsultationService: HistoVersusConsultationService,
    private router: Router,
    private route: ActivatedRoute,
    private localDatePipe: LocalDatePipe,
    private patientDataSerivce: PatientDataService,
    private consultationDataService: ConsultationDataService,
    private checkupTitleService: CheckupTitleService,
    private questionnaryDataService: QuestionnaryDataService,
    private translateService: TranslateService,
    private histoVersusConsultationService: HistoVersusConsultationService,
  ) {}

  public ngOnDestroy(): void {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async ngOnInit() {
    this.checkupTitleService.setCurrentTitle('Indicator');
    this.histoVersusConsultationService.setToReadOnly(true);

    // Allow to launch indicator with parameter for test
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((params: ParamMap) => {
      if (params.has('patientId') && params.has('consultId')) {
        const idConsulation = +params.get('consultId');
        const idPatient = +params.get('patientId');
        this.consultationDataService
          .getConsultationById(idConsulation)
          .then((c) => {
            this.currentConsultation = c;
          })
          .then(() => this.initData(idPatient, false));
      } else {
        // with no parameter use Localstorage
        this.currentConsultation = LocalStorageEx.currentConsultRapport;
        const patientId = LocalStorageEx.currentPatientSelected.patientId;
        this.initData(patientId, true);
      }
    });
  }

  public async initData(patientId: number, inConsultation: boolean) {
    console.log(' this.currentConsultation   ', this.currentConsultation);

    this.fragilityDatahistory = await this.patientDataSerivce.getFragilityDatahistoryByPatientId(patientId.toString());

    this.histoVerusConsultationService.setPageInConsultation('checkup/romberg/indicator');

    if (!inConsultation) {
      this.actionNext = new NavigationActionLink('Suivant', '/PatientResults');
    } else if (LocalStorageEx.cocheRomberg || LocalStorageEx.cocheWalk) {
      this.actionNext = new NavigationActionLink('Suivant', '/choixImpression');
    } else {
      this.actionNext = new NavigationActionLink('Suivant', '/PatientConsult');
    }

    const actionPrevious = new NavigationActionLink('Précédent', '', {
      visible: new BehaviorSubject<boolean>(true),
      active: new BehaviorSubject<boolean>(false),
    });

    const actionGoBackBilan = new NavigationActionLink('bilan.goback', 'checkup', {
      visible: this.histoVerusConsultationService.writeMode,
    });

    let actions = [actionPrevious, actionGoBackBilan, this.actionNext];
    if (LocalStorageEx.histoBoolean === false) {
      actions = [actionPrevious, this.actionNext];
    }
    this.navigationActionService.pushNavigationActions(actions);

    if (this.currentConsultation.fragilityState) {
      this.score = this.currentConsultation.fragilityState.score;
      this.physicalCriteria = this.currentConsultation.fragilityState.physicalCriteria;

      if (this.fragilityDatahistory.physicalCriteria.length > 0) {
        const lastValue = this.fragilityDatahistory.physicalCriteria[
          this.fragilityDatahistory.physicalCriteria.length - 1
        ].value;
        const lastDate = this.fragilityDatahistory.physicalCriteria[
          this.fragilityDatahistory.physicalCriteria.length - 1
        ].date;
        this.physicalDescription = {
          date: this.localDatePipe.transform(lastDate),
          last: (lastValue / 2).toFixed(0),
        };
      }

      this.speedCriteria = this.currentConsultation.fragilityState.speedCriteria;
      if (this.speedCriteria !== null) {
        if (this.fragilityDatahistory.speedCriteria.length > 0) {
          const lastValue = this.fragilityDatahistory.speedCriteria[this.fragilityDatahistory.speedCriteria.length - 1]
            .value;
          this.speedDescription = {
            date: this.localDatePipe.transform(this.currentConsultation.fragilityState.speedDetail.date),
            last: lastValue.toFixed(1),
          };
        }
      }

      this.strengthCriteria = this.currentConsultation.fragilityState.strengthCriteria;
      const strengthDetail = this.currentConsultation.fragilityState.strengthDetail;

      if (strengthDetail !== null) {
        if (this.fragilityDatahistory.strengthCriteria.length > 0) {
          const lastValue = this.fragilityDatahistory.strengthCriteria[
            this.fragilityDatahistory.strengthCriteria.length - 1
          ].value;
          this.strengthDescription = {
            date: this.localDatePipe.transform(strengthDetail.date),
            bmi: strengthDetail.bmi.toFixed(1),
            last: lastValue.toFixed(1),
          };
        }
      }

      this.vigorCriteria = this.currentConsultation.fragilityState.vigorCriteria;
      const virgorDetail = this.currentConsultation.fragilityState.vigorDetail;
      if (virgorDetail) {
        const form = await this.questionnaryDataService.getForm(11, this.translateService.currentLang);
        let question7 = '';
        if (virgorDetail.question7 !== null) {
          question7 = form.listeQuestions[0].listeItems[6].options[virgorDetail.question7].value;
        }
        let question20 = '';
        if (virgorDetail.question20 !== null) {
          question20 = form.listeQuestions[0].listeItems[19].options[virgorDetail.question20].value;
        }

        this.vigorDescription = {
          date: this.localDatePipe.transform(virgorDetail.date),
          question7,
          question20,
        };
      }

      this.weightCriteria = this.currentConsultation.fragilityState.weightCriteria;
      const weightDetail = this.currentConsultation.fragilityState.weightDetail;

      if (weightDetail !== null) {
        const absWeight = weightDetail.lastWeight - weightDetail.firstWeight;
        const relWeight = ((weightDetail.lastWeight - weightDetail.firstWeight) * 100) / weightDetail.firstWeight;
        if (this.fragilityDatahistory.weightCriteria.length > 1) {
          this.weightDescription = {
            firstDate: this.localDatePipe.transform(weightDetail.firstDate),
            lastDate: this.localDatePipe.transform(weightDetail.lastDate),
            // force "+" sign
            absWeight: (absWeight > 0 ? '+' : '') + absWeight.toFixed(1),
            relWeight: (relWeight > 0 ? '+' : '') + relWeight.toFixed(0),
          };
        }
      }
    } else {
      this.unmeasurable = true;
    }

    this.initAllcharts();
  }

  private initAllcharts() {
    this.initChart(this.energyRef, this.fragilityDatahistory.vigorCriteria);
    this.initChart(this.gripRef, this.fragilityDatahistory.strengthCriteria);
    this.initChart(this.weightRef, this.fragilityDatahistory.weightCriteria);
    this.initChart(this.locomotionRef, this.fragilityDatahistory.speedCriteria);
    this.initChart(this.physicalRef, this.fragilityDatahistory.physicalCriteria);
  }

  private initChart(ref: ElementRef, fragilityDatahistory: any[]) {
    if (this.unmeasurable || fragilityDatahistory.length === 0) {
      this.initEmptyChart(ref);
    } else {
      const chartCtx = this.getCanvasContext(ref);
      const data = [];
      const labels = [];
      fragilityDatahistory.forEach((dataHistory: any) => {
        data.push(dataHistory.value);
        /* if (dataHistory['value'] !== null) {
          data.push(dataHistory['value']);
        } else {
          data.push(0);
        }*/
        labels.push(dataHistory.date);
      });

      const chart = new Chart(chartCtx, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: ' ',
              data,
            },
          ],
        },
        options: {
          tooltips: {
            callbacks: {
              label: (tooltipItem, dataPt) => {
                const point = dataPt.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as ChartPoint;
                const date = this.localDatePipe.transform(point.x);
                return date + ' --- ' + (point.y as number).toFixed(1);
              },
            },
          },
          animation: {
            duration: 0,
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                position: 'bottom',
                time: {
                  displayFormats: {
                    millisecond: 'YYYY-MM-DD HH:mm:ss',
                    second: 'YYYY-MM-DD HH:mm:ss',
                    minute: 'YYYY-MM-DD HH:mm:ss',
                    hour: 'YYYY-MM-DD HH:mm:ss',
                    day: 'YYYY-MM-DD HH:mm:ss',
                    week: 'YYYY-MM-DD HH:mm:ss',
                    month: 'YYYY-MM-DD HH:mm:ss',
                    quarter: 'YYYY-MM-DD HH:mm:ss',
                    year: 'YYYY-MM-DD HH:mm:ss',
                  },
                },
                ticks: {
                  minRotation: 45,
                  maxRotation: 45,
                  callback: (label) => {
                    const labelDate = label ? new Date(label) : null;
                    return labelDate ? this.localDatePipe.parse(labelDate, 'd/MM/yyyy') : label;
                  },
                },
              },
            ],
          },
          events: ['mousemove', 'click'],
          legend: {
            display: false,
          },
        },
      });
    }
  }

  private initEmptyChart(ref: ElementRef) {
    const chartCtx = this.getCanvasContext(ref);

    const chart = new Chart(chartCtx, {
      type: 'line',
      data: {
        labels: [''],
        datasets: [],
      },
      options: {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }
  private getCanvasContext(ref: ElementRef): CanvasRenderingContext2D {
    const canvas = ref.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    return ctx;
  }
}
