import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InformationConnexionComponent } from './views/information-connexion/information-connexion.component';

import { ShoesSelectorComponent } from './views/shoes-selector/shoes-selector.component';
import { ShoesRombergSelectorComponent } from 'app/modules/force-platform/views/shoes-selector/shoes-selector-romberg.component';
import { WiiBoardService } from 'app/modules/force-platform/services/wiiboard.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    ShoesSelectorComponent,
    ShoesRombergSelectorComponent,
    InformationConnexionComponent,
  ],
  providers: [
    WiiBoardService,
  ],
  exports: [
    ShoesSelectorComponent,
    ShoesRombergSelectorComponent,
    InformationConnexionComponent,
  ],
})
export class ForcePlatformModule { }
