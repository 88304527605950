import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

import { LocalStorageObject, LocalStorageEx } from '../../../../tools/localstorageobject.enum';

import { IPatient } from '../../patient.interface';
import { HistoVersusConsultationService } from '../../../../modules/consultation/services/histo-versus-consultation.service';
import { PatientDataSource } from '../../patient-data-source';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';

@Component({
  selector: 'patient-sub-header-consult',
  templateUrl: './patient-sub-header-consult.component.html',
})
export class PatientSubHeaderConsultComponent implements OnChanges {
  @Input() public selectedPatient: IPatient;
  @Input() public patientSource: PatientDataSource;
  @Output() public shouldEraseConsultationInProgress = new EventEmitter<boolean>();
  @Output() public search = new EventEmitter<boolean>();

  public initSearch = '';

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private roleCheck: RolesCheckService,
    private histoVersusConsultationService: HistoVersusConsultationService,
  ) {}

  public ngOnChanges(change: SimpleChanges) {
    if (change.patientSource) {
      of(change.patientSource.currentValue as PatientDataSource)
        .pipe(
          filter((o) => !!o),
          delay(100),
        )
        .subscribe((v) => (this.initSearch = v.globalFilter));
    }
  }

  public get patientName(): string {
    let patientName: string = '';
    if (this.selectedPatient) {
      // TODO: we need to have a localizable string here. i.e. something like
      // patientName = format('{lastName} {firstName}' | Translate);
      if (this.selectedPatient.lastName) {
        patientName = this.selectedPatient.lastName;
      }
      if (this.selectedPatient.firstName) {
        if (patientName.length > 0) patientName += ' ';
        patientName += this.selectedPatient.firstName;
      }
    }
    return patientName;
  }

  public get isConsultInprogress(): boolean {
    return LocalStorageEx.checkObject(LocalStorageObject.ConsultationInProgress);
  }

  public get currentPatientName(): string {
    const patient = LocalStorageEx.currentPatient;
    if (!patient) {
      return '';
    }
    return patient.firstName + ' ' + patient.lastName;
  }

  /* Event handlers */
  public onSearchPatient(query: string = '') {
    this.patientSource.globalFilter = query;
    this.search.emit(true);
  }

  /**
   * when user decides to purshase a consultation in progress
   */
  public continueConsult(): void {
    this.histoVersusConsultationService.setToReadOnly(false);
    LocalStorageEx.currentPatientSelected = LocalStorageEx.currentPatient;
    const route: string = '/' + LocalStorageEx.getObject(LocalStorageObject.CurrentPageInConsult);
    this.roleCheck.allMenuDisable = true;
    this.router.navigate([route], { relativeTo: this.route });
  }

  /**
   * when user decides to cancel the consultation in progress
   */
  public eraseConsultationInProgress(): void {
    LocalStorageEx.removeObject(LocalStorageObject.hiddenDiagnostics);
    LocalStorageEx.removeObject(LocalStorageObject.savedDiagnostics);
    this.shouldEraseConsultationInProgress.emit(true);
  }
}
