import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { zip, Observable } from 'rxjs';

import { ServiceDal } from '../service-dal/service-dal';
import { Consultation } from '../model/consultation.model';

import * as DiagnosticTransform from '../transformations/diagnostic.transform';
import { HealthAnswer } from '../model/health-answer.model';
import { HealthForm } from '../model/health-form.model';

import { Diagnostic } from '../service-dal/model/diagnostic';

// constants
import * as Constants from '../constants';
@Injectable()
/**
 * this service manages diagnostics includingthe transformation
 * of data from/to the DAL model
 */
export class DiagnosticDataService {
  constructor(private serviceDal: ServiceDal, private router: Router, private route: ActivatedRoute) {}

  /**
   * This function get all diagnostics for a Patient and send
   * them as health-answer model for web-ui
   * @param patientId id's patient
   */
  public getDiagnosticsForPatient(patientId: number, language: string): Observable<HealthAnswer[]> {
    // Get all consultations for one patient
    const api = this.serviceDal.patientService;

    return zip(
      api.patientPrototypeGetDiagnostics(patientId.toString()),
      this.getDiagnosticForm(language),
      (consultationTab, consultationForm) =>
        DiagnosticTransform.ConsultationWithDiagnosticsToHealthAnswer(consultationTab, consultationForm),
    );
  }

  /**
   * this function gets and sends the diagnostic Form for web-ui
   */
  public async getDiagnosticForm(language: string): Promise<HealthForm> {
    const filter: string = '{"where": {"Type":"diagnostic", "Culture":' + '"' + language + '"' + '}}';
    const api = this.serviceDal.formService;
    return await api
      .formFind(filter)
      .toPromise()
      .then((response) => {
        return response[0].FormData as HealthForm;
      });
  }

  /**
   * Posts a answer of a diagnostic in database for creation of a diagnostic
   * @param form diagnostic created
   */
  public postDiagnosticForm(form: HealthAnswer): Observable<Diagnostic> {
    const apiDiag = this.serviceDal.diagnosticService;
    const diagnostic: Diagnostic = DiagnosticTransform.HealthAnswertoDiagnosticDB(form);

    return apiDiag.diagnosticCreate(diagnostic);
  }

  /**
   * Posts a answer of a diagnostic in database for editing diagnostic
   * @param form diagnostic edited
   */
  public editDiagnosticForm(form: HealthAnswer): Observable<Diagnostic> {
    const apiDiag = this.serviceDal.diagnosticService;
    const diagnostic: Diagnostic = DiagnosticTransform.HealthAnswertoDiagnosticDB(form);

    return apiDiag.diagnosticPrototypePatchAttributes(diagnostic.id.toString(), diagnostic);
  }

  /**
   * Remove a diagnostic in DB
   * @param id_diagnostic id in DB Diagnostic
   * @param id_trouble id in DB Troubles
   * @param id_localization id in DB Localization
   */
  public async removeOneDiagnostic(id_diagnostic: number): Promise<void> {
    const apiDiag = this.serviceDal.diagnosticService;

    const response3 = apiDiag.diagnosticDeleteById(id_diagnostic.toString()).toPromise();
  }
}
