import { OnDestroy, OnInit, Component, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Utilities } from 'app/tools/utilities';

import { PatientViews } from './patientviews.enum';
import { PatientDataService, Answer, HealthAnswer } from 'app/modules/smartflat-data-access';
import { TranslateService } from '@ngx-translate/core';
import { ConsultationSavingImp } from 'app/services/tempSaving/impl/consultationSavingImp.service';
import { ɵbo as Ng2SmartTableComponent } from 'ng2-smart-table';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';
import { IPatient } from 'app/view/patient/patient.interface';
import { COMPARE } from 'app/view/patient/sub/functions';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';
import { Diagnostic } from 'app/modules/smartflat-data-access/src/service-dal';

import { PatientCellValueComponent } from './patient-cellvalue/patient-cellvalue.component';
import { PatientCellApartmentComponent } from './patient-cellapartment/patient-cellapartment.component';
import { PatientCellDateComponent } from './patient-celldate/patient-celldate.component';
import { PatientCellNameComponent } from './patient-cellname/patient-cellname.component';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { readOnlyViews } from './patient.readonlyviews.constant';
import * as DiagnosticTransform from '../../modules/smartflat-data-access/src/transformations/diagnostic.transform';
import { PatientConsultFilterStateServiceService } from './patient-consult-filter-state-service.service';
import { PatientDataSource } from './patient-data-source';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';

interface IHealthAnswers extends HealthAnswer {
  answers: Answer[];
  created: Date;
  modified: Date;
}
@Component({
  templateUrl: './patientconsult.component.html',
})
export class PatientConsultComponent implements OnInit, OnDestroy {
  @ViewChildren(Ng2SmartTableComponent)
  public ng2SmartTables: QueryList<Ng2SmartTableComponent>;

  /* Patients */
  public selectedPatient: IPatient;
  public patientSource: PatientDataSource;
  public patientSettings: any;

  /* allows to use the PatientView enum in template */
  public PatientViews = PatientViews;

  public lastDiagnostic: IHealthAnswers;
  public currentEstablishment: any;

  protected patientDefaultSettings = {
    columns: {
      lastName: {
        title: 'Patient.Lastname',
        filter: false,
        type: 'custom',
        valuePrepareFunction: (cell, row) => this.patientCell(cell, row),
        compareFunction: COMPARE,
        renderComponent: PatientCellNameComponent,
      },
      firstName: {
        title: 'Patient.Firstname',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      birthdate: {
        title: 'Patient.BirthDate',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      lastConsultationDate: {
        title: 'Patient.LastConsultation',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      score: {
        title: 'Patient.Score',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      flatName: {
        title: 'Patient.Apartment',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      connected: {
        title: 'Patient.MainTable.Header.Online',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellApartmentComponent,
      },
      folderNumber: {
        title: 'Patient.FolderNumber',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      position: 'right',
      columnTitle: '',
    },
    edit: {
      editButtonContent: '',
      cancelButtonContent: 'Annuler',
      saveButtonContent: 'Sauvegarder',
      confirmSave: false,
    },
    delete: { deleteButtonContent: '', confirmDelete: true },
    attr: { id: 'patient_table', class: 'table table-striped table-condensed patient_table' },
    noDataMessage: '',
    mode: 'external',
    selectMode: 'single',
    pager: { perPage: 10 },
    hideSubHeader: true,
  };

  protected error: any;

  private unsubscribe = new Subject<void>();

  constructor(
    public router: Router,
    private serviceSave: DiagnosticSavingImp,
    private translate: TranslateService,
    private patientDataService: PatientDataService,
    private consultationSavingService: ConsultationSavingImp,
    private roleCheck: RolesCheckService,
    private filterState: PatientConsultFilterStateServiceService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.patientSettings = Utilities.deepCopy(this.patientDefaultSettings);
  }

  public ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
    this.onLangChange();
    this.loadPatients();

    LocalStorageEx.removeObject(LocalStorageObject.diagnostic_patient);
    LocalStorageEx.lastDiagnosticBackRoutLink = null;
    this.roleCheck.allMenuDisable = false;
  }

  public ngOnDestroy() {
    this.filterState.saveFilterState(this.patientSource);
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public updateDiagnosticStatus() {
    LocalStorageEx.diagnosticStatus = 'edit';
    LocalStorageEx.removeObject(LocalStorageObject.selectedDiagnosticOptionId);
    LocalStorageEx.removeObject(LocalStorageObject.selectedDiagnosticOptionValue);
    LocalStorageEx.removeObject(LocalStorageObject.selectedQuestion);
  }

  public patientCell(cell: any, rowData: any) {
    // if (this.ng2SmartTables.first.isPagerDisplay) {
    if (this.selectedPatient === rowData) {
      console.log('patientCell patient:' + this.selectedPatient.patientId);
      setTimeout(
        function () {
          if (this.patientSource.recoverRowSelected(rowData)) {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              row.isSelected = true;
            }
          } else {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              if (this.patientSource.selectFirstRow(rowData, true)) {
                row.isSelected = true;
              } else {
                row.isSelected = false;
                this.ng2SmartTables.first.grid.dataSet.selectRow(row);
              }
            }
          }
        }.bind(this),
        1,
      );
    }
    // }
    return cell;
  }

  /**
   * This method will be called by AuthGuard service before page is unloaded.
   * SFLAT-451
   */
  public canDeactivate() {
    return true;
  }

  /**
   * About Editor
   */
  public get isEditorReadOnly() {
    return readOnlyViews.MODIFIED;
  }

  public get isEditorEnable() {
    return !!this.selectedPatient;
  }

  public onSelectPatient(event) {
    this.selectedPatient = event.isSelected ? event.data : null;

    LocalStorageEx.currentPatient = this.selectedPatient;
    LocalStorageEx.currentPatientSelected = this.selectedPatient;

    this.lastDiagnostic = null;
    if (this.selectedPatient) {
      this.roleCheck.hasEditSyndrom
        .pipe(
          takeUntil(this.unsubscribe),
          filter((v) => v),
        )
        .subscribe(() => this.findlastDiagnostic(this.selectedPatient.patientId));
    }
  }

  public get isConsultInprogress(): boolean {
    return LocalStorageEx.checkObject(LocalStorageObject.ConsultationInProgress);
  }

  public onSearchPatient(event) {
    this.selectedPatient = null;
  }

  public onLangChange() {
    for (const columnName of Object.keys(this.patientDefaultSettings.columns)) {
      this.patientSettings.columns[columnName].title = this.translate.instant(
        this.patientDefaultSettings.columns[columnName].title,
      );
    }
    /* When translated completely, reset smart table. */
    this.patientSettings = Object.assign({}, this.patientSettings);
  }

  public editlastDiagnostic() {
    if (this.lastDiagnostic) {
      LocalStorageEx.store(LocalStorageObject.diagnostic_patient, this.lastDiagnostic);
      LocalStorageEx.lastDiagnosticBackRoutLink = '/PatientConsult';
    }
  }

  public disableMenu() {
    this.roleCheck.allMenuDisable = true;
  }

  /**
   * when user decides to cancel the consultation in progress
   */
  public eraseConsultationInProgress(): void {
    LocalStorageEx.currentPatient = null;
    this.consultationSavingService.cleanLocalConsultation();
  }

  public rowSelect(event) {
    this.onSelectPatient(event);

    // **** BEGIN HACK
    // The ng2-smart-table has a bug (or at least our use of it does)
    // When we first display our table or change pages, the isSelected is true
    // for the first row but it does not have the selected class on the row.
    // This means the row is not highlighted.
    // This is a hack that allows us to reinitialize the state.
    // If the ng2-smart-table component has been upgraded, try without this!
    this.ng2SmartTables.first.grid.getRows().forEach((row: Row) => {
      if (row.isSelected) {
        row.isSelected = false;
        this.changeDetector.detach();
        this.changeDetector.reattach();
        this.changeDetector.detectChanges();
        row.isSelected = true;
      }
    });
    // **** END HACK
  }

  protected setScoreToPatients(patients: IPatient[]) {
    patients.forEach((patient) => {
      if (patient.fragilityState !== undefined && patient.fragilityState !== null) {
        patient.score = patient.fragilityState.score;
      }
    });
  }

  protected setFlatNameToPatients(patients: IPatient[]) {
    patients.forEach((patient) => {
      if (patient.currentFlat !== undefined) {
        patient.flatName = patient.currentFlat.Name;
      }
    });
  }

  private async loadPatients() {
    try {
      const currentPage = this.patientSource ? this.patientSource.currentPage : 1;
      const patients = (await this.patientDataService.getPatientForCheckup().toPromise()) as IPatient[];
      this.setScoreToPatients(patients);
      this.setFlatNameToPatients(patients);
      const dataSource = new PatientDataSource(patients, this.patientDefaultSettings.attr.id, currentPage);

      this.patientSource = dataSource;

      this.filterState.restoreFilterState(this.patientSource);

      // Disable autoselection of first patient by replacing select method.
      this.ng2SmartTables.first.grid.getDataSet().select = () => {
        this.selectedPatient = null;
        return null;
      };
    } catch (e) {
      this.error = e;
      console.error('list error', e);
    }
  }

  private async findlastDiagnostic(patientId: number) {
    const diagnostics = (await this.serviceSave.getDiagnosticsForPatient(patientId)) as IHealthAnswers[];
    if (diagnostics.length > 0) {
      diagnostics.forEach((healthAnswer) => {
        healthAnswer.answers = [];
        healthAnswer.answerSets.forEach((set) => {
          set.answers.forEach((answer) => {
            healthAnswer.answers.push(answer);
          });
        });

        /**
         * TODO: these codes should be hide in smartflat-data-access
         */
        const diagnostic: Diagnostic = DiagnosticTransform.HealthAnswertoDiagnosticDB(healthAnswer);
        healthAnswer.modified = diagnostic.modified;
        healthAnswer.created = diagnostic.created;
      });

      const healthAnswerSorted = diagnostics.sort((a, b) => {
        return +a.created - +b.created;
      });
      this.lastDiagnostic = healthAnswerSorted.length > 0 ? healthAnswerSorted[healthAnswerSorted.length - 1] : null;
    }
  }
}
