import { Injectable } from '@angular/core';

export interface Logger {
  info: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
}

@Injectable()
export class LoggerService implements Logger {
  public info: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public warn: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public error: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
}
