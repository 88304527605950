import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { SpaceState } from 'app/view/patient/patient-cellapartment/patient-cellappartment.enum';

/**
 * PatientCellApartmentComponent
 * When implementing a custom editor or renderer,
 * remember to add it to the entryComponents and to the declarations part of your module as follows:
 * @NgModule({
 *  imports: [
 *    // ...
 *  ],
 *  entryComponents: [PatientCellApartmentComponent],
 *  declarations: [
 *  // ...
 *  PatientCellApartmentComponent,
 * ],
 * })
 */

@Component({
  selector: 'app-patient-cellapartment',
  templateUrl: './patient-cellapartment.component.html',
  styleUrls: ['./patient-cellapartment.component.css'],
})
export class PatientCellApartmentComponent implements ViewCell, OnInit {
  @Input()
  public value: any;
  @Input()
  public rowData: any;
  public noGateway: SpaceState;

  public ngOnInit() {
    // Just let TSLint happy
  }

  public get connected(): string {
    if (this.rowData.flatName) {
      if (this.rowData.connected === true) {
        return SpaceState[SpaceState.SENDINGDATA];
      } else if (this.rowData.connected === false) {
        return SpaceState[SpaceState.NOTSENDINGDATA];
      } else {
        // TODO correct this state.
        return SpaceState[SpaceState.NOTONLINE];
      }
    }
  }
}
