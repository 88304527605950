<div class="rapportPage container-fluid">
  <!-- WALKING PATIENT REPORT -->
  <div *ngIf="walkAnalysisResult">
    <div class="rapportPage-ct" id="print-section1">
      <div class="row infoPatient">
        <div class="col-md-3 col-xs-12">
          <div class="" id="imgWeight_protocole">
            <img src="{{ this.currentEstablishmentLogo }}" alt="" style="width: 200px; height: 100px" />
          </div>
        </div>
        <div class="col-md-9 col-xs-12 infos-titre">
          <h1>{{ 'Rapport.titre.Marche' | translate }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Patient.Lastname' | translate }}:</label>
              {{ patientIdentity.lastName }}
            </div>
            <div>
              <label>{{ 'Date' | translate }}: </label>{{ dateConsultation }}
            </div>
            <div *ngIf="shoesValueResult">
              <label>{{ 'Rapport.TypeDeChaussure' | translate }}:</label>
              {{ shoesType | localshoes }}
            </div>
          </div>
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label> {{ 'Patient.Firstname' | translate }} : </label>
              {{ patientIdentity.firstName }}
            </div>
            <div>
              <label>{{ 'Checkup.Walk.Summary.distance' | translate }} : </label>
              {{ walkAnalysisResult.distance }}&nbsp;m
            </div>
            <div *ngIf="shoesValueResult">
              <label>{{ 'Checkup.Walk.Summary.WalkingHelpNeeded' | translate }}: </label>
              {{ walkingAid }}
            </div>
          </div>
        </div>
      </div>

      <h2>{{ 'Checkup.Walk.Breadcrumb.Assessment' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <ng-container *ngFor="let assessment of assessments; let i = index">
              <div *ngIf="assessment && assessment.type == 0">
                <label>{{ 'Checkup.Walk.Assessment.Type.Hesitant' | translate }}:</label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
              <div *ngIf="assessment && assessment.type == 2">
                <label> {{ 'Checkup.Walk.Assessment.Type.Irregular' | translate }}: </label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
              <div *ngIf="assessment && assessment.type == 4">
                <label>{{ 'Checkup.Walk.Assessment.Type.Asymetric' | translate }}:</label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
            </ng-container>
          </div>
          <div class="col-md-6 col-xs-12 lablist">
            <ng-container *ngFor="let assessment of assessments; let i = index">
              <div *ngIf="assessment && assessment.type == 1">
                <label>{{ 'Checkup.Walk.Assessment.Type.SmallSteps' | translate }}:</label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
              <div *ngIf="assessment && assessment.type == 3">
                <label> {{ 'Checkup.Walk.Assessment.Type.Balance' | translate }}: </label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
              <div *ngIf="assessment && assessment.type == 5">
                <label> {{ 'Checkup.Walk.Assessment.Type.SwingingArms' | translate }}: </label>
                {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <h2>{{ 'RaportPraticien.mesures' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Checkup.Romberg.Graph.Longitu.SpeedMoy' | translate }}: </label>
              {{ walkAnalysisResult.AvgSpeed | float }}&nbsp;m.s<sup>-1</sup>
            </div>
            <div>
              <label>{{ 'Checkup.walk.cycleVariability' | translate }}:</label>
              {{ walkAnalysisResult.CycleVariability | float }}&nbsp;s
            </div>
            <div>
              <label>{{ 'Checkup.Walk.Summary.WalkSymmetry' | translate }}:</label>
              {{ walkAnalysisResult.SwingMeanStrideDuration | float }}&nbsp;s
            </div>
          </div>
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Checkup.walk.meanStepDuration' | translate }}:</label>
              {{ walkAnalysisResult.MeanStepDuration | float }}&nbsp;s
            </div>
            <div>
              <label>{{ 'Checkup.walk.roliTronc' | translate }}:</label>
              {{ walkAnalysisResult.RoliTronc | float }}&nbsp;rad.s<sup>-1</sup>
            </div>
            <div>
              <label>{{ 'Checkup.Walk.Summary.DoubleStandingDurationRatio' | translate }}:</label>
              {{ walkAnalysisResult.DoubleStance * 100 | float }}&nbsp;%
            </div>
          </div>
        </div>
      </div>

      <h2>{{ 'Checkup.Walk.Breadcrumb.Locogram' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div id="graphesLoco" class="col-lg-12 col-xs-12">
            <img [attr.src]="walkAnalysisResult.graphes.loco | secure | async" />
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>

      <h2>{{ 'Checkup.Walk.Breadcrumb.Rotagram' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div id="graphesRota" class="col-lg-12 col-xs-12">
            <img [attr.src]="walkAnalysisResult.graphes.rota | secure | async" />
          </div>
        </div>
      </div>
      <div class="force-page-break"></div>
      <h2>{{ 'Checkup.Walk.Breadcrumb.Semiogram' | translate }} :</h2>
      <div class="border" style="overflow: visible;">
        <div class="row" style="overflow: visible;">
          <div id="graphesSemio" class="col-md-12 radarChart" *ngIf="radarChartData != null"
            style="width: 800px; overflow: visible;">
            <div style="overflow: visible;">
              <canvas baseChart [datasets]="radarChartData" [options]="optionsRadarChart" [labels]="radarChartLabels"
                legend="true" chartType="radar" [colors]="colorsOverride">
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>
      <h2>{{ 'Checkup.Walk.Breadcrumb.Longitudinal' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="setOfGraph graphic span">
            <div *ngFor="let graph of graphs; let i = index" class="graphic2">
              <graph-timeline [title]="graph.title" [data]="graph" idTitre2="myTitreWalk{{ i }}"
                idLongitudinal2="myLongitudinalWalk{{ i }}" width="350" height="280"></graph-timeline>
            </div>
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>
      <div class="rapportPage-ct" style="display: block">
        <div class="row">
          <div class="col-lg-12 comments">
            <form>
              <label class="titre" for="entretienWalk">{{ 'RapportPraticien.entretien' | translate }}:</label>
              <textarea rows="7" cols="40" id="entretienWalk"></textarea>

              <label class="titre" for="remarquesWalk">{{ 'RaportPraticien.remarques' | translate }}:</label>
              <textarea rows="7" cols="40" id="remarquesWalk"></textarea>

              <label class="titre" for="conclusionWalk">{{ 'RapportPraticien.conclusions' | translate }}: </label>
              <textarea rows="4" cols="40" id="conclusionWalk"></textarea>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="rombergAnalysisResult" class="force-page-break"></div>
  </div>
  <!-- WALKING ROMBERG REPORT -->
  <div *ngIf="rombergAnalysisResult" class="rapportPage-ct">
    <div id="print-section3">
      <div class="row infoPatient">
        <div class="col-md-3 col-xs-12">
          <div>
            <img src="{{ this.currentEstablishmentLogo }}" style="width: 200px; height: 100px" alt="" />
          </div>
        </div>
        <div class="col-md-9 col-xs-12 infos-titre">
          <h1>{{ 'Rapport.titre.Equilibre' | translate }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label> {{ 'Patient.Lastname' | translate }} : </label>
              {{ patientIdentity.lastName }}
            </div>
            <div>
              <label>{{ 'Date' | translate }} : </label>
              {{ dateConsultation }}
            </div>
            <div *ngIf="shoesValueResult">
              <label>{{ 'Rapport.TypeDeChaussure' | translate }} :</label>
              {{ shoesType | localshoes }}
            </div>
          </div>
          <div class="col_lg-6 lablist">
            <div>
              <label>{{ 'Patient.Firstname' | translate }} :</label>
              {{ patientIdentity.firstName }}
            </div>
            <div>
              <label>{{ 'RaportPraticien.dureeTest' | translate }} : </label>
              {{ rombergAnalysisResult.duration }}&nbsp;s
            </div>
            <div *ngIf="shoesValueResult">
              <label>{{ 'stabilityhelp' | translate }}:</label>
              {{ helpStability }}
            </div>
          </div>
        </div>
      </div>

      <h2>{{ 'Checkup.Walk.Breadcrumb.Assessment' | translate | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div *ngFor="let assessment of assessmentsRomberg; let i = index">
            <div class="col-md-6 col-xs-12 lablist">
              <div *ngIf="assessment && assessment.type == 0 && assessment.nonEvaluated == false">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYO' | translate }}:</label>
                {{ assessment.value }}
              </div>
              <div *ngIf="assessment && assessment.type == 2 && assessment.nonEvaluated == false">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityWalk' | translate }}:</label>
                {{ assessment.value }}
              </div>
              <div *ngIf="assessment && assessment.type == 1 && assessment.nonEvaluated == false">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYF' | translate }}:</label>
                {{ assessment.value }}
              </div>
              <div *ngIf="assessment && assessment.type == 3 && assessment.nonEvaluated == false">
                <label>Le sujet a-t-il chuté durant la mesure ? :</label>
                {{ (assessment.value ? 'oui' : 'non') | translate }}
              </div>

              <div *ngIf="assessment && assessment.type == 0 && assessment.nonEvaluated == true">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYO' | translate }}:</label>
                {{ 'NonEvalue' | translate }}
              </div>
              <div *ngIf="assessment && assessment.type == 2 && assessment.nonEvaluated == true">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityWalk' | translate }}:</label>
                {{ 'NonEvalue' | translate }}
              </div>
              <div *ngIf="assessment && assessment.type == 1 && assessment.nonEvaluated == true">
                <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYF' | translate }}:</label>
                {{ 'NonEvalue' | translate }}
              </div>
              <div *ngIf="assessment && assessment.type == 3 && assessment.nonEvaluated == true">
                <label>{{ 'Checkup.Romberg.Assessment.appreciation' | translate }}:</label>
                {{ (assessment.value ? 'oui' : 'non') | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2>{{ 'RaportPraticien.mesures' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Checkup.Romberg.Summary.ScoreYO' | translate }}:</label>
              {{ this.rombergAnalysisResult.ScoreYO | float }}
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SurfaceYO' | translate }}:</label>
              {{ rombergAnalysisResult.SurfaceYO | float }}&nbsp;mm<sup>2</sup>
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SwayDensityYO' | translate }}:</label>
              {{ rombergAnalysisResult.SwayDensityYO | float }}&nbsp;s
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.VarianceLateraleYO' | translate }}: </label>
              {{ rombergAnalysisResult.VarianceLateraleYO | float }}
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SpeedMoyYO' | translate }}:</label>
              {{ rombergAnalysisResult.SpeedMoyYO | float }}&nbsp;mm.s<sup>-1</sup>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Checkup.Romberg.Summary.ScoreYF' | translate }}:</label>
              {{ rombergAnalysisResult.ScoreYF | float }}
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SurfaceYF' | translate }}:</label>
              {{ rombergAnalysisResult.SurfaceYF | float }}&nbsp;mm<sup>2</sup>
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SwayDensityYF' | translate }}:</label>
              {{ rombergAnalysisResult.SwayDensityYF | float }}&nbsp;s
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.VarianceLateraleYF' | translate }}:</label>
              {{ rombergAnalysisResult.VarianceLateraleYF | float }}
            </div>
            <div>
              <label>{{ 'Checkup.Romberg.Summary.SpeedMoyYF' | translate }}:</label>
              {{ rombergAnalysisResult.SpeedMoyYO | float }}&nbsp;mm.s<sup>-1</sup>
            </div>
          </div>
        </div>
      </div>

      <h2>{{ 'Checkup.Romberg.Breadcrumb.StatoKinesiGram' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="col-md-6 col-xs-12 lablist">
            <label>{{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }} :</label>
            <div class="graphstato">
              <img [attr.src]="rombergAnalysisResult.graphes.statok | secure | async" />
            </div>
          </div>
          <div class="col-md-6 col-xs-12 lablist">
            <div>
              <label>{{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }} :</label>
            </div>
            <div class="graphstato">
              <img [attr.src]="rombergAnalysisResult.graphes.stasym | secure | async" />
            </div>
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>
      <h2>{{ 'Checkup.Romberg.Breadcrumb.StabiloGram' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div id="graphesStabilo">
            <img [attr.src]="rombergAnalysisResult.graphes.stabilo | secure | async" />
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>
      <h2>{{ 'Checkup.Walk.Breadcrumb.Longitudinal' | translate }} :</h2>
      <div class="row">
        <div class="border">
          <div class="setOfGraph graphic span">
            <div *ngFor="let graph of graphsRomb; let i = index" class="graphic2">
              <graph-verticalbar [title]="graph.title" [data]="graph" idTitre="myTitre{{ i }}"
                idLongitudinal="myLongitudinal{{ i }}"></graph-verticalbar>
            </div>
          </div>
        </div>
      </div>

      <div class="force-page-break"></div>
      <div id="print-section4">
        <div class="row">
          <div class="col-lg-12 comments">
            <form>
              <label class="titre" for="entretienRomberg">{{ 'RapportPraticien.entretien' | translate }}:</label>
              <textarea rows="7" cols="40" id="entretienRomberg"></textarea>
              <label class="titre" for="remarquesRomberg">{{ 'RaportPraticien.remarques' | translate }}:</label>
              <textarea rows="7" cols="40" id="remarquesRomberg"></textarea>
              <label class="titre" for="conclusionsRomberg">{{ 'RapportPraticien.conclusions' | translate }}: </label>
              <textarea rows="4" cols="40" id="conclusionsRomberg"></textarea>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>