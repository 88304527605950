<div class="section-2" *ngIf="analysisResult">
  <div class="row type-h">
    <div class="col-md-4 col-xs-12">
      <div id="runner" class="col-md-2 col-xs-12 "><img src="assets/images/runner2x.png" alt="" /></div>
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'Rapport.TypeDeChaussure' | translate }}</div>
        <div class="info-2">{{ shoes.shoesType | localshoes }}</div>
      </div>
    </div>

    <div class="col-md-4 col-xs-12">
      <div id="runner" class="col-md-2 col-xs-12 "><img src="assets/images/runner2x.png" alt="" /></div>
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'stabilityhelp' | translate }}</div>
        <div class="info-2">{{ shoes.helpStability}}</div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12"><img src="assets/images/Score2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.Score' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.Score.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div>
        <ngx-gauge class="full-circle" [value]="scoreYO" type="full" append="" max="100" size="300" thick="20"
          cap="round" foregroundColor="rgba(250,16,119,1)" backgroundColor="rgba(182,213,230,1)">
        </ngx-gauge>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div>
        <ngx-gauge class="full-circle" [value]="scoreYF" type="full" append="" max="100" size="300" thick="20"
          cap="round" foregroundColor="rgba(250,16,119,1)" backgroundColor="rgba(182,213,230,1)">
        </ngx-gauge>
      </div>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12"><img src="assets/images/Surface2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.Surface' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.Surface.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12">
      <div id="SurfaceYO" class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div class="surfaceGraph"><canvas #graphSurfaceYO width="250" height="230"></canvas></div>
      <div class="labelSurface">
        <label id="labelSurfaceYOValue" class="value">{{ surfaceYO | float }}</label> <label class="unit">mm²</label>
      </div>
    </div>
    <div class="col-md-3 col-xs-12">
      <div id="SurfaceYF" class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div class="surfaceGraph"><canvas #graphSurfaceYF width="250" height="230"></canvas></div>
      <div class="labelSurface">
        <label id="labelSurfaceYFValue" class="value">{{ surfaceYF | float }}</label> <label class="unit">mm²</label>
      </div>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12"><img src="assets/images/Sway Density2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.SwayDensity' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.SwayDensity.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 swaygraph">
      <div class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div>
        <ngx-gauge class="semi-circle" [value]="swayDensityYO" type="semi" append="s." max="25" size="300" thick="20"
          cap="round" foregroundColor="rgba(250,16,119,1)" backgroundColor="rgba(182,213,230,1)">
          <ngx-gauge-value>{{ swayDensityYO | float }}</ngx-gauge-value>
        </ngx-gauge>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 swaygraph">
      <div class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div>
        <ngx-gauge class="semi-circle" [value]="swayDensityYF" type="semi" append="s." max="25" size="300" thick="20"
          cap="round" foregroundColor="rgba(250,16,119,1)" backgroundColor="rgba(182,213,230,1)">
          <ngx-gauge-value>{{ swayDensityYF | float }}</ngx-gauge-value>
        </ngx-gauge>
      </div>
    </div>
  </div>
  <div class="row mini-section disclaimer">
    <div id="disclaimer" class="col-md-12 col-xs-12">{{ 'Rapport.disclaimer' | translate }}</div>
  </div>
</div>