export class WalkAssessment {
  public Id: number;
  public BilanId: number;
  public HestitantWalk: number;
  public SmallStepWalk: number;
  public IrregularWalk: number;
  public AsymmetricalWalk: number;
  public BalanceLoss: number;
  public SwingingArmsLoss: number;
  public Distance: number;
  public Images: any;
}
