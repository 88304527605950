import { Injectable } from '@angular/core';
import { ServiceDal } from '../service-dal/service-dal';

import { Flat } from 'app/modules/smartflat-data-access/src/service-dal/model/flat';
import * as FlatTransform from '../transformations/flat.transform';
@Injectable()
export class FlatDataService {
  constructor(private serviceDal: ServiceDal) {}
  public getFlatDataEvents(flatId: number, aggregationType: string, startDate: Date, endDate: Date) {
    return this.serviceDal.flatService.flatPrototypeEventsData(flatId.toString(), aggregationType, startDate, endDate);
  }

  public GetAllFreeFlats() {
    const flatApi = this.serviceDal.flatService;
    return flatApi.flatListFreeFlat();
  }

  public async getFlatById(id: number): Promise<Flat> {
    return await this.serviceDal.flatService.flatFindById(id.toString()).toPromise();
  }
}
