import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ShoesService} from '../services/shoes.service';

@Injectable()
@Pipe({
  name: 'localshoes',
})
export class LocalShoesPipe implements PipeTransform {

  constructor(private shoesService: ShoesService) {

  }

  public transform(value: string): string {
    return this.shoesService.getLocale(value);
  }
}
