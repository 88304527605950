<div class="patientsTable">
  <div class="row">
    <div class="col-lg-12">
      <patient-sub-header-consult
        [selectedPatient]="selectedPatient"
        [patientSource]="patientSource"
        (search)="onSearchPatient($event)"
        (shouldEraseConsultationInProgress)="eraseConsultationInProgress()"
      >
      </patient-sub-header-consult>

      <div class="row">
        <div class="patients col-lg-8 no-padding-right">
          <div class="viewcontent">
            <ng2-smart-table
              [settings]="patientSettings"
              [source]="patientSource"
              (rowSelect)="rowSelect($event)"
            ></ng2-smart-table>
          </div>
        </div>

        <div class="patientplus col-lg-4">
          <div class="viewcontent">
            <patient-editor
              *ngIf="isEditorEnable"
              [readonly]="isEditorReadOnly"
              [patientSelect]="selectedPatient"
            ></patient-editor>
          </div>
        </div>

        <div class="buttons-component-table">
          <button
            *ngIf="lastDiagnostic && selectedPatient"
            class="btn btn-primary"
            (click)="updateDiagnosticStatus(); editlastDiagnostic(); disableMenu()"
            [routerLink]="['/diagnostic']"
            [routerLinkActive]="['router-link-active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ 'Modifier dernier syndrome' | translate }}
          </button>

          <button
            *ngIf="selectedPatient"
            class="btn btn-primary"
            type="button"
            (click)="disableMenu()"
            [routerLink]="['/diagnosticList']"
            [routerLinkActive]="['router-link-active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ 'Patient.Start.Consultation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
