import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { NavigationAction } from '../../models/navigation-action.model';

@Injectable()
export class NavigationActionsService {

  private _navigationActions = new Subject<NavigationAction[]>();

  constructor(private router: Router) {
    this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
  }

  public get navigationActions$(): Observable<NavigationAction[]> {
    return this._navigationActions.asObservable();
  }

  public pushNavigationActions(data: NavigationAction[]) {
    console.log(data);
    console.log(this._navigationActions);
    this._navigationActions.next(data);
  }

  private onRouteEvent(routeEvent: Event) {
    if (!(routeEvent instanceof NavigationEnd)) { return; }
    this._navigationActions.next([]);
  }
}
