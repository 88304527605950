import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HealthForm, AnswerSet, HealthAnswer } from '../../../modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../tools/localstorageobject.enum';

@Component({
  selector: 'app-health-form',
  templateUrl: './health-form.component.html',
})

/**
 * this component is used by health-form-init component. It exchanges data with health-form-init
 *  component gets diagnostic form and sends answers.
 * It also sends diagnostic questions sets to health-form-question-set component.
 */
export class HealthFormComponent implements OnInit {
  public backButtonLink: string[];
  public saveButtonLink: string[];

  public backButtonArgs = {};

  @Input()
  public healthForm: HealthForm;
  public valid: boolean = false;
  @Output()
  private healthAnswerOut = new EventEmitter<HealthAnswer>();

  private healthAnswer: HealthAnswer = new HealthAnswer();
  private nbSetValid: number = 0;
  private validSets: Map<string, boolean> = new Map<string, boolean>();

  /**
   * constructor initializes the variable which contains answers
   */
  constructor() {
    if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
      this.valid = true;
    }
    this.healthAnswer.answerSets = new Map<string, AnswerSet>();
  }

  /**
   * initializes all questions like not answered.
   */
  public ngOnInit(): void {
    this.healthForm.listeQuestions.forEach((element) => {
      this.validSets.set(element.id, false);
    });

    this.backButtonLink = ['/diagnosticList'];
    const backRoutLink = LocalStorageEx.lastDiagnosticBackRoutLink;
    console.log('------       ', LocalStorageEx.lastDiagnosticBackRoutLink);
    if (backRoutLink) {
      this.backButtonLink = [backRoutLink];
      this.healthForm.nom = 'HealthForm.Title.LastSyndrome';
    } else {
      if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
        this.healthForm.nom = 'HealthForm.Title.CurrentSyndrome';
      }
    }
  }

  /**
   * This function send the answers to the component health-form-init
   */
  public submit(): void {
    LocalStorageEx.removeObject(LocalStorageObject.diagnosticStatus);
    this.healthAnswerOut.emit(this.healthAnswer);
    this.valid = false;
  }

  /**
   * This functions is called by the html when the component health-form-set gives an answerSet completed.
   * @param AnswerSetOut contains answers from a set of questions
   */
  private addSet(response: { set: AnswerSet; id: string }): void {
    if (response.set !== null) {
      this.valid = true;
      this.validSets.set(response.id, true);

      this.validSets.forEach((element) => {
        this.valid = this.valid && element;
      });
      this.healthAnswer.answerSets.set(response.id, response.set);

      // cas particulier
      if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
        this.valid = true;
      }
    } else {
      this.valid = false;
      this.validSets.set(response.id, false);
    }
  }
}
