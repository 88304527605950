<div class="row_syndrome">
  <h3 class="page-sub-title">{{question.nom}}</h3>
  <form action="" class="questions">
    <div class="form_label" *ngFor="let option of question.options; let i = index">
      <input id="{{ question.id + '_' + i + '_' + option.question }}" type="radio" [checked]="answer.answer && option.id===answer.answer.id"
        name="{{name}}" (click)="setValue(i)" />
      <label for="{{ question.id + '_' + i + '_' + option.question }}">
        <span class="radio">{{option.question}}</span>
      </label>
      <span *ngIf="previousAnswer.answer && option.id===previousAnswer.answer.id
      && previousAnswer.option">
        ~ {{previousAnswer.option.value}}</span>
    </div>
  </form>

  <div *ngIf='plusieursOptions && !diagnosticStatus  '>
    <app-health-form-options [title]=title [options]=this.subOptionsToshow [name]=question.nom (value)="addOption($event)"
      (close)="close()" [preSelectedItem]="subOptionsPreselectedId"></app-health-form-options>
  </div>


</div>
