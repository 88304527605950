/**
 * smartflat-dal
 * Smartflat data access layer.
 *
 * OpenAPI spec version: 4.4.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Box } from '../model/box';
import { Event } from '../model/event';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2004 } from '../model/inlineResponse2004';
import { SensorTor } from '../model/sensorTor';
import { SensorTorTarget } from '../model/sensorTorTarget';
import { SensorTorType } from '../model/sensorTorType';
import { Zone } from '../model/zone';
import { ZoneSensorRelation } from '../model/zoneSensorRelation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SensorTorService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public sensorTorCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public sensorTorCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public sensorTorCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/SensorTors/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorCreate(data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorCreate(data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorCreate(data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorCreate(data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTor>(`${this.basePath}/SensorTors`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorExistsGetSensorTorsidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public sensorTorExistsGetSensorTorsidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public sensorTorExistsGetSensorTorsidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public sensorTorExistsGetSensorTorsidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorExistsGetSensorTorsidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorExistsHeadSensorTorsid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public sensorTorExistsHeadSensorTorsid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public sensorTorExistsHeadSensorTorsid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public sensorTorExistsHeadSensorTorsid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorExistsHeadSensorTorsid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse200>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SensorTor>>;
    public sensorTorFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SensorTor>>>;
    public sensorTorFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SensorTor>>>;
    public sensorTorFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<SensorTor>>(`${this.basePath}/SensorTors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<SensorTor>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<SensorTor>(`${this.basePath}/SensorTors/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPatchOrCreate(data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorPatchOrCreate(data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorPatchOrCreate(data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorPatchOrCreate(data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<SensorTor>(`${this.basePath}/SensorTors`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts Events of SensorTor.
     * 
     * @param id SensorTor id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCountEvents(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public sensorTorPrototypeCountEvents(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public sensorTorPrototypeCountEvents(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public sensorTorPrototypeCountEvents(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCountEvents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts Zones of SensorTor.
     * 
     * @param id SensorTor id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCountZones(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public sensorTorPrototypeCountZones(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public sensorTorPrototypeCountZones(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public sensorTorPrototypeCountZones(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCountZones.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in Events of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCreateEvents(id: string, data?: Event, observe?: 'body', reportProgress?: boolean): Observable<Event>;
    public sensorTorPrototypeCreateEvents(id: string, data?: Event, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Event>>;
    public sensorTorPrototypeCreateEvents(id: string, data?: Event, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Event>>;
    public sensorTorPrototypeCreateEvents(id: string, data?: Event, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCreateEvents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Event>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in SensorTorTarget of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCreateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'body', reportProgress?: boolean): Observable<SensorTorTarget>;
    public sensorTorPrototypeCreateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorTarget>>;
    public sensorTorPrototypeCreateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorTarget>>;
    public sensorTorPrototypeCreateSensorTorTarget(id: string, data?: SensorTorTarget, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCreateSensorTorTarget.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTorTarget>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorTarget`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in SensorTorType of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCreateSensorTorType(id: string, data?: SensorTorType, observe?: 'body', reportProgress?: boolean): Observable<SensorTorType>;
    public sensorTorPrototypeCreateSensorTorType(id: string, data?: SensorTorType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorType>>;
    public sensorTorPrototypeCreateSensorTorType(id: string, data?: SensorTorType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorType>>;
    public sensorTorPrototypeCreateSensorTorType(id: string, data?: SensorTorType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCreateSensorTorType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTorType>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorType`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in Zones of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeCreateZones(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public sensorTorPrototypeCreateZones(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public sensorTorPrototypeCreateZones(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public sensorTorPrototypeCreateZones(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeCreateZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all Events of this model.
     * 
     * @param id SensorTor id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDeleteEvents(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDeleteEvents(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDeleteEvents(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDeleteEvents(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDeleteEvents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all Zones of this model.
     * 
     * @param id SensorTor id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDeleteZones(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDeleteZones(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDeleteZones(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDeleteZones(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDeleteZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for Events.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Events
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDestroyByIdEvents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDestroyByIdEvents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDestroyByIdEvents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDestroyByIdEvents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDestroyByIdEvents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeDestroyByIdEvents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for Zones.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDestroyByIdZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDestroyByIdZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDestroyByIdZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDestroyByIdZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDestroyByIdZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeDestroyByIdZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes SensorTorTarget of this model.
     * 
     * @param id SensorTor id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDestroySensorTorTarget(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDestroySensorTorTarget(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDestroySensorTorTarget(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDestroySensorTorTarget(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDestroySensorTorTarget.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorTarget`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes SensorTorType of this model.
     * 
     * @param id SensorTor id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeDestroySensorTorType(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeDestroySensorTorType(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeDestroySensorTorType(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeDestroySensorTorType(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeDestroySensorTorType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of Zones relation to an item by id.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeExistsZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public sensorTorPrototypeExistsZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public sensorTorPrototypeExistsZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public sensorTorPrototypeExistsZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeExistsZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeExistsZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for Events.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Events
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeFindByIdEvents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Event>;
    public sensorTorPrototypeFindByIdEvents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Event>>;
    public sensorTorPrototypeFindByIdEvents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Event>>;
    public sensorTorPrototypeFindByIdEvents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeFindByIdEvents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeFindByIdEvents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Event>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for Zones.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeFindByIdZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public sensorTorPrototypeFindByIdZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public sensorTorPrototypeFindByIdZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public sensorTorPrototypeFindByIdZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeFindByIdZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeFindByIdZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation Box.
     * 
     * @param id SensorTor id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeGetBox(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Box>;
    public sensorTorPrototypeGetBox(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Box>>;
    public sensorTorPrototypeGetBox(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Box>>;
    public sensorTorPrototypeGetBox(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeGetBox.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Box>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Box`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries Events of SensorTor.
     * 
     * @param id SensorTor id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeGetEvents(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Event>>;
    public sensorTorPrototypeGetEvents(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Event>>>;
    public sensorTorPrototypeGetEvents(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Event>>>;
    public sensorTorPrototypeGetEvents(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeGetEvents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Event>>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches hasOne relation SensorTorTarget.
     * 
     * @param id SensorTor id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeGetSensorTorTarget(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<SensorTorTarget>;
    public sensorTorPrototypeGetSensorTorTarget(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorTarget>>;
    public sensorTorPrototypeGetSensorTorTarget(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorTarget>>;
    public sensorTorPrototypeGetSensorTorTarget(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeGetSensorTorTarget.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<SensorTorTarget>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorTarget`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches hasOne relation SensorTorType.
     * 
     * @param id SensorTor id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeGetSensorTorType(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<SensorTorType>;
    public sensorTorPrototypeGetSensorTorType(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorType>>;
    public sensorTorPrototypeGetSensorTorType(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorType>>;
    public sensorTorPrototypeGetSensorTorType(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeGetSensorTorType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<SensorTorType>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries Zones of SensorTor.
     * 
     * @param id SensorTor id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeGetZones(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public sensorTorPrototypeGetZones(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public sensorTorPrototypeGetZones(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public sensorTorPrototypeGetZones(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeGetZones.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for Zones.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeLinkZones(id: string, fk: string, data?: ZoneSensorRelation, observe?: 'body', reportProgress?: boolean): Observable<ZoneSensorRelation>;
    public sensorTorPrototypeLinkZones(id: string, fk: string, data?: ZoneSensorRelation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZoneSensorRelation>>;
    public sensorTorPrototypeLinkZones(id: string, fk: string, data?: ZoneSensorRelation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZoneSensorRelation>>;
    public sensorTorPrototypeLinkZones(id: string, fk: string, data?: ZoneSensorRelation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeLinkZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeLinkZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ZoneSensorRelation>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id SensorTor id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypePatchAttributes(id: string, data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorPrototypePatchAttributes(id: string, data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorPrototypePatchAttributes(id: string, data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorPrototypePatchAttributes(id: string, data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<SensorTor>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the Zones relation to an item by id.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeUnlinkZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sensorTorPrototypeUnlinkZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sensorTorPrototypeUnlinkZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sensorTorPrototypeUnlinkZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeUnlinkZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeUnlinkZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for Events.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Events
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeUpdateByIdEvents(id: string, fk: string, data?: Event, observe?: 'body', reportProgress?: boolean): Observable<Event>;
    public sensorTorPrototypeUpdateByIdEvents(id: string, fk: string, data?: Event, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Event>>;
    public sensorTorPrototypeUpdateByIdEvents(id: string, fk: string, data?: Event, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Event>>;
    public sensorTorPrototypeUpdateByIdEvents(id: string, fk: string, data?: Event, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeUpdateByIdEvents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeUpdateByIdEvents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Event>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Events/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for Zones.
     * 
     * @param id SensorTor id
     * @param fk Foreign key for Zones
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeUpdateByIdZones(id: string, fk: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public sensorTorPrototypeUpdateByIdZones(id: string, fk: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public sensorTorPrototypeUpdateByIdZones(id: string, fk: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public sensorTorPrototypeUpdateByIdZones(id: string, fk: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeUpdateByIdZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling sensorTorPrototypeUpdateByIdZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/Zones/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update SensorTorTarget of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeUpdateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'body', reportProgress?: boolean): Observable<SensorTorTarget>;
    public sensorTorPrototypeUpdateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorTarget>>;
    public sensorTorPrototypeUpdateSensorTorTarget(id: string, data?: SensorTorTarget, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorTarget>>;
    public sensorTorPrototypeUpdateSensorTorTarget(id: string, data?: SensorTorTarget, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeUpdateSensorTorTarget.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SensorTorTarget>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorTarget`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update SensorTorType of this model.
     * 
     * @param id SensorTor id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorPrototypeUpdateSensorTorType(id: string, data?: SensorTorType, observe?: 'body', reportProgress?: boolean): Observable<SensorTorType>;
    public sensorTorPrototypeUpdateSensorTorType(id: string, data?: SensorTorType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTorType>>;
    public sensorTorPrototypeUpdateSensorTorType(id: string, data?: SensorTorType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTorType>>;
    public sensorTorPrototypeUpdateSensorTorType(id: string, data?: SensorTorType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorPrototypeUpdateSensorTorType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SensorTorType>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/SensorTorType`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorReplaceByIdPostSensorTorsidReplace(id: string, data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorReplaceByIdPostSensorTorsidReplace(id: string, data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorReplaceByIdPostSensorTorsidReplace(id: string, data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorReplaceByIdPostSensorTorsidReplace(id: string, data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorReplaceByIdPostSensorTorsidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTor>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorReplaceByIdPutSensorTorsid(id: string, data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorReplaceByIdPutSensorTorsid(id: string, data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorReplaceByIdPutSensorTorsid(id: string, data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorReplaceByIdPutSensorTorsid(id: string, data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sensorTorReplaceByIdPutSensorTorsid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SensorTor>(`${this.basePath}/SensorTors/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorReplaceOrCreatePostSensorTorsReplaceOrCreate(data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorReplaceOrCreatePostSensorTorsReplaceOrCreate(data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorReplaceOrCreatePostSensorTorsReplaceOrCreate(data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorReplaceOrCreatePostSensorTorsReplaceOrCreate(data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTor>(`${this.basePath}/SensorTors/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorReplaceOrCreatePutSensorTors(data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorReplaceOrCreatePutSensorTors(data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorReplaceOrCreatePutSensorTors(data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorReplaceOrCreatePutSensorTors(data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SensorTor>(`${this.basePath}/SensorTors`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorUpdateAll(where?: string, data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2004>;
    public sensorTorUpdateAll(where?: string, data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2004>>;
    public sensorTorUpdateAll(where?: string, data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2004>>;
    public sensorTorUpdateAll(where?: string, data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2004>(`${this.basePath}/SensorTors/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorTorUpsertWithWhere(where?: string, data?: SensorTor, observe?: 'body', reportProgress?: boolean): Observable<SensorTor>;
    public sensorTorUpsertWithWhere(where?: string, data?: SensorTor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorTor>>;
    public sensorTorUpsertWithWhere(where?: string, data?: SensorTor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorTor>>;
    public sensorTorUpsertWithWhere(where?: string, data?: SensorTor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SensorTor>(`${this.basePath}/SensorTors/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
