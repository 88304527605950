<div class="help-page">
  <div class="row">
    <div class="col-lg-12">
      <div class="page-title">{{ 'aide' | translate }}</div>

      <div class="contenu">
        <div class="version" (click)="showVersionModal()">{{ 'App.Template.Version' | translate: this }}</div>
        <div class="version" (click)="showCguModal()">{{ 'App.Template.CGU' | translate: this }}</div>
        <div class="version" (click)="ceMark.show()">{{ 'CEmessage.link' | translate: this }}</div>
      </div>
    </div>
  </div>
</div>

<core-modal name="ceMark" #ceMark>
  <div class="modal-header">
    <img (click)="ceMark.hide()" src="../assets/images/close.png" alt="X" class="close-button" />
    <label>{{ 'CEmessage.Title' | translate }}</label>
  </div>
  <div class="modal-body" style="text-align: left !important">
    <img class="logoCE" src="assets/images/logoCE.svg" alt="Logo conformite CE" /><br />
    <span [innerHTML]="'CEmessage.Content' | translate"></span>
  </div>
</core-modal>
