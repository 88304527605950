/**
 *  Object representing a grip acquisition
 */
export class Grip {
    public Id: number;
    public Horodate: Date;
    public LeftHandV1: number;
    public LeftHandV2: number;
    public LeftHandV3: number;
    public RightHandV1: number;
    public RightHandV2: number;
    public RightHandV3: number;
    public BilanId: number;
}
