import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ServiceDal } from '../service-dal/service-dal';

import { QuestionnaryAnswerQuestion } from '../model/questionnary-answer-question.model';

import { Questionnaire } from '../service-dal/model/questionnaire';
import { Question as DalQuestion } from '../service-dal/model/question';
import { LastQuestionnaireForPatient } from '../service-dal/model/lastQuestionnaireForPatient';

import * as QuestionnaryTransform from '../transformations/questionnary.transform';
import { Questionnary } from '../model/questionnary.model';

import { QuestionnaryAnswer } from '../model/questionnary-answer.model';
import { QuestionnaryList } from '../model/questionnary-list.model';
import { LastQuestionnary } from '../model/last-questionnary.model';

@Injectable()
/**
 * this service manages questionnary answers, including the transformation of data to/from the DAL model.
 */
export class QuestionnaryDataService {
  constructor(private serviceDal: ServiceDal) {}

  /**
   * User create a 'questionnaire' in DB (when he selects it in questionnary-list component)
   * @param questionnary the questionnary selected
   */
  public async creationQuestionnaire(questionnary: QuestionnaryAnswer): Promise<QuestionnaryAnswer> {
    let dalQuestionnaire = QuestionnaryTransform.questionnaryToDalQuestionnary(questionnary);
    const api = this.serviceDal.questionnaryService;
    dalQuestionnaire = await api.questionnaireCreate(dalQuestionnaire).toPromise();
    return QuestionnaryTransform.dalQuestionnaryToQuestionnary(dalQuestionnaire);
  }

  /**
   * When the users unselects a questionnary during a consultation
   * @param id unselected questionnary's id
   */
  public async removeQuestionnaire(id: number): Promise<void> {
    const filter: string = '{"where": {"QuestionnaireId":' + id + '}}';
    const apiQuestion = this.serviceDal.questionService;
    const apiQuestionnary = this.serviceDal.questionnaryService;
    const res = await apiQuestion.questionFind(filter).toPromise();
    for (const question of res) {
      console.log(question.Id);
      await apiQuestion.questionDeleteById(question.Id.toString()).toPromise();
    }
    await apiQuestionnary.questionnaireDeleteById(id.toString()).toPromise();
  }

  /**
   * When a user give a answer for a question in a questionnary (questionnary component)
   * @param question the answer
   */
  public async createQuestion(question: QuestionnaryAnswerQuestion): Promise<QuestionnaryAnswerQuestion> {
    let dalQuestion: DalQuestion = QuestionnaryTransform.questionToDalQuestion(question);
    const api = this.serviceDal.questionService;
    // Why this ?
    /* if (question.Id !== null && question.Id !== undefined) {
            dalQuestion = await api.questionPrototypePatchAttributes(question.Id.toString(), dalQuestion).toPromise();
            const result = QuestionnaryTransform.dalQuestionToQueston(dalQuestion);
            return result;
        } else {
            dalQuestion = await api.questionCreate(dalQuestion).toPromise();
            const result = QuestionnaryTransform.dalQuestionToQueston(dalQuestion);
            return result;
        }*/
    dalQuestion.Id = undefined;
    dalQuestion = await api.questionCreate(dalQuestion).toPromise();
    const result = QuestionnaryTransform.dalQuestionToQueston(dalQuestion);
    return result;
  }

  /**
   * In case of modication of a questionnary, this functions finds the questionnary filled
   * @param bilan the id of the bilan (in a consultation)
   */
  public async getQuestionnaries(bilan: number): Promise<QuestionnaryAnswer[]> {
    const filterIdBilan: string = '{"where": {"BilanId":' + bilan + '}}';
    const api = this.serviceDal.questionnaryService;
    const res: QuestionnaryAnswer[] = [];
    const result = await api.questionnaireFind(filterIdBilan).toPromise();
    result.forEach((questionnaireDal) => {
      res.push(QuestionnaryTransform.dalQuestionnaryToQuestionnary(questionnaireDal));
    });
    return res;
  }

  /**
   * In case of modication of a questionnary, this functions finds the questionnary filled
   * @param bilan the id of the bilan (in a consultation)
   */
  public getQuestionnary(questionnaryId: number): Observable<QuestionnaryAnswer> {
    const api = this.serviceDal.questionnaryService;

    return api
      .questionnaireFindById(questionnaryId.toString())
      .pipe(map((questionnaireDal) => QuestionnaryTransform.dalQuestionnaryToQuestionnary(questionnaireDal)));
  }

  /**
   * Get all answer from a questionnary in a consultation
   * @param idQuestionnary questionnary's id
   */
  public async getQuestions(idQuestionnary: number): Promise<QuestionnaryAnswerQuestion[]> {
    const filterIdQuestionnary: string = '{"where": {"QuestionnaireId":' + idQuestionnary + '}}';
    const api = this.serviceDal.questionService;
    const res: QuestionnaryAnswerQuestion[] = [];
    const resultat = await api.questionFind(filterIdQuestionnary).toPromise();
    resultat.forEach((questionDal) => {
      res.push(QuestionnaryTransform.dalQuestionToQueston(questionDal));
    });
    return res;
  }

  public async getForm(idForm: number, language: string): Promise<Questionnary> {
    const filter: string = '{"where": {"Type":"questionnaire", "Culture":"' + language + '", "FormId":' + idForm + '}}';
    const api = this.serviceDal.formService;
    return await api
      .formFind(filter)
      .toPromise()
      .then((response) => {
        const rep = response[0].FormData as Questionnary;
        let count: number = 0;
        rep.listeQuestions.forEach((set) => {
          set.listeItems.forEach((element) => {
            element.position = count;
            count++;
          });
        });
        return rep;
      });
  }

  public async getQuestionnaryList(language: string): Promise<QuestionnaryList[]> {
    const api = this.serviceDal.formService;

    return await api.formFormsList(language).toPromise();
  }

  public async deleteQuestion(id: number): Promise<void> {
    const apiQuestion = this.serviceDal.questionService;
    await apiQuestion.questionDeleteById(id.toString()).toPromise();
  }

  public async getLastQuestionnariesByPatient(idPatient: number): Promise<Map<number, LastQuestionnary>> {
    const questionnaires: LastQuestionnaireForPatient[] = await this.serviceDal.patientService
      .patientPrototypeLastQuestionnaires(idPatient.toString())
      .toPromise();
    const lastQuestionnary: Map<number, LastQuestionnary> = QuestionnaryTransform.dalLastQuestionnaryToLastQuestionnary(
      questionnaires,
    );
    return lastQuestionnary;
  }
}
