<div id="print-section">
  <!-- WALKING PATIENT REPORT -->
  <div *ngIf="(typeRapport == 'Walk' || !typeRapport) && walkAnalysisResult">
    <div class="rapportPage container-fluid">
      <div id="print-section-Walk">
        <app-rapport-header [dateConsultation]="dateConsultation" [patientIdentity]="patientIdentity"
          [establishmentLogo]="currentEstablishmentLogo" [establishmentName]="currentEstablishmentName" [title]="'Rapport.titre.Marche' | translate"
          class="row"></app-rapport-header>

        <hr />
        <app-rapport-walk-part [shoes]="shoesValueResult2" [analysisResult]="walkAnalysisResult" [agePatient]="agePatient"></app-rapport-walk-part>
      </div>
    </div>
  </div>
  <!-- WALKING ROMBERG REPORT -->
  <div *ngIf="(typeRapport == 'Romberg' || !typeRapport) && rombergAnalysisResult">
    <div class="rapportPage container-fluid">
      <div id="print-section-Romberg">
        <app-rapport-header [dateConsultation]="dateConsultation" [patientIdentity]="patientIdentity"
          [establishmentLogo]="currentEstablishmentLogo" [establishmentName]="currentEstablishmentName" [title]="'Rapport.titre.Equilibre' | translate"
          class="row"></app-rapport-header>
        <hr />
        <app-rapport-romberg-part [shoes]="shoesValueResult" [analysisResult]="rombergAnalysisResult"></app-rapport-romberg-part>
      </div>
    </div>
  </div>
  <div class="cleareanceformenu"></div>
</div>
