import { AnalysisResultsSemioInfo } from '../models/analysis-results-semioInfo.model';

export class AnalysisResults {
  public AvgSpeed: number;
  public CycleVariability: number;
  public DoubleStance: number;
  public MeanStepDuration: number;
  public RoliTronc: number;
  public SwingMeanStrideDuration: number;
  public models: string[] = [];
  public distance: number;
  public graphes: {
    loco: string;
    rota: string;
    semioAll: string;
    semio0_17: string;
    semio18_39: string;
    semio40_59: string;
    semio60_79: string;
    semio80_200: string;
    semioInfo?: AnalysisResultsSemioInfo;
  };
}
