export type RollbackFunction = () => Promise<{}>;

export class Rollback {
    private rollbacks: RollbackFunction[];

    constructor() {
        this.rollbacks = [];
    }

    public push(rollbackFunction: RollbackFunction) {
        this.rollbacks.push(rollbackFunction);
    }

    public async rollback(): Promise<void> {
        // Do all rollbacks. Note that these may fail as well (a failure is likely to be related to
        // a communications error which will affect all communications)
        // Note: do last-first
        while (this.rollbacks.length > 0) {
            const rollback = this.rollbacks.pop();
            try {
                await rollback();
            } catch (rollbackError) {
                console.log('error in rollback');
                console.log(rollbackError);
                // log but continue to do others?
            }
        }
    }
}
