<div class="navBar" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{ exact: true }">
  <h1><!-- <img class="logo" src="assets/images/logo_smartflat.svg" alt="Smartflat"> --></h1>

  <div
    *ngFor="let button of buttons"
    class="navBar-item-container"
    [routerLink]="button.routerLink"
    [ngClass]="{ desactivated: isDesactivated(button) | async }"
  >
    <img
      class="img-responsive {{ button.class }}"
      [class.selected]="button === selectedItem"
      src="{{ button.imgSrcUrl }}"
    />
    <!-- <div class="navBar-item-label">{{button.title | translate}}</div> -->
  </div>

  <img (click)="logout()" class="deconnexion" src="assets/images/menu6.svg" />
</div>
