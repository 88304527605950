import { Gender } from '../model/gender.enum';
import { Handedness } from '../model/handedness.enum';

import { Patient } from '../model/patient.model';
import * as Constants from '../constants';
import * as tools from 'app/tools/date.tools';
import { Patient as DalPatient } from '../service-dal/model/patient';
import { Identity as DalIdentity } from '../service-dal/model/identity';
import { PatientDoctorRelation as DalPatientDoctorRelation } from '../service-dal/model/patientDoctorRelation';
import { upperCaseFirst } from './upper-case-first';
import { FragilityState } from '../service-dal';

// The strings for male/female are single-character strings
const dalGenderMale = 'M';
const dalGenderFemale = 'F';
const dalGenderUnknown = 'U';
const dalGenderOther = 'O';

// the database has strings in French for left/right/ambidextrous (gauchier/droitier/bilatéral)
// they are also 1-character strings
const dalHandednessLeft = 'G';
const dalHandednessRight = 'D';
const dalHandednessAmbidextrous = 'B'; // TODO ?
const dalHandednessUnknown = '';

export function genderToDalGender(gender: Gender): string {
  switch (gender) {
    case Gender.Unknown:
      return dalGenderUnknown;
    case Gender.Male:
      return dalGenderMale;
    case Gender.Female:
      return dalGenderFemale;
    case Gender.Other:
      return dalGenderOther;
    default:
      throw new RangeError('gender not appropriate value');
  }
}

export function dalGenderToGender(dalGender: string): Gender {
  switch (dalGender) {
    case dalGenderUnknown:
      return Gender.Unknown;
    case dalGenderMale:
      return Gender.Male;
    case dalGenderFemale:
      return Gender.Female;
    case dalGenderOther:
      return Gender.Other;
    default:
      throw new RangeError('gender returned from service-dal not appropriate value: ' + dalGender);
  }
}

export function handednessToDalHandedness(handedness: Handedness): string {
  switch (handedness) {
    case Handedness.Unknown:
      return dalHandednessUnknown;
    case Handedness.Left:
      return dalHandednessLeft;
    case Handedness.Right:
      return dalHandednessRight;
    case Handedness.Ambidextrous:
      return dalHandednessAmbidextrous;
    default:
      throw new RangeError('handedness not appropriate value');
  }
}

export function dalHandednessToHandedness(dalHandedness: string): Handedness {
  switch (dalHandedness) {
    case dalHandednessUnknown:
      return Handedness.Unknown;
    case dalHandednessLeft:
      return Handedness.Left;
    case dalHandednessRight:
      return Handedness.Right;
    case dalHandednessAmbidextrous:
      return Handedness.Ambidextrous;
    default:
      throw new RangeError('handedness returned from service-dal not appropriate value: ' + dalHandedness);
  }
}

// code from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/8809472#8809472
// Note: there are faster algorithms (see link), but I think this is the easiest to understand
//       if speed is needed, choose another algorithm
// tslint:disable:no-bitwise
export function generateUUID(): string {
  // Public Domain/MIT
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  // note: removed dashes as we have a limit of 32 characters on user id
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

// name is stored as a single string. Our UI has first and last names, so we need to split and join
// the name when storing/retreiving the data
function splitName(name: string): { firstName: string; lastName: string } {
  const names = name.split(' ');
  let firstName: string = '';
  let lastName: string = '';
  if (names.length < 2) {
    lastName = name;
  } else if (names.length === 2) {
    firstName = names[0];
    lastName = names[1];
  } else {
    lastName = names[names.length - 1];
    firstName = names.slice(0, names.length - 2).join(' ');
  }

  return { firstName, lastName };
}

function joinName(firstName: string, lastName: string) {
  return firstName + ' ' + lastName;
}

export function dalPatientUserToPatient(patient: DalPatient, user: DalIdentity): Patient {
  const result = new Patient();
  result.patientId = patient.Id;
  result.userId = user.id;
  result.firstName = user.name;
  result.lastName = user.lastName;
  result.gender = dalGenderToGender(patient.Gender);
  result.birthdate = patient.BirthDate;
  // the swagger integration doesn't correctly convert to date object
  if (typeof result.birthdate === 'string') {
    result.birthdate = new Date(result.birthdate);
  }
  result.handedness = dalHandednessToHandedness(patient.Lateralization);
  result.created = patient.Created;
  result.modified = patient.Modified;
  result.folderNumber = patient.FolderNumber;
  result.hasHomonyme = patient.HasHomonyme;
  result.phoneNumber = patient.phoneNumber;
  result.address = patient.address;
  result.email = patient.email;
  result.fragilityState = patient.fragilityState;
  // Swagger of smartflat do not include this fields
  // We must make an ugly cast to any
  result.lastConsultation = (patient as any).lastConsultation;
  result.lastConsultationDate = (patient as any).lastConsultationDate;

  result.currentFlat = (patient as any).currentFlat;
  if (result.currentFlat) {
    result.flatName = result.currentFlat.Name;
    result.flatId = result.currentFlat.Id;
  }

  result.lastMeasuredWeight = (patient as any).lastMeasuredWeight;
  result.connected = (patient as any).connected;
  result.doctorId = (patient as any).ownerId;
  result.consent = patient.consent;
  return result;
}

export function patientToDalUserPatient(patient: Patient): { dalPatient: DalPatient; dalUsers: DalIdentity } {
  // Note: for users we create dummy data. This isn't used but
  // we need to fill it out anyway (a user is necessary in order to create a patient)

  const dalPatient: DalPatient = {
    Id: patient.patientId,
    BirthDate: tools.toUTC(patient.birthdate),
    Gender: genderToDalGender(patient.gender),
    Lateralization: handednessToDalHandedness(patient.handedness),
    UserId: patient.userId,
    PatientId: patient.guid ? patient.guid : generateUUID(),
    Created: patient.created,
    Modified: patient.modified,
    FolderNumber: patient.folderNumber,
    phoneNumber: patient.phoneNumber,
    address: patient.address,
    email: patient.email,
    consent: patient.consent,
  };
  // HACK because the swagger do not contain the necessary fields.
  (dalPatient as any).flatId = patient.flatId;
  (dalPatient as any).ownerId = patient.doctorId;
  const dalUsers: DalIdentity = {
    id: patient.userId,
    email: dalPatient.PatientId + '@example.com', // Note: unique constraint
    role: 'Patient',
    name: upperCaseFirst(patient.firstName),
    lastName: patient.lastName.toLocaleUpperCase(),
    username: dalPatient.PatientId + '@example.com', // Note: unique constraint
  };

  return { dalPatient, dalUsers };
}

export function patientToDalRelationPatientDoctor(patient: Patient): DalPatientDoctorRelation {
  const dalDoctor: DalPatientDoctorRelation = {
    Id: patient.relationDoctorId,
    PatientId: patient.patientId,
    DoctorId: patient.doctorId,
    type: 'Owner',
  };

  return dalDoctor;
}

export function dalRelationsDoctorsToPatient(
  lstRelations: DalPatientDoctorRelation[],
  patientId: number,
): DalPatientDoctorRelation {
  const dalPatientDoctorRelation: DalPatientDoctorRelation = lstRelations.find((x) => x.PatientId === patientId);
  return dalPatientDoctorRelation;
}
