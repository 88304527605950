import { Injectable, EventEmitter, Optional, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Event, NavigationEnd } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

import { Breadcrumb } from '../models/breadcrumb';
import { LoggerService } from 'app/services/logger/logger.service';

@Injectable()
export class BreadcrumbService {
  public breadcrumbChanged$: Observable<Breadcrumb[]>;
  private breadcrumbChanged: BehaviorSubject<Breadcrumb[]>;

  private breadcrumbs = new Array<Breadcrumb>();
  private readonly defaultRouteParameterName = 'breadcrumb';
  private routeParameterName: string;

  constructor(
    private logger: LoggerService,
    private router: Router,
    @Optional() @Inject('BreadcrumbSeriveParamName') private parameterName?: string,
  ) {
    this.routeParameterName = parameterName || this.defaultRouteParameterName;
    this.logger.info('BreadcrumbService: ctor(). Name:', this.routeParameterName);
    this.buildCurrentBreadcrumb();
    this.breadcrumbChanged = new BehaviorSubject(this.breadcrumbs);
    this.breadcrumbChanged$ = this.breadcrumbChanged.asObservable();
    this.router.events.subscribe((routeEvent) => {
      this.onRouteEvent(routeEvent);
    });
  }

  public changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
    const rootUrl = this.createRootUrl(route);
    const breadcrumb = this.breadcrumbs.find((bc) => {
      return bc.url === rootUrl;
    });

    if (!breadcrumb) {
      this.logger.info('BreadcrumbService: changeBreadcrumb(): no breadcrumb found');
      return;
    }

    breadcrumb.displayName = name;
    this.logger.info('BreadcrumbService: changeBreadcrumb(): breadcrumb found:', breadcrumb);

    this.breadcrumbChanged.next(this.breadcrumbs);
  }

  private onRouteEvent(routeEvent: Event) {
    if (!(routeEvent instanceof NavigationEnd)) {
      return;
    }

    this.buildCurrentBreadcrumb();

    this.breadcrumbChanged.next(this.breadcrumbs);
  }

  private buildCurrentBreadcrumb(): void {
    let route = this.router.routerState.root.snapshot;
    let url = '';

    const breadcrumbs: Breadcrumb[] = [];

    while (route.firstChild !== null) {
      route = route.firstChild;

      if (route.routeConfig === null) {
        continue;
      }
      if (!route.routeConfig.path) {
        continue;
      }

      url += `/${this.createUrl(route)}`;

      if (!route.data[this.routeParameterName]) {
        continue;
      }

      breadcrumbs.push(this.createBreadcrumb(route, url));
    }

    this.breadcrumbs = breadcrumbs;
  }

  private createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
    return {
      displayName: route.data[this.routeParameterName],
      terminal: this.isTerminal(route),
      url,
    };
  }

  private isTerminal(route: ActivatedRouteSnapshot) {
    return route.firstChild === null || route.firstChild.routeConfig === null || !route.firstChild.routeConfig.path;
  }

  private createUrl(route: ActivatedRouteSnapshot) {
    return route.url.map((s) => s.toString()).join('/');
  }

  private createRootUrl(route: ActivatedRouteSnapshot) {
    let url = '';
    let next = route.root;

    while (next.firstChild !== route && next.firstChild !== null) {
      next = next.firstChild;

      if (next.routeConfig === null) {
        continue;
      }
      if (!next.routeConfig.path) {
        continue;
      }

      url += `/${this.createUrl(next)}`;
    }

    url += `/${this.createUrl(route)}`;

    return url;
  }
}
