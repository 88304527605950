import { Component, Input } from '@angular/core';

@Component({
    selector: 'consultation-wizard-steps',
    templateUrl: './wizard-steps.component.html',
})
export class WizardStepsComponent {
    @Input()
    public activeStep: number;

}
