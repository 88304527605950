<div class="support-2">

    <div class="row">
        <div class="col-lg-12">
            <div class="page-title">{{ 'Settings.WiiBoadScreen.Connexion.Title' | translate}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="page-sub-title">{{ 'Settings.WiiBoadScreen.Connexion.Title2' | translate }}</div>
        </div>
    </div>

  <div class="box">
        <div class="row">
            <div class="col-lg-12">
                <p>{{ 'Settings.WiiBoadScreen.Connexion.Text' | translate }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 explanations">
                <div class="number">1</div>
                <p class="description">{{'Settings.WiiBoadScreen.Connexion.Picture1' | translate}}</p>
                <img src="assets/images/connect1.svg" alt="connexion">
            </div>
            <div class="col-lg-4 explanations">
                <p class="number">2</p>
                <p class="description">{{'Settings.WiiBoadScreen.Connexion.Picture2' | translate}}</p>
                <img src="assets/images/connect3.png" />
            </div>      
            <div class="col-lg-4 explanations">
                <p class="number">3</p>
                <p class="description">{{'Settings.WiiBoadScreen.Connexion.Picture3' | translate}}</p>
                <img src="assets/images/connect2.svg" />
            </div>   
        </div>
    </div>

</div>