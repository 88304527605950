import { Pipe, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
@Pipe({
  name: 'localhour',
  pure: false,
})
export class LocalHourPipe extends DatePipe {
  /** @internal */

  public LOCALDATETIMEFORMAT = {
    fr: 'HH:mm:ss',
    cn: 'HH:mm:ss',
    en: 'HH:mm:ss',
  };

  // use navigator lang if available
  public currentLang: string = navigator.language.split('-')[0];

  public constructor(private translateService: TranslateService) {
    super('en-US');
  }

  public parse(value: any, pattern: string = null): string | null {
    const localDate = new LocalHourPipe(this.translateService);
    return localDate.transform(value, pattern);
  }

  public transform(value: any, pattern: string = null): string | null {
    const dateISOString = value && value instanceof Date ? value.toISOString() : moment(value).toISOString();
    let dateFormat: string = null;

    if (this.translateService) {
      this.currentLang = this.translateService.currentLang;
    }
    dateFormat = this.LOCALDATETIMEFORMAT[this.currentLang];
    return super.transform(dateISOString, dateFormat || pattern);
  }
}
