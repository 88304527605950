import { Component, OnInit, Input } from '@angular/core';
import { Questionnary } from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import {
  QuestionnaryAnswer,
  QuestionnaryAnswerQuestion,
  QuestionnaryAnswers,
  QuestionnaryAvailableAnswer,
} from 'app/modules/smartflat-data-access';
import { QuestionnaryDataService } from 'app/modules/smartflat-data-access';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnarySavingImp } from 'app/services/tempSaving/impl/questionnarySavingImp.service';

@Component({
  selector: 'app-questionnary',
  templateUrl: './questionnary.component.html',
  providers: [QuestionnaryDataService, QuestionnarySavingImp],
})
/**
 * This class corresponds to the page of questionnary. This component works like a "carrousel". Only the content changes (questions)
 */
export class QuestionnaryComponent implements OnInit {
  public ready: boolean = false;
  /**
   * Current index in table idTable
   */
  public currentIndex: number = 0;
  public idTable: Array<{ idQuestionnary: number; answer: QuestionnaryAnswer }> = [];

  public get currentQAnswer(): { idQuestionnary: number; answer: QuestionnaryAnswer } {
    if (!this.idTable || this.idTable.length === 0) return null;
    return this.idTable[this.currentIndex];
  }

  /**
   * The value saved when user answers to a input question
   */
  public valueInput: Array<{ value: string }> = new Array<{ value: string }>();

  /**
   * idQuestionnary = id of questionnary (1 to 10)
   * id = id in database
   */
  public currentQuestionnary: Questionnary = null;

  public valid: boolean = false;

  private questionnaries: QuestionnaryAnswers[];

  private prefilled: boolean = false;

  /**
   * Max index of prefilled questionnary (max index of the question answered)
   */
  private maxIndex: number = 0;

  /**
   * answer which need to be deleted
   */
  private answerDeleted: QuestionnaryAnswerQuestion[] = [];

  /**
   * this constructor checks if the questionnary is prefilled, create objects with answers.
   * @param serviceQuestion useful to set et get data from/to database
   */
  constructor(
    private serviceQuestion: QuestionnaryDataService,
    private languageService: TranslateService,
    private serviceSave: QuestionnarySavingImp,
  ) {
    // get questionnaries selected list
    this.questionnaries = LocalStorageEx.questionnaryAnswers;

    if (this.questionnaries) {
      this.questionnaries.forEach((questionnary) => {
        const questionnaryAnswer: QuestionnaryAnswer = new QuestionnaryAnswer();
        questionnaryAnswer.Type = questionnary.idQuestionnary;
        const mapAnswers: Map<number, QuestionnaryAnswerQuestion> = new Map<number, QuestionnaryAnswerQuestion>();
        // if the questionnary has an answer, we are in case of modification
        if (questionnary.answers) {
          this.prefilled = true;
          // get questionnaire vide
          this.serviceQuestion
            .getForm(questionnary.idQuestionnary, this.languageService.currentLang)
            .then((questionnaryEmpty) => {
              const typeTable: Array<{ type: string; rep: QuestionnaryAvailableAnswer[] }> = [];
              questionnaryEmpty.listeQuestions.forEach((elmt) => {
                elmt.listeItems.forEach((element) => {
                  typeTable.push({ type: element.typeQuestion, rep: element.options });
                });
              });
              questionnary.answers.forEach((answer) => {
                if (typeTable[answer.QuestionNumber].type === 'checkbox') {
                  if (answer.AnswerNumber !== null) {
                    mapAnswers.set(answer.QuestionNumber + answer.AnswerNumber * 0.01, answer);
                  } else {
                    // old code to be suppress
                    for (const i in typeTable[answer.QuestionNumber].rep) {
                      if (answer.QuestionAnswer === typeTable[answer.QuestionNumber].rep[i].value) {
                        mapAnswers.set(answer.QuestionNumber + +i * 0.01, answer);
                      }
                    }
                  }
                } else {
                  mapAnswers.set(answer.QuestionNumber, answer);
                }
              });
              questionnaryAnswer.Answers = mapAnswers;
            });
        }
        this.idTable.push({ idQuestionnary: questionnary.idQuestionnary, answer: questionnaryAnswer });
      }, this);
    }
  }

  /**
   * This function get the first questionnary to print it and create the questionnaryAnswer if it does not exist yet.
   * It calls the function to fill form in case of modification
   */
  public ngOnInit(): void {
    this.changeQuestionary();
    LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, 'FormsSelector');
  }

  public answerAtPosition(position: number) {
    // TODO try to understand this test when it is false
    if (this.questionnaries[this.currentIndex].answers) {
      return this.questionnaries[this.currentIndex].answers.filter((answer) => answer.QuestionNumber === position);
    } else {
      // TODO improve this think
      return Array.from(this.currentQAnswer.answer.Answers)
        .map((couple) => couple[1])
        .filter((answer) => answer.QuestionNumber === position);
    }
  }

  public responsesTextAtPosition(position: number) {
    const answers = this.answerAtPosition(position);
    if (answers.length > 0) {
      return answers[0].QuestionAnswer;
    }
    return null;
  }
  /**
   * When user click on "back". This function changes the current questionnary and does not save the answers
   */
  public backQuestionnary(): void {
    this.currentIndex--;
    this.changeQuestionary();
  }

  /**
   * When user click on save and next button.
   * This function save questionnary and load the next questionnary if it's not the last questionnary
   * @param end boolean corresponding if it's the last questionary or not
   */
  public async nextQuestionnary(end: boolean) {
    for (const answer of this.answerDeleted) {
      if (answer) {
        await this.serviceSave.removeQuestion(answer, this.currentQuestionnary.id);
      }
    }
    await Promise.all(
      Array.from(this.currentQAnswer.answer.Answers.values()).map(async (answer) => {
        await this.serviceSave.addQuestion(answer, this.currentQAnswer.idQuestionnary);
      }),
    );

    if (!end) {
      this.currentIndex++;
      this.changeQuestionary();
    }
  }

  /**
   * When user select an answer. This functions will save it in "idTable" variable. Case of radio and checkbox formular
   * @param position index of the question
   * @param option answer
   */
  public addAnswer(position: number, option: string, type: string, position2: number): void {
    const answer: QuestionnaryAnswerQuestion = new QuestionnaryAnswerQuestion();
    answer.QuestionAnswer = option;
    answer.QuestionNumber = position;
    answer.AnswerNumber = position2;

    if (type === 'checkbox') {
      const pos = position + position2 * 0.01;
      if (this.currentQAnswer.answer.Answers.get(pos)) {
        // Here is the case where user uncheck an answer

        this.answerDeleted.push(this.currentQAnswer.answer.Answers.get(pos));
        this.currentQAnswer.answer.Answers.delete(pos);
      } else {
        this.currentQAnswer.answer.Answers.set(pos, answer);
      }
    } else {
      if (this.currentQAnswer.answer.Answers.get(position)) {
        answer.Id = this.currentQAnswer.answer.Answers.get(position).Id;
      }

      if (this.currentQAnswer.answer.Answers.get(position)) {
        this.answerDeleted.push(this.currentQAnswer.answer.Answers.get(position));
      }
      this.currentQAnswer.answer.Answers.set(position, answer);
    }

    this.valid = this.isAllQuestionAnswered();
  }

  /**
   * Particular treatment for input form.
   * @param position index of the question
   */
  public addInput(position: number) {
    const oldAnswer = this.responsesTextAtPosition(position);
    if (oldAnswer) {
      let alreadyDeleted: boolean = false;
      for (const deletedAnswer of this.answerDeleted) {
        if (deletedAnswer.QuestionNumber === position) {
          alreadyDeleted = true;
        }
      }
      if (!alreadyDeleted) {
        const answerToBeDeleted: QuestionnaryAnswerQuestion = new QuestionnaryAnswerQuestion();
        answerToBeDeleted.QuestionAnswer = oldAnswer;
        answerToBeDeleted.QuestionNumber = position;
        this.answerDeleted.push(answerToBeDeleted);
      }
    }
    const answer: QuestionnaryAnswerQuestion = new QuestionnaryAnswerQuestion();

    answer.QuestionAnswer = this.valueInput[position].value;
    answer.QuestionNumber = position;
    if (this.currentQAnswer.answer.Answers.get(position)) {
      answer.Id = this.currentQAnswer.answer.Answers.get(position).Id;
    }
    this.currentQAnswer.answer.Answers.set(position, answer);

    if (this.idTable[this.currentIndex].idQuestionnary === 12) {
      this.valid = this.isAnyQuestionAnswered();
    } else {
      this.valid = this.isAllQuestionAnswered();
    }

    console.log('on keyup');
  }

  /**
   * in case of prefilled form : check if the answer has to be checked or not !
   * @param option content of answer
   * @param position position of question in form
   * @param answerIndex index of the answer
   */
  public checkIfCheck(option: string, position: number, answerIndex: number): boolean {
    const responses = this.answerAtPosition(position);
    return (
      responses.findIndex((answer) => answer.QuestionAnswer === option) !== -1 ||
      responses.findIndex((answer) => answer.AnswerNumber === answerIndex) !== -1
    );
  }

  /**
   * Init the table of input value.
   */
  private initValueInput() {
    let index: number = 0;
    this.currentQuestionnary.listeQuestions.forEach((set) => {
      set.listeItems.forEach((question) => {
        if (index >= this.valueInput.length) {
          this.valueInput.push({ value: '' });
        } else {
          this.valueInput[index].value = '';
        }
        index++;
      });
    });
    this.ready = true;
  }

  /**
   * It's useful for printing (selected the good item)
   */
  private prefillForm(): void {
    this.maxIndex = 0;

    if (this.questionnaries[this.currentIndex].answers) {
      this.questionnaries[this.currentIndex].answers.forEach((answer) => {
        if (answer.QuestionNumber > this.maxIndex) {
          this.maxIndex = answer.QuestionNumber;
        }
      });
    } else {
      this.currentQAnswer.answer.Answers.forEach((answer) => {
        if (answer.QuestionNumber > this.maxIndex) {
          this.maxIndex = answer.QuestionNumber;
        }
      });
    }
    this.prefilled = false;
    this.valid = true;
  }

  /**
   * Call when component change of questionnary to set internal variable.
   */
  private changeQuestionary() {
    this.ready = false;
    this.valid = false;

    // TODO check if there is bug here when we do back and forward on checkbox question
    this.answerDeleted = [];

    this.prefilled = this.currentQAnswer.answer !== null && this.questionnaries[this.currentIndex].answers !== null;
    this.serviceQuestion
      .getForm(this.currentQAnswer.idQuestionnary, this.languageService.currentLang)
      .then((response) => {
        this.currentQuestionnary = response;
        this.initValueInput();
        if (this.currentQAnswer.answer === null) {
          this.currentQAnswer.answer = new QuestionnaryAnswer();
          this.currentQAnswer.answer.Type = this.currentQuestionnary.id;
        }
      });
    if (this.prefilled) {
      this.prefillForm();
    }
  }

  /**
   * Calculate if all question are answered.
   * @returns true if all question have a non empty answer
   */
  private isAllQuestionAnswered() {
    const size = this.currentQuestionnary.listeQuestions.reduce((nbQuestion, elmt) => {
      nbQuestion += elmt.listeItems.length;
      return nbQuestion;
    }, 0);

    for (let i = 0; i < size; i++) {
      const listAnswer = this.answerAtPosition(i);
      // need to check for input if the answer is an emty string.
      if (listAnswer.length === 0 || listAnswer[0].QuestionAnswer === '') return false;
    }
    return true;
  }

  private isAnyQuestionAnswered() {
    const size = this.currentQuestionnary.listeQuestions.reduce((nbQuestion, elmt) => {
      nbQuestion += elmt.listeItems.length;
      return nbQuestion;
    }, 0);
    console.log('size  :   ', size);
    for (let i = 0; i < size; i++) {
      const listAnswer = this.answerAtPosition(i);
      // need to check for input if the answer is an emty string.
      // not perferct but only used in input only questionnary
      // if used in checkbox questionnary : test must be changed.

      console.log('listAnswer :   ', listAnswer);
      if (listAnswer.length !== 0 && listAnswer[0].QuestionAnswer !== null) {
        return true;
      }
    }
    return false;
  }
}
