<table *ngIf="ready" class="table-striped">
  <thead>
    <tr>
      <th>{{ 'Patient.Information' | translate }}</th>
    </tr>
  </thead>
  <tfoot>
    <tr>
      <!-- <td>{{'*Poids annoncé par le patient'|translate}}</td> -->
      <td></td>
      <td></td>
    </tr>
  </tfoot>

  <tbody [ngClass]="{ editing: !readonly }">
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.Lastname' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          [value]="patient"
          field="lastName"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.Firstname' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          [value]="patient"
          field="firstName"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Sexe' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          type="enum"
          [value]="patient"
          field="gender"
          (input)="onInput($event)"
          [option]="genderOptions"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.PhoneNumber' | translate }}:</label>
        <field-input [readonly]="readonlyOther" type="text" [value]="patient" field="phoneNumber"></field-input>
      </td>
    </tr>
    <tr></tr>
    <tr>
      <td>
        <label>{{ 'Patient.Email' | translate }}:</label>
        <field-input [readonly]="readonlyOther" type="text" [value]="patient" field="email"></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.Address' | translate }}:</label>
        <field-input [readonly]="readonlyOther" type="textarea" [value]="patient" field="address"></field-input>
      </td>
    </tr>
    <tr></tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.Handedness' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          type="enum"
          [value]="patient"
          field="handedness"
          (input)="onInput($event)"
          [option]="handednessOptions"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.BirthDate' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          type="date"
          [value]="patient"
          field="birthdate"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.YearsOld' | translate }}:</label>
        <font>{{ patientAge }}</font>
      </td>
    </tr>
    <tr *ngIf="currentEstablishment.Config !== undefined">
      <td *ngIf="currentEstablishment.Config['boxes'] === true">
        <label>{{ 'Patient.Apartment' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          type="number"
          [value]="patient"
          field="flatId"
          [option]="apartments"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.FolderNumber' | translate }}:</label>
        <field-input
          [readonly]="readonlyOther"
          type="string"
          [value]="patient"
          field="folderNumber"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.Doctor' | translate }}:</label>
        <field-input
          [readonly]="readonly"
          type="number"
          [value]="patient"
          field="doctorId"
          [option]="doctorsName"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label class="mandatory">{{ 'Patient.Consent' | translate }}:</label>
        <field-input
          [readonly]="readonly"
          type="boolean"
          [value]="patient"
          field="consent"
          [option]="patientConsent"
          (input)="onInput($event)"
        ></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.Created' | translate }}:</label>
        <field-input [readonly]="true" type="date" [value]="patient" field="created"></field-input>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'Patient.Modified' | translate }}:</label>
        <field-input [readonly]="true" type="date" [value]="patient" field="modified"></field-input>
      </td>
    </tr>
  </tbody>
</table>
