export class Notification {
  public constructor(private _message: string, private _level: NotificationLevel) {}

  public static newError(message: string): Notification {
    return new Notification(message, NotificationLevel.Error);
  }

  public static newWarning(message: string): Notification {
    return new Notification(message, NotificationLevel.Warn);
  }

  public static newInfo(message: string): Notification {
    return new Notification(message, NotificationLevel.Info);
  }

  public get message(): string {
    return this._message;
  }

  public get level(): NotificationLevel {
    return this._level;
  }
}

export enum NotificationLevel {
  Error,
  Warn,
  Info,
}
