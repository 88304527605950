<div class="suivi_continu">
  <current-patient-banner [flatId]="patientSelected.flatId"> </current-patient-banner>

  <form (ngSubmit)="checkDate()" #torForm="ngForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="box">
          <div class="row">
            <div class="col-lg-10">
              <div class="row first_line">
                <div class="col-lg-12">
                  <label>{{ 'Date.Period' | translate }} {{ 'Date.From' | translate }}</label>
                  <my-date-picker
                    [options]="localDateOptions"
                    [selDate]="onStartDateDefault"
                    (dateChanged)="onStartDateChanged($event)"
                  ></my-date-picker>
                  <label>{{ 'Date.To' | translate }}</label>
                  <my-date-picker
                    [options]="localDateOptions"
                    [selDate]="onEndDateDefault"
                    (dateChanged)="onEndDateChanged($event)"
                  ></my-date-picker>

                  <label>{{ 'Date.Frequency' | translate }}</label>
                  <select [(ngModel)]="timeFrequencySelected" name="timeFrequencySelected">
                    <option *ngFor="let timeFrequency of timeFrequencyArray" [ngValue]="timeFrequency.value">{{
                      timeFrequency.label | translate
                    }}</option>
                  </select>

                  <button class="btn-primary btn" type="submit">{{ 'button.validation' | translate }}</button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <label>{{ 'tor.indicator' | translate }}</label>
                  <select
                    [ngModel]="indicatorSelected | async"
                    name="indicatorSelected"
                    (ngModelChange)="onChangeIndicator($event)"
                    style="width:300px!important"
                  >
                    <option *ngFor="let indicator of indicatorsList" [ngValue]="indicator">{{
                      indicator | translate
                    }}</option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <select
                    (ngModelChange)="onChangeSubIndicator($event)"
                    [ngModel]="subIndicatorSelected | async"
                    name="subIndicatorSelected"
                    style="width:300px!important"
                  >
                    <option *ngFor="let subIndicator of (subIndicatorsList | async)" [ngValue]="subIndicator">{{
                      subIndicator | translate
                    }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <!-- <div *ngIf="captorConnected"> -->
              <div>
                <button class="btn btn-tor" (click)="desActivateCaptor()">
                  {{ 'tor.captor.desactivate' | translate }}
                </button>
                <button class="btn btn-tor" (click)="activateCaptor()">{{ 'tor.captor.activate' | translate }}</button>
                <span class="info">{{ 'tor.captor.desactivation' | translate }}</span>
              </div>
              <div *ngIf="!captorConnected">
                <span class="info">{{ 'tor.captor.disconnected' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="row">
  <div class="col-lg-12">
    <button class="btn btn-navigation" [class.btn-active]="tableOrGraph" (click)="showTable(true)">
      {{ 'tor.table' | translate }}
    </button>
    <button class="btn btn-navigation" [class.btn-active]="!tableOrGraph" (click)="showTable(false)">
      {{ 'tor.chart' | translate }}
    </button>
  </div>
</div>

<ng-container *ngIf="(torTable$ | async) as torTable">
  <tor-bilan-table *ngIf="tableOrGraph" [torTable]="torTable"></tor-bilan-table>
  <tor-bilan-histo *ngIf="!tableOrGraph" [torTable]="torTable"></tor-bilan-histo>
</ng-container>
<div class="row">
  <div class="col-lg-12">
    <div class="page-footer buttons-component">
      <button
        class="btn btn-primary btn-left"
        [routerLink]="['/PatientResults']"
        [routerLinkActive]="['router-link-active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'Précédent' | translate }}
      </button>
    </div>
  </div>
</div>
