<div class="page-title">{{'Nouvelle acquisition'|translate}}</div>

<div class="add_syndrome">
	<current-patient-banner [consultationStep]=0> </current-patient-banner>
	<div class="row">
		<div class="col-lg-12">
			<div *ngIf="myHealthForm">
  				<app-health-form [healthForm]=myHealthForm (healthAnswerOut)="publish($event)"></app-health-form>
  			</div>
			<div *ngIf="!myHealthForm">

			</div>
		</div>
	</div>
</div>
