import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { HealthFormInitComponent } from './view/health-form/health-form-init.component';
import { PatientComponent } from './view/patient/patient.component';
import { PatientResultsComponent } from './view/patient/patientresults.component';
import { PatientConsultComponent } from './view/patient/patientconsult.component';
import { DiagnosticListComponent } from 'app/view/diagnostic-list/diagnostic-list.component';
import { SettingsComponent } from 'app/view/settings/settings.component';
import { HelpComponent } from 'app/view/help/help.component';
import { QuestionnaryListComponent } from 'app/view/questionnary-list/questionnary-list.component';
import { QuestionnaryComponent } from 'app/view/questionnary/questionnary.component';
import { CheckupQuestionnaryComponent } from 'app/view/checkup-questionnary/checkup-questionnary.component';
import { WiiBoardActivationComponent } from 'app/view/settings/wiiBoardActivation/wiiBoardActivation.component';
import { WindowsWiiBoardSyncComponent } from 'app/view/settings/windowsWiiBoardSync/windowsWiiBoardSync.component';
import { ChoixImpressionComponent } from 'app/modules/rapports/choixImpression/choixImpression.component';
import { RapportPraticienComponent } from 'app/modules/rapports/rapportPraticien/rapportPraticien.component';

import { environment } from 'environments/environment';
import { AuthGuard } from 'app/modules/core';
import { RapportComponentNew } from './modules/rapports/rapport/rapportNew.component';
import { Roles } from './modules/core/roles.enum';
import { TorBilanComponent } from './modules/tor';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'diagnostic',
    component: HealthFormInitComponent,
    canActivate: [AuthGuard],
    data: {
      role: Roles.editSyndrom,
    },
  },
  { path: 'Patient', component: PatientComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  {
    path: 'PatientResults',
    component: PatientResultsComponent,
    canActivate: [AuthGuard],
    data: {
      role: Roles.accessResults,
    },
  },
  {
    path: 'PatientConsult',
    component: PatientConsultComponent,
    canActivate: [AuthGuard],
    data: {
      /* role: Roles.runCheckup */
    },
  },
  {
    path: 'diagnosticList',
    component: DiagnosticListComponent,
    canActivate: [AuthGuard],
    data: {
      /*role: Roles.runCheckup */
    },
  },
  { path: 'Settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'Help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: 'FormsSelector', component: QuestionnaryListComponent, canActivate: [AuthGuard] },
  {
    path: 'Forms',
    component: QuestionnaryComponent,
    canActivate: [AuthGuard],
    data: {
      /*role: Roles.runCheckup */
    },
  },
  {
    path: 'checkup-questionnary',
    component: CheckupQuestionnaryComponent,
    canActivate: [AuthGuard],
    /*    data: { role: Roles.runCheckup }, */
  },
  { path: 'tutorial-sync-ForceTab', component: WiiBoardActivationComponent, canActivate: [AuthGuard] },
  { path: 'tutorial-sync-ForceTab-windows', component: WindowsWiiBoardSyncComponent, canActivate: [AuthGuard] },
  {
    path: 'rapport',
    component: RapportComponentNew,
    canActivate: [AuthGuard],
    data: {
      role: Roles.accessResults,
    },
  },
  {
    path: 'rapport/:patientId/:consultId',
    component: RapportComponentNew,
    canActivate: [AuthGuard],
    data: {
      role: Roles.patientReport,
    },
  },
  {
    path: 'rapport/:patientId/:consultId/:typeRapport',
    component: RapportComponentNew,
    canActivate: [AuthGuard],
    data: {
      role: Roles.patientReport,
    },
  },
  {
    path: 'choixImpression',
    component: ChoixImpressionComponent,
    canActivate: [AuthGuard],
    data: {
      role: Roles.accessResults,
    },
  },
  {
    path: 'rapportPraticien',
    component: RapportPraticienComponent,
    canActivate: [AuthGuard],
    data: {
      role: Roles.doctorReport,
    },
  },
  {
    path: 'rapportPraticien/:patientId/:consultId',
    component: RapportPraticienComponent,
    canActivate: [AuthGuard],
    data: {
      role: Roles.doctorReport,
    },
  },
  { path: 'tor', component: TorBilanComponent, canActivate: [AuthGuard] },
  {
    path: 'exporting',
    loadChildren: './modules/exporting/exporting.module#ExportingModule',
    canActivate: [AuthGuard],
    data: { role: Roles.exportResult },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.enableRouteDebug,
      //  useHash: true,
      initialNavigation: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
