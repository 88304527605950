import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { Roles } from '../../roles.enum';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RolesCheckService {
  public readonly hasListAllPatient: Observable<boolean>;
  public readonly hasManagePatient: Observable<boolean>;
  public readonly hasSelectPatientReferrer: Observable<boolean>;
  public readonly hasEditSyndrom: Observable<boolean>;
  public readonly hasRunCheckup: Observable<boolean>;
  public readonly hasExportRawData: Observable<boolean>;
  public readonly hasDelegationChekup: Observable<boolean>;
  public readonly hasDelegationResult: Observable<boolean>;

  public allMenuDisable = false;

  constructor(authService: AuthService) {
    this.hasListAllPatient = authService.hasRole(Roles.listAllPatient);
    this.hasManagePatient = authService.hasRole(Roles.managePatient);
    this.hasSelectPatientReferrer = authService.hasRole(Roles.selectPatientReferrer);
    this.hasEditSyndrom = authService.hasRole(Roles.editSyndrom);
    this.hasRunCheckup = authService.hasRole(Roles.runCheckup);
    this.hasExportRawData = authService.hasRole(Roles.exportRawData);
    this.hasDelegationChekup = authService.hasRole(Roles.delegationChekup);
    this.hasDelegationResult = authService.hasRole(Roles.delegationResult);
  }
}
