<div class="version-smartflat">
  <div *ngIf="name">{{ this.name | nameFormat }}</div>
  <span>{{'App.Template.Version'| translate: this }}</span>
</div>
<div id="conteneur" class="conteneur">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-1 no-padding left-side">
        <nav id="left-side">
          <app-navbar></app-navbar>
        </nav>
      </div>
      <div class="col-lg-11 right-side">
        <section id="right-side">
          <core-notifications class="notification-container"></core-notifications>
          <router-outlet></router-outlet>

          <div class="page-footer navigation-actions-component">
            <core-navigation-actions></core-navigation-actions>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<core-modal #logoutmodal>
  <div class="modal-header">
    <label>{{ 'logout.confirmation' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'logout.confirmation.bodyText' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="logOut()">{{ 'Oui' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="cancelLogOut()">{{ 'Non' | translate }}</button>
  </div>
</core-modal>

<core-modal name="bilanlocomotionModal" #bilanlocomotionModal>
  <div class="modal-header">
    <label> {{ 'TitrePopupTestEnCours' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'TextPopupTestEnCours' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="doBilan('bilan_locomotion')">{{ 'Oui' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="cancelBilan('bilan_locomotion')">
      {{ 'Non' | translate }}
    </button>
  </div>
</core-modal>

<core-modal name="bilanrombergModal" #bilanrombergModal>
  <div class="modal-header">
    <label> {{ 'TitrePopupTestEnCours' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'TextPopupTestEnCours' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="doBilan('bilan_romberg')">{{ 'Oui' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="cancelBilan('bilan_romberg')">
      {{ 'Non' | translate }}
    </button>
  </div>
</core-modal>

<core-modal name="bilangripModal" #bilangripModal>
  <div class="modal-header">
    <label> {{ 'TitrePopupTestEnCours' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'TextPopupTestEnCours' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="doBilan('bilan_grip')">{{ 'Oui' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="cancelBilan('bilan_grip')">{{ 'Non' | translate }}</button>
  </div>
</core-modal>

<core-modal name="bilanpeseeModal" #bilanpeseeModal>
  <div class="modal-header">
    <label> {{ 'TitrePopupTestEnCours' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'TextPopupTestEnCours' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="doBilan('bilan_pesee')">{{ 'Oui' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="cancelBilan('bilan_pesee')">{{ 'Non' | translate }}</button>
  </div>
</core-modal>

<core-modal name="versionModal" #versionModal>
  <div class="modal-header">
    <img (click)="hideVersionModal()" src="../assets/images/close.png" alt="X" class="close-button" />
    <label>{{ 'Release.Title' | translate }}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;" [innerHTML]="'Release.Notes' | translate"></div>
</core-modal>

<core-modal name="cguModal" #cguModal>
  <div class="modal-header">
    <img (click)="hideCguModal()" src="../assets/images/close.png" alt="X" class="close-button" />
    <label>{{ 'CGU.Title' | translate }}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;" [innerHTML]="'CGU.Content' | translate"></div>
</core-modal>

<core-modal name="warningPatientModal" #warningPatientModal>
  <div class="modal-header">
    <img (click)="hideWarningPatientModal()" src="../assets/images/close.png" alt="X" class="close-button" />
    <label>{{ 'Attention' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'Patient.delete.warning' | translate }}
  </div>
</core-modal>

<core-modal name="warningWeightModal" #warningWeightModal>
  <div class="modal-header">
    <img (click)="hideWarningWeightModal()" src="../assets/images/close.png" alt="X" class="close-button" />
    <label>{{ 'Attention' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'Check.weight.Tare.Modal' | translate }}
  </div>
</core-modal>