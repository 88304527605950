import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'app/modules/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import {
  filter,
  flatMap,
  refCount,
  publishReplay,
  map,
  distinctUntilChanged,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { TimeService } from 'app/modules/time/services/time.service';

import { LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { Patient } from 'app/modules/smartflat-data-access';
import { TorService } from 'app/modules/tor/services/tor.service';

import { FlatEventsDataResponse } from 'app/modules/smartflat-data-access/src/service-dal/model/models';
import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { LocaleService } from 'mydatepicker/dist/services/my-date-picker.locale.service';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';

import { TORINDICATORS } from '../../models/constants.model';
import { TorTable } from '../../models/tortable.model';
import { TIME_FREQUENCY_ARRAY, PeriodType } from '../../models/timefrequency.model';
import { FlatDataService } from '../../../smartflat-data-access/src/api/flat-data.service';
import { TorWeekTableService } from 'app/modules/tor/services/torweektable.service';
import { TorMonthTableService } from '../../services/tormonthtable.service';
import { TorYearTableService } from '../../services/toryeartable.service';

@Component({
  selector: 'tor-bilan',
  templateUrl: './tor-bilan.component.html',
})
export class TorBilanComponent implements OnInit {
  public patientSelected: Patient;
  public timeFrequencyArray = TIME_FREQUENCY_ARRAY;
  public torTable$: Observable<TorTable>;
  public timeFrequencySelected: PeriodType;

  public indicatorsList = TORINDICATORS;
  public indicatorSelected = new BehaviorSubject<string>(TORINDICATORS[0]);

  public subIndicatorSelected = new BehaviorSubject<string>(null);
  public tableOrGraph: boolean;

  public captorState: boolean = false;
  public captorConnected: boolean = false;
  public localDateOptions: IMyDpOptions;

  public subIndicatorsList: Observable<string[]>;

  private _startDate: Date;
  private _endDate: Date;

  private period = new BehaviorSubject<{ start: Date; end: Date; type: PeriodType }>(null);
  private flatEvent: Observable<FlatEventsDataResponse[]>;

  private localeService: LocaleService = new LocaleService();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private localDatePipe: LocalDatePipe,
    private torService: TorService,
    private timeService: TimeService,
    private histoVersusConsultationService: HistoVersusConsultationService,
    private flatDataService: FlatDataService,
  ) {
    this.flatEvent = this.period.pipe(
      filter((period) => period && true),
      flatMap((period) =>
        this.flatDataService.getFlatDataEvents(this.patientSelected.flatId, period.type, period.start, period.end),
      ),
      publishReplay(1),
      refCount(),
    );

    this.subIndicatorsList = combineLatest(this.flatEvent, this.indicatorSelected).pipe(
      map(([events, indicatorSelected]) => {
        const indicatorsNames = new Set<string>();
        events.forEach((evt) => {
          evt.day.total[indicatorSelected].forEach((o) => indicatorsNames.add(o.alias));
          evt.night.total[indicatorSelected].forEach((o) => indicatorsNames.add(o.alias));
        });

        return Array.from(indicatorsNames);
      }),
      distinctUntilChanged(),
      tap((newList) => {
        if (newList.length > 0) {
          this.subIndicatorSelected.next(newList[0]);
        }
      }),
    );
    this.torTable$ = combineLatest(this.flatEvent, this.subIndicatorSelected).pipe(
      filter(([, subindicator]) => subindicator && true),
      withLatestFrom(this.indicatorSelected, this.period),
      map(([[events, subindicator], indicatorSelected, period]) =>
        this.torService.constructTorTable(
          period.start,
          period.end,
          period.type,
          events,
          indicatorSelected,
          subindicator,
        ),
      ),
    );
  }

  public ngOnInit(): void {
    this.histoVersusConsultationService.setToReadOnly(true);
    this.tableOrGraph = true;

    this.patientSelected = LocalStorageEx.currentPatientSelected;
    this.indicatorSelected.next(this.indicatorsList[0]);

    // initial value
    this._startDate = moment()
      .startOf('week')
      .add(-1, 'week')
      .toDate();
    this._endDate = moment()
      .startOf('week')
      .toDate();

    this.timeFrequencySelected = this.timeFrequencyArray[0].value;
    this.period.next({
      start: this._startDate,
      end: this._endDate,
      type: this.timeFrequencySelected,
    });

    this.areCaptorsActivate();
    this.onLangChange();
    this.translateService.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
  }

  public async checkDate(): Promise<void> {
    if (this._startDate.getTime() > this._endDate.getTime()) {
      this.translateService.get('Date.Comparaison.Error').subscribe((value) => {
        this.notificationService.pushErrorNotifications(value);
      });
      this._endDate = this._startDate;
    } else {
      this.period.next({
        start: this._startDate,
        end: this._endDate,
        type: this.timeFrequencySelected,
      });
    }
  }

  public set startDate(startDate: string) {
    this._startDate = this.timeService.setDateFromString(startDate);
    this._startDate = new Date(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate());
  }

  public get startDate(): string {
    return this.timeService.getDateAsStringNeededByInput(this._startDate);
  }

  public set endDate(endDate: string) {
    this._endDate = this.timeService.setDateFromString(endDate);
    this._endDate = new Date(this._endDate.getFullYear(), this._endDate.getMonth(), this._endDate.getDate());
  }

  public get endDate(): string {
    return this.timeService.getDateAsStringNeededByInput(this._endDate);
  }

  public showTable(bool: boolean) {
    this.tableOrGraph = bool;
  }

  public areCaptorsActivate() {
    /*
    this.torService.areCaptorsActivated(this.patientSelected.spaceId).subscribe((gatewayState) => {
      this.captorState = gatewayState.transmitting;
      this.captorConnected = gatewayState.connected;
    });
    */
  }

  public activateCaptor() {
    /*
    this.torService.setCaptorsActivation(this.patientSelected.spaceId, false).subscribe((response) => {
      this.notifyChangeState(response, 'tor.captor.activation.success', 'tor.captor.activation.error');
      if (response) {
        this.captorState = true;
      }
    });
    */
  }

  public desActivateCaptor() {
    /*
    this.torService.setCaptorsActivation(this.patientSelected.spaceId, true).subscribe((response) => {
      this.notifyChangeState(response, 'tor.captor.desactivation.success', 'tor.captor.desactivation.error');
      if (response) {
        this.captorState = false;
      }
    });
    */
  }

  public notifyChangeState(response: boolean, success: string, error: string): void {
    if (response) {
      this.translateService
        .get(success)
        .toPromise()
        .then((value) => {
          this.notificationService.pushInfoNotifications(value);
        });
    } else {
      this.translateService
        .get(error)
        .toPromise()
        .then((value) => {
          this.notificationService.pushInfoNotifications(value);
        });
    }
  }

  public onChangeIndicator(newValue: string) {
    this.indicatorSelected.next(newValue);
  }

  public onChangeSubIndicator(newValue: string) {
    this.subIndicatorSelected.next(newValue);
  }

  /**
   * get default date for my-date-picker component
   */
  public get onEndDateDefault(): string {
    return this.localDatePipe.parse(this._endDate, this.localDateOptions.dateFormat.replace(/[m]/g, 'M'));
  }

  public onEndDateChanged(event: IMyDateModel) {
    /**
     * ISO Date format is 'yyyy-mm-dd' in Javascript.
     */
    this._endDate = event.jsdate;
  }

  public get onStartDateDefault(): string {
    return this.localDatePipe.parse(this._startDate, this.localDateOptions.dateFormat.replace(/[m]/g, 'M'));
  }

  public onStartDateChanged(event: IMyDateModel) {
    /**
     * ISO Date format is 'yyyy-mm-dd' in Javascript.
     */
    this._startDate = event.jsdate;
  }

  public onLangChange() {
    const today = new Date();
    const currentLang = this.translateService.currentLang.replace(/cn/g, 'zh-cn');
    const localeOptions = this.localeService.getLocaleOptions(currentLang);

    localeOptions.editableDateField = false;
    localeOptions.showTodayBtn = false;
    localeOptions.inline = false;
    localeOptions.minYear = today.getFullYear() - 150; // TODO: configurable

    this.localDateOptions = localeOptions;
  }
}
