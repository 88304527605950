import { Injectable, OnDestroy } from '@angular/core';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';

import { FootWearDataService, FootWear } from '../../smartflat-data-access';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ShoesService implements OnDestroy {
  private unsubscribe = new Subject<void>();
  private translations = new BehaviorSubject<FootWear[]>([]);

  constructor(private footWearDataService: FootWearDataService, private translateService: TranslateService) {
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe((event: LangChangeEvent) => {
      this.updateTranslation(event.lang);
    });

    this.updateTranslation(translateService.currentLang);
  }
  public getLocale(alias: string): string {
    const shoes = this.translations.value.find((fw) => fw.Alias === alias);
    return shoes ? shoes.Translate : alias;
  }

  public ngOnDestroy(): void {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private updateTranslation(lang: string) {
    this.footWearDataService.GetAllFootWears(lang).then((values) => this.translations.next(values));
  }
}
