import { Injectable } from '@angular/core';
import { PatientFilterStateServiceService } from './patient-filter-state-service.service';
import { IPatient } from './patient.interface';

@Injectable({
  providedIn: 'root',
})
export class PatientResultFilterStateServiceService extends PatientFilterStateServiceService {
  private selectedPatient: IPatient;
  private bilanFilerState = { starDate: null as Date, endDate: null as Date, bilan: 0, question: -1 };

  public saveCurrentPatient(patient: IPatient) {
    this.selectedPatient = patient;
  }

  public get savedPatient() {
    return this.selectedPatient;
  }

  public set saveBilanFilter(state: { starDate: Date; endDate: Date; bilan: number; question: number }) {
    this.bilanFilerState = state;
  }

  public get saveBilanFilter() {
    return this.bilanFilerState;
  }
}
