import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { NotificationService } from 'app/modules/core';
import { InterceptorHttpParams } from './interceptor-http-params';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (evt) => {
          if (evt instanceof HttpResponse) {
            /* we can log here */
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              // redirect to the login route
              // this.router.navigate(['/login']);
            } else {
              let notification = true;
              if (req.params instanceof InterceptorHttpParams && req.params.interceptorConfig.noNotificationOnError) {
                notification = false;
              }
              if (notification) {
                this.notificationService.pushErrorNotifications('Error from server.');
              }
            }
          }
        },
      ),
    );
  }
}
