import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HealthAnswer, HealthForm } from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { DiagnosticDataService } from 'app/modules/smartflat-data-access';
import { TranslateService } from '@ngx-translate/core';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';

@Component({
  selector: 'app-health-form-init',
  templateUrl: './health-form-init.component.html',
  providers: [DiagnosticDataService, DiagnosticSavingImp],
})

/**
 * this component is the global component for the diagnostic form. It uses others components in getting their data
 * this component should get the form and send the completeds form from the service.
 */
export class HealthFormInitComponent {
  public myHealthForm: HealthForm = null;

  /**
   * Constructor initializes the service
   * @param healthFormService service for getting and publishing forms (questions and answers)
   */
  constructor(
    private diagnosticService: DiagnosticDataService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: TranslateService,
    private serviceSave: DiagnosticSavingImp,
  ) {}

  /**
   * this function gets the diagnostic form by using the service.
   */
  public ngOnInit(): void {
    this.diagnosticService.getDiagnosticForm(this.languageService.currentLang).then((response) => {
      console.log('response   ', response);

      this.myHealthForm = response;
    });
    LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, 'diagnosticList');
  }

  /**
   * send data on the database when form is complete. Clean localStorage (if we edit a diagnostic)
   * @param answer contains the answers.
   */
  private publish(answer: HealthAnswer): void {
    if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
      console.log('    move to /diagnostoicList     -----------------------  ');
      let routLink = '/diagnosticList';
      const backRoutLink = LocalStorageEx.lastDiagnosticBackRoutLink;
      if (backRoutLink) {
        console.log('    move to /patientList     -----------------------  ');
        routLink = backRoutLink;
        LocalStorageEx.lastDiagnosticBackRoutLink = null;
      }

      // case of modification of diagnostic
      const ex_answers = LocalStorageEx.restore(LocalStorageObject.diagnostic_patient, HealthAnswer);
      const sets = answer.answerSets;
      sets.forEach((element) => {
        const modifiedAnswers = element.answers;
        modifiedAnswers.forEach((elmt) => {
          ex_answers.answerSets.get(element.set_id).answers.set(elmt.question_id, elmt);
        });
      });
      LocalStorageEx.removeObject(LocalStorageObject.diagnostic_patient);

      if (ex_answers.id_diagnostic) {
        ex_answers.date_modify = new Date();
        this.diagnosticService.editDiagnosticForm(ex_answers).subscribe(
          (res) => {
            this.router.navigate([routLink]);
          },
          (error) => {
            console.log('erreur : ', error);
          },
        );
      } else {
        this.serviceSave.editNewDiagnostic(ex_answers);
        this.router.navigate([routLink]);
      }
    } else {
      const consultationDate = new Date();
      answer.date = consultationDate;
      this.serviceSave.saveNewDiagnostic(answer);
      this.router.navigate(['/diagnosticList'], { relativeTo: this.route });
    }
  }
}
