// intermediate model
import { Consultation } from '../model/consultation.model';
import { HealthForm } from '../model/health-form.model';

// model from database
import { Consultation as DalConsultation } from '../service-dal/model/consultation';
import { Diagnostic } from '../service-dal/model/diagnostic';

// model from web-ui
import { HealthAnswer } from '../model/health-answer.model';
import { AnswerSet } from '../model/answer-set.model';
import { Answer } from '../model/answer.model';
import { Question } from '../model/question.model';

// constants
import * as Constants from '../constants';

/**
 * Transforms a table of consultations (related to one patient) to a table of
 * HealthAnswer (= diagnostics forms) for web-ui
 * @param consultations tabe of consultations
 */
export function ConsultationWithDiagnosticsToHealthAnswer(
  consultations: DalConsultation[],
  form: HealthForm,
): HealthAnswer[] {
  let healthAnswers: HealthAnswer[] = [];

  consultations.forEach((consultation) => {
    let consultationDate = consultation.Date;

    if (typeof consultationDate === 'string') {
      consultationDate = new Date(consultationDate);
    }

    // Intermediate object
    const diagnostics = (consultation as any).diagnostics as Diagnostic[];
    const responses: HealthAnswer[] = DiagnosticTabToHealthAnswerTab(diagnostics, consultationDate, form);
    healthAnswers = healthAnswers.concat(responses);
  });
  return healthAnswers;
}

/**
 * This functions is called by ConsultationWithDiagnosticsToHealthAnswer function for a
 * group of diagnostics done at the same consultation. It
 * transforms a table of diagnostics (intermediates objects) to a table of healthAnswer
 * @param diagnostics: table of diagnostics
 * @param dateOfDiagnostics: the date of all of these diagnostics.
 */
function DiagnosticTabToHealthAnswerTab(
  diagnostics: Diagnostic[],
  dateOfDiagnostics: Date,
  form: HealthForm,
): HealthAnswer[] {
  return diagnostics.map((diagnostic) => {
    const oneHealthAnswer: HealthAnswer = new HealthAnswer();

    oneHealthAnswer.date = diagnostic.created;
    oneHealthAnswer.date_modify = diagnostic.modified;
    oneHealthAnswer.id_diagnostic = diagnostic.id;

    oneHealthAnswer.answerSets = DiagnosticToAnswerSetsMap(diagnostic, form);

    return oneHealthAnswer;
  });
}

function getQuestion(form: HealthForm, set: number, id: string) {
  return form.listeQuestions[set].listeItems.find((e) => e.id === id);
}

function getAnswer(question: Question, id_answer: number, id_detail: number = null) {
  const result = new Answer();
  result.question_id = question.id;
  result.answer = question.options.find((elem) => elem.id === id_answer);
  if (result.answer && result.answer.subOptions) {
    result.option = result.answer.subOptions.find((elem) => elem.id === id_detail);
  }
  result.question = question.nom;
  return result;
}

/**
 * Transforms a diagnostic to a component for HealthAnswer the map of sets of answer
 * @param diagnostic
 */
function DiagnosticToAnswerSetsMap(diagnostic: Diagnostic, form: HealthForm): Map<string, AnswerSet> {
  const sets = new Map<string, AnswerSet>();
  const firstSet: AnswerSet = new AnswerSet();
  const secondSet: AnswerSet = new AnswerSet();
  const temporalitySet: AnswerSet = new AnswerSet();
  // first set : etiology
  firstSet.set_id = Constants.firstSetId;
  firstSet.answers = new Map<string, Answer>();

  const etiologyQuestion = getQuestion(form, 0, Constants.etiologyIdQuestion);
  const etiologyAnswer = getAnswer(etiologyQuestion, diagnostic.etiology, diagnostic.etiologyDetail);
  firstSet.answers.set(Constants.etiologyIdQuestion, etiologyAnswer);

  // second set
  secondSet.set_id = Constants.secondSetId;
  secondSet.answers = new Map<string, Answer>();

  const typeQuestion = getQuestion(form, 1, Constants.typeIdQuestion);
  const typeAnswer = getAnswer(typeQuestion, diagnostic.type, diagnostic.typeDetail);
  secondSet.answers.set(typeAnswer.question_id, typeAnswer);

  const localizationQuestion = getQuestion(form, 1, Constants.localizationIdQuestion);
  const localizationAnswer = getAnswer(localizationQuestion, diagnostic.localization, diagnostic.localizationDetail);
  secondSet.answers.set(localizationAnswer.question_id, localizationAnswer);

  const lateralizationQuestion = getQuestion(form, 1, Constants.lateralizationIdQuestion);
  const lateralizationAnswer = getAnswer(lateralizationQuestion, diagnostic.lateralization);
  secondSet.answers.set(lateralizationAnswer.question_id, lateralizationAnswer);

  temporalitySet.set_id = Constants.temporalitySetId;
  temporalitySet.answers = new Map<string, Answer>();

  const durationQuestion = getQuestion(form, 2, Constants.durationIdQuestion);
  const durationAnswer = getAnswer(durationQuestion, diagnostic.duration);
  secondSet.answers.set(durationAnswer.question_id, durationAnswer);

  const rhythmQuestion = getQuestion(form, 2, Constants.rhythmIdQuestion);
  const rhythmAnswer = getAnswer(rhythmQuestion, diagnostic.rhythm);
  secondSet.answers.set(rhythmAnswer.question_id, rhythmAnswer);

  const currentQuestion = getQuestion(form, 2, Constants.currentIdQuestion);
  const currentAnswer = getAnswer(currentQuestion, diagnostic.current);
  secondSet.answers.set(currentAnswer.question_id, currentAnswer);

  sets.set(Constants.firstSetId, firstSet);
  sets.set(Constants.secondSetId, secondSet);
  sets.set(Constants.temporalitySetId, temporalitySet);

  return sets;
}

/**
 * Transforms a diagnostic answer (web ui) to an objet Diagnostic (transition between healthAnswer
 * and database obejcts)
 * @param answer diagnostic answer (web ui)
 */
export function HealthAnswertoDiagnosticDB(answer: HealthAnswer): Diagnostic {
  const theDiagnostic: Diagnostic = {
    id: answer.id_diagnostic,
    consultationId: answer.consultationId,
    created: answer.date,
    modified: answer.date_modify,
    etiology: null,
    etiologyDetail: null,
    lateralization: null,
    localization: null,
    localizationDetail: null,
    duration: null,
    rhythm: null,
    current: null,
    type: null,
    typeDetail: null,
  };

  answer.answerSets.forEach((set) => {
    set.answers.forEach((oneAnswer) => {
      switch (oneAnswer.question_id) {
        case Constants.etiologyIdQuestion:
          theDiagnostic.etiology = oneAnswer.answer.id;
          theDiagnostic.etiologyDetail = oneAnswer.option && oneAnswer.option.id;
          break;
        case Constants.typeIdQuestion:
          theDiagnostic.type = oneAnswer.answer.id;
          theDiagnostic.typeDetail = oneAnswer.option && oneAnswer.option.id;
          break;
        case Constants.durationIdQuestion:
          theDiagnostic.duration = oneAnswer.answer ? oneAnswer.answer.id : 0;
          break;
        case Constants.rhythmIdQuestion:
          theDiagnostic.rhythm = oneAnswer.answer ? oneAnswer.answer.id : 0;
          break;
        case Constants.currentIdQuestion:
          theDiagnostic.current = oneAnswer.answer ? oneAnswer.answer.id : 0;
          break;
        case Constants.lateralizationIdQuestion:
          theDiagnostic.lateralization = oneAnswer.answer.id;
          break;
        case Constants.localizationIdQuestion:
          theDiagnostic.localization = oneAnswer.answer.id;
          theDiagnostic.localizationDetail = oneAnswer.option && oneAnswer.option.id;
          break;
      }
    });
  });
  return theDiagnostic;
}
