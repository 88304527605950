import { OnDestroy, OnInit, Component, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ɵbo as Ng2SmartTableComponent } from 'ng2-smart-table';
import { Subject } from 'rxjs';

import { Utilities } from 'app/tools/utilities';
import { PatientDataService } from 'app/modules/smartflat-data-access';
import { IPatient } from 'app/view/patient/patient.interface';
import { COMPARE } from 'app/view/patient/sub/functions';
import { LocalStorageEx, LocalStorageObject } from '../../tools/localstorageobject.enum';
import { PatientCellNameComponent } from './patient-cellname/patient-cellname.component';
import { PatientCellValueComponent } from './patient-cellvalue/patient-cellvalue.component';
import { PatientCellDateComponent } from './patient-celldate/patient-celldate.component';
import { PatientCellApartmentComponent } from './patient-cellapartment/patient-cellapartment.component';

import { PatientDataSource } from './patient-data-source';

import { PatientResultFilterStateServiceService } from './patient-result-filter-state-service.service';
import { HistoVersusConsultationService } from 'app/modules/consultation';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';

@Component({
  templateUrl: './patientresults.component.html',
})
export class PatientResultsComponent implements OnInit, OnDestroy {
  @ViewChildren(Ng2SmartTableComponent)
  public ng2SmartTables: QueryList<Ng2SmartTableComponent>;

  /* Patients */
  public selectedPatient: IPatient;
  public patientSource: PatientDataSource;
  public patientSettings: any;

  private patientDefaultSettings = {
    columns: {
      lastName: {
        title: 'Patient.Lastname',
        filter: false,
        type: 'custom',
        valuePrepareFunction: (cell, row) => this.patientCell(cell, row),
        compareFunction: COMPARE,
        renderComponent: PatientCellNameComponent,
      },
      firstName: {
        title: 'Patient.Firstname',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      birthdate: {
        title: 'Patient.BirthDate',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      lastConsultationDate: {
        title: 'Patient.LastConsultation',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
      score: {
        title: 'Patient.Score',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      flatName: {
        title: 'Patient.Apartment',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
      connected: {
        title: 'Patient.MainTable.Header.Online',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellApartmentComponent,
      },
      folderNumber: {
        title: 'Patient.FolderNumber',
        filter: false,
        type: 'custom',
        compareFunction: COMPARE,
        renderComponent: PatientCellValueComponent,
      },
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      custom: [],
      position: 'right',
      columnTitle: '',
    },

    attr: { id: 'patient_table', class: 'table table-striped table-condensed patient_table' },
    noDataMessage: '',
    mode: 'external',
    selectMode: 'single',
    pager: { perPage: 10 },
    hideSubHeader: true,
  };

  private unsubscribe = new Subject<void>();

  constructor(
    public router: Router,
    private translate: TranslateService,
    private patientDataService: PatientDataService,
    private histoVsConsultationService: HistoVersusConsultationService,
    private filterState: PatientResultFilterStateServiceService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.patientSettings = Utilities.deepCopy(this.patientDefaultSettings);
  }

  public ngOnInit() {
    LocalStorageEx.selectedBilanResult = null;
    LocalStorageEx.comparisonEnabled = false;
    this.patientSettings = Utilities.deepCopy(this.patientDefaultSettings);
    this.translate.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
    this.onLangChange();
    this.loadPatients();
    this.histoVsConsultationService.comparaisonReset();

    LocalStorageEx.removeObject(LocalStorageObject.diagnostic_patient);
    LocalStorageEx.lastDiagnosticBackRoutLink = null;
  }

  public ngOnDestroy() {
    this.filterState.saveFilterState(this.patientSource);
    this.filterState.saveCurrentPatient(this.selectedPatient);
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public patientCell(cell: any, rowData: any) {
    // if (this.ng2SmartTables.first.isPagerDisplay) {
    if (this.selectedPatient === rowData) {
      console.log('patientCell patient:' + this.selectedPatient.patientId);
      setTimeout(
        function() {
          if (this.patientSource.recoverRowSelected(rowData)) {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              row.isSelected = true;
            }
          } else {
            const row = this.ng2SmartTables.first.grid.dataSet.findRowByData(rowData);
            if (row) {
              if (this.patientSource.selectFirstRow(rowData, true)) {
                row.isSelected = true;
              } else {
                row.isSelected = false;
                this.ng2SmartTables.first.grid.dataSet.selectRow(row);
              }
            }
          }
        }.bind(this),
        1,
      );
    }
    // }
    return cell;
  }

  /**
   * This method will be called by AuthGuard service before page is unloaded.
   */
  public canDeactivate() {
    return true;
  }

  public onSelectPatient(event) {
    this.selectedPatient = event.isSelected ? event.data : null;
    LocalStorageEx.currentPatientSelected = this.selectedPatient;
  }

  public rowSelect(event) {
    this.onSelectPatient(event);

    // **** BEGIN HACK
    // The ng2-smart-table has a bug (or at least our use of it does)
    // When we first display our table or change pages, the isSelected is true
    // for the first row but it does not have the selected class on the row.
    // This means the row is not highlighted.
    // This is a hack that allows us to reinitialize the state.
    // If the ng2-smart-table component has been upgraded, try without this!
    this.ng2SmartTables.first.grid.getRows().forEach((row: Row) => {
      if (row.isSelected) {
        row.isSelected = false;
        this.changeDetector.detach();
        this.changeDetector.reattach();
        this.changeDetector.detectChanges();
        row.isSelected = true;
      }
    });
    // **** END HACK
  }

  public onSearchPatient(event) {
    this.selectedPatient = null;
  }

  public onLangChange() {
    for (const columnName of Object.keys(this.patientDefaultSettings.columns)) {
      this.patientSettings.columns[columnName].title = this.translate.instant(
        this.patientDefaultSettings.columns[columnName].title,
      );
    }
    /* When translated completely, reset smart table. */
    this.patientSettings = Object.assign({}, this.patientSettings);
  }

  private setFlatNameToPatients(patients: IPatient[]) {
    patients.forEach((patient) => {
      if (patient.currentFlat) {
        patient.flatName = patient.currentFlat.Name;
      }
    });
  }

  private setScoreToPatients(patients: IPatient[]) {
    patients.forEach((patient) => {
      if (patient.fragilityState) {
        patient.score = patient.fragilityState.score;
      }
    });
  }

  private async loadPatients() {
    try {
      const currentPage = this.patientSource ? this.patientSource.currentPage : 1;
      const patients = (await this.patientDataService.getPatientForResults().toPromise()) as IPatient[];

      this.setScoreToPatients(patients);
      this.setFlatNameToPatients(patients);

      const dataSource = new PatientDataSource(patients, this.patientDefaultSettings.attr.id, currentPage);
      if (this.selectedPatient) {
        /**
         * When a patient is created, make sure the patient is shown on first line.
         */
        dataSource.setSort([{ field: 'created', direction: 'desc', compare: COMPARE }]);
      }
      this.patientSource = dataSource;
      this.filterState.restoreFilterState(this.patientSource);
      this.selectedPatient = this.filterState.savedPatient;
      if (this.selectedPatient)
        this.selectedPatient = this.patientSource.rowDatas.find(
          (patient) => patient.patientId === this.selectedPatient.patientId,
        );
    } catch (e) {
      console.error('list error', e);
    }
  }
}
