import { Injectable } from '@angular/core';
import { PatientDataSource } from './patient-data-source';
import { of } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientFilterStateServiceService {
  private filterState = '';
  private sortState: any[];

  public saveFilterState(source: PatientDataSource) {
    if (!source) {
      return;
    }

    this.filterState = source.globalFilter;
    this.sortState = source.getSort();
  }

  public restoreFilterState(patientSource: PatientDataSource) {
    if (!patientSource) {
      return;
    }
    of(this.sortState)
      .pipe(
        filter((o) => !!o),
        delay(100),
      )
      .subscribe((sortState) => patientSource.setSort(sortState, true));
    patientSource.globalFilter = this.filterState;
  }
}
