import { Injectable } from '@angular/core';

@Injectable()
export class MathService {

    public getEsperance(serie: number[]): number {
        let esperance: number = 0;
        for (const value of serie) {
            esperance += value / serie.length;
        }
        return esperance;
    }

    public getVariance(serie: number[]): number {
        const sqareSerie: number[] = new Array<number>();
        for (const value of serie) {
            sqareSerie.push(Math.pow(value, 2));
        }
        const esperanceOfSerie = this.getEsperance(serie);
        const esperanceOfSquareSerie = this.getEsperance(sqareSerie);
        const variance = esperanceOfSquareSerie - Math.pow(esperanceOfSerie, 2);
        return variance;
    }

    public getStandartDeviation(serie: number[]): number {
        const variance = this.getVariance(serie);
        const standartDeviation = Math.pow(variance, 0.5);
        return standartDeviation;
    }
}
