export * from './bilan.service';
import { BilanService } from './bilan.service';
export * from './box.service';
import { BoxService } from './box.service';
export * from './boxType.service';
import { BoxTypeService } from './boxType.service';
export * from './consultation.service';
import { ConsultationService } from './consultation.service';
export * from './dayNight.service';
import { DayNightService } from './dayNight.service';
export * from './diagnostic.service';
import { DiagnosticService } from './diagnostic.service';
export * from './doctor.service';
import { DoctorService } from './doctor.service';
export * from './establishment.service';
import { EstablishmentService } from './establishment.service';
export * from './establishmentPatientRelation.service';
import { EstablishmentPatientRelationService } from './establishmentPatientRelation.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './eventAggregate.service';
import { EventAggregateService } from './eventAggregate.service';
export * from './eventSourceType.service';
import { EventSourceTypeService } from './eventSourceType.service';
export * from './extraction.service';
import { ExtractionService } from './extraction.service';
export * from './flat.service';
import { FlatService } from './flat.service';
export * from './footwear.service';
import { FootwearService } from './footwear.service';
export * from './form.service';
import { FormService } from './form.service';
export * from './grip.service';
import { GripService } from './grip.service';
export * from './identity.service';
import { IdentityService } from './identity.service';
export * from './locomotionBilan.service';
import { LocomotionBilanService } from './locomotionBilan.service';
export * from './locomotionBilanVisualAssessment.service';
import { LocomotionBilanVisualAssessmentService } from './locomotionBilanVisualAssessment.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './patientDoctorRelation.service';
import { PatientDoctorRelationService } from './patientDoctorRelation.service';
export * from './patientFlatRelation.service';
import { PatientFlatRelationService } from './patientFlatRelation.service';
export * from './question.service';
import { QuestionService } from './question.service';
export * from './questionnaire.service';
import { QuestionnaireService } from './questionnaire.service';
export * from './rombergBilan.service';
import { RombergBilanService } from './rombergBilan.service';
export * from './rombergBilanVisualAssessment.service';
import { RombergBilanVisualAssessmentService } from './rombergBilanVisualAssessment.service';
export * from './sensorTor.service';
import { SensorTorService } from './sensorTor.service';
export * from './sensorTorTarget.service';
import { SensorTorTargetService } from './sensorTorTarget.service';
export * from './sensorTorType.service';
import { SensorTorTypeService } from './sensorTorType.service';
export * from './walkingAid.service';
import { WalkingAidService } from './walkingAid.service';
export * from './zone.service';
import { ZoneService } from './zone.service';
export * from './zoneSensorRelation.service';
import { ZoneSensorRelationService } from './zoneSensorRelation.service';
export * from './zoneType.service';
import { ZoneTypeService } from './zoneType.service';
export const APIS = [BilanService, BoxService, BoxTypeService, ConsultationService, DayNightService, DiagnosticService, DoctorService, EstablishmentService, EstablishmentPatientRelationService, EventService, EventAggregateService, EventSourceTypeService, ExtractionService, FlatService, FootwearService, FormService, GripService, IdentityService, LocomotionBilanService, LocomotionBilanVisualAssessmentService, PatientService, PatientDoctorRelationService, PatientFlatRelationService, QuestionService, QuestionnaireService, RombergBilanService, RombergBilanVisualAssessmentService, SensorTorService, SensorTorTargetService, SensorTorTypeService, WalkingAidService, ZoneService, ZoneSensorRelationService, ZoneTypeService];
