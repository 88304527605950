import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { NotificationService } from 'app/modules/core';
import { AuthService } from 'app/modules/core/services/authentification/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public login: string;
  public password: string;
  public loading: boolean;
  public errorMessage: string;
  private readonly redirectUrlAfterFirstLogin: string = '/Patient';

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    // Nothing
    this.loading = false;
  }

  public get isImplicit(): boolean {
    return this.authService.implicit;
  }

  public authentication(): void {
    this.loading = true;
    if (this.authService.implicit) {
      this.authService.initImplicitFlow();
    } else {
      this.authService.loginWithPasswordFlow(this.login, this.password).subscribe(
        () => {
          console.log('LoginComponent: successfully logged in.');
          this.loading = false;
          this.router.navigate([this.redirectUrlAfterFirstLogin]);
          console.log('redirectUrlAfterFirstLogin=' + this.redirectUrlAfterFirstLogin);
        },
        (err) => {
          console.error('LoginComponent: error logging in: ', err);
          this.loading = false;
          this.notificationService.pushErrorNotifications(this.translateService.instant('authenticationError'));
        },
      );
    }
  }
}
