import { TorValue } from './torvalue.model';
import { TorIndicator } from './torindicator.model';

export class TorRow {
  public dayTorIndicator: any;
  public nightTorIndicator: any;

  private _startDay: Date;
  private _endDay: Date;
  private _values: TorValue[];
  private _index: number;

  constructor(startDay: Date, endDay: Date, column: number, index: number) {
    this._startDay = startDay;
    this._endDay = endDay;
    this._values = new Array<TorValue>(column);
    this._index = index;
    this.dayTorIndicator = null;
    this.nightTorIndicator = null;
  }

  public get startDay(): Date {
    return this._startDay;
  }

  public get endDay(): Date {
    return this._endDay;
  }

  public get values(): TorValue[] {
    return this._values;
  }

  public get index(): number {
    return this._index;
  }
}
