import { Component, OnInit, Input, ViewChildren, TemplateRef, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { ICheckupOption } from '../../../../smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../../../tools/localstorageobject.enum';
import { ModalComponent } from '../../../../common';
import { AppComponent } from '../../../../../app.component';

@Component({
  selector: 'checkup-option',
  templateUrl: './optiontd.component.html',
})
export class OptiontdComponent implements OnInit {
  @ViewChildren(ModalComponent) public modals: QueryList<ModalComponent>;
  public readonly waitAnalyseName = 'WaitAnalyse';
  public optionLink: string = '/checkup';

  @Input() public option: ICheckupOption;
  @Input() public globalDisabled: boolean;
  constructor(private app: AppComponent, private router: Router) {}

  public ngOnInit() {
    /* */
  }

  public checkGUID(option: ICheckupOption) {
    switch (option.type) {
      case 'bilan_grip':
        if (LocalStorageEx.currentGripBilanStatus) {
          this.app.showCheckupModal(option.type);
        } else {
          this.router.navigate(['/checkup/grip/protocol']);
          LocalStorageEx.removeObject(LocalStorageObject.currentGripBilanStatus);
        }
        break;
      case 'bilan_locomotion':
        if (LocalStorageEx.currentWalkBilanStatus) {
          this.app.showCheckupModal(option.type);
        } else {
          this.router.navigate(['/checkup/walk/connexion']);
          LocalStorageEx.removeObject(LocalStorageObject.currentWalkBilanStatus);
        }
        break;
      case 'bilan_romberg':
        if (LocalStorageEx.currentRombergBilanStatus) {
          this.app.showCheckupModal(option.type);
        } else {
          this.router.navigate(['/checkup/romberg/installation']);
          LocalStorageEx.removeObject(LocalStorageObject.currentRombergBilanStatus);
        }
        break;
      case 'bilan_pesee':
        if (LocalStorageEx.currentWeightBilanStatus) {
          this.app.showCheckupModal(option.type);
        } else {
          this.router.navigate(['/checkup/weight/installation']);
          LocalStorageEx.removeObject(LocalStorageObject.currentWeightBilanStatus);
        }
        break;
    }
  }
}
