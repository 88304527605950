import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from as fromPromise } from 'rxjs';
import { flatMap, map, filter } from 'rxjs/operators';

import { OAuthService, AuthConfig, JwksValidationHandler, OAuthSuccessEvent } from 'angular-oauth2-oidc';

import { AppConfigurationService } from 'app/app-configuration.service';
import { LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { DoctorDataService } from 'app/modules/smartflat-data-access/src/api/doctor-data.service';
import { EstablishmentDataService } from 'app/modules/smartflat-data-access/src/api/establishment-data.service';

import { Roles } from '../../roles.enum';
import { VersionService } from '../version/version.service';
import { NotificationService } from '../notification/notification.service';
import { Establishment } from 'app/modules/smartflat-data-access/src/model/establishment.model';

@Injectable()
export class AuthService {
  public readonly logoutDisplayModal = new BehaviorSubject<boolean>(false);
  public readonly loginErrorDisplayModal = new BehaviorSubject<boolean>(false);
  public readonly appVersionDisplayModal = new BehaviorSubject<boolean>(false);

  public implicit = true;
  public stateUrl = null;

  public readonly isConnected = new BehaviorSubject<boolean>(false);

  private readonly currentClaims = new BehaviorSubject<any>(null);

  private theEstablisment: Establishment;

  constructor(
    private oauthService: OAuthService,
    private oauthConfig: AuthConfig,
    private appConfigurationService: AppConfigurationService,
    private doctorDataService: DoctorDataService,
    private establishmentDataService: EstablishmentDataService,
    private versionService: VersionService,
    private notificationService: NotificationService,
  ) {
    this.currentClaims.next(this.oauthService.getIdentityClaims());
  }

  public init() {
    this.oauthConfig.oidc = this.implicit;
    this.oauthService.configure(this.oauthConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.currentClaims.subscribe((claims) => {
      if (claims) {
        // update doctor from authenticate user.
        // console.log('User connected', claims);
        // to prevent problem with silent refresh
        // this generate a redirect to home page.
        this.oauthService.clearHashAfterLogin = false;
        this.doctorDataService.getCurrentDoctorId(this.versionService.getAppVersion()).subscribe(
          async ({ id, lastVersion }) => {
            if (LocalStorageEx.currentDoctorId !== id.toString()) {
              // User changed, clear all saved data to prevent conflict.
              localStorage.clear();
            }
            LocalStorageEx.currentDoctorId = id.toString();
            // last version use by the user.
            console.log({ lastVersion });
            if (lastVersion !== this.versionService.getAppVersion()) {
              this.appVersionDisplayModal.next(true);
            }
            this.theEstablisment = await this.establishmentDataService.saveCurrentEstablishment();
            this.isConnected.next(true);
          },
          (error) => {
            // if no id is return we logout.
            // TODO change error message depending of error and translate.
            this.notificationService.pushErrorNotifications('Profil invalide.');
            this.loginErrorDisplayModal.next(true);
            // this.oauthService.logOut();
            this.currentClaims.next(null);
            this.isConnected.next(false);
          },
        );
      } else {
        this.isConnected.next(false);
      }
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'logout')).subscribe(() => {
      this.currentClaims.next(null);
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe(() => {
      const claims = this.oauthService.getIdentityClaims();
      this.currentClaims.next(claims);
    });

    // Load Discovery Document and then try to login the user
    return fromPromise(
      this.oauthService.loadDiscoveryDocument(this.appConfigurationService.wso2Parameters.wellkown).then(() => {
        // This method just tries to parse the token(s) within the url when
        // the auth-server redirects the user back to the web-app
        // It dosn't send the user the the login page
        return this.oauthService.tryLogin({
          onTokenReceived: (opt) => {
            this.stateUrl = opt.state;
            this.oauthService.setupAutomaticSilentRefresh();
          },
        });
      }),
    );
  }
  /**
   * Really log out (see askLogOut for confirmation process)
   */
  public logOut() {
    this.logoutDisplayModal.next(false);
    this.oauthService.logOut();
  }

  /**
   * Ask for log out, UI may show a confirmation dialog
   */
  public askLogOut(): void {
    this.logoutDisplayModal.next(true);
  }

  /**
   * Cancel ask for log out, UI may hide a confirmation dialog
   */
  public cancelLogOut(): any {
    this.logoutDisplayModal.next(false);
  }

  public getIdentityClaims(): any {
    // TODO get more specific data (name, email, role etc...)
    return this.oauthService.getIdentityClaims();
  }

  public initImplicitFlow() {
    this.oauthService.initImplicitFlow(this.stateUrl);
  }

  public loginWithPasswordFlow(login: string, password: string): Observable<void> {
    return fromPromise(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(login, password)).pipe(
      flatMap(() => {
        return this.doctorDataService.getCurrentDoctorId(this.versionService.getAppVersion()).pipe(
          map(({ id, lastVersion }) => {
            LocalStorageEx.currentDoctorId = id.toString();
            // last version use by the user.
            console.log({ lastVersion });
          }),
        );
      }),
    );
  }

  /**
   * Get roles of current user.
   */
  public get roles(): Observable<string[]> {
    return this.currentClaims.pipe(map((claim) => (claim ? claim.roles : [])));
  }

  /**
   * Check if role is present.
   * @param neededRole Role to check
   */
  public hasRole(neededRole: Roles) {
    return this.roles.pipe(map((roles) => (roles.find((r) => r === neededRole) ? true : false)));
  }

  /**
   * Check if role is present at the current moment
   * May change during initialisation, prefer hasRole which return Observable
   * @param neededRole Role to check
   */
  public hasRoleInstant(neededRole: Roles) {
    const instantClaimValue = this.currentClaims.value;
    if (!instantClaimValue || !instantClaimValue.roles) {
      return false;
    }
    return instantClaimValue.roles.find((r) => r === neededRole) ? true : false;
  }

  public get establishment() {
    return this.theEstablisment;
  }
}
