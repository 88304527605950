/**
 * smartflat-dal
 * Smartflat data access layer.
 *
 * OpenAPI spec version: 4.4.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExtractionService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create one export. The result can be download later
     * 
     * @param queryListPatient List of ID of patient to export
     * @param queryListBilan List of ID of bilan to export, if empty all bilan
     * @param includeRawData include raw data
     * @param anonymous data are anonymous
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extractionExport(queryListPatient?: string, queryListBilan?: string, includeRawData?: boolean, anonymous?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public extractionExport(queryListPatient?: string, queryListBilan?: string, includeRawData?: boolean, anonymous?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public extractionExport(queryListPatient?: string, queryListBilan?: string, includeRawData?: boolean, anonymous?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public extractionExport(queryListPatient?: string, queryListBilan?: string, includeRawData?: boolean, anonymous?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (queryListPatient !== undefined) {
            formParams = formParams.append('queryListPatient', <any>queryListPatient) || formParams;
        }
        if (queryListBilan !== undefined) {
            formParams = formParams.append('queryListBilan', <any>queryListBilan) || formParams;
        }
        if (includeRawData !== undefined) {
            formParams = formParams.append('includeRawData', <any>includeRawData) || formParams;
        }
        if (anonymous !== undefined) {
            formParams = formParams.append('anonymous', <any>anonymous) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/Extractions/export`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param queryListPatient 
     * @param queryListBilan 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extractionExportDb(queryListPatient?: string, queryListBilan?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public extractionExportDb(queryListPatient?: string, queryListBilan?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public extractionExportDb(queryListPatient?: string, queryListBilan?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public extractionExportDb(queryListPatient?: string, queryListBilan?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (queryListPatient !== undefined && queryListPatient !== null) {
            queryParameters = queryParameters.set('queryListPatient', <any>queryListPatient);
        }
        if (queryListBilan !== undefined && queryListBilan !== null) {
            queryParameters = queryParameters.set('queryListBilan', <any>queryListBilan);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/Extractions/exportDb`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download export file
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extractionExportDownload(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public extractionExportDownload(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public extractionExportDownload(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public extractionExportDownload(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/Extractions/exportDownload`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get status of export
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extractionExportStatus(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public extractionExportStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public extractionExportStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public extractionExportStatus(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<string>(`${this.basePath}/Extractions/exportStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
