import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { LoggerService } from 'app/services/logger/logger.service';

@Injectable()
export class GripMathService {
  constructor(private logger: LoggerService) {}
  public calculateMean(value1, value2, value3): number | null {
    const values: number[] = [];
    this.pushIfValid(values, value1);
    this.pushIfValid(values, value2);
    this.pushIfValid(values, value3);
    const result = this._calculateMean(values);
    this.logger.info(this.constructor.name + ': calculateMean()=' + result + ' for: ', values);

    return result;
  }

  private _calculateMean(values: number[]): number | null {
    if (values.length === 0) {
      return null;
    }

    return values.reduce((a, b) => Math.max(a, b));
  }

  private pushIfValid(values: number[], value: any): void {
    if (value !== '' && value !== null && value !== undefined) {
      values.push(Number(value));
    }
  }
}
