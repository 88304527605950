/**
 * 3rd-party modules
 */
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule as AngularCommon } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';

/**
 * Our modules
 */
import { CommonModule } from '../../../common';
import { RombergRoutingModule } from './romberg-routing.module';
import { ForcePlatformModule } from '../../../force-platform';
import { environment } from 'environments/environment';

/**
 * Components from this module
 */
import { RombergComponent } from './views/main/romberg.component';
import { RombergInstallationComponent } from './views/part1/installation/romberg-installation.component';
import { RombergPreambleComponent } from './views/part1/preamble/romberg-preamble.component';
import { RombergMeasureComponent } from './views/part1/measure/romberg-measure.component';
import { AssessmentComponent } from './views/part1/assessment/assessment.component';
import { RombergAssessmentComponent } from './views/part1/assessment/romberg-assessment.component';
import { SummaryComponent } from './views/part2/summary/summary.component';
import { StabiloGramComponent } from './views/part2/stabilogram/stabilogram.component';
import { StatoKinesiGramComponent } from './views/part2/statokinesigram/statokinesigram.component';
import { ComparisonComponent } from './views/part2/comparison/comparison.component';
import { LongitudinalComponent } from './views/part2/longitudinal/longitudinal.component';
import { NavbarComponent } from './views/navbar/navbar.component';
/**
 * Services from this module
 */
import { RombergOperationService } from './services/romberg-operation.service';
import { RemoteInteraction } from './services/remote-interaction.service';
import { ForcePlatformOrchestrator } from './services/orchestrator.service';
import { AnalysisResultsRepository } from './services/analysis-result-repository.service';
import { RombergProtocolComponent } from './views/part1/protocol/romberg-protocol.component';
import { CoreModule } from '../../../core/core.module';
import { RombergComparisonService } from './services/romberg-comparaison.service';
import { HistoRombergComponent } from './views/histo/histo-romberg.component';
import { MathModule } from '../../../math/math.module';
import { ShoesModule } from '../../../shoes/shoes.module';
import { MyDatePickerModule } from 'mydatepicker';
import { CheckupCommonModule } from '../checkup-common/checkup-common.module';

@NgModule({
  imports: [
    AngularCommon,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ChartsModule,
    ForcePlatformModule,
    RombergRoutingModule,
    MathModule,
    ShoesModule,
    MyDatePickerModule,
    CheckupCommonModule,
  ],
  declarations: [
    RombergComponent,
    RombergInstallationComponent,
    RombergProtocolComponent,
    RombergPreambleComponent,
    RombergMeasureComponent,
    RombergAssessmentComponent,
    NavbarComponent,
    SummaryComponent,
    AssessmentComponent,
    StabiloGramComponent,
    StatoKinesiGramComponent,
    ComparisonComponent,
    LongitudinalComponent,
    HistoRombergComponent,
  ],
  providers: [
    RombergOperationService,
    RombergComparisonService,
    RemoteInteraction,
    ForcePlatformOrchestrator,
    AnalysisResultsRepository,
    HistoRombergComponent,
  ],
  exports: [],
})
export class RombergModule {
  constructor(router: Router) {
    if (environment.enableRouteDebug) {
      console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
  }
}
