import { Component, Input } from '@angular/core';

@Component({
  selector: 'patient-modal',
  templateUrl: './patient-modal.component.html',
  styleUrls: ['./patient-modal.component.scss'],
})
export class PatientModalComponent {
  @Input() public visible = false;
  @Input() public visibleAnimate = false;
  @Input() public autorizeOnContainerClicked: boolean = true;

  public show(event: any): void {
    this.visible = true;
    setTimeout(() => (this.visibleAnimate = true), 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => (this.visible = false), 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal') && this.autorizeOnContainerClicked) {
      this.hide();
    }
  }
}
