import { Component, Input } from '@angular/core';
import { ICheckupOption } from 'app/modules/smartflat-data-access';

@Component({
  selector: 'checkup-option',
  templateUrl: './optiontd.component.html',
})
export class OptiontdComponent {

  @Input() public option: ICheckupOption;
}
