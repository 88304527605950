import { TorIndicator } from './torindicator.model';

export class TorValue {
  public totalTorIndicator: any;
  public value: number;
  public isThereAValue: boolean;
  public dayNight: string;
  public startDate: Date;
  public endDate: Date;
  public singleDay: boolean;
  private _index: number;

  constructor(
    isThereAValue: boolean,
    dayNight: string,
    startDate: Date,
    endDate: Date,
    singleDay: boolean,
    index: number,
  ) {
    this.isThereAValue = isThereAValue;
    this.dayNight = dayNight;
    this.startDate = startDate;
    this.endDate = endDate;
    this.singleDay = singleDay;
    this._index = index;
    this.totalTorIndicator = null;
  }

  public get index(): number {
    return this._index;
  }
}
