import { DAYCLASS, NIGHTCLASS } from 'app/modules/tor/models/constants.model';

export class TorHeadTable {
    public headValues: string[];
    private _subHeadValues: string[];

    constructor(columnNumber: number) {
        this._subHeadValues = new Array<string>(columnNumber);
        for (let i = 0; i < columnNumber; i++) {
            let value;
            if (i % 2 === 0) {
                value = DAYCLASS;
            } else {
                value = NIGHTCLASS;
            }
            this._subHeadValues[i] = value;
        }
    }

    public get subHeadValues(): string[] {
        return this._subHeadValues;
    }
}
