<textarea
  *ngIf="fieldView.TextArea"
  [readonly]="readonly"
  [placeholder]="placeholder"
  [value]="fieldValue"
  [placeholder]="placeholder"
  (input)="onInput($event)"
  inputvaluecheck
></textarea>
<select *ngIf="fieldView.Select && !test" (change)="onInput($event)">
  <option *ngIf="!fieldValue" selected disabled hidden>{{ placeholder }}</option>
  <option *ngFor="let option of options" [value]="optionValue(option)" [selected]="isSelected(option)">
    {{ optionText(option) }}
  </option>
</select>

<select *ngIf="fieldView.Select && test" (change)="onInput($event)">
  <option *ngIf="!fieldValue" selected disabled hidden>{{ placeholder }}</option>
  <option *ngFor="let option of optionsRearranged" [value]="optionValue(option)" [selected]="isSelected(option)">
    {{ optionText(option) }}
  </option>
</select>
<input
  *ngIf="fieldView.Input && !fieldView.TextArea"
  [readonly]="readonly"
  type="text"
  [step]="step"
  [value]="fieldValue"
  [placeholder]="placeholder"
  (input)="onInput($event)"
  inputvaluecheck
/>
<font *ngIf="fieldView.ReadOnly">{{ fieldText }}</font>
<my-date-picker
  *ngIf="fieldView.Mydatepicker"
  [placeholder]="placeholder"
  [options]="localDateOptions"
  [selDate]="defaultDate"
  (dateChanged)="onDateChanged($event)"
>
</my-date-picker>
