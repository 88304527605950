import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from as fromPromise, zip } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

import { Doctor, Patient, DoctorDataService, PatientDataService } from 'app/modules/smartflat-data-access';
import { LocalStorageEx } from 'app/tools/localstorageobject.enum';

import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';

@Component({
  selector: 'app-patient-delegate',
  templateUrl: './patient-delegate.component.html',
  styleUrls: ['./patient-delegate.component.scss'],
})
export class PatientDelegateComponent {
  @Output()
  public readonly finish = new EventEmitter<void>();

  public doctorSelectedForCheckup: Doctor[];
  public doctorSelectedForResults: Doctor[];

  public doctorDropdownSetting: IDropdownSettings;
  public doctorList: Observable<Doctor[]>;

  private patient: Patient;

  constructor(
    public rolesChecker: RolesCheckService,
    private doctorService: DoctorDataService,
    private patiendService: PatientDataService,
    translateService: TranslateService,
  ) {
    this.doctorDropdownSetting = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableCheckAll: true,
      selectAllText: translateService.instant(i18nKey('Selectioner tous')),
      unSelectAllText: translateService.instant(i18nKey('Déselectioner tous')),
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 999999999999,
      searchPlaceholderText: translateService.instant(i18nKey('Rechercher')),
      noDataAvailablePlaceholderText: translateService.instant(i18nKey('Aucun medecin')),
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
    };

    const currentDoctorId = parseInt(LocalStorageEx.currentDoctorId, 10);
    this.doctorList = fromPromise(this.doctorService.GetAllDoctors()).pipe(
      map((list) => list.filter((d) => d.id !== currentDoctorId)),
      shareReplay(1),
    );
  }

  @Input()
  public get patientSelect(): Patient {
    return this.patient;
  }

  public set patientSelect(value: Patient) {
    this.patient = value;
    zip(this.doctorList, this.patiendService.getDelegations(value.patientId)).subscribe(([list, delegation]) => {
      this.doctorSelectedForCheckup = delegation.checkupDelegations
        .map((id) => list.find((d) => d.id === id))
        .filter((e) => e);
      this.doctorSelectedForResults = delegation.resultDelegations
        .map((id) => list.find((d) => d.id === id))
        .filter((e) => e);
    });
  }

  public onUpdate() {
    const checkupDelegations = this.doctorSelectedForCheckup.map((d) => d.id);
    const resultDelegations = this.doctorSelectedForResults.map((d) => d.id);

    this.patiendService
      .updateDelegations(this.patient.patientId, checkupDelegations, resultDelegations)
      .subscribe(() => this.finish.emit());
  }

  public onCancel() {
    this.finish.emit();
  }
}
