import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameFormat' })
export class NameFormat implements PipeTransform {

    public transform(name: string): string {
        if (name && typeof name === 'string') {
            name = name.toUpperCase();
            return name;
        }
        return name;
    }

}
