import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  public locales: any[];
  public selectLanguage: string;

  constructor(private translate: TranslateService) {
    this.locales = this.translate.getLangs().map((code) => {
      let label = '';
      switch (code) {
        case 'cn':
          label = '中文';
          break;
        case 'fr':
          label = 'Français';
          break;
        case 'en':
          label = 'English';
          break;
      }
      return {
        code,
        label,
      };
    });

    this.selectLanguage = this.translate.currentLang;
  }

  public setLanguage(local: any) {
    this.translate.use(local.target.selectedOptions[0].value);
    this.selectLanguage = this.translate.currentLang;
  }
}
