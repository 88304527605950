import { autoserialize, autoserializeAs } from 'cerialize';

import { SubOption } from './sub-options.model';

/**
 *  Object representing an answer of a question
 */
export class Answer {
    @autoserialize
    public question_id: string; // id answer (same as the question's id)
    @autoserialize
    public question: string;
    @autoserializeAs(SubOption)
    public answer: SubOption;
    @autoserialize
    public option: { id: number, value: string};
}
