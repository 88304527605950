import * as moment from 'moment';

import { TorCalendar } from '../model/torcalendar.model';
import { TorCalendarQuery, TorCalendarStringQuery, CalendarContentStringQuery } from '../model/torcalendarquery.model';
import { TorCalendarContent, TimeSpentInZone } from '../model/torcalendarcontent.model';
import { GatewayState } from '../model/gateway.model';

export function transformTorQueryToJson(calendarQuery: TorCalendarQuery): string {
    const query: TorCalendarStringQuery = transformCalendarQueryToString(calendarQuery);
    const queryJson = JSON.stringify(query);
    return queryJson;
}

export function transformCalendarQueryToString(calendarQuery: TorCalendarQuery): TorCalendarStringQuery {
    const calendarQueryString: TorCalendarStringQuery = new TorCalendarStringQuery();
    calendarQueryString.id = calendarQuery.id;
    calendarQueryString.spaceId = calendarQuery.spaceId;
    calendarQueryString.calendarStartDate = dateToString(calendarQuery.calendarStartDate);
    calendarQueryString.calendarEndDate = dateToString(calendarQuery.calendarEndDate);
    calendarQueryString.calendarContent = new Array<CalendarContentStringQuery>();
    for (const content of calendarQuery.calendarContent) {
        const contentString = new CalendarContentStringQuery();
        contentString.idValue = content.idValue;
        contentString.kindOf = content.kindOf;
        contentString.timePeriod = content.timePeriod;
        contentString.calendarContentStartDate = dateToString(content.calendarContentStartDate);
        contentString.calendarContentEndDate = dateToString(content.calendarContentEndDate);
        calendarQueryString.calendarContent.push(contentString);
    }
    return calendarQueryString;
}

export function dateToString(date: Date): string {
    const newDate = moment(date).add(1, 'hours').toDate();
    return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).format('YYYY-MM-DD');
}

export function transformJsonToTorCalendar(json: any): TorCalendar {
    console.log(json);
    const torCalendar: TorCalendar = new TorCalendar();
    torCalendar.lastSuccesfullTorScriptLaunch = json.lastSuccesfullTorScriptLaunch;
    torCalendar.spaceId = json.spaceId;
    torCalendar.calendarContent = new Array<TorCalendarContent>();
    for (const content of json.calendarContent) {
        console.log(content);
        const torCalendarContent: TorCalendarContent = new TorCalendarContent();
        torCalendarContent.distance = content.distance;
        torCalendarContent.idValue = content.idValue;
        torCalendarContent.maxSpeed = content.maxSpeed;
        torCalendarContent.meanSpeed = content.meanSpeed;
        torCalendarContent.nbrEntranceToilet = content.nbrEntranceToilet;
        torCalendarContent.openingFridge = content.openingFridge;
        torCalendarContent.timeSpentInZone = new Array<TimeSpentInZone>();
        for (const timeSpent of content.timeSpentinZone) {
            const timeSpentInZone = new TimeSpentInZone();
            timeSpentInZone.id = timeSpent.id;
            timeSpentInZone.value = timeSpent.value;
            torCalendarContent.timeSpentInZone.push(timeSpentInZone);
        }
        torCalendar.calendarContent.push(torCalendarContent);
    }
    return torCalendar;
}

export function transformJsonToGateway(json: any): GatewayState {
    const gatewayState: GatewayState = new GatewayState();
    gatewayState.Id = json.Id;
    gatewayState.connected = json.connected;
    gatewayState.ElectroningAddress = json.ElectronicAddress;
    gatewayState.Name = json.Name;
    gatewayState.spaceId = json.spaceId;
    gatewayState.transmitting = json.transmitting;
    return gatewayState;
}
