<div class="measure">
  <div class="row">
    <div class="col-lg-12">
      <p class="page-sub-title checkup_title">{{ 'Sélectionner un bilan à réaliser' | translate }}</p>
      <div class="checkup_item" *ngFor="let option of checkupOptions">
        <checkup-option [option]="option" [globalDisabled]="!(rolesCheck.hasRunCheckup | async)"></checkup-option>
      </div>
    </div>
  </div>
</div>
