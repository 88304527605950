import { Component, OnInit } from '@angular/core';
import { NavItem } from './nav-item.model';
import { Router, NavigationEnd } from '@angular/router';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HistoVersusConsultationService } from '../../modules/consultation';
import { AuthService } from '../../modules/core/services/authentification/auth.service';
import { Roles } from '../../modules/core/roles.enum';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
/**
 * This component is the navigation bar of the application
 */
export class NavbarComponent implements OnInit {
  public buttons: NavItem[] = new Array();
  public selectedItem: NavItem;

  constructor(
    private router: Router,
    private histoVersusConsultationService: HistoVersusConsultationService,
    private roleCheck: RolesCheckService,
    private authService: AuthService,
  ) {}

  /**
   * Initialization of the button with images, label and redirection
   */
  public ngOnInit() {
    const patientButton: NavItem = new NavItem(
      'assets/images/menu1.svg',
      'assets/images/menu1_selected.svg',
      '/Patient',
      'gestion patient',
      'navmenu1',
    );

    const diagButton: NavItem = new NavItem(
      'assets/images/menu2.svg',
      'assets/images/menu2_selected.svg',
      '/PatientConsult',
      'consult_plus',
      'navmenu2',
      /*      Roles.runCheckup, */
    );

    const formerCheckupsButton: NavItem = new NavItem(
      'assets/images/menu3.svg',
      'assets/images/menu3_selected.svg',
      '/PatientResults',
      'resultats anciens bilans',
      'navmenu3',
      Roles.accessResults,
    );

    const settingsButton: NavItem = new NavItem(
      'assets/images/menu4.svg',
      'assets/images/menu4_selected.svg',
      '/Settings',
      'param',
      'navmenu4',
    );

    const exportButton: NavItem = new NavItem(
      'assets/images/menuexport.svg',
      'assets/images/menuexport_selected.svg',
      '/exporting',
      'export',
      'navmenu4',
      Roles.exportResult,
    );

    const helpButton: NavItem = new NavItem(
      'assets/images/menu5.svg',
      'assets/images/menu5_selected.svg',
      '/Help',
      'aide',
      'navhelp',
    );

    this.buttons.push(patientButton);
    this.buttons.push(diagButton);
    this.buttons.push(formerCheckupsButton);
    this.buttons.push(settingsButton);
    this.buttons.push(exportButton);
    this.buttons.push(helpButton);
    this.router.events.subscribe((change: any) => {
      if (change instanceof NavigationEnd) {
        this.onChange(change.url);
        if (
          change.url !== '/checkup/grip/history' &&
          change.url !== '/tor' &&
          change.url !== '/checkup/walk/histo' &&
          change.url !== '/checkup/weight/histo' &&
          change.url !== '/checkup/romberg/histo'
        ) {
          this.histoVersusConsultationService.goToHistory(false);
        } else {
          this.histoVersusConsultationService.goToHistory(true);
        }
      }
    });
  }

  public logout() {
    this.authService.askLogOut();
  }

  public isDesactivated(button: NavItem) {
    if (this.roleCheck.allMenuDisable) {
      return of(true);
    }
    if (button.neededRole) {
      return this.authService.hasRole(button.neededRole).pipe(map((r) => !r));
    }
    return of(false);
  }

  /**
   * Function called when the user selects a button.
   * @param button Button selected
   */
  private onSelect(button: NavItem) {
    if (this.selectedItem !== undefined) {
      this.selectedItem.imgSrcUrl = this.selectedItem.imgSrcUrlUnClicked;
    }
    button.imgSrcUrl = button.imgSrcUrlClicked;
    this.selectedItem = button;
  }

  private onChange(url: string) {
    let baseUrl = url.slice(1, url.length);
    baseUrl = url.split('/')[1];
    baseUrl = '/' + baseUrl;
    for (const button of this.buttons) {
      if (button.routerLink === baseUrl) {
        this.onSelect(button);
        break;
      }
    }
  }
}
