import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService, Notification, NotificationLevel } from 'app/modules/core';

@Component({
    selector: 'core-notification',
    templateUrl: './notification.component.html',
})
export class NotificationComponent {

    @Input()
    public message: string;

    @Input()
    public level: string;
}
