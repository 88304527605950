// tslint:disable:max-classes-per-file

export class TorCalendarQuery {
  public spaceId: number;
  public calendarStartDate: Date;
  public calendarEndDate: Date;
  public calendarContent: CalendarContentQuery[];
  public id: string;

  constructor() {
    this.calendarContent = new Array<CalendarContentQuery>();
  }
}

export class CalendarContentQuery {
  public kindOf: string;
  public idValue: number;
  public calendarContentStartDate: Date;
  public calendarContentEndDate: Date;
  public timePeriod: string;
}

export class TorCalendarStringQuery {
  public spaceId: number;
  public calendarStartDate: string;
  public calendarEndDate: string;
  public calendarContent: CalendarContentStringQuery[];
  public id: string;

  constructor() {
    this.calendarContent = new Array<CalendarContentStringQuery>();
  }
}

export class CalendarContentStringQuery {
  public kindOf: string;
  public idValue: number;
  public calendarContentStartDate: string;
  public calendarContentEndDate: string;
  public timePeriod: string;
}
