// tslint:disable:max-classes-per-file

export class TorCalendarContent {
  public kindOf: string;
  public idValue: number;
  public timePeriod: string;
  public openingFridge: number;
  public nbrEntranceToilet: number;
  public distance: number;
  public meanSpeed: number;
  public maxSpeed: number;
  public timeSpentInZone: TimeSpentInZone[];
}

export class TimeSpentInZone {
  public id: number;
  public value: number;
}
