import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TimeService } from 'app/modules/time/services/time.service';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';
import { LocalHourPipe } from 'app/modules/time/pipes/localhour.pipe';

@NgModule({
  imports: [TranslateModule.forChild()],
  declarations: [LocalDatePipe, LocalHourPipe],
  providers: [TimeService, LocalDatePipe, LocalHourPipe],
  exports: [LocalDatePipe, LocalHourPipe],
})
export class TimeModule {}
