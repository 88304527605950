/**
 * Angular 2 decorators and services
 */
import { Component, Input, OnChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { gaugeChart, GaugeInterface } from 'gauge-chart';

/**
 * GaugeChart Component
 */
@Component({
  selector: 'gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit, OnChanges {
  @ViewChild('gaugeArea', { static: true })
  public gaugeArea: ElementRef<Element>;

  @Input() public canvasWidth: number;
  @Input() public needleValue: number;
  @Input() public centralLabel: string;
  @Input() public options;
  @Input() public wrapOptions?;
  @Input() public name?: string;
  @Input() public nameFont?: string;
  @Input() public bottomLabel?: string;
  @Input() public bottomLabelFont?: string;

  public nameMargin: string;
  public bottomLabelMargin: string;

  private element: Element;
  private gaugeChart: GaugeInterface;

  public ngOnInit() {
    // calculate styles for name and bottomLabel
    if (this.name) {
      if (!this.nameFont) {
        this.nameFont = '' + Math.round(this.canvasWidth / 15);
      }
      this.nameMargin = '' + Math.round(+this.nameFont / 4);
    }

    if (this.bottomLabel) {
      if (!this.bottomLabelFont) {
        this.bottomLabelFont = '' + Math.round(this.canvasWidth / 10);
      }
      this.bottomLabelMargin = '-' + this.bottomLabelFont;
    }

    if (this.optionsCheck()) {
      this.element = this.gaugeArea.nativeElement;
      this.options.centralLabel = this.centralLabel;
      this.gaugeChart = gaugeChart(this.element, this.canvasWidth, this.options); // Drawing and updating the chart
      this.gaugeChart.updateNeedle(this.needleValue);
    }
  }

  public ngOnChanges(changes) {
    if (changes.needleValue && !changes.needleValue.firstChange) {
      if (changes.needleValue.currentValue !== changes.needleValue.previousValue) {
        this.needleValue = changes.needleValue.currentValue;
        this.gaugeChart.updateNeedle(this.needleValue);
      }
    }
    if (changes.centralLabel && !changes.centralLabel.firstChange) {
      if (changes.centralLabel.currentValue !== changes.centralLabel.previousValue) {
        this.gaugeChart.removeGauge();
        this.centralLabel = changes.centralLabel.currentValue;
        this.options.centralLabel = this.centralLabel;
        this.gaugeChart = gaugeChart(this.element, this.canvasWidth, this.options);
        this.gaugeChart.updateNeedle(this.needleValue);
      }
    }
    if (changes.centralLabel && !changes.centralLabel.firstChange) {
      if (changes.bottomLabel.currentValue !== changes.bottomLabel.previousValue) {
        console.log(changes.bottomLabel.currentValue);
      }
    }
  }

  private optionsCheck() {
    if (this.canvasWidth === null) {
      console.warn('gauge-chart warning: canvasWidth is not specified!');
      return false;
    } else if (this.needleValue === null) {
      console.warn('gauge-chart warning: needleValue is not specified!');
      return false;
    }
    if (!this.centralLabel) {
      this.centralLabel = '';
    }
    return true;
  }
}
