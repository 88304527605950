import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationActionsService, NavigationActionLink, NavigationActionClick } from 'app/modules/core';

@Component({
  selector: 'wiiboard-activation',
  templateUrl: './wiiBoardActivation.component.html',
  styleUrls: ['./wiiBoardActivation.component.scss'],
})
export class WiiBoardActivationComponent implements OnInit {
  constructor(private navigationActionService: NavigationActionsService) {}

  public ngOnInit(): void {
    const action1 = new NavigationActionLink('Précédent', '/Settings');
    const action2 = new NavigationActionLink('Suivant', '/Settings');
    const actions = [action1, action2];
    this.navigationActionService.pushNavigationActions(actions);
  }
}
