import { Serialize, Deserialize, INewable } from 'cerialize';

export function StringMap<T>(targetType: INewable<T>) {
  return {
    Serialize: (map: Map<string, T>): any => {
      const obj: object = {};
      map.forEach((value: any, key: string) => {
        obj[key] = Serialize(value);
      });
      return obj;
    },
    Deserialize: (json: any) => {
      const map: Map<string, T> = new Map<string, T>();
      Object.keys(json).forEach((key: string) => {
        map.set(key, Deserialize(json[key], targetType));
      });
      return map;
    },
  };
}
