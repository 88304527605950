import { Footwear } from '../service-dal/model/footwear';
import { FootWear } from '../model/footwears.model';

/**
 * This function convert a FootWear from dataBase type
 * to FootWear type for web-ui
 * @param footWear bilan in dataBase type
 */
export function DalFootWearsToFootWears(footWear: Footwear): FootWear {
    const result: FootWear = new FootWear();
    result.Id = footWear.Id;
    result.Alias = footWear.Alias;
    result.Culture = footWear.Culture;
    result.Translate = footWear.Translate;
    return result;
}
