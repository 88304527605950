import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { TorWeekTableService } from 'app/modules/tor/services/torweektable.service';
import { TorMonthTableService } from 'app/modules/tor/services/tormonthtable.service';
import { TorYearTableService } from 'app/modules/tor/services/toryeartable.service';
import { TorTable } from 'app/modules/tor';
import { TorCalendarQuery, CalendarContentQuery, TorCalendar, GatewayState } from 'app/modules/smartflat-data-access';
import { TorDataService } from 'app/modules/smartflat-data-access/src/api/tor.service';
import { FlatDataService } from 'app/modules/smartflat-data-access/src/api/flat-data.service';
import { LocalStorageEx } from '../../../tools/localstorageobject.enum';
import { PeriodType } from '../models/timefrequency.model';
import { FlatEventsDataResponse } from 'app/modules/smartflat-data-access/src/service-dal';

@Injectable()
export class TorService {
  constructor(
    private torWeekTableService: TorWeekTableService,
    private torMonthTableService: TorMonthTableService,
    private torYearTableService: TorYearTableService,
    private torCaptorService: TorDataService,
    private flatDataService: FlatDataService,
  ) {}

  public constructTorTable(
    startDate: Date,
    endDate: Date,
    aggregationType: PeriodType,
    events: FlatEventsDataResponse[],
    indicatorSelected: string,
    subIndicatorSelected: string,
  ): TorTable {
    const torTable = this.timeTableService(aggregationType).getTimeTable(startDate, endDate);

    let index = 0;
    events.forEach((data) => {
      if (torTable.rows[index]) {
        if (new Date(data.day.total.endDate).getTime() - 24 * 3600 * 1000 < torTable.rows[index].startDay.getTime()) {
          return;
        }
        console.log('Transform ', {
          indicatorSelected,
          subIndicatorSelected,
          data: data.day.total,
          idx: index,
          dest: torTable.rows[index],
        });

        torTable.rows[index].dayTorIndicator = this.getValue(data.day.total[indicatorSelected], subIndicatorSelected);
        torTable.rows[index].nightTorIndicator = this.getValue(
          data.night.total[indicatorSelected],
          subIndicatorSelected,
        );

        let nbDay = 0;
        let nbNight = 0;
        torTable.rows[index].values.forEach((row) => {
          if (row.isThereAValue) {
            if (row.dayNight === 'day') {
              row.totalTorIndicator = this.getValue(data.day.detail[nbDay][indicatorSelected], subIndicatorSelected);
              nbDay++;
            } else {
              row.totalTorIndicator = this.getValue(
                data.night.detail[nbNight][indicatorSelected],
                subIndicatorSelected,
              );
              nbNight++;
            }
          }
        });
      }
      index++;
    });

    return torTable;
  }

  public async getSubspacesBySpace(spaceId: number) {
    const subspaces = await this.torCaptorService.getSubspacesBySpace(spaceId);
    return subspaces;
  }

  public areCaptorsActivatedAll(): Observable<GatewayState> {
    return this.torCaptorService.areCaptorsActivatedAll();
  }

  public setCaptorsActivation(spaceId: number, mute: boolean): Observable<boolean> {
    console.log('space id     ', spaceId);
    return this.torCaptorService.setCaptorsActivation(spaceId, mute);
  }

  public areCaptorsActivated(spaceId: number): Observable<GatewayState> {
    return this.torCaptorService.areCaptorsActivated(spaceId);
  }

  private timeTableService(aggregationType: PeriodType) {
    switch (aggregationType) {
      case PeriodType.Week:
        return this.torWeekTableService;
      case PeriodType.Month:
        return this.torMonthTableService;
      case PeriodType.Year:
        return this.torYearTableService;
    }
  }

  private getValue(valueList: Array<{ alias: string; value: number }>, wantedAlias: string) {
    const cpl = valueList.find((c) => c.alias === wantedAlias);

    return cpl ? cpl.value : null;
  }
}
