import { SubOption } from './sub-options.model';

/**
 * Object representing a questions in a diagnostic form
 */
export class Question {
  public id: string; // TODO: eventuallly I think an ID will be needed for the response
  public type: number;
  public nom: string;
  public options: SubOption[];
}
