import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputvaluecheck',
})
export class InputValuePipe implements PipeTransform {
    public transform(value: any, arg1: any): any {
        if (arg1) {
            return this.parseDecimalAbs(String(value), this.getPrecision(String(arg1)));
        }
        return value;
    }

    public getPrecision(step: string): number {
        const pointIndex = step.indexOf('.');
        return (pointIndex !== -1) ? (step.length - pointIndex - 1) : 0;
    }

    public isNumeric(value: any): boolean {
        return !isNaN(Number(value));
    }

    public parseDecimal(value: string, precision: number = 2): string {

        let clean = value.replace(/[^-0-9\.]/g, '');
        const negativeCheck = clean.split('-');
        const decimalCheck = clean.split('.'); // TODO: internationalization... '.' is not necessarily correct for all locales

        if (negativeCheck[1] !== undefined) {
            negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
            clean = negativeCheck[0] + '-' + negativeCheck[1];
            if (negativeCheck[0].length > 0) {
                clean = negativeCheck[0];
            }
        }
        if (decimalCheck[1] !== undefined) {
            if (precision > 0 && decimalCheck[1].length >= 0) {
                decimalCheck[1] = decimalCheck[1].slice(0, precision);
                clean = decimalCheck[0] + '.' + decimalCheck[1];
            } else if (precision === 0) clean = decimalCheck[0];
        }
        return clean;
    }

    public parseDecimalAbs(value: string, precision: number = 2): string {

        let clean = value.replace(/[^0-9\.]/g, '');
        const decimalCheck = clean.split('.'); // TODO: internationalization... '.' is not necessarily correct for all locales

        if (decimalCheck[1] !== undefined) {
            if (precision > 0 && decimalCheck[1].length >= 0) {
                decimalCheck[1] = decimalCheck[1].slice(0, precision);
                clean = decimalCheck[0] + '.' + decimalCheck[1];
            } else if (precision === 0) clean = decimalCheck[0];
        }
        return clean;
    }
}
