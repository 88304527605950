import { autoserialize, autoserializeAs } from 'cerialize';

/**
 * Object representing options possible in questions. (when a user select a answer, sometimes there are options for the answer.)
 */
export class SubOption {
  @autoserialize
  public id?: number;

  @autoserialize
  public question: string;

  @autoserialize
  public subOptions: Array<{ id: number; value: string }>;
}
