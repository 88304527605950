<div class="page-title">{{ 'Nouvelle acquisition' | translate }}</div>

<div class="questionnaire">
  <current-patient-banner [consultationStep]="1"> </current-patient-banner>
  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="ready">
        <h2 class="page-sub-title">
          {{ currentQuestionnary.nom }} <span class="info">{{ 'questionnary.mcq' | translate }}</span>
        </h2>
        <div *ngFor="let questionSet of currentQuestionnary.listeQuestions" class="questionSet">
          <p class="form-title">{{ questionSet.nom }}</p>

          <div *ngFor="let question of questionSet.listeItems">
            <p class="form-sub-title">{{ question.question }}</p>

            <div class="questions_row">
              <span *ngIf="question.typeQuestion === 'checkbox'">*</span>

              <div *ngFor="let option of question.options; let i = index">
                <ng-template #input>
                  <input
                    type="text"
                    *ngIf="question.typeQuestion === 'input'"
                    placeholder="{{ responsesTextAtPosition(question.position) }}"
                    name="{{ question.position }}"
                    id="{{ question.position }}"
                    [(ngModel)]="valueInput[question.position].value"
                    (keyup)="addInput(question.position)"
                    (change)="addInput(question.position)"
                    (click)="currentPos = question.position"
                  />

                  <input
                    type="date"
                    *ngIf="question.typeQuestion === 'input-date'"
                    placeholder="{{ responsesTextAtPosition(question.position) }}"
                    name="{{ question.position }}"
                    id="{{ question.position }}"
                    [(ngModel)]="valueInput[question.position].value"
                    (keyup)="addInput(question.position)"
                    (change)="addInput(question.position)"
                    (click)="currentPos = question.position"
                  />

                  <input
                    min="0"
                    type="number"
                    *ngIf="question.typeQuestion === 'inputNumber'"
                    placeholder="{{ responsesTextAtPosition(question.position) }}"
                    name="{{ question.position }}"
                    id="{{ question.position }}"
                    [(ngModel)]="valueInput[question.position].value"
                    (keyup)="addInput(question.position)"
                    (change)="addInput(question.position)"
                    (click)="currentPos = question.position"
                  />
                </ng-template>

                <div
                  *ngIf="
                    question.typeQuestion !== 'input' &&
                      question.typeQuestion !== 'input-date' &&
                      question.typeQuestion !== 'inputNumber';
                    else input
                  "
                >
                  <div class="form_label">
                    <input
                      type="{{ question.typeQuestion }}"
                      [checked]="checkIfCheck(option.value, question.position, i)"
                      class="{{ question.typeQuestion }}"
                      name="Q{{ question.position }}"
                      id="Q{{ question.position }}A{{ i }}"
                      (click)="addAnswer(question.position, option, question.typeQuestion, i)"
                    />
                    <label for="Q{{ question.position }}A{{ i }}">
                      <span class="{{ question.typeQuestion }}">{{ option.value }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button
          *ngIf="currentIndex === 0"
          class="btn btn-primary general-button btn-left"
          [routerLink]="['/FormsSelector']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'Précédent' | translate }}
        </button>

        <button *ngIf="currentIndex > 0" class="btn btn-primary btn-left" (click)="backQuestionnary()">
          {{ 'Précédent' | translate }}
        </button>

        <button
          *ngIf="currentIndex >= idTable.length - 1"
          class="btn btn-primary"
          [routerLink]="['/checkup']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="nextQuestionnary(true)"
          [disabled]="!valid"
        >
          {{ 'PasserBilan' | translate }}
        </button>

        <button
          *ngIf="currentIndex < idTable.length - 1"
          class="btn btn-primary"
          (click)="nextQuestionnary(false)"
          [disabled]="!valid"
        >
          {{ 'SaveAndNext' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
