import { Serialize, GenericDeserialize, INewable } from 'cerialize';

import { Consultation, Patient } from 'app/modules/smartflat-data-access';
import { QuestionnaryAnswers } from 'app/modules/smartflat-data-access';
import { GripMeasure } from '../modules/checkup/modules/grip';
import { BilanDetailed } from 'app/model/bilan-detailed.model';
import { ShoesSelectorValues } from 'app/modules/force-platform';
import { Assessment } from 'app/modules/checkup/modules/walk/models/assessment.model';
import { AssessmentRomberg } from 'app/modules/checkup/modules/romberg/models/assessment.model';
import { DataEncryption } from 'app/tools/data-encryption';
import { Establishment } from '../modules/smartflat-data-access/src/service-dal';
import { BilanResults } from '../modules/smartflat-data-access/src/model/bilan.model';
import { IHealthAnswer } from '../view/diagnostic-list/diagnostic-list.component';

/**
 * Declare item key unique of localStorage using class or interface name
 */
export enum LocalStorageObject {
  // CLEAN :
  currentPatient,
  currentPatientSelected,
  currentConsultation,
  currentBilan,
  currentGripCheckup,
  histoGripCheckup,
  IdQuestionnaries,
  currentGripBilanStatus,
  filteredSearch,
  bilanResults,
  selectedBilanResult,
  comparisonEnabled,
  dateSelected,
  cocheWalk,
  cocheRomberg,
  cocheWeight,
  cocheGrip,
  diagnosticStatus,

  // Walk
  currentWalkPreambleCheckup,
  currentWalkAssessmentCheckup,
  currentWalkRecordingSession,
  histoWalkRecordingSession,
  currentWalkBilanStatus,
  histoWeightResultBilan,
  currentWeightBilanStatus,
  // Romberg
  currentRombergPreambleCheckup,
  currentRombergAssessmentCheckup,
  currentRombergRecordingSession,
  histoRombergRecordingSession,
  currentRombergBilanStatus,

  // TO CLEAN :
  lastDiagnosticBackRoutLink,
  diagnostic_patient,
  hiddenDiagnostics,
  savedDiagnostics,
  selectedDiagnosticOptionId,
  selectedDiagnosticOptionValue,
  selectedQuestion,
  Questionnaries,
  NewDiagnostics,
  CurrentPageInConsult,
  ConsultationInProgress,
  Height,
  Weight,
  MeasuredWeight,
  boardConnected,

  currentDoctorId,
  histoBoolean,

  weightResultBilan,
  // Rapport generation
  currentConsultRapport,
  currentRapportPatient,
  currentRapportDocteur,
  currentRapportType,
  currentEstablishment,
  MeasurementWeight,
}
/**
 * LocalStorage extended
 *
 * It allows to insert, select and delete an object into localStorage
 *
 * Usage:
 *  import {LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum'
 *  LocalStorageEx.setObject(LocalStorageObject.Patient, MyObject);
 *  const patient = LocalStorageEx.getObject(LocalStorageObject.Patient) as IPatient;
 */
export class LocalStorageEx {
  public static store<T>(key: LocalStorageObject, value: T | T[]): void {
    const val = Serialize(value);
    localStorage.setItem(LocalStorageObject[key], JSON.stringify(val));
  }

  public static restore<T>(key: LocalStorageObject, type: INewable<T>): T {
    const value = localStorage.getItem(LocalStorageObject[key]);
    if (value) {
      return GenericDeserialize(JSON.parse(value), type);
    }
    return null;
  }

  public static restoreArray<T>(key: LocalStorageObject, type: INewable<T>): T[] {
    const value = localStorage.getItem(LocalStorageObject[key]);
    if (value) {
      return JSON.parse(value).map((oneValue) => GenericDeserialize(oneValue, type));
    }
    return null;
  }

  public static setObject(key: LocalStorageObject, value): void {
    if (value !== null) {
      localStorage.setItem(LocalStorageObject[key], DataEncryption.encrypt(JSON.stringify(value)));
    }
  }

  public static getObject(key: LocalStorageObject): any {
    if (
      localStorage.getItem(LocalStorageObject[key]) !== undefined &&
      localStorage.getItem(LocalStorageObject[key]) !== null
    ) {
      const value = DataEncryption.decrypt(localStorage.getItem(LocalStorageObject[key]));

      return value && JSON.parse(value);
    }
    return '';
  }

  public static removeObject(key: LocalStorageObject): void {
    localStorage.removeItem(LocalStorageObject[key]);
  }

  /**
   * This function allow checking if an object is in the local storage or not
   * @param key object's key
   */
  public static checkObject(key: LocalStorageObject): boolean {
    return localStorage.getItem(LocalStorageObject[key]) !== null;
  }

  /**
   * This function return the string stored in the localStorage
   * @param key object's key
   */

  public static set selectedDiagnosticOptionId(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.selectedDiagnosticOptionId, value);
  }

  public static get selectedDiagnosticOptionId(): number {
    return LocalStorageEx.getObject(LocalStorageObject.selectedDiagnosticOptionId);
  }

  public static set selectedDiagnosticOptionValue(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.selectedDiagnosticOptionValue, value);
  }
  public static get selectedDiagnosticOptionValue(): string {
    return LocalStorageEx.getObject(LocalStorageObject.selectedDiagnosticOptionValue);
  }

  public static get diagnosticStatus(): string {
    return LocalStorageEx.getObject(LocalStorageObject.diagnosticStatus);
  }

  public static set diagnosticStatus(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.diagnosticStatus, value);
  }

  public static set selectedQuestion(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.selectedQuestion, value);
  }

  public static get selectedQuestion(): string {
    return LocalStorageEx.getObject(LocalStorageObject.selectedQuestion);
  }
  public static set hiddenDiagnostics(value: IHealthAnswer[]) {
    LocalStorageEx.setObject(LocalStorageObject.hiddenDiagnostics, value);
  }

  public static get hiddenDiagnostics(): IHealthAnswer[] {
    return LocalStorageEx.getObject(LocalStorageObject.hiddenDiagnostics);
  }

  public static set savedDiagnostics(value: IHealthAnswer[]) {
    LocalStorageEx.setObject(LocalStorageObject.savedDiagnostics, value);
  }

  public static get savedDiagnostics(): IHealthAnswer[] {
    return LocalStorageEx.getObject(LocalStorageObject.savedDiagnostics);
  }

  public static get cocheWalk(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.cocheWalk);
  }

  public static set cocheWalk(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.cocheWalk, value);
  }
  public static get cocheRomberg(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.cocheRomberg);
  }

  public static set cocheRomberg(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.cocheRomberg, value);
  }

  public static get cocheWeight(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.cocheWeight);
  }

  public static set cocheWeight(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.cocheWeight, value);
  }
  public static get cocheGrip(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.cocheGrip);
  }

  public static set cocheGrip(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.cocheGrip, value);
  }

  public static get currentEstablishment(): Establishment {
    return LocalStorageEx.getObject(LocalStorageObject.currentEstablishment);
  }

  public static set currentEstablishment(value: Establishment) {
    LocalStorageEx.setObject(LocalStorageObject.currentEstablishment, value);
  }

  public static get currentConsultRapport(): Consultation {
    return LocalStorageEx.getObject(LocalStorageObject.currentConsultRapport);
  }

  public static set currentConsultRapport(value: Consultation) {
    LocalStorageEx.setObject(LocalStorageObject.currentConsultRapport, value);
  }

  public static get currentPatient(): Patient {
    return LocalStorageEx.getObject(LocalStorageObject.currentPatient);
  }

  public static set currentPatient(value: Patient) {
    LocalStorageEx.setObject(LocalStorageObject.currentPatient, value);
  }

  public static get currentPatientSelected(): Patient {
    return LocalStorageEx.getObject(LocalStorageObject.currentPatientSelected);
  }

  public static set currentPatientSelected(value: Patient) {
    LocalStorageEx.setObject(LocalStorageObject.currentPatientSelected, value);
  }

  public static get currentConsultation(): Consultation {
    return LocalStorageEx.getObject(LocalStorageObject.currentConsultation);
  }

  public static set currentConsultation(value: Consultation) {
    LocalStorageEx.setObject(LocalStorageObject.currentConsultation, value);
  }

  public static get Height(): number {
    return LocalStorageEx.getObject(LocalStorageObject.Height);
  }

  public static set Height(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.Height, value);
  }

  public static get Weight(): number {
    return LocalStorageEx.getObject(LocalStorageObject.Weight);
  }

  public static set Weight(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.Weight, value);
  }

  public static get MeasuredWeight(): number {
    return LocalStorageEx.getObject(LocalStorageObject.MeasuredWeight);
  }

  public static set MeasuredWeight(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.MeasuredWeight, value);
  }

  // Permet de savoir si le rapport Patient a été imprimé
  public static get currentRapportPatient(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.currentRapportPatient);
  }

  public static set currentRapportPatient(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.currentRapportPatient, value);
  }

  // Permet de savoir si le rapport Docteur a été imprimé
  public static get currentRapportDocteur(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.currentRapportDocteur);
  }

  public static set currentRapportDocteur(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.currentRapportDocteur, value);
  }

  public static get currentRapportType(): string {
    return LocalStorageEx.getObject(LocalStorageObject.currentRapportType);
  }

  public static set currentRapportType(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.currentRapportType, value);
  }

  public static get currentBilan(): BilanDetailed {
    return LocalStorageEx.getObject(LocalStorageObject.currentBilan);
  }

  public static set currentBilan(value: BilanDetailed) {
    LocalStorageEx.setObject(LocalStorageObject.currentBilan, value);
  }

  public static get questionnaryAnswers(): QuestionnaryAnswers[] {
    return LocalStorageEx.getObject(LocalStorageObject.IdQuestionnaries);
  }

  public static set questionnaryAnswers(value: QuestionnaryAnswers[]) {
    LocalStorageEx.setObject(LocalStorageObject.IdQuestionnaries, value);
  }

  public static get currentGripCheckup(): GripMeasure {
    return LocalStorageEx.getObject(LocalStorageObject.currentGripCheckup);
  }

  public static set currentGripCheckup(value: GripMeasure) {
    LocalStorageEx.setObject(LocalStorageObject.currentGripCheckup, value);
  }

  public static get histoGripCheckup(): GripMeasure {
    return LocalStorageEx.getObject(LocalStorageObject.histoGripCheckup);
  }

  public static set histoGripCheckup(value: GripMeasure) {
    LocalStorageEx.setObject(LocalStorageObject.histoGripCheckup, value);
  }

  public static get currentWalkPreambleCheckup(): ShoesSelectorValues {
    return LocalStorageEx.getObject(LocalStorageObject.currentWalkPreambleCheckup);
  }

  public static set currentWalkPreambleCheckup(value: ShoesSelectorValues) {
    LocalStorageEx.setObject(LocalStorageObject.currentWalkPreambleCheckup, value);
  }

  public static get currentWalkAssessmentCheckup(): Assessment[] {
    return LocalStorageEx.getObject(LocalStorageObject.currentWalkAssessmentCheckup);
  }

  public static set currentWalkAssessmentCheckup(value: Assessment[]) {
    LocalStorageEx.setObject(LocalStorageObject.currentWalkAssessmentCheckup, value);
  }

  public static get currentWalkRecordingSession(): string {
    return LocalStorageEx.getObject(LocalStorageObject.currentWalkRecordingSession);
  }

  public static set currentWalkRecordingSession(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.currentWalkRecordingSession, value);
  }

  public static get histoWalkRecordingSession(): string {
    return LocalStorageEx.getObject(LocalStorageObject.histoWalkRecordingSession);
  }

  public static set histoWalkRecordingSession(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.histoWalkRecordingSession, value);
  }

  // Romberg
  public static get currentRombergPreambleCheckup(): ShoesSelectorValues {
    return LocalStorageEx.getObject(LocalStorageObject.currentRombergPreambleCheckup);
  }

  public static set currentRombergPreambleCheckup(value: ShoesSelectorValues) {
    LocalStorageEx.setObject(LocalStorageObject.currentRombergPreambleCheckup, value);
  }

  public static get currentRombergAssessmentCheckup(): AssessmentRomberg[] {
    return LocalStorageEx.getObject(LocalStorageObject.currentRombergAssessmentCheckup);
  }

  public static set currentRombergAssessmentCheckup(value: AssessmentRomberg[]) {
    LocalStorageEx.setObject(LocalStorageObject.currentRombergAssessmentCheckup, value);
  }

  public static get currentRombergRecordingSession(): string {
    return LocalStorageEx.getObject(LocalStorageObject.currentRombergRecordingSession);
  }

  public static set currentRombergRecordingSession(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.currentRombergRecordingSession, value);
  }

  public static get currentRombergBilanStatus(): string {
    return LocalStorageEx.getObject(LocalStorageObject.currentRombergBilanStatus);
  }

  public static set currentRombergBilanStatus(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.currentRombergBilanStatus, value);
  }

  public static get histoRombergRecordingSession(): string {
    return LocalStorageEx.getObject(LocalStorageObject.histoRombergRecordingSession);
  }

  public static set histoRombergRecordingSession(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.histoRombergRecordingSession, value);
  }

  public static set lastDiagnosticBackRoutLink(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.lastDiagnosticBackRoutLink, value);
  }

  public static get lastDiagnosticBackRoutLink(): string {
    return LocalStorageEx.getObject(LocalStorageObject.lastDiagnosticBackRoutLink);
  }

  public static get currentDoctorId(): string {
    return LocalStorageEx.getObject(LocalStorageObject.currentDoctorId);
  }

  public static set currentDoctorId(value: string) {
    LocalStorageEx.setObject(LocalStorageObject.currentDoctorId, value);
  }

  public static set histoBoolean(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.histoBoolean, value);
  }

  public static get histoBoolean(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.histoBoolean);
  }

  public static set weightResultBilan(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.weightResultBilan, value);
  }

  public static get weightResultBilan(): number {
    return LocalStorageEx.getObject(LocalStorageObject.weightResultBilan);
  }

  public static set histoWeightResultBilan(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.histoWeightResultBilan, value);
  }

  public static get histoWeightResultBilan(): number {
    return LocalStorageEx.getObject(LocalStorageObject.histoWeightResultBilan);
  }

  public static set currentWalkBilanStatus(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.currentWalkBilanStatus, value);
  }

  public static get currentWalkBilanStatus(): number {
    return LocalStorageEx.getObject(LocalStorageObject.currentWalkBilanStatus);
  }

  public static set currentGripBilanStatus(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.currentGripBilanStatus, value);
  }

  public static get currentGripBilanStatus(): number {
    return LocalStorageEx.getObject(LocalStorageObject.currentGripBilanStatus);
  }

  public static set currentWeightBilanStatus(value: number) {
    LocalStorageEx.setObject(LocalStorageObject.currentWeightBilanStatus, value);
  }

  public static get currentWeightBilanStatus(): number {
    return LocalStorageEx.getObject(LocalStorageObject.currentWeightBilanStatus);
  }

  public static get bilanResults(): BilanResults[] {
    return LocalStorageEx.getObject(LocalStorageObject.bilanResults);
  }
  public static set bilanResults(value: BilanResults[]) {
    LocalStorageEx.setObject(LocalStorageObject.bilanResults, value);
  }

  public static set comparisonEnabled(value: boolean) {
    LocalStorageEx.setObject(LocalStorageObject.comparisonEnabled, value);
  }
  public static get comparisonEnabled(): boolean {
    return LocalStorageEx.getObject(LocalStorageObject.comparisonEnabled);
  }

  public static get selectedBilanResult(): BilanResults {
    return LocalStorageEx.getObject(LocalStorageObject.selectedBilanResult);
  }

  public static set selectedBilanResult(value: BilanResults) {
    LocalStorageEx.setObject(LocalStorageObject.selectedBilanResult, value);
  }
}
