<h2 translate>Délégations</h2>
<div class="delegate">
  <ng-container *ngIf="rolesChecker.hasDelegationChekup | async">
    <h3 translate>Délégation pour les tests</h3>
    <ng-multiselect-dropdown
      [placeholder]="'Délégation pour les tests' | translate"
      [data]="doctorList | async"
      [(ngModel)]="doctorSelectedForCheckup"
      [settings]="doctorDropdownSetting"
    ></ng-multiselect-dropdown>
  </ng-container>
  <ng-container *ngIf="rolesChecker.hasDelegationResult | async">
    <h3 translate>Délégation pour les résultats</h3>
    <ng-multiselect-dropdown
      [placeholder]="'Délégation pour les résultats' | translate"
      [data]="doctorList | async"
      [(ngModel)]="doctorSelectedForResults"
      [settings]="doctorDropdownSetting"
    ></ng-multiselect-dropdown>
  </ng-container>
  <div class="btn-toolbar pull-right" role="toolbar">
    <button class="btn btn-primary " type="button" (click)="onUpdate()">{{ 'Enregistrer' | translate }}</button>
    <button class="btn btn-primary " type="button" (click)="onCancel()">{{ 'Annuler' | translate }}</button>
  </div>
</div>
