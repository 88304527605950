import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';

import { LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { NavigationActionsService, NavigationActionLink, NavigationActionClick } from 'app/modules/core';
import { ShoesSelectorValues } from 'app/modules/force-platform/models/shoes-selector-values.model';

import { Consultation } from 'app/modules/smartflat-data-access/src/model/consultation.model';
import { ConsultationDataService, PatientDataService } from 'app/modules/smartflat-data-access';
import { Patient, Bilan } from 'app/modules/smartflat-data-access';

// service API
import { BilanDataService } from 'app/modules/smartflat-data-access/src/api/bilan-data.service';
import { RombergDataService } from 'app/modules/smartflat-data-access/src/api/romberg.service';
import { LocomotionDataService } from 'app/modules/smartflat-data-access/src/api/locomotion-walk.service';
import { AnalysisResults as AnalysisResultsWalk } from 'app/modules/checkup/modules/walk/models/analysis-results.model';
import { AnalysisResults as AnalysisResultsRomberg } from 'app/modules/checkup/modules/romberg/models/analysis-results.model';
import { RemoteInteraction as RemoteInteractionWalk } from 'app/modules/checkup/modules/walk/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionRomberg } from 'app/modules/checkup/modules/romberg/services/remote-interaction.service';

import { BilanType } from 'app/modules/smartflat-data-access/src/model/bilan.model';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';
import { Compute } from 'app/tools/compute';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rapport-new',
  templateUrl: './rapportNew.component.html',
  providers: [AnalysisResultsWalk, AnalysisResultsRomberg, RemoteInteractionWalk, RemoteInteractionRomberg],
  styleUrls: ['./rapportNew.component.scss'],
})
export class RapportComponentNew implements OnInit, OnDestroy {
  @ViewChild('tag', { static: false })
  public tag: ElementRef;

  public currentEstablishmentLogo: string;
  public currentEstablishmentName: string;

  public dateConsultation: string;
  public agePatient: number;

  // walk
  public shoesValueResult2: ShoesSelectorValues;

  // roomberg
  public shoesValueResult: ShoesSelectorValues;

  public typeRapport: string;

  public patientIdentity: Patient;
  public walkAnalysisResult: AnalysisResultsWalk;
  public rombergAnalysisResult: AnalysisResultsRomberg;

  private unsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    // -----------------Locomotion & Romberg services-----------------------------------------
    private navigationActionService: NavigationActionsService,
    private bilanService: BilanDataService,
    private rombergService: RombergDataService,
    private locomotionService: LocomotionDataService,
    private remoteInteractionWalk: RemoteInteractionWalk,
    private remoteInteractionRomberg: RemoteInteractionRomberg,
    private localDatePipe: LocalDatePipe,
    private patientDataService: PatientDataService,
    private consultationDataService: ConsultationDataService,
  ) { }

  public ngOnDestroy() {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async ngOnInit() {
    LocalStorageEx.setObject(LocalStorageObject.currentRapportPatient, true);
    const actionPrevious = new NavigationActionClick('Précédent', () => this.location.back());
    const actionPrint = new NavigationActionClick('Impression', () => this.printPatientReport());
    const actions = [actionPrevious, actionPrint];
    this.navigationActionService.pushNavigationActions(actions);

    this.currentEstablishmentLogo = LocalStorageEx.currentEstablishment.Logo;
    if (LocalStorageEx.currentEstablishment.Name !== undefined && LocalStorageEx.currentEstablishment.Name !== null) {
      this.currentEstablishmentName = LocalStorageEx.currentEstablishment.Name;
    } else {
      this.currentEstablishmentName = null;
    }

    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((params: ParamMap) => {
      if (params.has('patientId') && params.has('consultId')) {
        const idConsulation = +params.get('consultId');
        const idPatient = +params.get('patientId');
        // limit rapport to one type
        if (params.has('typeRapport')) {
          this.typeRapport = params.get('typeRapport');
        }
        this.patientDataService
          .getPatientByDoctor()
          .then((listPatient) => {
            this.patientIdentity = listPatient.find((p) => p.patientId === idPatient);
          })
          .then(() => this.consultationDataService.getConsultationById(idConsulation))
          .then((c) => this.initPart2(c));
      } else {
        const consultation = LocalStorageEx.currentConsultRapport;
        this.patientIdentity = LocalStorageEx.currentPatientSelected;
        this.initPart2(consultation);
      }
    });
  }

  // Split init in two part.
  public initPart2(consultation: Consultation) {
    const idConsulation = consultation.id;
    this.dateConsultation = this.localDatePipe.parse(consultation.date);

    this.agePatient = Compute.age(new Date(this.patientIdentity.birthdate), new Date(consultation.date));

    this.bilanService.getBilans(idConsulation).then((ListOfBilans) => {
      if (this.ContainsRomberg(ListOfBilans)) {
        const bilanId = ListOfBilans.filter((bilan) => bilan.Type === BilanType.CheckupRomberg)[0].Id;
        this.remoteInteractionRomberg.getAnalysisResultsForBilan(bilanId).then((rombergResult) => {
          this.rombergAnalysisResult = rombergResult;
          this.displayShoesValueForRomberg(bilanId);
        });
      }

      if (this.ContainsWalk(ListOfBilans)) {
        const bilanId = ListOfBilans.filter((bilan) => bilan.Type === BilanType.CheckupWalk)[0].Id;
        this.remoteInteractionWalk.getAnalysisResultsForBilan(bilanId).then((walkResult) => {
          this.walkAnalysisResult = walkResult;
          this.displayShoesValueForWalk(bilanId);
        });
      }
    });
  }

  public printPatientReport() {
    window.print();
  }

  private ContainsRomberg(MyList: Bilan[]): boolean {
    return MyList.filter((bilan) => bilan.Type === BilanType.CheckupRomberg).length > 0;
  }

  private ContainsWalk(MyList: Bilan[]): boolean {
    return MyList.filter((bilan) => bilan.Type === BilanType.CheckupWalk).length > 0;
  }

  private displayShoesValueForWalk(bilanId: number): void {
    this.locomotionService.getShoesValuesByBilan(bilanId).then((shoesValues) => {
      this.shoesValueResult2 = shoesValues;
    });
  }

  private displayShoesValueForRomberg(bilanId: number): void {
    this.rombergService.getShoesValuesByBilan(bilanId).then((shoesValues) => {
      this.shoesValueResult = shoesValues;
    });
  }
}
