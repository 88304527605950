/**
 * 3rd-party modules
 */
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CommonModule as AngularCommon } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Our modules
 */

/**
 * Components
 */
import { ModalComponent } from './views/modal/modal.component';
import { HorizontalBarComponent } from 'app/modules/common/views/horizontalBar/horizontalBar.component';
import { TimelineChartComponent } from 'app/modules/common/views/timelineChart/timelineChart.component';
import { VerticalBarComponent } from 'app/modules/common/views/verticalBar/verticalBar.component';
import { NameFormat } from 'app/modules/common/pipes/nameformat.pipe';
import { BooleanFormat } from 'app/modules/common/pipes/boolean.pipe';
import { SecurePipe } from './pipes/securePipe';

/**
 * Services
 */

/**
 * Misc
 */

@NgModule({
  imports: [AngularCommon, RouterModule, TranslateModule.forChild()],
  declarations: [
    ModalComponent,
    HorizontalBarComponent,
    TimelineChartComponent,
    VerticalBarComponent,
    NameFormat,
    BooleanFormat,
    SecurePipe,
   ],
  providers: [NameFormat, BooleanFormat, SecurePipe],
  exports: [
    BooleanFormat,
    TimelineChartComponent,
    ModalComponent,
    NameFormat,
    HorizontalBarComponent,
    VerticalBarComponent,
    SecurePipe,
   ],
})
export class CommonModule {}
