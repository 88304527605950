import * as CryptoJS from 'crypto-js';
import { SECRET_KEY } from './divers.const';
import { ValueTransformer } from '../../../node_modules/@angular/compiler/src/util';

export class DataEncryption {
  public static encrypt(value: string): string {
    return  CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
  }

  public static decrypt(value: string): string {
    return   CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(value, SECRET_KEY));
  }
}
