<div class="col-lg-6 col-md-6 col-xs-12 container_buttons">
    <button class="btn btn-primary" class="{{option.type}}" [disabled]="option.disabled"
        [routerLink]=option.link
        [routerLinkActive]="['router-link-active']"
        [routerLinkActiveOptions]="{exact:true}">
        <span class="option_passed" *ngIf="option.passed===true">&#10004;</span>
        <span class="option_type">
            {{option.type|translate}}
        </span>
    </button>
</div>
