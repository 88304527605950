import {
  Consultation,
  LocomotionBilan,
  LocomotionBilanVisualAssessment,
  RombergBilan,
  RombergBilanVisualAssessment,
  Questionnaire,
} from '../service-dal';
import { QuestionnaryAnswers } from './questionnary-answers.model';

/**
 * Object representing the bilan model
 */
export class Bilan {
  public Id?: number;
  public Type?: number;
  public ConsultationId: BilanType;
}

export enum BilanType {
  Questionnary = 0,
  CheckupGrip = 1,
  CheckupWeight = 2,
  CheckupWalk = 3,
  CheckupRomberg = 4,
}

// tslint:disable-next-line:max-classes-per-file
export class BilanResults {
  public Id?: number;
  public Type?: number;
  public ConsultationId: BilanType;
  public consultation?: Consultation;
  public locomotionBilans?: LocomotionBilan[];
  public locomotionBilansVisualAssessment?: LocomotionBilanVisualAssessment[];
  public rombergBilans?: RombergBilan[];
  public rombergBilansVisualAssessment?: RombergBilanVisualAssessment[];
  public questionnaires?: QuestionnaryAnswers[];
}
