import { Question } from './question.model';

/**
 * Object representing a set of questions in a diagnostic form
 */
export class QuestionSet {
  public id: string;
  public nom: string;
  public listeItems: Question[];
}
