<div *ngIf="healthForm">
  <div class="form">
    <div class="questions">
      <h2 class="page-sub-title">{{healthForm.nom|translate}} </h2>
      <div *ngFor="let qs of healthForm.listeQuestions questionSet">
        <app-health-form-question-set [questionSet]=qs (AnswerSetOut)="addSet($event)"></app-health-form-question-set>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="page-footer buttons-component">
      <button class="btn btn-primary general-button btn-left" [routerLink]="backButtonLink" [routerLinkActive]="['router-link-active']"
        [routerLinkActiveOptions]="{exact:true}">{{'Précédent' | translate}}</button>
      <button type="button" class="btn btn-primary general-button" (click)="submit()" [disabled]="!valid" [routerLink]="backButtonLink">
        {{'savediagnostic' | translate}} </button>
    </div>
  </div>
</div>
