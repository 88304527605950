import { OnInit, Directive, ElementRef, HostListener } from '@angular/core';
import { InputValuePipe } from './inputvalue.pipe';

@Directive({
  selector: '[inputvaluecheck]',
})
export class InputValueDirective implements OnInit {

  private inputElement: HTMLInputElement;

  constructor(private elementRef: ElementRef, private inputValuePipe: InputValuePipe) {
    this.inputElement = this.elementRef.nativeElement;
  }

  public ngOnInit() {
    if (this.isNumeric) {
      this.inputElement.value = this.inputValuePipe.parseDecimalAbs(this.inputElement.value, this.precision);
    }
  }

  private get precision(): number {
    return this.inputValuePipe.getPrecision(this.inputElement.step);
  }

  private get isNumeric(): boolean {
    return this.inputValuePipe.isNumeric(this.inputElement.step);
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(value) {
    if (this.isNumeric) {
      this.inputElement.value = this.inputValuePipe.parseDecimalAbs(value, this.precision); // opossite of transform
    }
  }

  @HostListener('blur', ['$event.target.value'])
  public onBlur(value) {
    if (this.isNumeric) {
      this.inputElement.value = this.inputValuePipe.parseDecimalAbs(value, this.precision);
    }
  }

  @HostListener('keyup', ['$event.target.value'])
  public onKeyUp(value) {
    if (this.isNumeric) {
      this.inputElement.value = this.inputValuePipe.parseDecimalAbs(value, this.precision);
    }
  }

  @HostListener('input', ['$event.target.value'])
  public onInput(value) {
    if (this.isNumeric) {
      this.inputElement.value = this.inputValuePipe.parseDecimalAbs(value, this.precision);
    }
  }
}
