export class RombergAssessment {
  public Id: number;
  public BilanId: number;
  public EyesOpenBalanceQuality: number;
  public EyesClosedBalanceQuality: number;
  public WalkingQuality: number;
  public EverydayBalance: number;
  public Duration: number;
  public Images: any;
}
