import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientViews } from '../patientviews.enum';

@Component({
  selector: 'patient-action',
  templateUrl: './patient-action.component.html',
  styleUrls: ['./patient-action.component.css'],
})
export class PatientActionComponent implements OnInit {
  /**
   * Tag attributes
   */
  @Input() public viewSwitch: PatientViews;

  @Input() public canAdd = false;
  /**
   * Tag events in view Edit
   */
  @Output() public create = new EventEmitter<any>();

  /**
   * Tag events in view Plus
   */
  @Output() public update = new EventEmitter<any>();
  @Output() public cancel = new EventEmitter<any>();

  /**
   * Event handlers
   */

  public onUpdate() {
    this.update.emit();
  }
  public onCancel() {
    this.cancel.emit();
  }
  public onCreate() {
    this.create.emit();
  }
  /**
   * Property view
   */
  public get view() {
    return {
      Edit: this.viewSwitch === PatientViews.Edit,
      Plus: this.viewSwitch === PatientViews.Plus,
    };
  }
  /**
   * Property action
   */
  public get action() {
    return {
      Update: this.view.Plus,
      Cancel: this.view.Plus,
      Create: this.view.Edit && this.canAdd,
    };
  }

  public ngOnInit() {
    // Just let TSLint happy
  }
}
