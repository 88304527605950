import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

/**
 * PatientCellValueComponent
 * When implementing a custom editor or renderer,
 * remember to add it to the entryComponents and to the declarations part of your module as follows:
 * @NgModule({
 *  imports: [
 *    // ...
 *  ],
 *  entryComponents: [PatientCellValueComponent],
 *  declarations: [
 *  // ...
 *  PatientCellValueComponent,
 * ],
 * })
 */

@Component({
  selector: 'patient-cellname',
  templateUrl: './patient-cellname.component.html',
})
export class PatientCellNameComponent implements ViewCell, OnInit {

  @Input() public value: any;
  @Input() public rowData: any;

  public ngOnInit() {
    // Just let TSLint happy
  }
}
