import { LocalDataSource } from 'ng2-smart-table';

export class SearchDataSource extends LocalDataSource {
  private _notFirstRowSelected: boolean = false;
  private _tableId: string;
  private _selectedPage: number;
  private _selectedIndex: number;
  private _keepCurrentPage: boolean;

  public constructor(data: any[] = [], id: string = null, page: number = 1) {
    super(data);
    this._tableId = id;
    this._selectedPage = page;
  }

  public set tableId(id: string) {
    this._tableId = id;
  }

  public get tableId() {
    return this._tableId;
  }

  public set keepCurrentPage(keep: boolean) {
    this._keepCurrentPage = keep;
  }

  public saveRowSelected() {
    const rowIndex = this.getSelectedRowIndex(this._tableId);
    if (rowIndex >= 0 || this._selectedPage === this.pagingConf.page) {
      this._selectedIndex = rowIndex;
      this._selectedPage = this.pagingConf.page;
    }
  }

  public initRowSelected() {
    this._selectedIndex = -1;
    this._selectedPage = this.pagingConf.page;
  }

  public recoverRowSelected(data: any): boolean {
    let rowElement: HTMLElement = null;
    const currentPage: number = this.pagingConf.page;
    if (currentPage === this._selectedPage && this._selectedIndex >= 0) {
      rowElement = this.getRowElement(this._selectedIndex, this.tableId);
      if (rowElement) {
        this._notFirstRowSelected = true;
        rowElement.className = 'ng2-smart-row selected';

        this.initRowSelected();
        return true;
      }
    }

    return false;
  }

  public getSelectedRowIndex(tableId: string): number {
    let rowIndex: number = -1;
    try {
      const selectors: string = 'ng2-smart-table > table#' + tableId + ' > tbody tr';
      const nodes: NodeList = document.querySelectorAll(selectors);
      let index: number = 0;
      Array.prototype.forEach.call(nodes, (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const rowElement = node as HTMLElement;
          if (rowElement.className.includes('selected') || rowElement.style.backgroundColor !== '') {
            rowIndex = index;
            throw new Error('break');
          }
          index++;
        }
      });
    } catch (e) {
      // Just let TSLint happy
    }

    return rowIndex;
  }

  public getRowElement(index: number, tableId: string): HTMLElement {
    let rowElement: HTMLElement = null;
    try {
      const selectors: string = 'ng2-smart-table > table#' + tableId + ' > tbody tr';
      const nodes: NodeList = document.querySelectorAll(selectors);
      let rowIndex: number = 0;

      Array.prototype.forEach.call(nodes, (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          rowElement = node as HTMLElement;
          if (rowIndex++ === index) {
            throw new Error('break');
          }
          rowElement = null;
        }
      });
    } catch (e) {
      // Just let TSLint happy
    }

    return rowElement;
  }

  public selectRowElementStyle(rowElement: HTMLElement) {
    if (rowElement) {
      rowElement.className = 'ng2-smart-row selected';
      rowElement.style.backgroundColor = '#337ab7';
      const divs: NodeList = rowElement.querySelectorAll('table-cell-view-mode div');
      Array.prototype.forEach.call(divs, (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const a: HTMLElement = node as HTMLElement;
          a.style.color = 'white';
        }
      });
      const actions = rowElement.getElementsByClassName('ng2-smart-action');
      // tslint:disable-next-line
      for (let index = 0; index < actions.length; index++) {
        const node = actions[index];
        if (node.nodeType === Node.ELEMENT_NODE) {
          const a: HTMLElement = node as HTMLElement;
          a.style.color = 'white';
        }
      }
    }
  }

  public deselectRowElementStyle(rowElement: HTMLElement) {
    if (rowElement) {
      rowElement.className = 'ng2-smart-row';
      rowElement.style.backgroundColor = '';
      const divs: NodeList = rowElement.querySelectorAll('table-cell-view-mode div');
      Array.prototype.forEach.call(divs, (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const a: HTMLElement = node as HTMLElement;
          a.style.color = '';
        }
      });
      const actions = rowElement.getElementsByClassName('ng2-smart-action');
      // tslint:disable-next-line
      for (let index = 0; index < actions.length; index++) {
        const node = actions[index];
        if (node.nodeType === Node.ELEMENT_NODE) {
          const a: HTMLElement = node as HTMLElement;
          a.style.color = '';
        }
      }
    }
  }

  public isFirstRowData(data: any): boolean {
    const index = (this.pagingConf.page - 1) * this.pagingConf.perPage;
    return this.filteredAndSorted[index] === data;
  }

  public selectFirstRow(data: any, keepSelected: boolean = false): boolean {
    let isFirstRowSelected: boolean = false;
    if (this._notFirstRowSelected === false) {
      const firstRow = this.getRowElement(0, this.tableId);
      if (this.isFirstRowData(data)) {
        if (firstRow.className.includes('selected')) {
          if (keepSelected === false) {
            this.deselectRowElementStyle(firstRow);
            this._notFirstRowSelected = true;
          }
        } else {
          this.selectRowElementStyle(firstRow);
        }
      } else {
        this.deselectRowElementStyle(firstRow);
        this._notFirstRowSelected = true;
      }
      isFirstRowSelected = firstRow.className.includes('selected');
    }
    return isFirstRowSelected;
  }

  public get currentPage(): number {
    return this.pagingConf.page;
  }

  public set selectedPage(page: number) {
    this._selectedPage = page;
  }

  public get rowDatas(): any[] {
    return this.filteredAndSorted && this.filteredAndSorted.length ? this.filteredAndSorted : this.data;
  }

  /**
   * Overloaded functions
   */
  public setPage(page: number, doEmit: boolean = true): LocalDataSource {
    if (this._keepCurrentPage) throw new Error('Have to keep current page.');
    this.saveRowSelected();
    this._notFirstRowSelected = false;
    super.setPage(page, doEmit);
    return this;
  }

  public setPaging(page: number, perPage: number, doEmit: boolean): LocalDataSource {
    if (this._selectedPage) {
      const lastPage = Math.ceil(this.data.length / perPage);
      if (this._selectedPage > lastPage || this._selectedPage === -1) this._selectedPage = lastPage;
    }
    super.setPaging(this._selectedPage ? this._selectedPage : page, perPage, doEmit);
    return this;
  }
}
