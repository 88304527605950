import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { DiagnosticDataService, AnswerSet } from 'app/modules/smartflat-data-access';
import { Answer, HealthAnswer } from 'app/modules/smartflat-data-access';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';
import { NavigationActionsService } from 'app/modules/core';
import * as DiagnosticTransform from 'app/modules/smartflat-data-access/src/transformations/diagnostic.transform';
import { Diagnostic } from 'app/modules/smartflat-data-access/src/service-dal/model/diagnostic';
import { IPatient } from 'app/view/patient/patient.interface';
import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';

export interface IHealthAnswer extends HealthAnswer {
  answers: Answer[];
  answerColumn1: Answer[];
  answerColumn2: Answer[];
  created: Date;
  modified: Date;
}

@Component({
  selector: 'app-diagnostic-list',
  templateUrl: './diagnostic-list.component.html',
  providers: [DiagnosticDataService, DiagnosticSavingImp],
})
/**
 * This component is the page listing all diagnostics of a patient. It calls health form component for diagnostic's edition
 */
export class DiagnosticListComponent implements OnInit {
  public firstAcquisition: boolean;
  public patient: IPatient;
  public disabledButton: boolean = true;
  public diagnostics: IHealthAnswer[] = null;
  private selectedDiagnostic: number;

  /**
   * Initialize the service listing all diagnostic according to a patient
   * @param listService the service
   */
  public constructor(
    public rolesCheck: RolesCheckService,
    private localDatePipe: LocalDatePipe,
    private serviceSave: DiagnosticSavingImp,
    private navigationActionService: NavigationActionsService,
    private histoVersusConsultationService: HistoVersusConsultationService,
    private diagnosticService: DiagnosticDataService,
    private languageService: TranslateService,
  ) {}

  public async ngOnInit() {
    LocalStorageEx.removeObject(LocalStorageObject.diagnosticStatus);
    this.histoVersusConsultationService.setToReadOnly(false);
    this.navigationActionService.pushNavigationActions([]);
    LocalStorageEx.removeObject(LocalStorageObject.diagnostic_patient);

    if (LocalStorageEx.savedDiagnostics) {
      this.diagnostics = LocalStorageEx.savedDiagnostics;
    } else {
      const currentPatient: number = LocalStorageEx.currentPatient.patientId;
      this.diagnostics = (await this.serviceSave.getDiagnosticsForPatient(currentPatient)) as IHealthAnswer[];
    }
    this.patient = LocalStorageEx.currentPatientSelected as IPatient;
    if (this.diagnostics.length > 0) {
      this.disabledButton = false;
      this.firstAcquisition = false;
    } else {
      if (
        !LocalStorageEx.getObject(LocalStorageObject.Height) ||
        !LocalStorageEx.getObject(LocalStorageObject.Weight)
      ) {
        this.firstAcquisition = true;
        await this.setDefaultDiagnostic();
        this.disabledButton = false;
      }
    }

    this.diagnostics.forEach((healthAnswer) => {
      healthAnswer.answers = [];
      if (healthAnswer.answerSets) {
        healthAnswer.answerSets.forEach((set) => {
          if (set.answers.size > 0) {
            set.answers.forEach((answer) => {
              healthAnswer.answers.push(answer);
            });
          }
        });
      }
      /**
       * TODO: these codes should be hide in smartflat-data-access
       */
      const diagnostic: Diagnostic = DiagnosticTransform.HealthAnswertoDiagnosticDB(healthAnswer);
      healthAnswer.modified = diagnostic.modified;
      healthAnswer.created = diagnostic.created;

      /**
       * Separate the list into two lists in order to display in two columns.
       */
      healthAnswer.answerColumn1 = [];
      healthAnswer.answerColumn2 = [];
      const rowCount: number = healthAnswer.answers.length + 1; // "created" and "modified" are in a same line
      const rowsPerColumn: number = (rowCount % 2) + Math.floor(rowCount / 2);
      healthAnswer.answers.forEach((answer) => {
        if (healthAnswer.answerColumn1.length < rowsPerColumn) healthAnswer.answerColumn1.push(answer);
        else healthAnswer.answerColumn2.push(answer);
      });
    });

    LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, 'diagnosticList');
    // Put empty object just to inform we start a consultation
    LocalStorageEx.setObject(LocalStorageObject.ConsultationInProgress, {});
    LocalStorageEx.lastDiagnosticBackRoutLink = null;
  }

  /**
   * Call the service and get an Array of diagnostics of a patient
   */
  public filterDiagnostics(diagnostics: IHealthAnswer[], diagnostic: IHealthAnswer): IHealthAnswer[] {
    return diagnostics.filter(
      (obj) =>
        this.localDatePipe.parse(obj.created, 'dd/MM/yyyy HH:mm:ss') !==
        this.localDatePipe.parse(diagnostic.created, 'dd/MM/yyyy HH:mm:ss'),
    );
  }

  public switch(event): void {
    this.firstAcquisition = false;
  }

  public selectDiagnostic(i: number) {
    this.selectedDiagnostic = i;
  }

  /**
   * Delete a diagnostic
   */
  public delete(event) {
    const i: number = this.selectedDiagnostic;
    let hiddenDiagnostics: IHealthAnswer[] = [];
    if (LocalStorageEx.hiddenDiagnostics) {
      hiddenDiagnostics = LocalStorageEx.hiddenDiagnostics;
      hiddenDiagnostics[hiddenDiagnostics.length] = this.diagnostics[i];
      LocalStorageEx.hiddenDiagnostics = hiddenDiagnostics;
    } else {
      hiddenDiagnostics[0] = this.diagnostics[i];
      LocalStorageEx.hiddenDiagnostics = hiddenDiagnostics;
    }
    this.diagnostics.splice(i, 1);
    LocalStorageEx.savedDiagnostics = this.diagnostics;
    if (this.diagnostics.length === 0) {
      this.disabledButton = true;
    }
  }

  /**
   * Edit a diagnostic : store the diagnostic in the localStorage before calling health form component for edition (in html)
   * @param i the index of the diagnostic in the Array of diagnostics
   */
  public edit(i: number) {
    LocalStorageEx.store(LocalStorageObject.diagnostic_patient, this.diagnostics[i]);
  }

  public updateDiagnosticStatus() {
    LocalStorageEx.lastDiagnosticBackRoutLink = '/diagnosticList';
    LocalStorageEx.diagnosticStatus = 'edit';
    LocalStorageEx.removeObject(LocalStorageObject.selectedDiagnosticOptionId);
    LocalStorageEx.removeObject(LocalStorageObject.selectedDiagnosticOptionValue);
    LocalStorageEx.removeObject(LocalStorageObject.selectedQuestion);
  }

  private async setDefaultDiagnostic() {
    const healthForm = await this.diagnosticService.getDiagnosticForm(this.languageService.currentLang);

    const allNewDiag: HealthAnswer[] = [];
    const defaultHealthAnswer: HealthAnswer = new HealthAnswer();
    defaultHealthAnswer.answerSets = new Map<string, AnswerSet>();
    defaultHealthAnswer.date = new Date();

    for (const question of healthForm.listeQuestions) {
      const answerSet: AnswerSet = new AnswerSet();
      answerSet.answers = new Map<string, Answer>();
      for (const item of question.listeItems) {
        const answer: Answer = new Answer();
        answer.question_id = item.id;
        answer.question = item.nom;
        answer.option = null;
        answer.answer = item.options[0];
        answerSet.answers.set(item.id, answer);
      }
      answerSet.set_id = question.id;
      defaultHealthAnswer.answerSets.set(question.id, answerSet);
    }

    if (!LocalStorageEx.checkObject(LocalStorageObject.NewDiagnostics)) {
      allNewDiag.push(defaultHealthAnswer);
      LocalStorageEx.store(LocalStorageObject.NewDiagnostics, allNewDiag);
      this.diagnostics.push(defaultHealthAnswer as IHealthAnswer);
    }
  }
}
