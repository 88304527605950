import { SearchDataSource } from './searchDataSource';
import { IPatient } from './patient.interface';

export class PatientDataSource extends SearchDataSource {
  private filterValue = '';

  public constructor(data: IPatient[], id: string = null, page: number = 1) {
    super(data, id, page);
  }

  public set globalFilter(filter: string) {
    this.setFilter(
      [
        // fields we want to include in the search
        {
          field: 'firstName',
          search: filter,
        },
        {
          field: 'lastName',
          search: filter,
        },
        {
          field: 'birthdate',
          search: filter,
        },
        {
          field: 'lastConsultation',
          search: filter,
        },
        {
          field: 'flatName',
          search: filter,
        },
        {
          field: 'folderNumber',
          search: filter,
        },
      ],
      false,
    );
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here

    if (filter === '') {
      this.reset(true);
      this.refresh();
    }
    this.filterValue = filter;
  }

  public get globalFilter() {
    return this.filterValue;
  }
}
