import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Moment } from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ITorTableService } from 'app/modules/tor/services/tortable.service';
import { TimeService } from 'app/modules/time/services/time.service';

import { TorTable } from '../models/tortable.model';
import { TorRow } from '../models/torrow.model';
import { TorValue } from '../models/torvalue.model';
import { TorHeadTable } from '../models/torheadtable.model';
import { DAY, NIGHT } from '../models/constants.model';

@Injectable()
export class TorYearTableService implements ITorTableService {
  private columns: number = 24;
  private index: number;

  constructor(private translateService: TranslateService, private timeService: TimeService) {
    moment.locale(this.translateService.currentLang);
    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
    });
  }

  public getTimeTable(startDate: Date, endDate: Date): TorTable {
    this.index = 1;
    const numberOfRows: number = moment(endDate).year() - moment(startDate).year() + 1;

    const firstDayOfEndDate: Moment = moment(startDate).startOf('week');
    const table: TorTable = new TorTable(startDate, endDate);
    const tableHead: TorHeadTable = new TorHeadTable(this.columns);

    this.timeService.getMonths().then((value) => {
      tableHead.headValues = value;
    });
    table.torTableHead = tableHead;

    let rowIndex: number = 0;
    while (rowIndex < numberOfRows) {
      let row: TorRow;
      if (rowIndex === 0 && numberOfRows > 1) {
        // Date Range is greater than one Year
        row = this.firstYear(startDate);
      } else if (rowIndex === 0 && numberOfRows === 1) {
        // Date Range is equal or less to one Year
        row = this.theOnlyYear(startDate, endDate);
      } else if (rowIndex === numberOfRows - 1) {
        // Last year
        row = this.lastYear(endDate);
      } else {
        // Casual year
        row = this.casualYear(moment(startDate).startOf('week'), rowIndex);
      }
      table.rows.push(row);
      rowIndex++;
    }
    return table;
  }

  private firstYear(startDate: Date): TorRow {
    const torRow: TorRow = new TorRow(
      startDate,
      moment(startDate)
        .endOf('year')
        .toDate(),
      this.columns,
      this.index,
    );
    this.index++;
    let month: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const yearMoment: Moment = moment(startDate)
        .startOf('year')
        .add(month, 'month');
      let value;
      if (yearMoment.get('month') < moment(startDate).get('month')) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, null, null, false, this.index);
      } else {
        value = new TorValue(
          true,
          i % 2 === 0 ? DAY : NIGHT,
          this.getStartDateTorValue(moment(startDate), yearMoment).toDate(),
          yearMoment.endOf('month').toDate(),
          false,
          this.index,
        );
      }
      this.index++;
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        month++;
      }
    }
    return torRow;
  }

  private lastYear(endDate: Date): TorRow {
    const torRow: TorRow = new TorRow(
      moment(endDate)
        .startOf('year')
        .toDate(),
      endDate,
      this.columns,
      this.index,
    );
    let month: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const yearMoment: Moment = moment(endDate)
        .startOf('year')
        .add(month, 'month');
      let value;
      if (yearMoment.get('month') > moment(endDate).get('month')) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, null, null, false, this.index);
      } else {
        value = new TorValue(
          true,
          i % 2 === 0 ? DAY : NIGHT,
          yearMoment.toDate(),
          this.getEndDateTorValue(moment(endDate), yearMoment).toDate(),
          false,
          this.index,
        );
      }
      this.index++;
      torRow.values[i] = value;
      // Because i is both day and night
      if (i % 2 !== 0) {
        month++;
      }
    }
    return torRow;
  }

  private casualYear(startMoment: Moment, index: number): TorRow {
    const firstDayOfYear = startMoment.add(index, 'year');
    const torRow: TorRow = new TorRow(
      firstDayOfYear.startOf('year').toDate(),
      firstDayOfYear.endOf('year').toDate(),
      this.columns,
      this.index,
    );

    let month: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const firstDayOfMonth = firstDayOfYear.add(month, 'month');
      const value = new TorValue(
        true,
        i % 2 === 0 ? DAY : NIGHT,
        firstDayOfMonth.toDate(),
        firstDayOfMonth.endOf('month').toDate(),
        false,
        this.index,
      );
      this.index++;
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        month++;
      }
    }
    return torRow;
  }

  private theOnlyYear(startDate: Date, endDate: Date): TorRow {
    // const torRow: TorRow =
    //    new TorRow(moment(startDate).startOf('year').toDate(), moment(startDate).endOf('year').toDate(), this.columns);
    const torRow: TorRow = new TorRow(startDate, endDate, this.columns, this.index);
    this.index++;
    let month: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const yearMoment: Moment = moment(startDate)
        .startOf('year')
        .add(month, 'M');
      let value;
      if (
        yearMoment.get('month') < moment(startDate).get('month') ||
        yearMoment.get('month') > moment(endDate).get('month')
      ) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, null, null, false, this.index);
      } else {
        value = new TorValue(
          true,
          i % 2 === 0 ? DAY : NIGHT,
          this.getStartDateTorValue(moment(startDate), yearMoment).toDate(),
          this.getEndDateTorValue(moment(endDate), yearMoment).toDate(),
          false,
          this.index,
        );
      }
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        month++;
      }
    }
    return torRow;
  }

  private getStartDateTorValue(startDate: Moment, yearMoment: Moment): Moment {
    if (startDate.get('month') !== yearMoment.get('month')) {
      return yearMoment.startOf('month');
    } else {
      return startDate;
    }
  }

  private getEndDateTorValue(endDate: Moment, yearMoment: Moment): Moment {
    if (endDate.get('month') !== yearMoment.get('month')) {
      return yearMoment.endOf('month');
    } else {
      return endDate;
    }
  }
}
