import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalShoesPipe } from './pipes/localshoes.pipe';
import { ShoesService } from './services/shoes.service';

@NgModule({
  imports: [CommonModule],
  declarations: [LocalShoesPipe],
  providers: [ShoesService, LocalShoesPipe],
  exports: [LocalShoesPipe],
})
export class ShoesModule {}
