import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { NotificationService, Notification, NotificationLevel } from 'app/modules/core';

@Component({
  selector: 'core-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private static readonly delayInMillis = 10000;

  public notificationLevel = NotificationLevel;
  public notifications: Notification[] = [];

  private subscription: Subscription;

  public constructor(private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.subscription = this.notificationService.notificationChanged$
      .pipe(
        flatMap((notifs) => notifs),
        map((notif) => {
          return {
            notif,
            cleaner: () => this.cleanOne(),
          };
        }),
      )
      .subscribe((item) => {
        this.notifications.push(item.notif);
        item.cleaner();
      });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private cleanOne() {
    setTimeout(() => {
      this.notifications.shift();
    }, NotificationsComponent.delayInMillis);
  }
}
