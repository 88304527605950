import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

import { Chart } from 'chart.js';
import { ColorService } from 'app/modules/core/services/color.service';
import * as moment from 'moment';
import { DAYPERIOD } from 'app/modules/tor/models/constants.model';
import { MathService } from 'app/modules/math/services/math.service';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';

import { TorIndicator } from '../../../models/torindicator.model';
import { TorTable } from '../../../models/tortable.model';
import { TorRow } from '../../../models/torrow.model';
import { TorValue } from '../../../models/torvalue.model';

@Component({
  selector: 'tor-bilan-histo',
  templateUrl: './tor-bilan-histo.component.html',
})
export class TorBilanHistoComponent implements OnInit, OnChanges {
  @Input()
  public torTable: TorTable;

  public periodSelected: string;

  @ViewChild('torHisto', { static: true })
  public histo: ElementRef;
  @ViewChild('torHistoTotal', { static: true })
  public torHistoTotal: ElementRef;

  public chartsView: Chart[];

  public barChartLabels: string[];
  public barChartData: any[];

  public barChartTotal: any[];
  public barChartTotalLabels: string[];

  private colorsUsed: string[];

  constructor(
    private colorService: ColorService,
    private mathService: MathService,
    private localDatePipe: LocalDatePipe,
  ) {
    this.periodSelected = 'day';
    this.barChartLabels = new Array<string>();
  }

  public ngOnInit(): void {
    this.destroyCharts();
    this.initCharts();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.destroyCharts();
    this.initCharts();
  }

  public onViewChange(event: any) {
    this.ngOnChanges(null);
  }

  private initCharts(): void {
    this.chartsView = new Array<Chart>();
    this.barChartData = new Array<{ data: number[]; label: string }>();
    this.barChartTotal = new Array<{ data: number[]; label: string }>();
    this.initBarchartData();
    this.initGraph(this.histo, this.barChartData, this.barChartLabels, true);
    this.initGraph(this.torHistoTotal, this.barChartTotal, this.barChartTotalLabels, false);
  }

  private destroyCharts(): void {
    if (this.chartsView !== undefined && this.chartsView.length === 2) {
      this.chartsView[0].destroy();
      this.chartsView[1].destroy();
    }
  }

  private initGraph(elementRef: ElementRef, data: any, labelsChart: string[], notToal: boolean): void {
    const canvas = elementRef.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const ctorOptions: any = {
      type: 'bar',
      data: { datasets: data, labels: labelsChart },
      options: {
        showLines: false,
        responsive: true,
        legend: { display: notToal },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: 'black',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          enabled: false,
        },
        elements: {
          point: {
            pointStyle: 'line',
          },
        },
      },
    };
    const myChart = new Chart(ctx, ctorOptions);
    this.chartsView.push(myChart);
  }

  private getCanvasContext(ref: ElementRef): CanvasRenderingContext2D {
    const canvas = ref.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    return ctx;
  }

  private initBarchartData(): void {
    const date = new Date();

    // this.barChartLabels = this.torTable.torTableHead.headValues.slice(0, date.getDay()-1);
    //  this.barChartLabels = this.torTable.torTableHead.headValues;
    this.barChartTotalLabels = new Array<string>();
    this.colorsUsed = new Array<string>();

    this.barChartTotal = [];
    this.barChartData = [];

    const datasTotal: number[] = new Array<number>();
    const datasTotalMin: number[] = new Array<number>();
    const datasTotalMax: number[] = new Array<number>();
    const datasTotalLabel: string[] = new Array<string>();
    for (const row of this.torTable.rows) {
      // const startDateString = moment(row.startDay).format('DD-MM-YYYY');
      // const endDateString = moment(row.endDay).format('DD-MM-YYYY');
      const startDateString = this.localDatePipe.parse(row.startDay);
      const endDateString = this.localDatePipe.parse(row.endDay);
      this.barChartLabels = this.torTable.torTableHead.headValues;
      const datasSerie: any = this.initDatasSerie(row, startDateString, endDateString);
      datasTotal.push(datasSerie.total);
      datasTotalMin.push(datasSerie.stdDvt.min);
      datasTotalMax.push(datasSerie.stdDvt.max);
      this.barChartTotalLabels.push(startDateString + ' ' + endDateString);
    }
    this.barChartTotal.push({ data: datasTotalMin, type: 'line', pointRadius: 10, borderColor: 'rgb(0,0,0)' });
    this.barChartTotal.push({ data: datasTotalMax, type: 'line', pointRadius: 10, borderColor: 'rgb(0,0,0)' });
    this.barChartTotal.push({ data: datasTotal, label: 'total', backgroundColor: this.colorsUsed });
  }

  private initDatasSerie(
    row: TorRow,
    startDate: string,
    endDate: string,
  ): { serie: number[]; total: number; stdDvt: any } {
    const datas = [];
    const color = this.colorService.nextGraphColor();
    this.colorsUsed.push(color);
    const totalIndicator = row.dayTorIndicator;
    for (const content of row.values) {
      if (content.dayNight === this.periodSelected && content.isThereAValue) {
        // datas.push(this.getIndicatorValue(content.totalTorIndicator));
        datas.push(content.totalTorIndicator);
      }
    }
    const serie = { data: datas, label: startDate + ' ' + endDate, backgroundColor: color };
    this.barChartData.push(serie);
    const standartDeviation = this.getStandartDevisationSerie(datas, totalIndicator);
    return { serie: datas, total: totalIndicator, stdDvt: standartDeviation };
  }

  private getStandartDevisationSerie(serie: number[], totalIndicator: number): { min: number; max: number } {
    const standartDeviation = this.mathService.getStandartDeviation(serie);
    const minValue = totalIndicator - standartDeviation / 2;
    const maxValue = totalIndicator + standartDeviation / 2;
    return { min: minValue, max: maxValue };
  }
}
