import { Consultation } from '../model/consultation.model';
import * as Constants from '../constants';

import { Consultation as DalConsultation } from '../service-dal/model/consultation';

export function DalConsultationToConsultation(dalConsultation: DalConsultation): Consultation {
  const result = new Consultation();
  result.id = dalConsultation.Id;
  result.date = dalConsultation.Date;
  result.Height = dalConsultation.Height;
  // result.patientId = dalConsultation.PatientId;
  result.Weight = dalConsultation.Weight;
  result.MeasuredWeight = dalConsultation.MeasuredWeight;
  result.fragilityState = dalConsultation.fragilityState;
  // the swagger integration doesn't correctly convert to date object
  if (typeof result.date === 'string') {
    result.date = new Date(result.date);
  }

  return result;
}

export function ConsultationToDalConsultation(consultation: Consultation): DalConsultation {
  const result: DalConsultation = {
    Id: consultation.id,
    Date: consultation.date,
    Height: consultation.Height,
    Weight: consultation.Weight,
    MeasuredWeight: consultation.MeasuredWeight,
  };

  return result;
}
