import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Observable, combineLatest, Subject } from 'rxjs';
import { flatMap, map, takeUntil, delay } from 'rxjs/operators';

import { Consultation, FlatDataService, GenderArray, HandednessArray } from '../../../modules/smartflat-data-access';
import { Patient, DoctorDataService } from '../../../modules/smartflat-data-access';
import { Compute } from '../../../tools/compute';
import { IInputOption } from './field-input/inputoption.interface';

import { LocalStorageEx } from '../../../tools/localstorageobject.enum';
import { Establishment } from 'app/modules/smartflat-data-access/src/service-dal';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';
import { readOnlyViews } from '../patient.readonlyviews.constant';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'patient-editor',
  templateUrl: './patient-editor.component.html',
  styleUrls: ['./patient-editor.component.scss'],
  providers: [DoctorDataService, FlatDataService],
})
export class PatientEditorComponent implements OnInit {
  /* Tag attributes */
  @Input()
  public readonly: number;

  @Input()
  public editOnlyReferer = false;

  public patient: Patient;

  /* Tag events */
  @Output()
  public input = new EventEmitter<any>();
  @Output()
  public shown = new EventEmitter<boolean>();
  public ready: boolean = false;

  /**
   * constants
   */

  public apartments: IInputOption[] = [];
  public doctorsName: IInputOption[] = [];
  public readonly patientConsent: IInputOption[] = [
    { completeName: i18nKey('non'), value: false, show: true, name: 'non' },
    { completeName: i18nKey('oui'), value: true, show: true, name: 'oui' },
  ];
  public currentEstablishment: Establishment;
  public readonly handednessOptions = HandednessArray.filter((item) => item.name !== 'Unknown');
  public readonly genderOptions = GenderArray;

  public constructor(private doctorService: DoctorDataService, private flatService: FlatDataService) {}

  @Input()
  set patientSelect(patient: Patient) {
    this.patient = patient;
    if (this.patient !== undefined) {
      this.loadFlats(this.patient);
    } else {
      this.loadFlats(null);
    }
  }

  public async ngOnInit() {
    this.currentEstablishment = LocalStorageEx.currentEstablishment;
    const doctorTable = await this.doctorService.GetAllDoctors();
    doctorTable.forEach((element) => {
      this.doctorsName.push({
        completeName: element.name,
        name: element.name,
        value: element.id,
        show: true, // Could be change if for example a doctor left the service
      });
    });
  }

  public get readonlyOther() {
    return this.editOnlyReferer ? true : this.readonly;
  }

  public onInput(event) {
    // This code must be redone
    this.input.emit(event);
    const fname = document.querySelectorAll('[field="firstName"]')[0];
    const lname = document.querySelectorAll('[field="lastName"]')[0];
    const target: HTMLInputElement = event.target;

    if (target) {
      const inputValue = target && target.value;
      if (target.parentElement === fname) {
        let result = ((ipv) => {
          return ipv && ipv.charAt(0).toUpperCase() + ipv.slice(1);
        })(inputValue);

        const splitIdentifier = new RegExp(/[\u0020\.\;\,\(\)\[\]\/\'\"\`\?\!\|\{\}\\\_\~\^\%\&\#\$\*\+\=\-]/g);

        if (splitIdentifier.test(result)) {
          const identified = result.match(splitIdentifier);

          identified.forEach((identifier, i) => {
            const split = result.split(identifier);

            const capitalized = split.map((elem) => {
              return elem.charAt(0).toUpperCase() + elem.slice(1);
            });

            result = capitalized.join(identifier);

            return result;
          });
        }
        if (target) target.value = result;
        return result;
      }
      if (target.parentElement === lname) {
        event.target.value = inputValue.toUpperCase();
      }
    }
  }

  public get lastConsultation(): Consultation {
    if (this.patient) {
      return this.patient.lastConsultation;
    }
    return null;
  }

  public get patientAge(): number {
    if (this.patient && this.patient.birthdate) {
      return Compute.age(new Date(this.patient.birthdate));
    }
    return null;
  }
  public get patientBMI(): number {
    if (
      this.lastConsultation &&
      this.lastConsultation.Height &&
      this.lastConsultation.Height > 0 &&
      this.lastConsultation.Weight &&
      this.lastConsultation.Weight > 0
    ) {
      return Compute.BMI(
        this.lastConsultation.Height,
        this.lastConsultation.Weight,
        this.lastConsultation.MeasuredWeight,
      );
    }
    return null;
  }

  private loadFlats(patient: Patient): void {
    this.ready = false;

    this.apartments = [];
    this.apartments.push({ completeName: ' ', name: ' ', value: null, show: true });
    // this.flatService.getFlatById(patient.flatId)
    if (patient && patient.currentFlat) {
      const flat = patient.currentFlat;
      this.apartments.push({ completeName: flat.Name, name: flat.Name, value: flat.Id, show: true });
    }

    this.flatService
      .GetAllFreeFlats()
      .pipe(
        flatMap((res) => res),
        map((flat) => ({
          completeName: flat.Name,
          name: flat.Name,
          value: flat.Id,
          show: true,
        })),
      )
      .subscribe(
        (elem) => {
          this.apartments.push(elem);
        },
        () => {
          this.ready = true;
          this.shown.emit(this.ready);
        },
        () => {
          this.ready = true;
          this.shown.emit(this.ready);
        },
      );
  }
}
