export class AnalysisResults {
  public ScoreYF: number;
  public ScoreYO: number;
  public SurfaceYF: number;
  public SurfaceYO: number;
  public SwayDensityYF: number;
  public SwayDensityYO: number;
  public VarianceLateraleYF: number;
  public VarianceLateraleYO: number;
  public SpeedMoyYO: number;
  public SpeedMoyYF: number;
  public models: string[] = [];
  public duration: number;
  public graphes: {
    statok: string;
    stabilo: string;
    stasym: string;
    // loco: string,
    // rota: string,
    // semioAll: string,
    // semio0_17: string,
    // semio18_39: string,
    // semio40_59: string,
    // semio60_79: string,
    // semio80_200: string,
  };
}
