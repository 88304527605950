<div class="new_consultation">
  <div class="header">
    <consultation-wizard-steps></consultation-wizard-steps>
    <div class="col-lg-12 page-sub-title">
      <p>
        <span>{{ patientIdentity.lastName }}</span> {{ patientIdentity.firstName }}
      </p>
    </div>
    <div class="row"></div>
  </div>

  <div class="section">
    <consultation-identity-banner [patient]="patientIdentity"></consultation-identity-banner>

    <div class="row">
      <div class="col-lg-6">
        <span style="width: 100px !important">{{ 'Poids(kg)' | translate }}* :</span>
        <input
          type="number"
          min="{{ MIN_WEIGHT_KG }}"
          max="{{ MAX_WEIGHT_KG }}"
          name="Poids"
          step="1"
          [(ngModel)]="patientIdentity.Weight"
          required
        />
      </div>
      <div class="col-lg-6">
        <span>{{ 'MeasuredWeight' | translate }} :</span>
        <input
          type="number"
          min="{{ MIN_WEIGHT_KG }}"
          max="{{ MAX_WEIGHT_KG }}"
          name="Poidsmesure"
          step="1"
          [(ngModel)]="patientIdentity.MeasuredWeight"
        />
        <button class="btn btn-primary" (click)="saveAndWeight()">
          {{ 'Pesée' | translate }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <span style="width: 100px !important">{{ 'Taille(cm)' | translate }}* :</span>
        <input
          type="number"
          min="{{ MIN_HEIGHT_CM }}"
          max="{{ MAX_HEIGHT_CM }}"
          name="taille"
          step="1"
          [(ngModel)]="patientIdentity.Height"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <span class="more_info">* {{ 'Champs obligatoires pour démarrer une acquisition' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="page-footer buttons-component">
    <button
      class="btn btn-primary btn-left"
      [routerLink]="['/PatientConsult']"
      [routerLinkActive]="['router-link-active']"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ 'Précédent' | translate }}
    </button>

    <button class="btn btn-primary" (click)="saveAndNext()">{{ 'SaveAndNext' | translate }}</button>
  </div>
</div>
