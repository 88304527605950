<div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage | translate }}</div>

<div class="patientsTable">
  <div class="row">
    <div class="col-lg-12">
      <patient-sub-header
        [selectedPatient]="selectedPatient"
        [patientSource]="patientSource"
        (search)="onSearchPatient($event)"
      >
      </patient-sub-header>

      <div class="row">
        <div class="patients col-lg-8 no-padding-right">
          <div class="viewcontent">
            <ng2-smart-table
              [settings]="patientSettings"
              [source]="patientSource"
              (edit)="onModifyPatient($event)"
              (delete)="onDeletePatient($event)"
              (custom)="onCustomActionPatient($event)"
              (rowSelect)="rowSelect($event)"
            ></ng2-smart-table>

            <patient-modal #patientmodal>
              <div class="app-modal-header">
                <label>{{ patientmodal.headerLabel | translate }}</label>
              </div>
              <div class="app-modal-body">
                {{ patientmodal.bodyText | translate }}
              </div>
              <div class="app-modal-footer">
                <button
                  *ngIf="patientmodal.footerConfirmText"
                  type="button"
                  class="btn btn-default"
                  (click)="onPatientDeleteConfirmed(); patientmodal.hide()"
                >
                  {{ patientmodal.footerConfirmText | translate }}
                </button>
                <button type="button" class="btn btn-primary" (click)="patientmodal.hide()">
                  {{ patientmodal.footerCancelText | translate }}
                </button>
              </div>
            </patient-modal>
          </div>
        </div>

        <div class="patientplus col-lg-4">
          <div class="viewcontent">
            <patient-editor
              *ngIf="viewSwitch !== PatientViews.Delegate"
              [readonly]="isEditorReadOnly"
              [editOnlyReferer]="!(rolesCheck.hasManagePatient | async)"
              [patientSelect]="selectedPatient"
              (input)="onInputPatient($event)"
              (shown)="onEditorShown($event)"
            ></patient-editor>
            <app-patient-delegate
              *ngIf="viewSwitch === PatientViews.Delegate"
              [patientSelect]="selectedPatient"
              (finish)="onDelegateEnd($event)"
            >
            </app-patient-delegate>
          </div>
        </div>

        <div class="buttons-component-table">
          <patient-action
            *ngIf="isEditorShown"
            [canAdd]="rolesCheck.hasManagePatient | async"
            [viewSwitch]="viewSwitch"
            (update)="onUpdatePatient()"
            (cancel)="onCancelPatient()"
            (create)="onCreatePatient()"
          >
          </patient-action>
        </div>
      </div>
    </div>
  </div>
</div>

<core-modal #ValidateChangeDoctor>
  <div class="modal-header">
    <label> {{ 'Patient.TitleChangeDoctor' | translate }}</label>
  </div>
  <div class="modal-body">
    {{ 'Patient.TextChangeDoctor' | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="changeDoctorValidated.next(true)">
      {{ 'Oui' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="changeDoctorValidated.next(false)">
      {{ 'Non' | translate }}
    </button>
  </div>
</core-modal>
