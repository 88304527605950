import { Injectable } from '@angular/core';

import { Patient } from '../model/patient.model';

import { Rollback } from '../utilities/rollback';

import { ServiceDal } from '../service-dal/service-dal';

import { Patient as DalPatient } from '../service-dal/model/patient';
import { Identity as DalIdentity } from '../service-dal/model/identity';

import * as PatientDalPatientTransform from '../transformations/patient-dalpatient.transform';

import * as moment from 'moment';

import { LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { Consultation } from '../model/consultation.model';
import { FlatDataService } from './flat-data.service';
import { Flat } from 'app/modules/smartflat-data-access/src/model/flat.model';
import { map } from 'rxjs/operators';

const includeAttributes = '{"include": ["identity"]}';

@Injectable()
/**
 * this service manages patients, including the transformation of data to/from the DAL model.
 */
export class PatientDataService {
  private patientOrganizationId: string = null;

  constructor(private serviceDal: ServiceDal, private flatDataService: FlatDataService) {}

  public async getAllPatients(): Promise<Patient[]> {
    const patients = await this.serviceDal.patientService.patientFind(includeAttributes).toPromise();
    return this.getPatients(patients);
  }

  public async getPatientByDoctor() {
    const doctorId = LocalStorageEx.currentDoctorId;
    const patients = await this.serviceDal.doctorService
      .doctorPrototypeGetPatients(doctorId, includeAttributes)
      .toPromise();
    const filter = this.getFilterForDoctorPatientList(patients);
    return this.getPatients(patients, filter);
  }

  public getPatientForAdmin() {
    return this.serviceDal.patientService.patientListForAdmin().pipe(
      map((dalPatients) =>
        dalPatients.map((dalPatient) => {
          const user = (dalPatient as any).identity as DalIdentity;
          const patient = PatientDalPatientTransform.dalPatientUserToPatient(dalPatient, user);
          // todo check for spaceId
          return patient;
        }),
      ),
    );
  }

  public getPatientForResults() {
    return this.serviceDal.patientService.patientListForResult().pipe(
      map((dalPatients) =>
        dalPatients.map((dalPatient) => {
          const user = (dalPatient as any).identity as DalIdentity;
          const patient = PatientDalPatientTransform.dalPatientUserToPatient(dalPatient, user);
          // todo check for spaceId
          return patient;
        }),
      ),
    );
  }

  public getPatientForCheckup() {
    return this.serviceDal.patientService.patientListForCheckup().pipe(
      map((dalPatients) =>
        dalPatients.map((dalPatient) => {
          const user = (dalPatient as any).identity as DalIdentity;
          const patient = PatientDalPatientTransform.dalPatientUserToPatient(dalPatient, user);
          // todo check for  spaceId
          return patient;
        }),
      ),
    );
  }

  public async getPatients(patients: DalPatient[], filter?: string) {
    filter = filter ? filter : '';
    const relationsPatientsDoctors = await this.serviceDal.patientDoctorRelationService
      .patientDoctorRelationFind(filter)
      .toPromise();
    return patients.map((patient) => {
      const users = (patient as any).identity as DalIdentity;
      const unPatient = PatientDalPatientTransform.dalPatientUserToPatient(patient, users);

      const dalRelationDoctorPatient = PatientDalPatientTransform.dalRelationsDoctorsToPatient(
        relationsPatientsDoctors,
        unPatient.patientId,
      );

      if (dalRelationDoctorPatient) {
        unPatient.relationDoctorId = dalRelationDoctorPatient.Id;
        unPatient.doctorId = dalRelationDoctorPatient.DoctorId;
      }

      return unPatient;
    });
  }

  public async createPatient(patient: Patient): Promise<Patient> {
    console.log('createPatient');
    console.log(patient);

    const dalPatientAndUser = PatientDalPatientTransform.patientToDalUserPatient(patient);

    // TODO : the date must be stored in UTC in DB.
    dalPatientAndUser.dalPatient.Created = moment.utc().toDate();
    dalPatientAndUser.dalPatient.Modified = moment.utc().toDate();
    console.log(dalPatientAndUser);

    const rollback = new Rollback();

    try {
      // create user
      const dalUsers = await this.serviceDal.identityService.identityCreate(dalPatientAndUser.dalUsers).toPromise();
      patient = this.refresh(patient, dalUsers);
      rollback.push(() => this.serviceDal.identityService.identityDeleteById(dalUsers.id.toString()).toPromise());

      dalPatientAndUser.dalPatient.UserId = dalUsers.id;
      const dalPatient = await this.serviceDal.patientService.patientCreate(dalPatientAndUser.dalPatient).toPromise();

      const newPatient = PatientDalPatientTransform.dalPatientUserToPatient(dalPatient, dalUsers);

      return newPatient;
    } catch (e) {
      console.log('error in processing createPatient, rolling back');
      console.log(e);
      await rollback.rollback();
      throw e;
    }
  }

  public async updatePatient(patient: Patient): Promise<Patient> {
    // Note: we don't attempt any rollback here. I think it would be extremely complicated to
    //       do right and the end-result wouldn't be all that reliable anyway
    //       If we fail to update everything, we will just have to live with a partial update

    const dalPatientAndUser = PatientDalPatientTransform.patientToDalUserPatient(patient);

    const patientService = this.serviceDal.patientService;
    await patientService
      .patientPrototypePatchAttributes(dalPatientAndUser.dalPatient.Id.toString(), dalPatientAndUser.dalPatient)
      .toPromise();

    const usersService = this.serviceDal.identityService;
    const userResult = await usersService
      .identityPrototypePatchAttributes(dalPatientAndUser.dalUsers.id.toString(), dalPatientAndUser.dalUsers)
      .toPromise();

    patient = this.refresh(patient, userResult);

    return patient;
  }

  public async deletePatient(patient: Patient): Promise<void> {
    console.log('deletePatient');

    // TODO: do we just want to set active=false in users (and elsewhere)?
    //       if we do this, we should also change the name to something else.
    //       as we have a unique constraint on the name and it is too likely
    //       that a new patient will have the same name (which will cause unexpected exceptions)
    const dalPatientAndUser = PatientDalPatientTransform.patientToDalUserPatient(patient);
    // Note: there is a cascading delete, so all we need to do is to delete the user
    await this.serviceDal.identityService.identityDeleteById(dalPatientAndUser.dalUsers.id.toString()).toPromise();

    // Delete relation with Doctor !
    if (patient.doctorId && patient.relationDoctorId) {
      const dalRelationPatientDoctor = PatientDalPatientTransform.patientToDalRelationPatientDoctor(patient);
      await this.serviceDal.patientDoctorRelationService
        .patientDoctorRelationDeleteById(dalRelationPatientDoctor.Id.toString())
        .toPromise();
    }
  }

  public async getFragilityDatahistoryByPatientId(patientId: string) {
    return await this.serviceDal.patientService.patientPrototypeFragilityDataHistory(patientId).toPromise();
  }

  public getDelegations(patientId: number) {
    return this.serviceDal.patientService.patientPrototypeDelegationsFind(patientId.toString()).pipe(
      map((result) => ({
        checkupDelegations: result.checkupDelegationIds || [],
        resultDelegations: result.resultDelegationIds || [],
      })),
    );
  }

  public updateDelegations(patientId: number, checkupDelegations: number[], resultDelegations: number[]) {
    return this.serviceDal.patientService
      .patientPrototypeDelegationsUpdate(
        patientId.toString(),
        JSON.stringify(checkupDelegations),
        JSON.stringify(resultDelegations),
      )
      .pipe(
        map((result) => ({
          checkupDelegations: result.checkupDelegationIds || [],
          resultDelegations: result.resultDelegationIds || [],
        })),
      );
  }

  private getFilterForDoctorPatientList(patients: DalPatient[]): string {
    const ids = new Array<any>();
    for (const patient of patients) {
      ids.push({ id: patient.Id });
    }
    const filterPatientId = JSON.stringify(ids);
    return '{"where":{"or":' + filterPatientId + '}}';
  }

  private refresh(patient: Patient, patientDal: DalIdentity): Patient {
    patient.firstName = patientDal.name;
    patient.lastName = patientDal.lastName;
    return patient;
  }

  private async getConsultationByDate(patientId: string, consultationDate: string): Promise<Consultation> {
    return await this.serviceDal.patientService.patientPrototypeDestroyByIdConsultations('', '').toPromise();
  }
}
