import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Moment } from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TimeService } from 'app/modules/time/services/time.service';

import { ITorTableService } from './tortable.service';
import { TorTable } from '../models/tortable.model';
import { TorRow } from '../models/torrow.model';
import { TorValue } from '../models/torvalue.model';
import { TorHeadTable } from '../models/torheadtable.model';
import { DAY, NIGHT } from '../models/constants.model';

@Injectable()
export class TorWeekTableService implements ITorTableService {
  private columns: number = 14;
  private index: number;

  constructor(private translateService: TranslateService, private timeService: TimeService) {
    moment.locale(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
    });
  }

  public getTimeTable(startDate: Date, endDate: Date): TorTable {
    this.index = 1;
    const firstDayOfEndDate: Moment = moment(startDate).startOf('week');

    const numberOfRows: number =
      moment(endDate).week() + 52 * (moment(endDate).year() - moment(startDate).year()) - moment(startDate).week() + 1;
    const table: TorTable = new TorTable(startDate, endDate);
    const tableHead: TorHeadTable = new TorHeadTable(this.columns);
    this.timeService.getWeekDays().then((value) => {
      tableHead.headValues = value;
    });
    table.torTableHead = tableHead;
    let rowIndex: number = 0;
    while (rowIndex < numberOfRows) {
      let row: TorRow;
      if (rowIndex === 0 && numberOfRows > 1) {
        // Date Range is greater than one Week
        row = this.firstWeek(startDate);
      } else if (rowIndex === 0 && numberOfRows === 1) {
        // Date Range is equal to one week
        row = this.theOnlyWeek(startDate, endDate);
      } else if (rowIndex === numberOfRows - 1) {
        row = this.lastWeek(endDate);
      } else {
        row = this.casualWeek(moment(startDate).startOf('week'), rowIndex);
      }
      table.rows.push(row);
      rowIndex++;
    }
    return table;
  }

  private firstWeek(startDate: Date): TorRow {
    const torRow: TorRow = new TorRow(
      startDate,
      moment(startDate)
        .endOf('week')
        .toDate(),
      this.columns,
      this.index,
    );
    this.index++;
    let day: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const dayMoment: Moment = moment(startDate)
        .startOf('week')
        .add(day, 'day');
      let value;
      if (dayMoment.isBefore(moment(startDate))) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, moment(dayMoment).toDate(), null, true, this.index);
      } else {
        value = new TorValue(true, i % 2 === 0 ? DAY : NIGHT, moment(dayMoment).toDate(), null, true, this.index);
      }
      this.index++;
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        day++;
      }
    }
    return torRow;
  }

  private lastWeek(endDate: Date): TorRow {
    const torRow: TorRow = new TorRow(
      moment(endDate)
        .startOf('week')
        .toDate(),
      endDate,
      this.columns,
      this.index,
    );
    this.index++;
    let day: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const dayMoment: Moment = moment(endDate)
        .startOf('week')
        .add(day, 'day');
      let value;
      if (dayMoment.isAfter(moment(endDate))) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, null, null, true, this.index);
      } else {
        value = new TorValue(true, i % 2 === 0 ? DAY : NIGHT, moment(dayMoment).toDate(), null, true, this.index);
      }
      this.index++;
      torRow.values[i] = value;
      // Because i is both day and night
      if (i % 2 !== 0) {
        day++;
      }
    }
    return torRow;
  }

  private casualWeek(startMoment: Moment, index: number): TorRow {
    const firstDayOfWeek = moment(startMoment)
      .startOf('week')
      .add(index, 'week');
    const torRow: TorRow = new TorRow(
      firstDayOfWeek.toDate(),
      moment(firstDayOfWeek)
        .endOf('week')
        .toDate(),
      this.columns,
      this.index,
    );
    this.index++;

    let day: number = 0;
    for (let i: number = 0; i < this.columns; i++) {
      const currentDayOfWeek: Moment = moment(firstDayOfWeek).add(day, 'day');
      const value = new TorValue(
        true,
        i % 2 === 0 ? DAY : NIGHT,
        moment(currentDayOfWeek).toDate(),
        null,
        true,
        this.index,
      );
      this.index++;
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        day++;
      }
    }

    return torRow;
  }

  private theOnlyWeek(startDate: Date, endDate: Date): TorRow {
    const torRow: TorRow = new TorRow(startDate, endDate, this.columns, this.index);
    this.index++;
    let day: number = 0;
    for (let i: number = 0; i < torRow.values.length; i++) {
      const dayMoment: Moment = moment(startDate)
        .startOf('week')
        .add(day, 'day');
      let value;
      if (dayMoment.isBefore(moment(startDate)) || dayMoment.isAfter(moment(endDate))) {
        value = new TorValue(false, i % 2 === 0 ? DAY : NIGHT, null, null, true, this.index);
      } else {
        value = new TorValue(true, i % 2 === 0 ? DAY : NIGHT, moment(dayMoment).toDate(), null, true, this.index);
      }
      this.index++;
      torRow.values[i] = value;

      // Because i is both day and night
      if (i % 2 !== 0) {
        day++;
      }
    }
    return torRow;
  }
}
