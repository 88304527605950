import { ICheckupSaving } from 'app/services/tempSaving/checkupSaving.service';
import { Injectable } from '@angular/core';
import { Consultation } from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';
import { GripMeasure } from 'app/modules/checkup/modules/grip';
import { Grip, GripDataService, RombergTest } from 'app/modules/smartflat-data-access';
import { WalkAssessmentType, Assessment } from 'app/modules/checkup/modules/walk/models/assessment.model';
import { WalkAssessment } from 'app/modules/smartflat-data-access/src/model/walk-assessment.model';
import { LocomotionDataService } from 'app/modules/smartflat-data-access/src/api/locomotion-walk.service';
import { RombergAssessmentType, AssessmentRomberg } from 'app/modules/checkup/modules/romberg/models/assessment.model';
import { RombergDataService } from 'app/modules/smartflat-data-access/src/api/romberg.service';
import * as models from 'app/modules/smartflat-data-access/src/service-dal/model/models';
import { RombergAssessment } from 'app/modules/smartflat-data-access/src/model/romberg-assessment.model';
import * as medicalConst from 'app/tools/medical.constants';
@Injectable()
export class CheckupSavingImp implements ICheckupSaving {
  private consultation: Consultation;

  public constructor(
    private gripDataService: GripDataService,
    private walkDataService: LocomotionDataService,
    private rombergDataService: RombergDataService,
  ) {}

  public saveGripCheckup(newGripCheckup: GripMeasure, bilanId: number, date: Date): Promise<void> {
    const grip = this.mapToStorage(newGripCheckup);
    grip.BilanId = bilanId;
    grip.Horodate = new Date(Date.parse(date.toISOString()));
    return this.gripDataService.createGripAcquisition(grip);
  }

  public async saveWalkCheckup(bilanId: number, date: Date): Promise<void> {
    const assessments = LocalStorageEx.currentWalkAssessmentCheckup;
    console.log('assessments', assessments);
    const walkAssesmentModel = this.instantiateWalkAssessmentModel(assessments);
    walkAssesmentModel.BilanId = bilanId;
    walkAssesmentModel.Distance = medicalConst.DISTANCE_TO_LOCOMOTION;
    return await this.walkDataService.createWalkAcquisition(walkAssesmentModel);
  }
  public async saveRombergCheckup(bilanId: number, date: Date): Promise<void> {
    const assessments = LocalStorageEx.currentRombergAssessmentCheckup;
    console.log('assessments', assessments);
    const rombergAssesmentModel = this.instantiateRombergAssessmentModel(assessments);
    console.log('rombergAssesmentModel', rombergAssesmentModel);
    rombergAssesmentModel.BilanId = bilanId;
    rombergAssesmentModel.Duration = medicalConst.TIME_TO_ROMBERG;
    return await this.rombergDataService.createRombergAcquisition(rombergAssesmentModel);
  }

  public cleanMemory(): void {
    LocalStorageEx.currentGripCheckup = null;
    LocalStorageEx.weightResultBilan = null;
  }

  private mapToStorage(newGripCheckup: GripMeasure): Grip {
    const out = new Grip();

    out.LeftHandV1 = newGripCheckup.left1;
    out.LeftHandV2 = newGripCheckup.left2;
    out.LeftHandV3 = newGripCheckup.left3;
    out.RightHandV1 = newGripCheckup.right1;
    out.RightHandV2 = newGripCheckup.right2;
    out.RightHandV3 = newGripCheckup.right3;

    return out;
  }

  private instantiateWalkAssessmentModel(assessments: Assessment[]): WalkAssessment {
    const walkAssesmentModel = new WalkAssessment();
    for (const assessment of assessments) {
      switch (assessment.type) {
        case WalkAssessmentType.Asymetric:
          walkAssesmentModel.AsymmetricalWalk = assessment.value;
          break;
        case WalkAssessmentType.Balance:
          walkAssesmentModel.BalanceLoss = assessment.value;
          break;
        case WalkAssessmentType.Hesitant:
          walkAssesmentModel.HestitantWalk = assessment.value;
          break;
        case WalkAssessmentType.Irregular:
          walkAssesmentModel.IrregularWalk = assessment.value;
          break;
        case WalkAssessmentType.SmallSteps:
          walkAssesmentModel.SmallStepWalk = assessment.value;
          break;
        case WalkAssessmentType.SwingingArms:
          walkAssesmentModel.SwingingArmsLoss = assessment.value;
          break;
      }
    }
    console.log('Assessment Value');
    return walkAssesmentModel;
  }
  private instantiateRombergAssessmentModel(assessments: AssessmentRomberg[]): RombergAssessment {
    const rombergAssesmentModel = new RombergAssessment();
    for (const assessment of assessments) {
      console.log('assessment.type', assessment.type, 'assessment.value', assessment.value);
      switch (assessment.type) {
        case RombergAssessmentType.Fall:
          rombergAssesmentModel.EverydayBalance = assessment.value;
          break;
        case RombergAssessmentType.QualityEquiYF:
          rombergAssesmentModel.EyesClosedBalanceQuality = assessment.value;
          break;
        case RombergAssessmentType.QualityEquiYO:
          rombergAssesmentModel.EyesOpenBalanceQuality = assessment.value;
          break;
        case RombergAssessmentType.QualityWalk:
          rombergAssesmentModel.WalkingQuality = assessment.value;
          break;
      }
    }
    console.log('instantiate.type', rombergAssesmentModel);
    return rombergAssesmentModel;
  }
}
