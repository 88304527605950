import { Observable } from 'rxjs';
import { NavigationAction } from './navigation-action.model';
import { INavigationActionOptions } from './navigation-action-options.model';

export class NavigationActionClick extends NavigationAction {

    public click: () => void;

    public constructor(key: string | Observable<string>, click: () => void, options?: INavigationActionOptions) {
        super(key, options);
        this.click = click;
    }
}
