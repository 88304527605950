<input
  class="form-control"
  type="text"
  placeholder="{{ 'Nouvelle recherche personne' | translate }}..."
  [(ngModel)]="inputValue"
  (keyup)="onSearch(inputValue)"
/>
<input type="button" (click)="onSearch(inputValue)" />
<table *ngIf="showValue && value" class="caption">
  <thead>
    <tr>
      <th>{{ title }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ value }}</td>
    </tr>
  </tbody>
</table>
