<div class="old_consultation">
  <div class="header">
    <consultation-wizard-steps *ngIf="(obs$ | async)" [activeStep]="consultationStep"></consultation-wizard-steps>
    <div class="row">
      <div class="col-lg-4 page-sub-title" style="z-index: 1000000000 !important;">
        <p>
          <span>{{ patientIdentity.lastName }}</span> {{ patientIdentity.firstName }}
        </p>
      </div>
      <img (click)="showModal()" src="assets/images/contact.png" alt="" class="contact-button" /> <br />
    </div>
  </div>

  <div class="section">
    <consultation-identity-banner [patient]="patientIdentity"></consultation-identity-banner>
    <div class="row">
      <div class="col-lg-3">
        {{ 'Poids(kg)' | translate }}: <span *ngIf="!(obs$ | async)">{{ patientIdentity.Weight }}</span>
        <input
          *ngIf="(obs$ | async)"
          type="number"
          min="{{MIN_WEIGHT_KG}}"
          max="{{MAX_WEIGHT_KG}}"
          value="{{patientIdentity.Weight}}"
          [(ngModel)]="weight"
          (change)="changeWeight()"
        />
      </div>
      <div class="col-lg-3">
        {{ 'Taille(cm)' | translate }}: <span *ngIf="!(obs$ | async)">{{ patientIdentity.Height }}</span>
        <input
          *ngIf="(obs$ | async)"
          type="number"
          min="{{MIN_HEIGHT_CM}}"
          max="{{MAX_HEIGHT_CM}}"
          value="{{patientIdentity.Height}}"
          [(ngModel)]="height"
          (change)="changeHeight()"
        />
      </div>

      <div class="col-lg-3" *ngIf="height !== 0">
        {{ 'IMC' | translate }}: <span>{{ patientIdentity.IMC | float: 1 }}</span>
      </div>

      <div class="col-lg-3 no-padding-right">
        {{ 'MeasuredWeight' | translate }}: <span>{{ patientIdentity.MeasuredWeight | float }}</span>
      </div>
    </div>
  </div>
</div>

<core-modal name="contact" #contact>
  <div class="modal-header">
    <label> {{ 'Contacts' | translate }} </label>
    <img (click)="hideModal()" src="assets/images/close.png" alt="" class="close-button" />
  </div>
  <div class="modal-body">
    <div class="row">
      <div style="float: left;">
        {{ 'Patient.Email' | translate }} : <b>{{ this.patientIdentity.email }} </b>
      </div>
    </div>
    <div class="row">
      <div style="float: left;">
        {{ 'Patient.PhoneNumber' | translate }} : <b>{{ this.patientIdentity.phoneNumber }}</b>
      </div>
    </div>
    <div class="row">
      <div style="float: left;">
        {{ 'Patient.Address' | translate }} : <b>{{ this.patientIdentity.address }}</b>
      </div>
    </div>
  </div>
</core-modal>
