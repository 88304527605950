/** 3rd-party module imports */
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Router } from '@angular/router';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MyDatePickerModule } from 'mydatepicker';
import { NgIdleModule } from '@ng-idle/core';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

/** 1st-party module imports */
import { MathModule } from './modules/math/math.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './modules/core/core.module';
import { CommonModule } from './modules/common';
import { ConsultationModule } from './modules/consultation/consultation.module';
import { CheckupModule } from './modules/checkup';
import { TimeModule } from './modules/time/time.module';
import { TorModule } from './modules/tor/tor.module';

import { SmartFlatDataAccessModule } from './modules/smartflat-data-access/src/smartflat-data-access.module';
import { ForcePlatformModule } from './modules/force-platform';

/** Component imports */
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { NavbarComponent } from './view/navbar/navbar.component';
import { HealthFormComponent } from './view/health-form/health-form/health-form.component';
import { HealthFormQuestionComponent } from './view/health-form/health-form-question/health-form-question.component';
import { HealthFormQuestionSetComponent } from './view/health-form/health-form-question-set/health-form-question-set.component';
import { HealthFormInitComponent } from './view/health-form/health-form-init.component';
import { HealthFormOptionsComponent } from './view/health-form/health-form-options/health-form-options.component';
import { PatientComponent } from './view/patient/patient.component';
import { DiagnosticListComponent } from './view/diagnostic-list/diagnostic-list.component';
import { SettingsComponent } from './view/settings/settings.component';
import { HelpComponent } from './view/help/help.component';
import { HomeComponent } from './view/home/home.component';
import { PatientEditorComponent } from './view/patient/patient-editor/patient-editor.component';
import { FieldInputComponent } from './view/patient/patient-editor/field-input/field-input.component';
import { PatientActionComponent } from './view/patient/patient-action/patient-action.component';
import { PatientHeaderComponent } from './view/patient/patient-header/patient-header.component';
import { PatientModalComponent } from './view/patient/patient-modal/patient-modal.component';
import { PatientCellValueComponent } from './view/patient/patient-cellvalue/patient-cellvalue.component';
import { PatientCellNameComponent } from './view/patient/patient-cellname/patient-cellname.component';
import { PatientResultsComponent } from './view/patient/patientresults.component';
import { PatientConsultComponent } from './view/patient/patientconsult.component';
import { PatientCellApartmentComponent } from './view/patient/patient-cellapartment/patient-cellapartment.component';
import { PatientSubResultsComponent } from './view/patient/sub/results/patient-sub-results.component';
import { PatientSubHeaderComponent } from './view/patient/sub/header/patient-sub-header.component';
import { QuestionnaryListComponent } from './view/questionnary-list/questionnary-list.component';
import { PatientIdentityComponent } from './view/patient-identity/patient-identity.component';
import { QuestionnaryComponent } from './view/questionnary/questionnary.component';
import { BoardSyncComponent } from './view/board-sync/board-sync.component';
import { PatientCellDateComponent } from './view/patient/patient-celldate/patient-celldate.component';
import { CheckupQuestionnaryComponent } from './view/checkup-questionnary/checkup-questionnary.component';
import { PatientCelldateWithHoursComponent } from './view/patient/patient-celldate-with-hours/patient-celldate-with-hours.component';
import { PatientCelldateHoursComponent } from './view/patient/patient-celldate-hours/patient-celldate-hours.component';

import { WiiBoardActivationComponent } from './view/settings/wiiBoardActivation/wiiBoardActivation.component';
import { WindowsWiiBoardSyncComponent } from './view/settings/windowsWiiBoardSync/windowsWiiBoardSync.component';
import { RapportsModule } from './modules/rapports/rapports.module';

/** Service imports */
import { AppConfigurationService } from './app-configuration.service';
import { ServiceDalConfig } from './modules/smartflat-data-access';
import { QuestionnarySavingImp } from './services/tempSaving/impl/questionnarySavingImp.service';
import { DiagnosticSavingImp } from './services/tempSaving/impl/diagnosticSavingImp.service';
import { CheckupSavingImp } from './services/tempSaving/impl/checkupSavingImp.service';

/** Misc imports */
import { environment } from 'environments/environment';
import { InputValuePipe } from './view/patient/patient-editor/field-input/inputvalue.pipe';
import { InputValueDirective } from './view/patient/patient-editor/field-input/inputvalue.directive';
import { ConsultationSavingImp } from './services/tempSaving/impl/consultationSavingImp.service';
import { TableCellDisplay } from './pipes/celltable.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ChoixImpressionModule } from './modules/rapports/choixImpression/choixImpression.module';

import { NgxGaugeModule } from 'ngx-gauge';
import { PatientDelegateComponent } from './view/patient/patient-delegate/patient-delegate.component';
import { LoggerService } from './services/logger/logger.service';
import { ConsoleLoggerService } from './services/logger/console-logger.service';

import { WebpackTranslateLoader } from './services/webpack-translate-loader';
import { PatientSubHeaderConsultComponent } from './view/patient/sub/header/patient-sub-header-consult.component';
import { PatientSubHeaderResultComponent } from './view/patient/sub/header/patient-sub-header-result.component';

export function loadConfiguration(configService: AppConfigurationService): () => Promise<void> {
  return () => configService.load();
}

export function createServiceDalConfig(configService: AppConfigurationService): ServiceDalConfig {
  return configService.createServiceDalConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    HealthFormComponent,
    HealthFormQuestionSetComponent,
    HealthFormQuestionComponent,
    HealthFormInitComponent,
    HealthFormOptionsComponent,
    PatientComponent,
    DiagnosticListComponent,

    DiagnosticListComponent,
    SettingsComponent,
    HelpComponent,
    HomeComponent,
    PatientEditorComponent,
    FieldInputComponent,
    InputValuePipe,
    InputValueDirective,
    PatientActionComponent,
    PatientHeaderComponent,
    PatientModalComponent,
    PatientCellValueComponent,
    PatientCellNameComponent,
    PatientResultsComponent,
    PatientConsultComponent,
    PatientCellApartmentComponent,
    PatientSubResultsComponent,
    PatientSubHeaderComponent,
    PatientSubHeaderConsultComponent,
    PatientSubHeaderResultComponent,
    QuestionnaryListComponent,
    PatientIdentityComponent,
    QuestionnaryComponent,
    BoardSyncComponent,
    PatientCellDateComponent,
    CheckupQuestionnaryComponent,
    PatientCelldateWithHoursComponent,
    WiiBoardActivationComponent,
    WindowsWiiBoardSyncComponent,
    TableCellDisplay,
    CapitalizePipe,
    PatientCelldateHoursComponent,
    PatientDelegateComponent,
  ],
  entryComponents: [
    PatientCellNameComponent,
    PatientCellValueComponent,
    PatientCellApartmentComponent,
    PatientCellDateComponent,
    PatientCelldateWithHoursComponent,
    PatientCelldateHoursComponent,
  ],
  imports: [
    MathModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    Ng2SmartTableModule,
    MyDatePickerModule,
    CoreModule,
    CommonModule,
    ConsultationModule,
    ChoixImpressionModule,
    CheckupModule,
    AppRoutingModule,
    TimeModule,
    TorModule,
    RapportsModule,
    NgxGaugeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    SmartFlatDataAccessModule,
    ForcePlatformModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgIdleModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        // UGLY send token for all url (but reading configuration at this point is nightmare)
        allowedUrls: ['http'],
        sendAccessToken: true,
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: LoggerService, useClass: ConsoleLoggerService },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      deps: [AppConfigurationService],
      multi: true,
    },
    {
      provide: ServiceDalConfig,
      useFactory: createServiceDalConfig,
      deps: [AppConfigurationService],
    },
    DiagnosticSavingImp,
    CheckupSavingImp,
    QuestionnarySavingImp,
    ConsultationSavingImp,
    TableCellDisplay,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, private logger: LoggerService) {
    if (environment.enableRouteDebug) {
      this.logger.info('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
  }
}
