export class PatientIdentity {
  public firstName: string;
  public lastName: string;
  public birthdate: Date;
  public genderString: string; // TODO: ???
  public handednessString: string; // TODO: right way to localize?
  public connected: boolean;
  public Height: number;
  public Weight: number;
  public MeasuredWeight: number;
  public IMC: number;
  public age: number;
  public flatId?: number;
  public phoneNumber: string;
  public address: string;
  public email: string;
}
