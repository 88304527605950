export const DAYCLASS = 'Date_Day';
export const NIGHTCLASS = 'Date_Night';
export const DAY: string = 'day';
export const NIGHT: string = 'night';
export const DAYPERIOD: string[] = [DAY, NIGHT];
export const TORINDICATORS: string[] = ['indicators', 'timeInZone', 'nbActivations'];

export enum CAPTORSTATE {
  none,
  active,
  inactive,
}
