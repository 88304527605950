import { Component, OnInit, Input } from '@angular/core';

import { Patient } from 'app/modules/smartflat-data-access';

@Component({
  selector: 'app-rapport-header',
  templateUrl: './rapport-header.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-header.component.scss'],
})
export class RapportHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public establishmentLogo: string;
  @Input()
  public establishmentName: string;
  @Input()
  public patientIdentity: Patient;
  @Input()
  public dateConsultation: string;
}
