import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { QuestionnaryAnswers, QuestionnaryList } from 'app/modules/smartflat-data-access';

import { QuestionnaryDataService, QuestionnaryAnswerQuestion, Patient } from 'app/modules/smartflat-data-access';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnarySavingImp } from 'app/services/tempSaving/impl/questionnarySavingImp.service';
import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { LastQuestionnary } from 'app/modules/smartflat-data-access/src/model/last-questionnary.model';

@Component({
  selector: 'app-questionnary-list',
  templateUrl: './questionnary-list.component.html',
  providers: [QuestionnaryDataService, QuestionnarySavingImp],
})
/**
 * This component corresponds to the page when the user has to select the questionnaries that he wants to answer.
 */
export class QuestionnaryListComponent implements OnInit {
  public listOfForms: QuestionnaryList[] = null;
  public formsAlreadyFilled: Map<number, LastQuestionnary>;
  public selectedId: number[] = [];
  /**
   * idQuestionnary = id of questionnary (1 to 10)
   * id = id in database
   */
  public selectedQuestionnaries: QuestionnaryAnswers[] = [];
  public preselectedId: boolean[] = [];
  public idmax = 0;
  /**
   * idQ = id of questionnary (1 to 10)
   * id = id in database
   */
  private toBeDeleted: Array<{ idQ: number; answers: QuestionnaryAnswerQuestion[] }> = [];

  /**
   * Initializations of all services
   * @param serviceBilan Service for creating bilan
   * @param serviceQuestionnary service for getting and setting questionnaries in DB
   * @param router for routing
   * @param route for routing
   */
  public constructor(
    private serviceQuestionnary: QuestionnaryDataService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: TranslateService,
    private serviceSave: QuestionnarySavingImp,
    private histoVersusConsultationService: HistoVersusConsultationService,
  ) {}

  /**
   * This function gets the form's list and check if we are in case of modificaton questionnaries of creating questionnaries.
   */
  public async ngOnInit(): Promise<void> {
    this.histoVersusConsultationService.setToReadOnly(false);
    LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, 'FormsSelector');
    const res: number[] = await this.serviceSave.getQuestionnaries();
    if (res.length !== 0) {
      // case we are modifying questionnaries
      await Promise.all(
        res.map(async (questionnary) => {
          let tableAnswers: QuestionnaryAnswerQuestion[] = [];
          tableAnswers = await this.serviceSave.getQuestions(questionnary);
          if (tableAnswers.length !== 0) {
            this.selectedQuestionnaries.push({ idQuestionnary: questionnary, answers: tableAnswers });
          } else {
            this.selectedQuestionnaries.push({ idQuestionnary: questionnary, answers: null });
          }
          this.preselectedId[questionnary] = true;
          if (this.idmax < questionnary) {
            this.idmax = questionnary;
          }
        }),
      );
    }
    this.listOfForms = await this.serviceQuestionnary.getQuestionnaryList(this.languageService.currentLang);
    const patient: Patient = LocalStorageEx.currentPatientSelected;
    this.formsAlreadyFilled = await this.serviceQuestionnary.getLastQuestionnariesByPatient(patient.patientId);
    this.setAlreadyDone();
  }

  /**
   * This function add or remove a questionnary from a list.
   * It deals with the list of questionnary which has to be deleted
   * (case of : user answer a questionnary, saves it and then deselects it in the questionnary list)
   * @param idQuestionnary id of the questionnary selected (between 1 and 10 )
   */
  public async AddOrRemoveQuestionnary(idQuestionnary: number): Promise<void> {
    let find: boolean = false;
    if (this.selectedId.length !== 0 || this.preselectedId[idQuestionnary]) {
      for (const i in this.selectedId) {
        if (this.selectedId[+i] === idQuestionnary) {
          this.selectedId.splice(+i, 1);
          find = true;
        }
      }
      for (const i in this.selectedQuestionnaries) {
        if (this.selectedQuestionnaries[+i].idQuestionnary === idQuestionnary) {
          this.toBeDeleted.push({
            answers: this.selectedQuestionnaries[+i].answers,
            idQ: idQuestionnary,
          });
          this.selectedQuestionnaries.splice(+i, 1);
          find = true;
          break;
        }
      }
    }
    if (!find) {
      let inToBeDeletedTable: boolean = false;
      for (const i in this.toBeDeleted) {
        if (idQuestionnary === this.toBeDeleted[+i].idQ) {
          this.selectedQuestionnaries.push({
            idQuestionnary: this.toBeDeleted[+i].idQ,
            answers: this.toBeDeleted[+i].answers,
          });
          this.toBeDeleted.splice(+i, 1);
          inToBeDeletedTable = true;
          break;
        }
      }
      if (!inToBeDeletedTable) {
        this.selectedId.push(idQuestionnary);
      }
    }
  }

  /**
   * When user click on save and next button, this function deletes the questionnary that must be deleted
   * (case of : user answer a questionnary, saves it and then deselects it in the questionnary list)
   * It also creates new "questionnaire" in database and save the list in local storage
   */
  public async addTableinLS(): Promise<void> {
    if (this.toBeDeleted.length !== 0) {
      for (const id of this.toBeDeleted) {
        await this.serviceSave.deleteQuestionnary(id.idQ);
      }
    }
    if (this.selectedId.length !== 0) {
      let index: number = 0;
      for (const id of this.selectedId) {
        await this.serviceSave.addQuestionnary(id);
        const lastquestionnary = this.formsAlreadyFilled.get(id);
        let questions;
        if (lastquestionnary) {
          questions = await this.serviceQuestionnary.getQuestions(lastquestionnary.Id);
        } else {
          questions = null;
        }
        this.selectedQuestionnaries.push({ idQuestionnary: id, answers: questions });
        LocalStorageEx.questionnaryAnswers = this.selectedQuestionnaries;
        index++;
        if (index === this.selectedId.length) {
          this.router.navigate(['/Forms'], { relativeTo: this.route });
        }
      }
    } else if (this.selectedQuestionnaries.length !== 0) {
      LocalStorageEx.questionnaryAnswers = this.selectedQuestionnaries;
      this.router.navigate(['/Forms'], { relativeTo: this.route });
    }
  }

  private setAlreadyDone() {
    for (const form of this.listOfForms) {
      form.questionnaries.forEach((q) => {
        if (this.formsAlreadyFilled.get(q.id)) {
          const bilan = this.formsAlreadyFilled.get(q.id);
          q.idBilan = bilan.BilanId;
          q.lastDate = bilan.date;
          q.lastScore = bilan.score;
        }
      });
    }
  }
}
