export const COMPARE = (direction: any, a: any, b: any) => {
  // may be equal to 0
  if (!a && a !== 0) return 1;
  if (!b && b !== 0) return -1;

  if (a < b) {
    return -1 * direction;
  }
  if (a > b) {
    return direction;
  }
  return 0;
};
