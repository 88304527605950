/**
 * 3rd-party modules
 */
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MathModule } from 'app/modules/math/math.module';
import { ChartsModule } from 'ng2-charts';

/**
 * Our modules
 */

/**
 * Components
 */
import { RapportComponentNew } from './rapport/rapportNew.component';

import { RapportPraticienComponent } from './rapportPraticien/rapportPraticien.component';

/**
 * Services
 */
import { RemoteInteraction as RemoteInteractionWalk } from 'app/modules/checkup/modules/walk/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionRomberg } from 'app/modules/checkup/modules/romberg/services/remote-interaction.service';

/**
 * Misc
 */
import { environment } from 'environments/environment';
import { TimelineChartComponent } from 'app/modules/common/views/timelineChart/timelineChart.component';
import { CommonModule as com } from 'app/modules/common/common.module';
import { VerticalBarComponent } from 'app/modules/common/views/verticalBar/verticalBar.component';
import { ShoesModule } from 'app/modules/shoes/shoes.module';
import { NgxGaugeModule } from 'ngx-gauge';
import { GaugeChartComponent } from 'app/modules/common/views/gauge-chart';

import { RapportHeaderComponent } from './rapport/rapport-header/rapport-header.component';
import { RapportWalkPartComponent } from './rapport/rapport-walk-part/rapport-walk-part.component';
import { RapportRombergPartComponent } from './rapport/rapport-romberg-part/rapport-romberg-part.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ChartsModule,
    MathModule,
    NgxGaugeModule,
    com,
    ShoesModule,
  ],
  declarations: [
    // ChoixImpressionComponent,
    RapportComponentNew,
    RapportPraticienComponent,
    // FloatDisplayed
    GaugeChartComponent,
    RapportHeaderComponent,
    RapportWalkPartComponent,
    RapportRombergPartComponent,
  ],
  providers: [RemoteInteractionWalk, RemoteInteractionRomberg, TimelineChartComponent, VerticalBarComponent],
})
export class RapportsModule {
  constructor(router: Router) {
    if (environment.enableRouteDebug) {
      console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
  }
}
