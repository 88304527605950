import { Assessment, WalkAssessmentType } from 'app/modules/checkup/modules/walk/models/assessment.model';
import { LocomotionBilanVisualAssessment,
          RombergBilanVisualAssessment } from 'app/modules/smartflat-data-access/src/service-dal/model/models';
import { RombergAssessment } from 'app/modules/smartflat-data-access/src/model/romberg-assessment.model';
import { RombergAssessmentType, AssessmentRomberg } from 'app/modules/checkup/modules/romberg/models/assessment.model';
import { RombergTest } from 'app/modules/smartflat-data-access';

export function locomotionBilanAssessmentToAssessment(loco: LocomotionBilanVisualAssessment): Assessment[] {
    const assessments: Assessment[] = initAssessment();
    if (loco) {
        if (loco.AsymmetricalWalk) {
            assessments[3].nonEvaluated = false;
            assessments[3].value = loco.AsymmetricalWalk;
        }
        if (loco.BalanceLoss) {
            assessments[5].nonEvaluated = false;
            assessments[5].value = loco.BalanceLoss;
        }
        if (loco.HestitantWalk) {
            assessments[0].nonEvaluated = false;
            assessments[0].value = loco.HestitantWalk;
        }
        if (loco.IrregularWalk) {
            assessments[2].nonEvaluated = false;
            assessments[2].value = loco.IrregularWalk;
        }
        if (loco.SmallStepWalk) {
            assessments[1].nonEvaluated = false;
            assessments[1].value = loco.SmallStepWalk;
        }
        if (loco.SwingingArmsLoss) {
            assessments[4].nonEvaluated = false;
            assessments[4].value = loco.SwingingArmsLoss;
        }
    }
    console.log('ASSESSMENT', assessments);
    return assessments;
}

function initAssessment(): Assessment[] {
    const assessments = [WalkAssessmentType.Hesitant, WalkAssessmentType.SmallSteps,
    WalkAssessmentType.Irregular, WalkAssessmentType.Asymetric, WalkAssessmentType.SwingingArms, WalkAssessmentType.Balance]
        .map((type) => {
            const assessment = new Assessment();
            assessment.type = type;
            assessment.nonEvaluated = true;
            return assessment;
        });
    return assessments;

}
function initRombergAssessment(): AssessmentRomberg[] {
    const assessments = [RombergAssessmentType.Fall, RombergAssessmentType.QualityEquiYF,
        RombergAssessmentType.QualityEquiYO, RombergAssessmentType.QualityWalk]
        .map((type) => {
            const assessment = new AssessmentRomberg();
            assessment.type = type;
            assessment.nonEvaluated = true;
            return assessment;
        });
    return assessments;

}
export function rombergBilanAssessmentToAssessment(romb: RombergBilanVisualAssessment): AssessmentRomberg[] {
    const assessments: AssessmentRomberg[] = initRombergAssessment();
    if (romb) {
        if (romb.EverydayBalance) {
          assessments[0].nonEvaluated = false;
          assessments[0].value = romb.EverydayBalance;
        }
        if (romb.EyesClosedBalanceQuality) {
            assessments[1].nonEvaluated = false;
            assessments[1].value = romb.EyesClosedBalanceQuality;
        }
        if (romb.EyesOpenBalanceQuality) {
            assessments[2].nonEvaluated = false;
            assessments[2].value = romb.EyesOpenBalanceQuality;
        }
        if (romb.WalkingQuality) {
            assessments[3].nonEvaluated = false;
            assessments[3].value = romb.WalkingQuality;
        }
    }
    console.log('ASSESSMENT', assessments);
    return assessments;
}
