import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartPoint } from 'chart.js';
import { LongitudinalGraph } from 'app/modules/checkup/modules/walk/models/longitudinal.model';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';

@Component({
  selector: 'graph-timeline',
  templateUrl: './timelineChart.component.html',
})
export class TimelineChartComponent implements OnInit {
  @Input()
  public idLongitudinal2: string;

  @Input()
  public idTitre2: string;

  @Input()
  public title: string;

  @Input()
  public data: LongitudinalGraph;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @ViewChild('horizontalBar', { static: true })
  public histo: ElementRef;

  constructor(private localDatePipe: LocalDatePipe) {}

  public ngOnInit(): void {
    const points: any[] = [];
    const pointsDate: any[] = [];
    const labels: string[] = [];
    console.log('Graph', this.data);
    for (const pointData of this.data.graph) {
      pointsDate.push(pointData);
    }
    points.push({ data: pointsDate, fill: false, showLine: true, borderColor: 'rgba(255, 0, 0, 1)', lineTension: 0 });
    const datasets: any = { datasets: points };
    this.initGraph(datasets, labels);
  }

  private initGraph(datasets: any, labels: string[]): void {
    const canvas = this.histo.nativeElement as HTMLCanvasElement;

    let isResponsive = true;
    if (this.width && this.height) {
      canvas.height = this.height;
      canvas.width = this.width;
      isResponsive = false;
    }

    const ctx = canvas.getContext('2d');
    const ctorOptions: ChartConfiguration = {
      type: 'scatter',
      data: datasets,
      options: {
        responsive: isResponsive,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const point = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as ChartPoint;
              const date = this.localDatePipe.transform(point.x);
              return date + ' --- ' + (point.y as number).toFixed(1);
            },
          },
        },
        animation: { duration: 0 },
        legend: {
          display: false,
        },
        elements: {
          point: {
            pointStyle: 'circle',
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
            },
          ],
        },
      },
    };
    console.log('GraphScatter', ctorOptions);
    const myChart = new Chart(ctx, ctorOptions);
  }
}
