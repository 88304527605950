/**
 * This class describes the content of a romberg test. eo = eyes open, ec = eyes close
 */
export class RombergTest {
    public id_patient: number;
    public date: Date;
    public settings: string = '';
    public average_speed_eo: number;
    public average_speed_ec: number;
    public sway_density_eo: number;
    public sway_density_ec: number;
    public surface_eo: number;
    public surface_ec: number;
    public lateral_variance_eo: number;
    public lateral_variance_ec: number;
    public duration_eo: number;
    public duration_ec: number;
    public fall: boolean; // if the patient was catched or not during the test
    public Id: number;
    public BilanId: number;
}
export enum EtatYeux {
    YO, YF,
}
