import {
  Component,
  OnInit,
  Input,
  QueryList,
  ViewChildren,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { PatientCellDateComponent } from '../../patient-celldate/patient-celldate.component';
import { PatientCelldateHoursComponent } from '../../patient-celldate-hours/patient-celldate-hours.component';

import { Utilities } from '../../../../tools/utilities';
import { LocalStorageEx } from '../../../../tools/localstorageobject.enum';
import {
  Consultation,
  BilanDataService,
  Bilan,
  BilanType,
  QuestionnaryDataService,
  QuestionnaryAnswer,
  ConsultationDataService,
  Patient,
} from '../../../../modules/smartflat-data-access';
import { BilanDetailed } from '../../../../model/bilan-detailed.model';
import { SearchDataSource } from '../../searchDataSource';
import { ɵbo as Ng2SmartTableComponent } from 'ng2-smart-table';
import * as moment from 'moment';

import { COMPARE } from '../functions';
import {
  NavigationActionsService,
  NavigationActionLink,
  NavigationActionClick,
  NotificationService,
} from '../../../../modules/core';
import { IPatient } from '../../patient.interface';

import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { QuestionnaryList } from '../../../../modules/smartflat-data-access';
import { LOCALDATEFORMAT } from '../../../../tools/divers.const';
import { PatientResultFilterStateServiceService } from '../../patient-result-filter-state-service.service';
import { Row } from 'ng2-smart-table/lib/lib/data-set/row';

@Component({
  selector: 'patient-sub-results',
  templateUrl: './patient-sub-results.component.html',
})
export class PatientSubResultsComponent implements OnInit, OnChanges {
  public static readonly TableId = 'consultation_table';
  @ViewChildren(Ng2SmartTableComponent)
  public ng2SmartTables: QueryList<Ng2SmartTableComponent>;
  @Input()
  public selectedPatient: IPatient;
  @Input()
  public selectedConsultation: Consultation;

  public consultationSource: SearchDataSource;
  public consultations: Consultation[];

  public consultationDefaultSettings = {
    columns: {
      date: {
        title: 'Date',
        filter: false,
        type: 'custom',
        valuePrepareFunction: (cell, row) => this.consultCell(cell, row),
        sort: true,
        sortDirection: 'desc',
        compareFunction: COMPARE,
        renderComponent: PatientCellDateComponent,
      },
    },
    actions: { add: false, delete: true, edit: false, position: 'right', columnTitle: '' },
    attr: { id: PatientSubResultsComponent.TableId, class: 'table table-striped table-condensed consultation_table' },
    delete: { deleteButtonContent: '', confirmDelete: true },
    noDataMessage: '',
    mode: 'external',
    selectMode: 'single',
    pager: { perPage: 10 },
    hideSubHeader: true,
  };

  public consultationSettings: any;
  public selectedBilan: BilanDetailed;
  public bilans: BilanDetailed[];
  public bilanSource: SearchDataSource;
  public bilanOptions: string[];
  public questionOptions: Array<{ id: number; nom: string }> = [];
  public bilanDefaultSettings = {
    columns: {
      Type: {
        title: 'Type',
        filter: false,
        compareFunction: COMPARE,
      },
      Date: {
        title: 'Date',
        filter: false,
        type: 'custom',
        renderComponent: PatientCelldateHoursComponent,
      },
    },
    actions: { add: false, delete: true, edit: false, position: 'right', columnTitle: '' },
    attr: { id: 'bilan_table', class: 'table table-striped table-condensed bilan_table' },
    delete: { deleteButtonContent: '', confirmDelete: true },
    noDataMessage: 'Patient.Bilan.TableHeader.NoBilan',
    mode: 'external',
    selectMode: 'single',
    pager: { perPage: 10 },
    hideSubHeader: true,
  };
  public bilanSettings: any;

  public _staticBilan: number;
  public _staticQuestion: number;

  // function called too many time for nothing, probably a part to refactor
  // value not used apparently, bilanDate only used as boolean in a ngIf? to check
  public get bilanDate(): string {
    if (this.selectedConsultation) return this.selectedConsultation.date.toString();
    // TODO: localize correctly
    else return null;
  }

  public get showBilanListX(): boolean {
    if (this.selectedBilan) {
      return true;
    }
    return false;
  }

  public get showBilanList(): boolean {
    if (this.selectedConsultation) {
      return true;
    }
    return false;
  }

  public startDate = null;
  public startDateOptions: IMyDpOptions = {
    dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
  };

  public endDate = null;
  public endDateOptions: IMyDpOptions = {
    dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
  };

  private patientSelected$ = new BehaviorSubject<boolean>(false);
  private torButtonStatus$ = new BehaviorSubject<boolean>(false);

  private listOfForms: QuestionnaryList[];

  public constructor(
    private languageService: TranslateService,
    private serviceQuestionnary: QuestionnaryDataService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private bilanService: BilanDataService,
    private questionnaryService: QuestionnaryDataService,
    private notificationService: NotificationService,
    private navigationActionsService: NavigationActionsService,
    private consultationService: ConsultationDataService,
    private filterState: PatientResultFilterStateServiceService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.consultationSettings = Utilities.deepCopy(this.consultationDefaultSettings);
    this.bilanSettings = Utilities.deepCopy(this.bilanDefaultSettings);

    this.translateService.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
  }

  public ngOnInit() {
    const actions = [];
    this.bilanOptions = [
      '',
      this.translateService.instant('Patient.Bilan.TableHeader.Grip'),
      this.translateService.instant('Patient.Bilan.TableHeader.Weighing'),
      this.translateService.instant('Patient.Bilan.TableHeader.Locomotion'),
      this.translateService.instant('Patient.Bilan.TableHeader.Romberg'),
      this.translateService.instant('Patient.Questions'),
    ];

    const state = this.filterState.saveBilanFilter;
    this.startDate = state.starDate ? { jsdate: state.starDate } : null;
    this.disableUntil(state.starDate);
    this.endDate = state.endDate ? { jsdate: state.endDate } : null;
    this.disableSince(state.endDate);
    this._staticBilan = state.bilan;
    if (this._staticBilan === 5) {
      this.fillQuestionnaire();
    }
    this._staticQuestion = state.question;

    console.log('Restore bilan id ', this._staticBilan);
    this.onLangChange();

    const goToTor = new NavigationActionLink('Visualiser le suivi continu', '/tor', {
      active: this.torButtonStatus$,
    });

    const goToBilan = new NavigationActionClick('Visualiser le bilan sélectionné', () => this.bilanView(), {
      active: this.patientSelected$.asObservable(),
    });

    const goToIndicators = new NavigationActionClick('Visualiser les indicateurs', () => this.indicatorView(), {
      active: this.patientSelected$.asObservable(),
    });

    if (LocalStorageEx.currentEstablishment.Config) {
      if (LocalStorageEx.currentEstablishment.Config.boxes === true) {
        actions.push(goToTor);
      }
    }
    actions.push(goToIndicators);
    actions.push(goToBilan);

    this.navigationActionsService.pushNavigationActions(actions);
  }

  public ngOnDestroy() {
    this.filterState.saveBilanFilter = {
      starDate: this.startDate ? this.startDate.jsdate : null,
      endDate: this.endDate ? this.endDate.jsdate : null,
      bilan: this._staticBilan,
      question: this._staticQuestion,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPatient) {
      const newPatient = changes.selectedPatient.currentValue as IPatient;

      const hasFlat = !!(newPatient && newPatient.flatName);
      this.torButtonStatus$.next(hasFlat);

      this.selectedConsultation = null;
      this.selectedBilan = null;

      if (!newPatient) {
        this.patientSelected$.next(false);
        this.bilanSource = new SearchDataSource([], this.bilanDefaultSettings.attr.id);
      } else {
        this.patientSelected$.next(true);
        this.getSearchedConsultations();
      }
    }
  }

  public onLangChange() {
    for (const columnName of Object.keys(this.consultationDefaultSettings.columns)) {
      this.consultationSettings.columns[columnName].title = this.translateService.instant(
        this.consultationDefaultSettings.columns[columnName].title,
      );
    }
    /* when translated completely, reset smart table. */
    this.consultationSettings = Object.assign({}, this.consultationSettings);

    for (const columnName of Object.keys(this.bilanDefaultSettings.columns)) {
      this.bilanSettings.columns[columnName].title = this.translateService.instant(
        this.bilanDefaultSettings.columns[columnName].title,
      );
    }
    /* When translated completely, reset smart table. */
    this.bilanSettings = Object.assign({}, this.bilanSettings);

    for (const columnName of Object.keys(this.bilanDefaultSettings.columns)) {
      this.bilanSettings.columns[columnName].title = this.translateService.instant(
        this.bilanDefaultSettings.columns[columnName].title,
      );
    }

    this.bilanSettings.noDataMessage = this.translateService.instant(this.bilanSettings.noDataMessage);

    /* When translated completely, reset smart table. */
    this.bilanSettings = Object.assign({}, this.bilanSettings);
  }

  public onSelectConsultation(event) {
    LocalStorageEx.currentBilan = null;
    this.selectedConsultation = event.data;
    LocalStorageEx.currentConsultation = this.selectedConsultation;
    this.getBilans();
  }

  public rowSelect(event) {
    this.onSelectConsultation(event);

    // **** BEGIN HACK
    // The ng2-smart-table has a bug (or at least our use of it does)
    // When we first display our table or change pages, the isSelected is true
    // for the first row but it does not have the selected class on the row.
    // This means the row is not highlighted.
    // This is a hack that allows us to reinitialize the state.
    // If the ng2-smart-table component has been upgraded, try without this!
    const smartTable = this.ng2SmartTables.find((table) => table.tableId === this.consultationDefaultSettings.attr.id);
    if (smartTable) {
      smartTable.grid.getRows().forEach((row: Row) => {
        if (row.isSelected) {
          row.isSelected = false;
          this.changeDetector.detach();
          this.changeDetector.reattach();
          this.changeDetector.detectChanges();
          row.isSelected = true;
        }
      });
    }
    // **** END HACK
  }

  public bilanCell(cell: any, rowData: any) {
    if (this.selectedBilan === rowData) {
      const smartTable = this.ng2SmartTables.find((table) => table.tableId === this.bilanDefaultSettings.attr.id);
      if (smartTable && smartTable.isPagerDisplay) {
        setTimeout(() => {
          if (this.bilanSource.recoverRowSelected(rowData)) {
            const row = smartTable.grid.dataSet.findRowByData(rowData);
            if (row) {
              row.isSelected = true;
            }
          }
        }, 1);
      }
    }
    return cell;
  }

  public async onSelectBilan(event) {
    this.selectedBilan = event.data;

    /**
     * Resolve the bug of ng2-smart-table
     */
    if (this.bilanSource.selectFirstRow(event.data)) {
      const smartTable = this.ng2SmartTables.find((table) => table.tableId === this.bilanDefaultSettings.attr.id);
      if (smartTable) {
        const row = smartTable.grid.dataSet.findRowByData(event.data);
        smartTable.grid.dataSet.selectRow(row);
        event = row;
      }
    }
  }

  public onDeleteBilan(event) {
    this.selectedBilan = event.data;
  }

  /**
   * when user wants to see a checkup. This function changes the root.
   */
  public bilanView() {
    if (this.selectedBilan) {
      switch (this.selectedBilan.TypeInDB) {
        case BilanType.Questionnary: {
          LocalStorageEx.currentBilan = this.selectedBilan;
          this.router.navigate(['/checkup-questionnary'], { relativeTo: this.route });
          break;
        }
        case BilanType.CheckupGrip:
          LocalStorageEx.currentBilan = this.selectedBilan;
          this.router.navigate(['/checkup/grip/history']);
          break;
        case BilanType.CheckupWeight:
          LocalStorageEx.currentBilan = this.selectedBilan;
          this.router.navigate(['/checkup/weight/histo']);
          break;
        case BilanType.CheckupWalk:
          LocalStorageEx.currentBilan = this.selectedBilan;
          this.router.navigate(['/checkup/walk/histo']);
          break;
        case BilanType.CheckupRomberg:
          LocalStorageEx.currentBilan = this.selectedBilan;
          this.router.navigate(['/checkup/romberg/histo']);
          break;
        default: {
          console.error('PatientComponent: unsupported bilan.Type: ', this.selectedBilan.TypeInDB);
          const opt = { type: this.selectedBilan.TypeInDB };
          const msg = this.translateService.instant('Patient.Bilan.Errors.UnknownTypeMessage', opt);
          this.notificationService.pushErrorNotifications(msg);
          break;
        }
      }
    }
  }

  public indicatorView() {
    if (this.selectedPatient && this.selectedConsultation && this.selectedBilan) {
      const idPatient = this.selectedPatient.patientId;
      const idConsultation = this.selectedConsultation.id;

      this.router.navigate(['/checkup/indicator', idPatient, idConsultation]);
    }
  }

  public consultCell(cell: any, rowData: any) {
    if (this.selectedConsultation === rowData) {
      const smartTable = this.ng2SmartTables.find(
        (table) => table.tableId === this.consultationDefaultSettings.attr.id,
      );
      if (smartTable && smartTable.isPagerDisplay) {
        setTimeout(() => {
          if (this.consultationSource.recoverRowSelected(rowData)) {
            const row = smartTable.grid.dataSet.findRowByData(rowData);
            if (row) {
              row.isSelected = true;
            }
          }
        }, 1);
      }
    } else {
      this.selectedBilan = null;
      this.bilanSource = new SearchDataSource([], this.bilanDefaultSettings.attr.id);
    }
    return cell;
  }

  /**
   * Get all bilans for a consultation. And then, check if it's questionnaries. In case of questionnaries we make a little modification
   * We considere one bilan = one questionnary.
   * TODO : if we change type of bilan change here (for the moment 0 = questionnary)
   */
  public async getBilans(): Promise<void> {
    const bilanTable: Bilan[] = await this.bilanService.getBilans(this.selectedConsultation.id);
    this.bilans = [];

    const allQuestionnaries = await this.questionnaryService.getQuestionnaryList(this.translateService.currentLang);
    for (const bilan of bilanTable) {
      switch (bilan.Type) {
        case BilanType.Questionnary:
          const questionnaries: QuestionnaryAnswer[] = await this.questionnaryService.getQuestionnaries(bilan.Id);

          // complicated code, because we need to compare each questionnary id to find the questionnary's name.
          for (const questionnaireAnswered of questionnaries) {
            for (const questionnaires of allQuestionnaries) {
              for (const questionnaire of questionnaires.questionnaries) {
                if (questionnaire.id === questionnaireAnswered.Type) {
                  const theBilan = new BilanDetailed();
                  theBilan.QuestionnaryId = questionnaireAnswered.Id;
                  theBilan.Id = bilan.Id;
                  const baseType = this.translateService.instant('Patient.Bilan.TableHeader.Questionnary');
                  theBilan.Type = baseType + ' ' + questionnaire.nom;
                  theBilan.TypeInDB = BilanType.Questionnary;
                  theBilan.questionnaryType = questionnaireAnswered.Type;
                  theBilan.Date = this.selectedConsultation.date;
                  this.bilans.push(theBilan);
                  break;
                }
              }
            }
          }
          break;
        case BilanType.CheckupGrip:
          const gripBilan = new BilanDetailed();
          gripBilan.Type = this.translateService.instant('Patient.Bilan.TableHeader.Grip');
          gripBilan.TypeInDB = bilan.Type;
          gripBilan.Id = bilan.Id;
          gripBilan.Date = this.selectedConsultation.date;
          this.bilans.push(gripBilan);
          break;
        case BilanType.CheckupWalk:
          const walkBilan = new BilanDetailed();
          walkBilan.Type = this.translateService.instant('Patient.Bilan.TableHeader.Locomotion');
          walkBilan.TypeInDB = bilan.Type;
          walkBilan.Id = bilan.Id;
          walkBilan.Date = this.selectedConsultation.date;
          this.bilans.push(walkBilan);
          break;
        case BilanType.CheckupRomberg:
          const rombergBilan = new BilanDetailed();
          rombergBilan.Type = this.translateService.instant('Patient.Bilan.TableHeader.Romberg');
          rombergBilan.TypeInDB = bilan.Type;
          rombergBilan.Id = bilan.Id;
          rombergBilan.Date = this.selectedConsultation.date;
          this.bilans.push(rombergBilan);
          break;

        case BilanType.CheckupWeight:
          const weightBilan = await this.getMeasuredWeightBilan();
          this.bilans.push(weightBilan);
          break;
        default:
          console.error('PatientComponent: unsupported bilan.Type: ', bilan.Type);
          const unsupportedBilan = new BilanDetailed();
          unsupportedBilan.Type = this.translateService.instant('Patient.Bilan.TableHeader.UnknownType');
          unsupportedBilan.TypeInDB = bilan.Type;
          unsupportedBilan.Id = bilan.Id;
          unsupportedBilan.Date = this.selectedConsultation.date;
          this.bilans.push(unsupportedBilan);
          break;
      }
    }
    this.bilanSource = new SearchDataSource(this.bilans, this.bilanDefaultSettings.attr.id);
    this.selectedBilan = this.bilans[0];
  }

  public onStartDateChanged(value: any) {
    this.startDate = value;

    const d = this.startDate ? this.startDate.jsdate : null;
    this.disableUntil(d);
    this.getSearchedConsultations();
  }

  public onEndDateChanged(value: any) {
    this.endDate = value;

    const d = this.endDate ? this.endDate.jsdate : null;
    this.disableSince(d);
    this.getSearchedConsultations();
  }

  public getSearchedConsultations() {
    const startDate = this.parseDate(this.startDate, false);
    const endDate = this.parseDate(this.endDate, true);

    let typeBilanStr = this._staticBilan > 0 ? this._staticBilan.toString() : '';
    let typeQuestionnaire = this._staticQuestion.toString();
    if (this._staticQuestion >= 0) {
      typeBilanStr = '';
    } else {
      typeQuestionnaire = '';
    }

    this.consultationService
      .getSearchedConsultationsForPatient(
        this.selectedPatient.patientId,
        startDate,
        endDate,
        typeBilanStr,
        typeQuestionnaire,
      )
      .then((consultationList) => {
        this.consultations = consultationList;
        this.consultationSource = new SearchDataSource(this.consultations, PatientSubResultsComponent.TableId);
        this.selectedConsultation = this.consultations[0];
        LocalStorageEx.currentConsultation = this.selectedConsultation;
      });
  }

  public async onBilanTypeChange(value: number) {
    this._staticBilan = value;
    this._staticQuestion = -1;
    this.questionOptions = [];
    if (this._staticBilan !== 0) {
      if (this._staticBilan === 5) {
        this.fillQuestionnaire();
      } else {
        this.getSearchedConsultations();
      }
    } else {
      this._staticBilan = 0;
      this.getSearchedConsultations();
    }
  }

  public onQuestionOptionChange(question: number) {
    this._staticQuestion = question;
    if (question >= 0) {
      this.getSearchedConsultations();
    }
  }

  private async fillQuestionnaire() {
    this.questionOptions.push({ nom: '', id: -1 });
    this.listOfForms = await this.serviceQuestionnary.getQuestionnaryList(this.languageService.currentLang);
    for (const questionnaryList of this.listOfForms) {
      for (const questionnary of questionnaryList.questionnaries) {
        this.questionOptions.push(questionnary);
      }
    }
  }

  // Weightings are stored in the current consultation (MeasuredWeight !== 0)
  private async getMeasuredWeightBilan(): Promise<BilanDetailed> {
    const consultation = this.selectedConsultation;

    if (consultation.MeasuredWeight > 0) {
      const weightBilan = new BilanDetailed();
      weightBilan.Type = this.translateService.instant('Patient.Bilan.TableHeader.Weighing');
      weightBilan.TypeInDB = BilanType.CheckupWeight;
      weightBilan.Id = null;
      weightBilan.Date = consultation.date;
      return weightBilan;
    }

    return null;
  }

  private parseDate(inputdate: IMyDateModel, isEndDate: boolean): string {
    const d = inputdate ? inputdate.jsdate : null;
    if (d) {
      const momentDate = moment(d);

      if (isEndDate) {
        momentDate.hour(23);
        momentDate.minute(59);
        momentDate.second(59);
      } else {
        momentDate.hour(0);
        momentDate.minute(0);
        momentDate.second(0);
      }
      return momentDate.toISOString();
    }
    return '';
  }

  private disableUntil(start: Date) {
    if (start) {
      const startmoment = moment(start).add(-1, 'day');
      this.endDateOptions = {
        dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
        disableUntil: {
          year: startmoment.year(),
          month: startmoment.month() + 1,
          day: startmoment.date(),
        },
      };
    } else {
      this.endDateOptions = {
        dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
        disableUntil: {
          year: 0,
          month: 0,
          day: 0,
        },
      };
    }
  }

  private disableSince(start: Date) {
    if (start) {
      const endmoment = moment(start).add(1, 'day');
      this.startDateOptions = {
        dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
        disableSince: {
          year: endmoment.year(),
          month: endmoment.month() + 1,
          day: endmoment.date(),
        },
      };
    } else {
      this.startDateOptions = {
        dateFormat: LOCALDATEFORMAT[this.translateService.currentLang],
        disableSince: {
          year: 0,
          month: 0,
          day: 0,
        },
      };
    }
  }
}
