import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationAction } from '../../models/navigation-action.model';
import { NavigationActionClick } from '../../models/navigation-action-click.model';
import { NavigationActionLink } from '../../models/navigation-action-link.model';
import { NavigationActionsService } from 'app/modules/core/services/navigation-actions/navigation-actions.service';

@Component({
  selector: 'core-navigation-actions',
  templateUrl: './navigation-actions.component.html',
  styleUrls: ['./navigation-actions.component.css'],
})
export class NavigationActionsComponent {
  public actions: NavigationAction[] = [];
  @ViewChild('linkTemplate', { static: true })
  public linkTemplate: TemplateRef<any>;
  @ViewChild('clickTemplate', { static: true })
  public clickTemplate: TemplateRef<any>;
  @ViewChild('emptyTemplate', { static: true })
  public emptyTemplate: TemplateRef<any>;
  private subscription: Subscription;

  public constructor(private navigationActionsService: NavigationActionsService) {}

  public ngOnInit(): void {
    this.subscription = this.navigationActionsService.navigationActions$.subscribe(
      (actions) => (this.actions = actions),
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getTemplate(action: NavigationAction): TemplateRef<any> {
    if (action instanceof NavigationActionLink) {
      return this.linkTemplate;
    } else if (action instanceof NavigationActionClick) {
      return this.clickTemplate;
    } else {
      console.error('NavigationActionsComponent: unsupported derivative of NavigationAction: ', action);
      return this.emptyTemplate;
    }
  }
}
