import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { Notification } from '../../models/notification.model';

@Injectable()
export class NotificationService {

    public notificationChanged$: Observable<Notification[]>;
    private notificationChanged: BehaviorSubject<Notification[]>;

    public constructor() {
        this.notificationChanged = new BehaviorSubject([]);
        this.notificationChanged$ = this.notificationChanged.asObservable();
    }

    public pushNotifications(...notifications: Notification[]): void {
        this.notificationChanged.next(notifications);
    }

    public pushErrorNotifications(messages: string[] | string): void {
        const mm = (typeof messages === 'string') ? [messages] : messages;
        const notifications = mm.map((m) => Notification.newError(m));
        this.notificationChanged.next(notifications);
    }

    public pushInfoNotifications(messages: string[] | string): void {
        const mm = (typeof messages === 'string') ? [messages] : messages;
        const notifications = mm.map((m) => Notification.newInfo(m));
        this.notificationChanged.next(notifications);
    }

}
