import { BodyPosition } from '../models/body-position.model';

export class SensorConfiguration {

    public stationId: string;
    public sensorId: string;
    public bodyPosition: BodyPosition;

    public constructor(
        stationId: string,
        sensorId: string,
        bodyPosition: BodyPosition) {

        this.stationId = stationId;
        this.sensorId = sensorId;
        this.bodyPosition = bodyPosition;
    }
}
