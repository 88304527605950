import { QuestionSet } from './question-set.model';

/**
 * Object representing a diagnostic form (all of the questions with the possible answers)
 */
export class HealthForm {
  public id: string;
  public nom: string;
  public listeQuestions: QuestionSet[];
}
