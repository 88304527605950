import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Answer, HealthAnswer, Question, SubOption } from '../../../modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../tools/localstorageobject.enum';

@Component({
  selector: 'app-health-form-question',
  templateUrl: './health-form-question.component.html',
})
/**
 * This component representes a question and an answer of the diagnostic form
 * It exchanges data with health-form-question-set component (it gets a questions and sends an answer)
 * It also sends the options of an answer to health-form-options component
 */
export class HealthFormQuestionComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public question: Question;
  @Input()
  public name = 'default';
  @Output()
  public answer_out = new EventEmitter<{ answer: Answer; id: string }>();

  // Current answer
  public answer: Answer = new Answer();

  // previous anser
  public previousAnswer: Answer = new Answer();

  // Sub options for the user to select
  public subOptionsToshow;
  // Sub option preselected
  public subOptionsPreselectedId;

  // show sub selection
  public plusieursOptions: boolean = false;

  public diagnosticStatus: boolean = false;

  public ngOnChanges() {
    if (LocalStorageEx.diagnosticStatus !== undefined && LocalStorageEx.diagnosticStatus === 'edit') {
      this.diagnosticStatus = true;
    } else {
      this.diagnosticStatus = false;
    }
  }
  /**
   * Initialization. If we are in case of editing an diagnostic this function will call an other function to fill the form.
   */
  public ngOnInit(): void {
    this.answer.question = this.question.nom;
    this.answer.question_id = this.question.id;
    if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
      this.preFillForm();
    }
  }

  /**
   * This function is called in case of edit an existing diagnostic.
   * This function specifies the place of the answer in the question for the html
   *  part (put the answer in orange)
   */
  private preFillForm(): void {
    const prefilledForm = LocalStorageEx.restore(LocalStorageObject.diagnostic_patient, HealthAnswer);
    const _this = this;

    prefilledForm.answerSets.forEach((element) => {
      element.answers.forEach((elmt) => {
        if (_this.question.id === elmt.question_id) {
          _this.previousAnswer.answer = elmt.answer;
          _this.previousAnswer.option = elmt.option;
          // preselect only answer and not option
          _this.answer.answer = elmt.answer;
        }
      });
    });
  }

  /**
   * When the user gives an answer, this function is called.
   * It saves answer and if there are no options for the answer, it sends the answer to the component health-form-question-set
   * @param val the answer
   */
  private setValue(val: number): void {
    this.diagnosticStatus = false;
    this.title = this.question.options[val].question;
    this.answer.answer = this.question.options[val];

    if (this.question.options[val].subOptions.length === 0) {
      // if no suboption validation
      this.plusieursOptions = false;
      this.answer.option = null;
      this.subOptionsToshow = [];
      this.subOptionsPreselectedId = null;
      this.previousAnswer.answer = this.answer.answer;
      this.previousAnswer.option = this.answer.option;
      //      this.preselectedItem = this.selectedItem;
      this.answer_out.emit({ answer: this.answer, id: this.question.id });
    } else {
      // same item selectioned change suboption
      this.subOptionsToshow = this.question.options[val].subOptions;
      if (this.previousAnswer.answer && this.answer.answer.id === this.previousAnswer.answer.id) {
        this.subOptionsPreselectedId = this.previousAnswer.option && this.previousAnswer.option.id;
      }
      // show select dialog
      this.plusieursOptions = true;
      // clear response : waiting for sub selection
      this.answer_out.emit({ answer: null, id: this.question.id });
    }
  }

  /**
   * This function is called by the html when the user add an option at the answer.
   * @param val the id of the option
   */
  private addOption(val: number) {
    // set the curent value
    this.answer.option = this.subOptionsToshow.find((opt) => opt.id === val);

    // reset previsous answer
    this.previousAnswer.answer = this.answer.answer;
    this.previousAnswer.option = this.answer.option;

    // hide dialog
    this.plusieursOptions = false;

    this.answer_out.emit({ answer: this.answer, id: this.question.id });
  }

  /**
   * Close popup for options without selecting a value
   */
  private close() {
    // hide dialog
    this.plusieursOptions = false;
    // resore previous value if one is present
    if (this.previousAnswer.answer) {
      this.answer.answer = this.previousAnswer.answer;
      this.answer.option = this.previousAnswer.option;
      this.answer_out.emit({ answer: this.answer, id: this.question.id });
    } else {
      this.answer.answer = null;
      this.answer.option = null;
      this.answer_out.emit({ answer: null, id: this.question.id });
    }
  }
}
