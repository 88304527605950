import { Observable, Subscription, of } from 'rxjs';

import { INavigationActionOptions } from './navigation-action-options.model';
import { tap } from 'rxjs/operators';

export abstract class NavigationAction {
  public key: Observable<string>;
  public visible: Observable<boolean>;
  public active: Observable<boolean>;
  public classes: Observable<string[]>;
  private currentKey = 'yetUnknown';

  public constructor(key: string | Observable<string>, options?: INavigationActionOptions) {
    this.handleKey(key);
    this.handleVisible(options);
    this.handleActive(options);
    this.handleClasses(options);
  }

  private handleKey(key: string | Observable<string>): void {
    if (typeof key === 'string') {
      this.currentKey = key;
      this.key = of(key);
    } else {
      this.key = key.pipe(
        tap((newKey) => {
          console.log(this.constructor.name + ': changing key from "' + this.currentKey + '" to "' + newKey + '".');
          this.currentKey = newKey;
        }),
      );
    }
  }

  private handleClasses(options?: INavigationActionOptions): void {
    if (options && options.classes) {
      // replace options.classes instanceof Observable (do not work)
      if ((options.classes as Observable<string[]>).subscribe) {
        this.classes = options.classes as Observable<string[]>;
      } else {
        this.classes = of(options.classes as string[]);
      }
    }
  }

  private handleVisible(options?: INavigationActionOptions): void {
    if (options && options.visible) {
      this.visible = options.visible.pipe(
        tap((visible) => {
          console.log(this.constructor.name + ': for "' + this.currentKey + '", changed visibility to', visible);
        }),
      );
    }

    if (!this.visible) {
      this.visible = of(true);
    }
  }

  private handleActive(options?: INavigationActionOptions): void {
    if (options && options.active) {
      this.active = options.active.pipe(
        tap((active) => {
          console.log(this.constructor.name + ': for "' + this.currentKey + '", changed activation to', active);
        }),
      );
    }

    if (!this.active) {
      this.active = of(true);
    }
  }
}
