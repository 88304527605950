import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { LocalStorageEx } from 'app/tools/localstorageobject.enum';
import {
  Footwear,
  RombergBilan,
  RombergBilanVisualAssessment,
  WalkingAid,
} from 'app/modules/smartflat-data-access/src/service-dal/model/models';
import { NotificationService } from 'app/modules/core';
import { RombergAssessment } from 'app/modules/smartflat-data-access/src/model/romberg-assessment.model';
import * as Transform from '../transformations/assessment.transform';
import { AssessmentRomberg } from 'app/modules/checkup/modules/romberg/models/assessment.model';

import { ServiceDal } from '../service-dal/service-dal';
import { ShoesSelectorValues } from '../../../force-platform/models/shoes-selector-values.model';
import { ServiceDalConfig } from '../service-dal/service-dal.config';
import { TIME_TO_ROMBERG } from '../../../../tools/medical.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RombergDataService {
  private rombergProxyBaseUri: string;

  constructor(
    private serviceDal: ServiceDal,
    private notificationService: NotificationService,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    config: ServiceDalConfig,
  ) {
    this.rombergProxyBaseUri = config.rombergProxyBaseUri;
  }
  public async createRombergAcquisition(rombergAssesmentModel: RombergAssessment): Promise<void> {
    const rombergBilanAssessment: RombergAssessment = {
      Id: rombergAssesmentModel.Id,
      BilanId: rombergAssesmentModel.BilanId,
      EverydayBalance: rombergAssesmentModel.EverydayBalance,
      EyesClosedBalanceQuality: rombergAssesmentModel.EyesClosedBalanceQuality,
      EyesOpenBalanceQuality: rombergAssesmentModel.EyesOpenBalanceQuality,
      WalkingQuality: rombergAssesmentModel.WalkingQuality,
      Images: rombergAssesmentModel.Images,
      Duration: rombergAssesmentModel.Duration,
    };
    console.log('Saving assessment', rombergAssesmentModel, rombergBilanAssessment);
    const rombergBilanVisualAssessment = await this.serviceDal.rombergAssessmentService
      .rombergBilanVisualAssessmentCreate(rombergBilanAssessment)
      .toPromise();
    await this.persistRombergBilan(rombergBilanAssessment);
  }
  public async getOldLocomtionBilan(idPatient: number): Promise<RombergBilan[]> {
    const rombergs: RombergBilan[] = await this.serviceDal.patientService
      .patientPrototypeGetRombergBilans(idPatient.toString())
      .toPromise();
    return rombergs;
  }

  public async getRombergAssessment(bilanId: number): Promise<AssessmentRomberg[]> {
    const filter = '{"where":{"BilanId":"' + bilanId + '"}}';
    const rombergBilanVisualAssessment: RombergBilanVisualAssessment[] = await this.serviceDal.rombergAssessmentService
      .rombergBilanVisualAssessmentFind(filter)
      .toPromise();
    console.log('from db ', rombergBilanVisualAssessment);
    const assessments = Transform.rombergBilanAssessmentToAssessment(rombergBilanVisualAssessment[0]);
    console.log('after transform ', rombergBilanVisualAssessment);
    return assessments;
  }

  public async getRombergBilan(bilanId: number): Promise<RombergBilan> {
    const filter = '{"where":{"BilanId":"' + bilanId + '"}}';
    const rombergBilan: RombergBilan[] = await this.serviceDal.rombergBilanService.rombergBilanFind(filter).toPromise();
    return rombergBilan[0];
  }

  public async getShoesValuesByBilan(bilanId: number): Promise<ShoesSelectorValues> {
    const romberg: RombergBilan = await this.getRombergBilan(bilanId);
    const shoesSelector = new ShoesSelectorValues();

    // TODO use real ID
    const shoes: Footwear = await this.serviceDal.shoesService.footwearFindById('' + romberg.FootwearId).toPromise();

    const local = this.translateService.currentLang;
    const filter: string = '{ "where": { "id":' + romberg.StablizationHelpId + ', "culture":"' + local + '" } }';
    const help: WalkingAid = await this.serviceDal.walkingAidServive.walkingAidFindOne(filter).toPromise();
    shoesSelector.shoesType = shoes.Alias;
    shoesSelector.helpStability = help.translate;
    return shoesSelector;
  }

  private async persistRombergBilan(rombergAssesmentModel: RombergAssessment): Promise<any> {
    try {
      const preambleCheckup: ShoesSelectorValues = LocalStorageEx.currentRombergPreambleCheckup;
      console.log('preambleCheckup', preambleCheckup);

      const guid: string = LocalStorageEx.currentRombergRecordingSession;
      const url: string = this.rombergProxyBaseUri + '/analysis/' + guid + '/persist';

      const footwear: Footwear[] = await this.serviceDal.shoesService
        .footwearFind('{ "where":{"Alias":"' + preambleCheckup.shoesType + '"}}')
        .toPromise();
      console.log('find footwear', footwear);

      const helpStability: WalkingAid[] = await this.serviceDal.walkingAidServive
        .walkingAidFind('{ "where":{"translate":"' + preambleCheckup.helpStability + '"}}')
        .toPromise();
      console.log('find helpStability', helpStability);

      const body: any = {};
      rombergAssesmentModel.Duration = TIME_TO_ROMBERG;
      body.bilanId = rombergAssesmentModel.BilanId;
      body.duration = TIME_TO_ROMBERG;
      // TODO Set the correct ID
      body.stablizationHelpId = helpStability[0].id;
      body.footwearId = footwear[0].Id;

      return this.httpClient.post(url, body).toPromise();
    } catch (error) {
      console.error('Error while persist romberg ' + ', error');
      this.notificationService.pushErrorNotifications('Error while persist romberg');
      return Promise.reject<any>(error);
    }
  }
}
