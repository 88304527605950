// Exports of this library

import * as Constants from './src/constants';
export { ServiceDalConfig } from './src/service-dal/service-dal.config';
export { Handedness, HandednessArray } from './src/model/handedness.enum';
export { Gender, GenderArray } from './src/model/gender.enum';
export { Patient } from './src/model/patient.model';
export { PatientDataService } from './src/api/patient-data.service';
export { ConsultationDataService } from './src/api/consultation-data.service';
export { Consultation } from './src/model/consultation.model';
export { DiagnosticDataService } from './src/api/diagnostic-data.service';
export { BilanDataService } from './src/api/bilan-data.service';
export { Bilan, BilanType } from './src/model/bilan.model';
export { QuestionnaryAnswerQuestion } from './src/model/questionnary-answer-question.model';
export { QuestionnaryAnswer } from './src/model/questionnary-answer.model';
export { QuestionnaryDataService } from './src/api/questionnary-data.service';
export { GripDataService } from './src/api/grip-data.service';
export { FlatDataService } from './src/api/flat-data.service';
export { HealthAnswer } from './src/model/health-answer.model';
export { Answer } from './src/model/answer.model';
export { Doctor } from './src/model/doctor.model';
export { DoctorDataService } from './src/api/doctor-data.service';
export { FootWearDataService } from './src/api/footwears-data.service';
export { FootWear } from './src/model/footwears.model';
export { Grip } from './src/model/grip.model';
export { AnswerSet } from './src/model/answer-set.model';
export { HealthForm } from './src/model/health-form.model';
export { Question } from './src/model/question.model';
export { SubOption } from './src/model/sub-options.model';
export { QuestionSet } from './src/model/question-set.model';
export { QuestionnaryAvailableAnswer } from './src/model/questionnary-available-answer.model';
export { IBilan } from './src/model/ibilan.model';
export { RombergTest } from './src/model/romberg-test.model';
export { ICheckupOption } from './src/model/icheckupoption.model';
export { PatientIdentity } from './src/model/patient-identity.model';
export { Data } from './src/model/data-walking-checkup.model';
export { QuestionnaryAnswers } from './src/model/questionnary-answers.model';
export { QuestionnaryList } from './src/model/questionnary-list.model';
export { Questionnary } from './src/model/questionnary.model';
export { Constants };
export { SmartFlatDataAccessModule } from './src/smartflat-data-access.module';
export { TorCalendar } from './src/model/torcalendar.model';
export { TorCalendarContent, TimeSpentInZone } from './src/model/torcalendarcontent.model';
export {
  TorCalendarQuery,
  TorCalendarStringQuery,
  CalendarContentStringQuery,
  CalendarContentQuery,
} from './src/model/torcalendarquery.model';
export { GatewayState } from './src/model/gateway.model';
export { EstablishmentDataService } from './src/api/establishment-data.service';
export { ExtractionService, ExtractionStatus } from './src/api/extraction.service';
