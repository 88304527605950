import { TorRow } from './torrow.model';
import { TorHeadTable } from './torheadtable.model';

export class TorTable {
  public torTableHead: TorHeadTable;

  private _startDate: Date;
  private _endDate: Date;
  private _rows: TorRow[] = [];

  constructor(startDate: Date, endDate: Date) {
    this._startDate = startDate;
    this._endDate = endDate;
    this.torTableHead = new TorHeadTable(0);
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public get rows() {
    return this._rows;
  }
}
