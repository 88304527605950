import { Injectable } from '@angular/core';
import { WalkAssessment } from 'app/modules/smartflat-data-access/src/model/walk-assessment.model';

import { HttpClient } from '@angular/common/http';
import { LocalStorageEx } from 'app/tools/localstorageobject.enum';

import { HttpHeaders } from '@angular/common/http';
import {
  Footwear,
  LocomotionBilan,
  LocomotionBilanVisualAssessment,
  WalkingAid,
} from 'app/modules/smartflat-data-access/src/service-dal/model/models';
import { NotificationService } from 'app/modules/core';
import * as TransformLocomotion from '../transformations/assessment.transform';
import { Assessment } from 'app/modules/checkup/modules/walk/models/assessment.model';
import { ShoesSelectorValues } from '../../../force-platform/models/shoes-selector-values.model';
import { ServiceDal } from '../service-dal/service-dal';
import { ServiceDalConfig } from '../service-dal/service-dal.config';
import { DISTANCE_TO_LOCOMOTION } from '../../../../tools/medical.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocomotionDataService {
  private locomotionProxyBaseUri: string;

  constructor(
    private serviceDal: ServiceDal,
    private notificationService: NotificationService,
    private http: HttpClient,
    private translateService: TranslateService,
    config: ServiceDalConfig,
  ) {
    this.locomotionProxyBaseUri = config.locomotionProxyBaseUri;
  }

  public async createWalkAcquisition(walkAssesmentModel: WalkAssessment): Promise<void> {
    const locomotionBilanAssessment: LocomotionBilanVisualAssessment = {
      BilanId: walkAssesmentModel.BilanId,
      HestitantWalk: walkAssesmentModel.HestitantWalk,
      SmallStepWalk: walkAssesmentModel.SmallStepWalk,
      IrregularWalk: walkAssesmentModel.IrregularWalk,
      AsymmetricalWalk: walkAssesmentModel.AsymmetricalWalk,
      BalanceLoss: walkAssesmentModel.BalanceLoss,
      SwingingArmsLoss: walkAssesmentModel.SwingingArmsLoss,
      Distance: walkAssesmentModel.Distance || DISTANCE_TO_LOCOMOTION,
    };
    console.log('Saving assessment', walkAssesmentModel, locomotionBilanAssessment);
    const locomotionBilanVisualAssessment = await this.serviceDal.locomotionAssessmentService
      .locomotionBilanVisualAssessmentCreate(locomotionBilanAssessment)
      .toPromise();
    await this.persistLocomotionBilan(walkAssesmentModel);
  }

  public async getOldLocomtionBilan(idPatient: number): Promise<LocomotionBilan[]> {
    const locomotions: LocomotionBilan[] = await this.serviceDal.patientService
      .patientPrototypeGetLocomotionBilans(idPatient.toString())
      .toPromise();
    return locomotions;
  }

  public async getLocomotionAssessment(bilanId: number): Promise<Assessment[]> {
    const filter = '{"where":{"BilanId":"' + bilanId + '"}}';
    const locomotionBilanVisualAssessment: LocomotionBilanVisualAssessment[] = await this.serviceDal.locomotionAssessmentService
      .locomotionBilanVisualAssessmentFind(filter)
      .toPromise();
    console.log('from db ', locomotionBilanVisualAssessment);
    const assessments = TransformLocomotion.locomotionBilanAssessmentToAssessment(locomotionBilanVisualAssessment[0]);
    console.log('after transform ', locomotionBilanVisualAssessment);
    return assessments;
  }

  public async getLocomotionBilan(bilanId: number): Promise<LocomotionBilan> {
    const filter = '{"where":{"BilanId":"' + bilanId + '"}}';
    const locomotionBilan: LocomotionBilan[] = await this.serviceDal.locomotionBilanService
      .locomotionBilanFind(filter)
      .toPromise();
    return locomotionBilan[0];
  }

  public async getShoesValuesByBilan(bilanId: number): Promise<ShoesSelectorValues> {
    const locomotion: LocomotionBilan = await this.getLocomotionBilan(bilanId);
    const shoesSelector = new ShoesSelectorValues();
    // TODO use real ID
    const shoes: Footwear = await this.serviceDal.shoesService.footwearFindById('' + locomotion.FootwearId).toPromise();
    const local = this.translateService.currentLang;
    const filter: string = '{ "where": { "id":' + locomotion.StablizationHelpId + ', "culture":"' + local + '" } }';
    const walkingAid: WalkingAid = await this.serviceDal.walkingAidServive.walkingAidFindOne(filter).toPromise();
    shoesSelector.shoesType = shoes.Alias;
    shoesSelector.walkingAid = walkingAid.translate;
    return shoesSelector;
  }

  private async persistLocomotionBilan(walkAssesmentModel: WalkAssessment): Promise<any> {
    try {
      const preambleCheckup: ShoesSelectorValues = LocalStorageEx.currentWalkPreambleCheckup;
      const guid: string = LocalStorageEx.currentWalkRecordingSession;
      const url: string = this.locomotionProxyBaseUri + '/analysis/' + guid + '/persist';
      const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      const footwear: Footwear[] = await this.serviceDal.shoesService
        .footwearFind('{ "where":{"Alias":"' + preambleCheckup.shoesType + '"}}')
        .toPromise();
      const walkingAid: WalkingAid[] = await this.serviceDal.walkingAidServive
        .walkingAidFind('{ "where":{"translate":"' + preambleCheckup.walkingAid + '"}}')
        .toPromise();
      const body: any = {};
      walkAssesmentModel.Distance = DISTANCE_TO_LOCOMOTION;
      body.bilanId = walkAssesmentModel.BilanId;
      // TODO Set the correct ID
      body.footwearId = footwear[0].Id;
      body.stablizationHelpId = walkingAid[0].id;
      body.distance = DISTANCE_TO_LOCOMOTION;
      return this.http.post(url, body).toPromise();
    } catch (error) {
      console.error('Error while persist walk ' + ', error');
      this.notificationService.pushErrorNotifications('Error while persist walk');
      return Promise.reject<any>(error);
    }
  }
}
