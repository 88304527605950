import { Observable } from 'rxjs';
import { NavigationAction } from './navigation-action.model';
import { INavigationActionOptions } from './navigation-action-options.model';

export class NavigationActionLink extends NavigationAction {

    public link: string;

    public constructor(key: string | Observable<string>, link: string, options?: INavigationActionOptions) {
        super(key, options);
        this.link = link;
    }
}
