import { catchError, flatMap, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as _throw, EMPTY as empty, of } from 'rxjs';

import * as TorTransform from '../transformations/tor.transform';
import { TorCalendarQuery } from '../model/torcalendarquery.model';
import { TorCalendar } from '../model/torcalendar.model';
import { GatewayState } from '../model/gateway.model';
import { ServiceDalConfig } from '../service-dal/service-dal.config';

// TODO this class is normally generated by swagger,  it's used for TOR captor => another LoopBack than Dal?
@Injectable()
export class HttpTempService {
  private basePath: string;
  private intelligentBridgePath: string;

  constructor(private http: HttpClient, config: ServiceDalConfig) {
    this.basePath = config.uriBase;
    this.intelligentBridgePath = config.intelligentBridgeUri;
  }

  public areCaptorsActivatedAll(): Observable<GatewayState> {
    if (!this.intelligentBridgePath) {
      return empty;
    }

    const url = this.intelligentBridgePath + '/IoTs/allGatewayState';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<any[]>(url, { headers: httpHeaders })
      .pipe(
        flatMap((e) => e),
        map(TorTransform.transformJsonToGateway),
      )
      .pipe(
        catchError((error) => {
          console.error('Error while getting gateways state:', error);
          return _throw(error);
        }),
      );
  }

  public areCaptorsActivated(gatewayId: string): Observable<GatewayState> {
    if (!this.intelligentBridgePath) {
      return empty;
    }

    const url = this.intelligentBridgePath + '/IoTs/gatewayState';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new URLSearchParams();
    body.set('gatewayDeviceId', gatewayId);
    return this.http
      .post<any>(url, body.toString(), { headers: httpHeaders })
      .pipe(map(TorTransform.transformJsonToGateway))
      .pipe(
        catchError((error) => {
          console.error('Error while getting gateway state (id=' + gatewayId + ': ', error);
          return _throw(error);
        }),
      );
  }

  public setCaptorsActivation(gatewayId: string, mute: boolean): Observable<boolean> {
    if (!this.intelligentBridgePath) {
      console.error('Error while setting gateway mute state (id=' + gatewayId + ': no intelligentBridge url defined');
      return of(false);
    }

    const url = this.intelligentBridgePath + '/IoTs/muteGateway';
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new URLSearchParams();
    body.set('gatewayDeviceId', gatewayId);
    body.set('mute', '' + mute);
    return this.http.post<any>(url, body.toString(), { headers: httpHeaders }).pipe(
      map((data) => data.response.success),
      catchError((error) => {
        console.error('Error while setting gateway mute state (id=' + gatewayId + ': ', error);
        return of(false);
      }),
    );
  }

  public torCalendarQuery(query: TorCalendarQuery): Observable<TorCalendar> {
    if (!this.intelligentBridgePath) {
      return empty;
    }
    const json: string = TorTransform.transformTorQueryToJson(query);
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<any>(this.basePath + '/FlatsEventsAggregates/queryCalendar', json, { headers: httpHeaders })
      .pipe(map(TorTransform.transformJsonToTorCalendar));
  }
}
