import { Injectable } from '@angular/core';

@Injectable()
export class ColorService {

    private index: number;

    private colors: string[] =
    [
        'rgba(255, 99, 132, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(255, 205, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(201, 203, 207, 0.5)'];

    constructor() {
        this.index = 0;
    }

    public nextGraphColor(): string {
        const color = this.colors[this.index];
        if (this.index < this.colors.length - 1) {
            this.index++;
        } else {
            this.index = 0;
        }

        return color;
    }
}
