import { Component, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'app-patient-celldate-with-hours',
  templateUrl: './patient-celldate-with-hours.component.html',
  styleUrls: ['./patient-celldate-with-hours.component.css'],
})
/**
 * This componentn is a cell of the table. It corresponds to a cell with date at format day + hour
 */
export class PatientCelldateWithHoursComponent implements ViewCell, OnInit {
  @Input() public value: any;
  @Input() public rowData: any;

  public ngOnInit() {
    // Just let TSLint happy
  }

}
