import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Moment } from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { ITorTableService } from 'app/modules/tor/services/tortable.service';
import { TimeService } from 'app/modules/time/services/time.service';
import { TorCalendarQuery } from 'app/modules/smartflat-data-access';

import { TorTable } from '../models/tortable.model';
import { TorRow  } from '../models/torrow.model';
import { TorValue } from '../models/torvalue.model';
import { TorHeadTable } from '../models/torheadtable.model';
import { DAY, NIGHT } from '../models/constants.model';

@Injectable()
export class TorMonthTableService implements ITorTableService {
    private torCalendarQuey: TorCalendarQuery;
    private columns: number = 10;

    // utilisé pour chaque TorRowContent et TorValue.
    private index: number;

    constructor(private translateService: TranslateService, private timeService: TimeService) {
        moment.locale(this.translateService.currentLang);
        translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            moment.locale(event.lang);
        });
    }

    public getTimeTable(startDate: Date, endDate: Date): TorTable {
        this.index = 1;
        const numberOfRows: number =
            moment(endDate).month() + (12 * (moment(endDate).year() - moment(startDate).year())) - moment(startDate).month() + 1;

        const firstDayOfEndDate: Moment = moment(startDate).startOf('month');
        const table: TorTable = new TorTable(startDate, endDate);
        const tableHead: TorHeadTable = new TorHeadTable(this.columns);

        this.timeService.getWeeks().then((value) => {
            tableHead.headValues = value;
        });
        table.torTableHead = tableHead;

        let rowIndex: number = 0;
        while (rowIndex < numberOfRows) {
            let row: TorRow;
            if (rowIndex === 0 && numberOfRows > 1) {
                // Date Range is greater than one Year
                row = this.firstMonth(startDate);
            } else if (rowIndex === 0 && numberOfRows === 1) {
                // Date Range is equal or less to one Year
                row = this.theOnlyMonth(startDate, endDate);
            } else if (rowIndex === (numberOfRows - 1)) {
                // Last year
                row = this.lastMonth(endDate);
            } else {
                // Casual year
                row = this.casualMonth(moment(startDate).startOf('week'), rowIndex);
            }
            table.rows.push(row);
            rowIndex++;

        }
        return table;
    }

    private firstMonth(startDate: Date): TorRow {
        const weekNumberOfStartDate: number = this.getTheWeekNumber(moment(startDate));
        const torRow: TorRow =
            new TorRow(startDate, moment(startDate).endOf('month').toDate(), this.columns, this.index);
        this.index++;
        let week: number = 0;
        for (let i: number = 0; i < torRow.values.length; i++) {
            const monthMoment: Moment = this.incrementByWeeks(moment(startDate), week);
            let value;
            if (monthMoment === null || this.getTheWeekNumber(monthMoment) < this.getTheWeekNumber(moment(startDate))) {
                value = new TorValue(false, (i % 2 === 0) ? DAY : NIGHT, null, null, false, this.index);
            } else {
                value =
                    new TorValue(
                        true,
                        (i % 2 === 0) ? DAY : NIGHT,
                        this.getStartDateTorValue(moment(startDate), monthMoment, weekNumberOfStartDate, week).toDate(),
                        this.getEndDateTorValue(null, monthMoment, null, week + 1).toDate(),
                        false,
                        this.index);
            }
            this.index++;
            torRow.values[i] = value;

            // Because i is both day and night
            if (i % 2 !== 0) {
                week++;
            }
        }
        return torRow;
    }

    private lastMonth(endDate: Date): TorRow {
        const weekNumberOfEndDate: number = this.getTheWeekNumber(moment(endDate));

        const torRow: TorRow =
            new TorRow(moment(endDate).startOf('month').toDate(), endDate, this.columns, this.index);
        this.index++;
        let week: number = 0;
        for (let i: number = 0; i < torRow.values.length; i++) {
            const monthMoment: Moment = this.incrementByWeeks(moment(endDate), week);
            let value;
            if (monthMoment === null || this.getTheWeekNumber(moment(monthMoment)) > this.getTheWeekNumber(moment(endDate))) {
                value = new TorValue(false, (i % 2 === 0) ? DAY : NIGHT, null, null, false, this.index);
            } else {
                value = new TorValue(
                    true,
                    (i % 2 === 0) ? DAY : NIGHT,
                    monthMoment.toDate(),
                    this.getEndDateTorValue(moment(endDate), moment(monthMoment), weekNumberOfEndDate, week + 1).toDate(),
                    false,
                    this.index);
            }
            this.index++;
            torRow.values[i] = value;
            // Because i is both day and night
            if (i % 2 !== 0) {
                week++;
            }
        }
        return torRow;
    }

    private casualMonth(startMoment: Moment, index: number): TorRow {
        const firstDayOfMonth = moment(startMoment).startOf('month').add(index, 'month');
        const torRow: TorRow =
            new TorRow(moment(firstDayOfMonth).toDate(), moment(firstDayOfMonth).endOf('month').toDate(), this.columns, this.index);
        this.index++;
        let week: number = 0;
        for (let i: number = 0; i < torRow.values.length; i++) {
            const firtWeekDayOfCurrentMont: Moment = this.incrementByWeeks(firstDayOfMonth, week);
            let value;
            if (firtWeekDayOfCurrentMont !== null) {
                value =
                    new TorValue(
                        true,
                        (i % 2 === 0) ? DAY : NIGHT,
                        firtWeekDayOfCurrentMont.toDate(),
                        this.getEndDateTorValue(null, moment(firtWeekDayOfCurrentMont), null, week + 1).toDate(),
                        false,
                        this.index);
            } else {
                value = new TorValue(false, (i % 2 === 0) ? DAY : NIGHT, null, null, false, this.index);
            }
            this.index++;
            torRow.values[i] = value;
            if (i % 2 !== 0) {
                week++;
            }
        }
        return torRow;
    }

    private theOnlyMonth(startDate: Date, endDate: Date): TorRow {
        const weekNumberOfStartDate: number = this.getTheWeekNumber(moment(startDate));
        const weekNumberOfEndDate: number = this.getTheWeekNumber(moment(endDate));

        const torRow: TorRow = new TorRow(startDate, endDate, this.columns, this.index);
        this.index++;
        let week: number = 0;
        for (let i: number = 0; i < torRow.values.length; i++) {
            const monthMoment: Moment = this.incrementByWeeks(moment(startDate), week);
            let value;
            if (monthMoment === null || this.getTheWeekNumber(monthMoment) < this.getTheWeekNumber(moment(startDate))
                || this.getTheWeekNumber(monthMoment) > this.getTheWeekNumber(moment(endDate))) {
                value = new TorValue(false, (i % 2 === 0) ? DAY : NIGHT, null, null, false, this.index);
                this.index++;
            } else {
                value = new TorValue(
                    true,
                    (i % 2 === 0) ? DAY : NIGHT,
                    this.getStartDateTorValue(moment(startDate), monthMoment, (week + 1), weekNumberOfStartDate).toDate(),
                    this.getEndDateTorValue(moment(endDate), monthMoment, (week + 1), weekNumberOfEndDate).toDate(),
                    true,
                    this.index++);
            }
            torRow.values[i] = value;

            // Because i is both day and night
            if (i % 2 !== 0) {
                week++;
            }
        }
        return torRow;
    }

    // The week number are not the same as Momentjs
    private getTheWeekNumber(momentTmp: Moment): number {
        const date = momentTmp.get('date');
        const weekNumber = Math.ceil(date / 7);
        return weekNumber;
    }

    private getStartDateTorValue(
        startDate: Moment,
        monthMoment: Moment,
        startDateWeekNumber: number,
        monthMomentWeekNumber: number): Moment {
        if (startDateWeekNumber !== monthMomentWeekNumber) {
            return monthMoment;
        } else {
            return startDate;
        }
    }

    private getEndDateTorValue(
        endDate: Moment,
        monthMoment: Moment,
        endDateWeekNumber: number,
        monthMomentWeekNumber: number): Moment {
        if (monthMomentWeekNumber === 5) {
            return monthMoment.endOf('month');
        }
        if (endDateWeekNumber !== monthMomentWeekNumber) {
            return monthMoment.add(6, 'days');
        } else {
            return endDate;
        }
    }

    private incrementByWeeks(yearMoment: Moment, weeks: number): Moment {
        // Frebuary has no 5th week
        if (yearMoment.get('month') === 1 && weeks === 4) {
            return null;
        } else {
            return yearMoment.startOf('month').add(weeks * 7, 'days');
        }
    }
}
