<div class="page-title">
  {{ 'Nouvelle consultation' | translate }}
</div>

<div *ngIf="isConsultInprogress">
  <patient-modal
    #consultationInProgressTpl
    [visible]="true"
    [visibleAnimate]="true"
    [autorizeOnContainerClicked]="false"
  >
    <div class="app-modal-header">
      <label>{{ 'Reprendre lacquisition' | translate }}</label>
    </div>
    <div class="app-modal-body">
      {{'TextPopupAcquisitionEnCours'|translate : this }}
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-primary" (click)="consultationInProgressTpl.hide(); continueConsult()">
        {{ 'Oui' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        (click)="consultationInProgressTpl.hide(); eraseConsultationInProgress()"
      >
        {{ 'Non' | translate }}
      </button>
    </div>
  </patient-modal>
</div>

<div class="row">
  <div class="col-lg-9">
    <div class="page-sub-title searchBar">
      <patient-header (search)="onSearchPatient($event)" [inputValue]="initSearch"> </patient-header>
    </div>
  </div>
</div>
