import { Roles } from 'app/modules/core/roles.enum';

export class NavItem {
  public imgSrcUrl: string;
  public imgSrcUrlUnClicked: string;
  public imgSrcUrlClicked: string;
  public routerLink: string;
  public title: string;
  public class: string;
  public neededRole: Roles;

  constructor(
    imgSrcUrlUnClicked: string,
    imgSrcUrlClicked: string,
    routerLink: string,
    title: string,
    clazz: string,
    neededRole?: Roles,
  ) {
    this.imgSrcUrl = imgSrcUrlUnClicked;
    this.imgSrcUrlUnClicked = imgSrcUrlUnClicked;
    this.routerLink = routerLink;
    this.imgSrcUrlClicked = imgSrcUrlClicked;
    this.title = title;
    this.class = clazz;
    this.neededRole = neededRole;
  }
}
