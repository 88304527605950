export class Assessment {

    public type: WalkAssessmentType;
    public title: string;

    public nonEvaluated: boolean;
    public value: number;
}

export enum WalkAssessmentType {
    Hesitant, SmallSteps, Irregular, Balance, Asymetric, SwingingArms,
}
