import { Injectable } from '@angular/core';

import { Logger } from './logger.service';

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {
  public constructor() {
    // TODO check how to configure it
    // if (!environment.production) {
    this.activate();
    // } else {
    //  this.deactivate();
    // }
  }

  public info: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public warn: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;
  public error: (message?: any, ...optionalParams: any[]) => void = (): any => undefined;

  public activate() {
    this.info = console.info.bind(console);
    this.warn = console.warn.bind(console);
    this.error = console.error.bind(console);
  }

  public deactivate() {
    this.info = noop;
    this.warn = noop;
    this.error = noop;
  }
}
