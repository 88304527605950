import { Injectable } from '@angular/core';

import { ServiceDal } from '../service-dal/service-dal';
import { Bilan as BilanDal } from '../service-dal/model/bilan';
import { Bilan, BilanResults } from '../model/bilan.model';
import * as BilanTransform from '../transformations/bilan.transform';
import { LocalStorageEx } from '../../../../tools/localstorageobject.enum';

@Injectable()
/**
 * this service manages bilan, including the transformation of data to/from the DAL model.
 */
export class BilanDataService {
  constructor(private serviceDal: ServiceDal) {}

  /**
   * This function create a bilan in database
   * @param idConsult consultation id
   * @param type type of checkup
   */
  public async creationBilan(idConsult: number, type: number): Promise<Bilan> {
    const api = this.serviceDal.bilanService;
    const bilan: BilanDal = {
      Type: type,
      ConsultationId: idConsult,
    };
    const dalBilan: BilanDal = await api.bilanCreate(bilan).toPromise();
    return BilanTransform.DalBilanToBilan(dalBilan);
  }

  public async getBilans(idConsult: number): Promise<Bilan[]> {
    const filter: string = '{"where": {"ConsultationId":' + idConsult + '}}';
    const api = this.serviceDal.bilanService;
    const bilanTable: BilanDal[] = await api.bilanFind(filter).toPromise();
    const res: Bilan[] = [];
    bilanTable.forEach((bilan) => {
      res.push(BilanTransform.DalBilanToBilan(bilan));
    });
    return res;
  }

  public async getAssessments(
    patientId: number,
    bilanType: number,
    questionnaryType: number[],
  ): Promise<BilanResults[]> {
    let filter: string;

    if (questionnaryType !== null) {
      filter =
        '{"patientId":"' +
        patientId +
        '","typeBilan":"' +
        bilanType +
        '","typeQuestionnaire":[' +
        questionnaryType +
        ']}';
    } else {
      filter = '{"patientId":"' + patientId + '","typeBilan":"' + bilanType + '" }';
    }
    const api = this.serviceDal.bilanService;
    const bilans = await api.bilanGetAssessments(filter).toPromise();
    return bilans.filter(this.filterByCurrenConsultationDate);
  }

  public filterByCurrenConsultationDate(element) {
    return element.consultation.Date !== LocalStorageEx.currentConsultation.date;
  }
}
