import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../core';
import { TorBilanComponent } from '.';

const checkupRoutes: Routes = [
    {
        path: 'tor',
        component: TorBilanComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(checkupRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class TorRoutingModule { }
