import { Grip as DalGrip } from '../service-dal/model/grip';
import { Grip } from '../model/grip.model';

export function DalGripToGrip(grip: DalGrip): Grip {

    const gripInterne = new Grip();
    gripInterne.BilanId = grip.BilanId;
    gripInterne.Horodate = grip.Horodate;
    gripInterne.Id = grip.Id;
    gripInterne.LeftHandV1 = grip.LeftHandV1;
    gripInterne.LeftHandV2 = grip.LeftHandV2;
    gripInterne.LeftHandV3 = grip.LeftHandV3;
    gripInterne.RightHandV1 = grip.RightHandV1;
    gripInterne.RightHandV2 = grip.RightHandV2;
    gripInterne.RightHandV3 = grip.RightHandV3;

    return gripInterne;
}

export function DalGripsToGrips(grips: DalGrip[]): Grip[] {
    const gripLst: Grip[] = [];
    grips.forEach((grip) => {
        const gripInterne = DalGripToGrip(grip);
        gripLst.push(gripInterne);
    });

    return gripLst;
}
