import { GripMeasureDataset } from './grip-measure-dataset.model';

export class GripMeasureDatasets {

    public leftHand: GripMeasureDataset;
    public rightHand: GripMeasureDataset;

    constructor() {
        this.leftHand = new GripMeasureDataset();
        this.rightHand = new GripMeasureDataset();
    }
}
