import { BilanType } from 'app/modules/smartflat-data-access';

/**
 * This class is like a bilan but with a string for the type. It's used to get questionnaries like a bilan for results page.
 */
export class BilanDetailed {
    public Id: number;
    public QuestionnaryId?: number;
    public Type: string;
    public TypeInDB: BilanType;
    public questionnaryType?: number;
    public Date: Date;
}
