import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/modules/core';

// const consultationRoutes: Routes = [
//     {
//     },
// ];

@NgModule({
    imports: [
        // RouterModule.forChild(consultationRoutes),
    ],
    exports: [
        // RouterModule,
    ],
})
export class ConsultationRoutingModule { }
