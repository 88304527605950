import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ShoesSelectorValues } from 'app/modules/force-platform';
import { AnalysisResults } from 'app/modules/checkup/modules/walk/models/analysis-results.model';
import { AnalysisResultsDetailByAge } from '../../../checkup/modules/walk/models/analysis-results-detailByAge.model';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-rapport-walk-part',
  templateUrl: './rapport-walk-part.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-walk-part.component.scss'],
})
export class RapportWalkPartComponent implements OnChanges {
  @Input()
  public shoes: ShoesSelectorValues;
  @Input()
  public analysisResult: AnalysisResults;
  @Input()
  public agePatient: number;

  public speedValue: number;
  public symetryNeedle: number;
  public symetryLabel: number;
  public symetryoptions = {
    hasNeedle: true,
    outerNeedle: true,
    needleColor: 'rgb(250, 16, 119)',
    rangeLabel: ['G', 'D'],
    centralLabel: '2',
    rangeLabelFontSize: 32,
    arcColors: ['rgb(107, 137, 159)', 'rgb(182, 213, 230)', 'rgb(0,0,0)', 'rgb(182, 213, 230)', 'rgb(107, 137, 159)'],
    arcDelimiters: [25, 49.5, 50.5, 75],
  };
  public radarChartLabels = [
    this.translateService.instant('Rapport.AnalyseDetailMarche.regularite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.fluidite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.vigueur'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Rythme'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Synchronisation'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.symetrie'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.stabilite'),
  ];
  public colorsOverride: Color[] = [
    {
      backgroundColor: 'transparent',
      borderColor: 'black',
      pointBackgroundColor: 'black',
      pointBorderColor: 'black',
      pointHoverBackgroundColor: 'black',
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#FD3F92',
      pointBackgroundColor: '#FD3F92',
      pointBorderColor: '#FD3F92',
      pointHoverBackgroundColor: '#FD3F92',
    },
  ];
  public radarChartData: any;
  public optionsRadarChart = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    animation: { animateScale: true, animateRotate: true },
    scale: {
      ticks: {
        min: -4,
        max: 4,
      },
    },
  };

  public semioDataForAge: AnalysisResultsDetailByAge;

  constructor(public translateService: TranslateService) {}

  public get stepNumber(): number {
    if (!this.analysisResult || !this.analysisResult.graphes || !this.analysisResult.graphes.semioInfo) {
      return 0;
    }

    return this.analysisResult.graphes.semioInfo.rightStep + this.analysisResult.graphes.semioInfo.leftStep;
  }

  public ngOnChanges(change: SimpleChanges) {
    // if not all the needed value here, return;
    if (!this.analysisResult || !this.agePatient) {
      return;
    }

    this.speedValue = Math.floor(this.analysisResult.AvgSpeed * 100) / 100;

    const semioDataSymmetry = Math.floor(this.analysisResult.SwingMeanStrideDuration * 10) / 10;
    if (semioDataSymmetry > 0.5) {
      this.symetryNeedle = 100;
      this.symetryLabel = 0.5;
    } else if (semioDataSymmetry < -0.5) {
      this.symetryNeedle = 0;
      this.symetryLabel = -0.5;
    } else if (semioDataSymmetry !== null && semioDataSymmetry !== undefined) {
      this.symetryNeedle = 50 + semioDataSymmetry * 100;
      this.symetryLabel = semioDataSymmetry;
    } else {
      this.symetryNeedle = 50;
      this.symetryLabel = 0;
    }

    this.semioDataForAge = this.detailByAgeSemio(this.analysisResult.graphes.semioInfo.detailByAge, this.agePatient);
    this.radarChartLabels = this.radarChartLabels.map((label) => this.translateService.instant(label));
    this.radarChartData = [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        label: this.translateService.instant('Moyenne'),
      },
      {
        data: [
          this.semioDataForAge.regularity,
          this.semioDataForAge.fluidity,
          this.semioDataForAge.vigor,
          this.semioDataForAge.pace,
          this.semioDataForAge.synchronization,
          this.semioDataForAge.symmetry,
          this.semioDataForAge.stability,
        ],
        label: this.translateService.instant('Patient'),
      },
    ];
  }

  /**
   * @description Restrieve the data of the semio by age of the patient
   * @param detailByAge
   * @param agePatient
   */
  private detailByAgeSemio(detailByAge: AnalysisResultsDetailByAge[], agePatient: number) {
    for (const element of detailByAge) {
      const ageMax = element.ageMax;
      const ageMin = element.ageMin;
      if (ageMin === 0 && ageMax === 100) {
        // skip catch all values
        continue;
      }
      if (agePatient >= ageMin && agePatient <= ageMax) {
        return element;
      }
    }
    return new AnalysisResultsDetailByAge();
  }
}
