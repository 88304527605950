import { autoserialize, autoserializeAs } from 'cerialize';

import { StringMap } from 'app/tools/serialize-string-map';

import { AnswerSet } from './answer-set.model';

/**
 *  Object representing all of the answers of a diagnostic form
 */
export class HealthAnswer {
    @autoserialize
    public consultationId: number;
    @autoserialize
    public id_diagnostic: number;
    @autoserializeAs(Date)
    public date: Date;
    @autoserializeAs(Date)
    public date_modify: Date;

    @autoserializeAs(StringMap(AnswerSet))
    public answerSets: Map<string, AnswerSet>; // the map is usful for the case when the user changes an answer
}
