import { Component, OnInit, Input, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

import { PatientIdentity, BilanDataService, HandednessArray } from 'app/modules/smartflat-data-access';
import { LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { Compute } from 'app/tools/compute';
import * as medicalConst from 'app/tools/medical.constants';
import { GenderArray, Handedness, ConsultationDataService } from 'app/modules/smartflat-data-access';
import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { WeightAndHeightService } from 'app/modules/consultation/services/weightAndHeight.service';
import { ModalComponent } from 'app/modules/common';
import { BilanType } from '../../../smartflat-data-access/src/model/rapport.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'current-patient-banner',
  templateUrl: './current-patient-banner.component.html',
})
export class CurrentPatientBannerComponent implements OnInit, OnDestroy {
  @ViewChildren(ModalComponent)
  public modals: QueryList<ModalComponent>;

  @Input()
  public needed: boolean = true;
  @Input()
  public flatId: number = null; // FIXME :remove it ?
  public obs$;
  @Input()
  public consultationStep: number = 0;
  @Input()
  public inWeight: boolean = false;
  public patientIdentity: PatientIdentity = new PatientIdentity();
  public weight: number;
  public height: number;
  public history: boolean;
  public readonly MIN_WEIGHT_KG: number = medicalConst.MIN_WEIGHT_KG;
  public readonly MAX_WEIGHT_KG: number = medicalConst.MAX_WEIGHT_KG;
  public readonly MIN_HEIGHT_CM: number = medicalConst.MIN_HEIGHT_CM;
  public readonly MAX_HEIGHT_CM: number = medicalConst.MAX_HEIGHT_CM;
  private unsubscribe = new Subject<void>();

  constructor(
    private weightAndHeightService: WeightAndHeightService,
    private consultationDataService: ConsultationDataService,
    private bilanDataService: BilanDataService,
    private histoVersusConsultation: HistoVersusConsultationService,
    private router: Router,
  ) {
    // nothing to do
    this.obs$ = new BehaviorSubject(true);
  }

  /**
   * Select current patient data from the localStorage
   * TODO MUST BE REFACTORED
   */
  public ngOnInit() {
    if (this.histoVersusConsultation.history) {
      this.initCurrentPatientDuringHistory();
    } else {
      this.weightAndHeightService.weightDisplayRefresh.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
        if (value) {
          this.patientIdentity.MeasuredWeight = value;
          this.updateBMI();
        }
      });
      this.initCurrentPatientDuringAcquisition();
    }
  }

  public ngOnDestroy() {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public showModal() {
    this.modals.first.show();
  }

  public hideModal() {
    this.modals.first.hide();
  }

  private async initCurrentPatientDuringHistory(): Promise<void> {
    this.obs$ = this.histoVersusConsultation.writeMode;
    const patient = LocalStorageEx.currentPatientSelected;
    patient.lastConsultation = await this.consultationDataService.getLastConsultationForPatient(patient.patientId);

    this.patientIdentity.firstName = patient.firstName;
    this.patientIdentity.lastName = patient.lastName;
    this.patientIdentity.phoneNumber = patient.phoneNumber;
    this.patientIdentity.address = patient.address;
    this.patientIdentity.email = patient.email;
    this.patientIdentity.birthdate = patient.birthdate;
    this.patientIdentity.age = Compute.age(new Date(patient.birthdate));
    this.patientIdentity.connected = false; // TODO
    const tablegender = GenderArray;
    this.patientIdentity.genderString = tablegender[patient.gender].completeName;

    const tableHand = HandednessArray;
    this.patientIdentity.handednessString = tableHand[patient.handedness].completeName;
    this.patientIdentity.Weight = patient.lastConsultation.Weight;
    this.patientIdentity.Height = patient.lastConsultation.Height;
    if (patient.lastMeasuredWeight) {
      this.patientIdentity.MeasuredWeight = patient.lastMeasuredWeight;
    }

    // UGLY but i need to check bilan to see if last consultation have
    // weight measurement
    let measuredWeight = null;
    if (patient.lastConsultation) {
      const bilans = await this.bilanDataService.getBilans(patient.lastConsultation.id);
      if (bilans.find((b) => b.Type === BilanType.CheckupWeight)) {
        measuredWeight = this.patientIdentity.MeasuredWeight;
      }
    }

    this.patientIdentity.IMC = Compute.BMI(this.patientIdentity.Height, this.patientIdentity.Weight, measuredWeight);
    this.weight = this.patientIdentity.Weight;
    this.height = this.patientIdentity.Height;
  }

  private initCurrentPatientDuringAcquisition(): void {
    this.obs$ = this.histoVersusConsultation.writeMode;
    const patient = LocalStorageEx.currentPatientSelected;
    if (patient.lastConsultation === null || patient.lastConsultation === undefined) {
      this.consultationDataService.getLastConsultationForPatient(patient.patientId).then((value) => {
        patient.lastConsultation = value;
      });
    }
    this.patientIdentity.firstName = patient.firstName;
    this.patientIdentity.lastName = patient.lastName;
    this.patientIdentity.phoneNumber = patient.phoneNumber;
    this.patientIdentity.address = patient.address;
    this.patientIdentity.email = patient.email;
    this.patientIdentity.birthdate = patient.birthdate;
    this.patientIdentity.age = Compute.age(new Date(patient.birthdate));
    this.patientIdentity.connected = false; // TODO
    const tablegender = GenderArray;
    this.patientIdentity.genderString = tablegender[patient.gender].completeName;

    const tableHand = HandednessArray;
    this.patientIdentity.handednessString = tableHand[patient.handedness].completeName;

    if (LocalStorageEx.Height && LocalStorageEx.Height !== undefined && LocalStorageEx.Height !== null) {
      this.patientIdentity.Height = LocalStorageEx.Height;
    } else if (patient.lastConsultation !== undefined && patient.lastConsultation !== null) {
      this.patientIdentity.Height = patient.lastConsultation.Height;
      LocalStorageEx.setObject(LocalStorageObject.Height, this.patientIdentity.Height);
    }

    if (LocalStorageEx.currentPatientSelected && LocalStorageEx.currentPatientSelected.lastMeasuredWeight) {
      this.patientIdentity.MeasuredWeight = LocalStorageEx.currentPatientSelected.lastMeasuredWeight;
    } else if (patient.lastMeasuredWeight) {
      this.patientIdentity.MeasuredWeight = patient.lastMeasuredWeight;
      LocalStorageEx.MeasuredWeight = this.patientIdentity.MeasuredWeight;
    } else if (LocalStorageEx.MeasuredWeight) {
      this.patientIdentity.MeasuredWeight = LocalStorageEx.MeasuredWeight;
    } else if (patient.lastMeasuredWeight) {
      this.patientIdentity.MeasuredWeight = patient.lastMeasuredWeight;
      LocalStorageEx.MeasuredWeight = this.patientIdentity.MeasuredWeight;
    } else {
      this.patientIdentity.MeasuredWeight = null;
    }

    if (LocalStorageEx.Weight && LocalStorageEx.Weight !== undefined && LocalStorageEx.Weight !== null) {
      this.patientIdentity.Weight = LocalStorageEx.Weight;
    } else if (patient.lastConsultation) {
      this.patientIdentity.Weight = patient.lastConsultation.Weight;
      LocalStorageEx.setObject(LocalStorageObject.Weight, this.patientIdentity.Weight);
    }

    this.weight = this.patientIdentity.Weight;
    this.height = this.patientIdentity.Height;
    this.updateBMI();
  }

  /**
   * Changing weight in the banner: we save it in localStorage and we compute BMI
   */
  private changeWeight(): void {
    LocalStorageEx.setObject(LocalStorageObject.Weight, this.weight);
    this.updateBMI();
  }

  /**
   * Changing height in the banner: we save it in localStorage and we compute BMI
   */
  private changeHeight(): void {
    LocalStorageEx.setObject(LocalStorageObject.Height, this.height);
    this.updateBMI();
  }

  private updateBMI() {
    const measure = LocalStorageEx.getObject(LocalStorageObject.MeasurementWeight) as { date: Date; weight: number };
    const measuredWeight = measure ? measure.weight : null;
    this.patientIdentity.IMC = Compute.BMI(this.height, this.weight, measuredWeight);
  }
}
