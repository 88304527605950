import { Injectable } from '@angular/core';
import * as medicalConst from 'app/tools/medical.constants';
import { NotificationService } from 'app/modules/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WeightAndHeightService {

    public weightDisplayRefresh: BehaviorSubject<number>;

    constructor(private notificationService: NotificationService, private translateService: TranslateService) {
        // make it happy
        this.weightDisplayRefresh = new BehaviorSubject<number>(0);
    }

    public async isHeightCorrect(height: number): Promise<boolean> {
        if (!height || height < medicalConst.MIN_HEIGHT_CM ||
            height > medicalConst.MAX_HEIGHT_CM) {
            const checkHeight = await this.translateService.get('Check.height').toPromise();
            this.notificationService.pushErrorNotifications(checkHeight);
            return false;
        } else {
            return true;
        }
    }

    public pushWeightValue(weight: number): void {
        this.weightDisplayRefresh.next(weight);
    }

    public async isWeightCorrect(weight: number): Promise<boolean> {
        if (!weight || weight < medicalConst.MIN_WEIGHT_KG ||
            weight > medicalConst.MAX_WEIGHT_KG) {
            const checkWeight = await this.translateService.get('Check.weight').toPromise();
            this.notificationService.pushErrorNotifications(checkWeight);
            return false;
        } else {
            return true;
        }
    }

}
