import { FragilityState } from '../service-dal';

export class Consultation {
  public id?: number;
  public Height: number;
  public Weight: number;
  public MeasuredWeight: number;
  public date?: Date;
  public patientId?: number;
  public fragilityState?: FragilityState;
}
