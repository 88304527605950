export enum JobState {
    Running,
    Done,
    Error,
}

export class AnalysisState {
    public jobState: JobState;
    public errorCode: string;
}
