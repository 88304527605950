import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

import { environment } from '../environments/environment';

import { ServiceDalConfig } from './modules/smartflat-data-access/src/service-dal/service-dal.config';
import { LoggerService } from './services/logger/logger.service';

@Injectable({ providedIn: 'root' })
/**
 * this service manages global configuration of the application
 */
export class AppConfigurationService {
  private configuration: any;

  private readonly httpClient: HttpClient;
  constructor(private logger: LoggerService, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public async load(): Promise<void> {
    this.logger.info('loading configuration: ' + environment.configurationFile);
    const result = await this.httpClient.get<any>('../assets/' + environment.configurationFile).toPromise();
    this.logger.info('configuration');
    this.configuration = result;
    this.logger.info(this.configuration);
  }

  public createServiceDalConfig(): ServiceDalConfig {
    this.logger.info('creating serviceDal config');
    const serviceDalConfig = new ServiceDalConfig();
    serviceDalConfig.uriBase = this.serviceDalUri;
    serviceDalConfig.intelligentBridgeUri = this.intelligentBridgeUri;
    serviceDalConfig.locomotionProxyBaseUri = this.locomotionProxyBaseUri;
    serviceDalConfig.rombergProxyBaseUri = this.rombergProxyBaseUri;
    return serviceDalConfig;
  }

  // TODO: this is temporary for HttpTempService
  public get serviceDalUri(): string {
    return this.configuration.serviceDalUri;
  }

  public get walkPlatformBaseUri(): string {
    return this.configuration.walkPlatformBaseUri;
  }

  public get forcePlatformBaseUri(): string {
    return this.configuration.forcePlatformBaseUri;
  }

  public get intelligentBridgeUri(): string {
    return this.configuration.intelligentBridgeUri;
  }

  public get locomotionProxyBaseUri(): string {
    return this.configuration.locomotionProxyBaseUri;
  }
  public get rombergProxyBaseUri(): string {
    return this.configuration.rombergProxyBaseUri;
  }

  // TODO: make class for return parameters
  public get wso2Parameters(): any {
    return this.configuration.wso2;
  }
}
