import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IDiagnosticSaving } from 'app/services/tempSaving/diagnosticSaving.service';
import { HealthAnswer } from 'app/modules/smartflat-data-access';
import { Answer, DiagnosticDataService } from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';

@Injectable()
export class DiagnosticSavingImp implements IDiagnosticSaving {
  public constructor(private serviceBD: DiagnosticDataService, private languageService: TranslateService) {}

  public async getDiagnosticsForPatient(patientId: number): Promise<HealthAnswer[]> {
    const allDiags: HealthAnswer[] = [];
    if (LocalStorageEx.checkObject(LocalStorageObject.NewDiagnostics)) {
      const allNewDiag = LocalStorageEx.restoreArray(LocalStorageObject.NewDiagnostics, HealthAnswer);
      allDiags.push(...allNewDiag);
    }
    const response = await this.serviceBD
      .getDiagnosticsForPatient(patientId, this.languageService.currentLang)
      .toPromise();
    allDiags.push(...response);
    return allDiags;
  }

  public saveNewDiagnostic(form: HealthAnswer): void {
    let allNewDiag: HealthAnswer[];
    if (!LocalStorageEx.checkObject(LocalStorageObject.NewDiagnostics)) {
      allNewDiag = [];
    } else {
      const diag = LocalStorageEx.restoreArray(LocalStorageObject.NewDiagnostics, HealthAnswer);
      allNewDiag = diag;
    }
    allNewDiag.push(form);
    LocalStorageEx.store(LocalStorageObject.NewDiagnostics, allNewDiag);
  }

  public cleanMemory(): void {
    LocalStorageEx.removeObject(LocalStorageObject.NewDiagnostics);
  }

  public editNewDiagnostic(form: HealthAnswer): void {
    const allNewDiags = LocalStorageEx.restoreArray(LocalStorageObject.NewDiagnostics, HealthAnswer);

    for (const i in allNewDiags) {
      if (+i >= 0) {
        const date = new Date(allNewDiags[+i].date);
        if (date.toDateString() === form.date.toDateString()) {
          allNewDiags[+i] = form;
          break;
        }
      }
    }
    LocalStorageEx.store(LocalStorageObject.NewDiagnostics, allNewDiags);
  }

  public removeNewDiagnostic(date: Date): void {
    const allNewDiags = LocalStorageEx.restoreArray(LocalStorageObject.NewDiagnostics, HealthAnswer);
    for (const i in allNewDiags) {
      if (+i >= 0) {
        const dateD = new Date(allNewDiags[+i].date);

        if (new Date(dateD).toLocaleString() === new Date(date).toLocaleString()) {
          allNewDiags.splice(+i, 1);
          break;
        }
      }
    }
    if (allNewDiags.length !== 0) {
      LocalStorageEx.store(LocalStorageObject.NewDiagnostics, allNewDiags);
    } else {
      LocalStorageEx.removeObject(LocalStorageObject.NewDiagnostics);
    }
  }
}
