import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckupTitleService } from '../../services/checkup-title.service';
import { HistoVersusConsultationService } from '../../../consultation/services/histo-versus-consultation.service';

@Component({
  templateUrl: './checkup.component.html',
  styleUrls: ['./checkup.component.scss'],
})
export class CheckupComponent {
  public title: Observable<string>;

  constructor(private checkupTitleService: CheckupTitleService, private bannerService: HistoVersusConsultationService) {
    this.title = this.checkupTitleService.currentTitle$;
  }
}
