import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceDal } from '../service-dal/service-dal';
import { EstablishmentService } from '../service-dal';
import { Establishment } from '../model/establishment.model';
import * as EstablishmentTransform from '../transformations/establishment.transform';
import { LocalStorageEx, LocalStorageObject } from '../../../../tools/localstorageobject.enum';
import { LOGO_ENGIE } from 'app/tools/divers.const';

@Injectable()
/**
 * this service manages establishments, including the transformation of data to/from the DAL model.
 */
export class EstablishmentDataService {
  constructor(private serviceDal: ServiceDal) {}

  public async saveCurrentEstablishment() {
    let result: Establishment = new Establishment();
    try {
      result = await this.serviceDal.establishmentService.establishmentMe().toPromise();
    } catch (e) {
      result.Logo = LOGO_ENGIE;
      result.Config = {
        boxes: false,
        cpp: false,
        disabled: false,
        minimumAge: 18,
      };
      result.Name = 'No Establishment';
    }
    LocalStorageEx.setObject(LocalStorageObject.currentEstablishment, result);
    return result;
  }
}
