<nav>
  <p *ngIf="actions.length == 1"></p>
  <ng-container *ngFor="let action of actions">
    <ng-template
      [ngTemplateOutlet]="getTemplate(action)"
      [ngTemplateOutletContext]="{ $implicit: action }"
    ></ng-template>
  </ng-container>
</nav>

<ng-template #linkTemplate let-action>
  <button
    class="btn btn-primary"
    *ngIf="action.visible | async"
    [disabled]="!(action.active | async)"
    [routerLink]="[action.link]"
    [routerLinkActive]="['router-link-active']"
    [routerLinkActiveOptions]="{ exact: true }"
    [ngClass]="action.classes | async"
  >
    {{ action.key | async | translate }}
  </button>
</ng-template>

<ng-template #clickTemplate let-action>
  <button
    class="btn btn-primary"
    *ngIf="action.visible | async"
    [disabled]="!(action.active | async)"
    (click)="action.click(action)"
    [ngClass]="action.classes | async"
  >
    {{ action.key | async | translate }}
  </button>
</ng-template>

<ng-template #emptyTemplate let-action>
  {{ action | json }}
</ng-template>
