import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';

import { LocalStorageObject, LocalStorageEx } from '../../../tools/localstorageobject.enum';
import { NavigationActionsService, NavigationActionLink, NavigationActionClick } from '../../core';
import { ShoesSelectorValues } from '../../force-platform/models/shoes-selector-values.model';
import { Consultation } from '../../smartflat-data-access/src/model/consultation.model';
import { Patient, Bilan, PatientDataService, ConsultationDataService } from '../../smartflat-data-access';
import { BilanDataService } from '../../smartflat-data-access/src/api/bilan-data.service';
import { RombergDataService } from '../../smartflat-data-access/src/api/romberg.service';
import { LocomotionDataService } from '../../smartflat-data-access/src/api/locomotion-walk.service';
import { AnalysisResults as AnalysisResultsWalk } from '../../checkup/modules/walk';

import { AnalysisResults as AnalysisResultsRomberg } from '../../checkup/modules/romberg/models/analysis-results.model';
import { RemoteInteraction as RemoteInteractionWalk } from '../../checkup/modules/walk/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionRomberg } from '../../checkup/modules/romberg/services/remote-interaction.service';
import { AnalysisResultsDetailByAge } from '../../checkup/modules/walk/models/analysis-results-detailByAge.model';
import { Assessment } from '../../checkup/modules/walk/models/assessment.model';
import { AssessmentRomberg } from '../../checkup/modules/romberg/models/assessment.model';
import { RombergBilan, LocomotionBilan } from '../../smartflat-data-access/src/service-dal/model/models';
import { BilanType } from '../../smartflat-data-access/src/model/bilan.model';
import { LongitudinalGraph as LongitudinalGraphWalk } from '../../checkup/modules/walk/models/longitudinal.model';
import { LongitudinalGraph as LongitudinalGraphRomberg } from '../../checkup/modules/romberg/models/longitudinal.model';
import { LocalDatePipe } from '../../time/pipes/localdate.pipe';

import { LongitudinalGraph } from '../../checkup/modules/walk/models/longitudinal.model';
import { Compute } from 'app/tools/compute';
import { Color } from 'ng2-charts';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rapportPraticien',
  templateUrl: './rapportPraticien.component.html',
  providers: [AnalysisResultsRomberg, RemoteInteractionWalk, RemoteInteractionRomberg],
  styleUrls: ['./rapportPraticien.component.scss'],
})
export class RapportPraticienComponent implements OnInit, OnDestroy {
  public radarChartLabels = [
    this.translateService.instant('Rapport.AnalyseDetailMarche.regularite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.fluidite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.vigueur'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Rythme'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Synchronisation'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.symetrie'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.stabilite'),
  ];
  public colorsOverride: Color[] = [
    {
      backgroundColor: 'transparent',
      borderColor: 'black',
      pointBackgroundColor: 'black',
      pointBorderColor: 'black',
      pointHoverBackgroundColor: 'black',
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#FD3F92',
      pointBackgroundColor: '#FD3F92',
      pointBorderColor: '#FD3F92',
      pointHoverBackgroundColor: '#FD3F92',
    },
  ];
  public radarChartData: any;
  public optionsRadarChart = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    animation: { animateScale: true, animateRotate: true },
    scale: {
      ticks: {
        min: -4,
        max: 4,
      },
    },
  };

  // -------------------Locomotion & Romberg variables------------------------------------------
  public typeRapport: string;

  public dateConsultation: string;
  public patientIdentity: Patient;
  public shoesType: string;
  public helpStability: string;
  public walkingAid: string;
  public shoesStability: boolean;
  public walkingHelpNeed: boolean;
  public shoesValueResult: ShoesSelectorValues;
  public agePatient: number;
  public walkAnalysisResult: AnalysisResultsWalk;
  public rombergAnalysisResult: AnalysisResultsRomberg;

  // Longitudinal Bilan to graphe
  public graphs: LongitudinalGraphWalk[] = [];
  public graphsRomb: LongitudinalGraphRomberg[];
  // Assessment
  public assessments: Assessment[];
  public assessmentsRomberg: AssessmentRomberg[];
  public currentEstablishmentLogo: string;

  private unsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private navigationActionService: NavigationActionsService,
    private bilanService: BilanDataService,
    private rombergService: RombergDataService,
    private locomotionService: LocomotionDataService,
    private remoteInteractionWalk: RemoteInteractionWalk,
    private remoteInteractionRomberg: RemoteInteractionRomberg,
    private localDatePipe: LocalDatePipe,
    private translateService: TranslateService,
    private locomotionDataService: LocomotionDataService,
    private patientDataService: PatientDataService,
    private consultationDataService: ConsultationDataService,
  ) { }

  public ngOnDestroy() {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public async ngOnInit(): Promise<void> {
    LocalStorageEx.currentRapportDocteur = true;
    this.currentEstablishmentLogo = LocalStorageEx.currentEstablishment.Logo;

    const actionPrevious = new NavigationActionLink(this.translateService.instant('Précédent'), '/choixImpression');
    const actionPrint = new NavigationActionClick(this.translateService.instant('Impression'), () =>
      this.printPatientReport(),
    );
    const actions = [actionPrevious, actionPrint];
    this.navigationActionService.pushNavigationActions(actions);

    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((params: ParamMap) => {
      if (params.has('patientId') && params.has('consultId')) {
        const idConsulation = +params.get('consultId');
        const idPatient = +params.get('patientId');

        this.patientDataService
          .getPatientByDoctor()
          .then((listPatient) => {
            this.patientIdentity = listPatient.find((p) => p.patientId === idPatient);
          })
          .then(() => this.consultationDataService.getConsultationById(idConsulation))
          .then((c) => this.initPart2(c));
      } else {
        const consultation = LocalStorageEx.currentConsultRapport;
        this.patientIdentity = LocalStorageEx.currentPatientSelected;
        this.initPart2(consultation);
      }
    });
  }

  public initPart2(consultation: Consultation) {
    const idConsulation = consultation.id;

    this.dateConsultation = this.localDatePipe.parse(consultation.date);
    this.agePatient = Compute.age(new Date(this.patientIdentity.birthdate), new Date(consultation.date));

    this.bilanService.getBilans(idConsulation).then((ListOfBilans) => {
      if (this.ContainsRomberg(ListOfBilans)) {
        LocalStorageEx.setObject(LocalStorageObject.currentRapportType, 'Romberg');
        const bilanId = ListOfBilans.filter((bilan) => bilan.Type === BilanType.CheckupRomberg)[0].Id;
        this.remoteInteractionRomberg.getAnalysisResultsForBilan(bilanId).then((rombergResult) => {
          this.displayBilanRomberg(rombergResult);
          this.displayShoesValueForRomberg(bilanId);
          this.displayVisualAssessmentForRomberg(bilanId);
        });
      }

      if (this.ContainsWalk(ListOfBilans)) {
        if (LocalStorageEx.getObject(LocalStorageObject.currentRapportType) === 'None') {
          LocalStorageEx.setObject(LocalStorageObject.currentRapportType, 'Walk');
        } else if (LocalStorageEx.getObject(LocalStorageObject.currentRapportType) === 'Romberg') {
          LocalStorageEx.setObject(LocalStorageObject.currentRapportType, 'Both');
        }

        const bilanId = ListOfBilans.filter((bilan) => bilan.Type === BilanType.CheckupWalk)[0].Id;
        this.remoteInteractionWalk.getAnalysisResultsForBilan(bilanId).then((walkResult) => {
          this.displayBilanWalk(walkResult);
          this.displayShoesValueForWalk(bilanId);
          this.displayVisualAssessmentForWalk(bilanId);
        });
      }

      this.typeRapport = LocalStorageEx.getObject(LocalStorageObject.currentRapportType);
    });
  }

  public printPatientReport() {
    window.print();
  }

  private ContainsRomberg(MyList: Bilan[]): boolean {
    return MyList.filter((bilan) => bilan.Type === BilanType.CheckupRomberg).length > 0;
  }
  private ContainsWalk(MyList: Bilan[]): boolean {
    return MyList.filter((bilan) => bilan.Type === BilanType.CheckupWalk).length > 0;
  }

  private async displayBilanRomberg(romberResult: AnalysisResultsRomberg): Promise<void> {
    this.rombergAnalysisResult = romberResult;
    const patient = this.patientIdentity;

    // ----------------Construction BilanToGraphRomberg------------------------------------------

    const result: RombergBilan[] = [];
    await this.rombergService.getOldLocomtionBilan(patient.patientId).then((bilans) => {
      for (const bilan of bilans) {
        result.push(bilan);
      }
    });

    this.transformBilanToGraphRomberg(result);
  }

  private displayShoesValueForRomberg(bilanId: number): void {
    this.rombergService.getShoesValuesByBilan(bilanId).then((shoesValues) => {
      this.shoesValueResult = shoesValues;
      this.shoesType = shoesValues.shoesType;
      this.helpStability = shoesValues.helpStability;
    });
  }

  private displayVisualAssessmentForRomberg(bilanId: number): void {
    this.rombergService.getRombergAssessment(bilanId).then((assessmentsRomberg) => {
      this.assessmentsRomberg = assessmentsRomberg;
    });
  }

  private async displayBilanWalk(walkResult: AnalysisResultsWalk): Promise<void> {
    this.walkAnalysisResult = walkResult;

    const detailByAge = this.walkAnalysisResult.graphes.semioInfo.detailByAge;
    const semioDataForAge = this.detailByAgeSemio(detailByAge, this.agePatient);
    const patient = this.patientIdentity;

    this.locomotionDataService.getOldLocomtionBilan(patient.patientId).then((bilans) => {
      this.transformBilanToGraph(bilans);
    });

    this.radarChartLabels = this.radarChartLabels.map((label) => this.translateService.instant(label));

    this.radarChartData = [
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'Moyenne' },
      {
        data: [
          semioDataForAge.regularity,
          semioDataForAge.fluidity,
          semioDataForAge.vigor,
          semioDataForAge.pace,
          semioDataForAge.synchronization,
          semioDataForAge.symmetry,
          semioDataForAge.stability,
        ],
        label: 'Patient',
      },
    ];
  }

  private displayShoesValueForWalk(bilanId: number): void {
    this.locomotionService.getShoesValuesByBilan(bilanId).then((shoesValues) => {
      this.shoesValueResult = shoesValues;
      this.shoesType = shoesValues.shoesType;
      console.log('shoesStability    ' + shoesValues.shoesType);
      this.walkingAid = shoesValues.walkingAid;
    });
  }

  private displayVisualAssessmentForWalk(bilanId: number): void {
    this.locomotionService.getLocomotionAssessment(bilanId).then((assessments) => {
      this.assessments = assessments;
      console.log('appréciation tableau visual value : ' + this.assessments);
    });
  }

  /**
   * @description Retrieve the data of the semio by age of the patient
   * @param detailByAge
   * @param agePatient
   */
  private detailByAgeSemio(detailByAge: AnalysisResultsDetailByAge[], agePatient: number): AnalysisResultsDetailByAge {
    for (const element of detailByAge) {
      const ageMax = element.ageMax;
      const ageMin = element.ageMin;

      if (ageMin === 0 && ageMax === 100) {
        // skip catch all values
        continue;
      }

      if (agePatient >= ageMin && agePatient <= ageMax) {
        return element;
      }
    }
    return new AnalysisResultsDetailByAge();
  }

  private transformBilanToGraph(bilans: LocomotionBilan[]) {
    console.log('transformBilanToGraph');
    this.graphs = new Array<LongitudinalGraph>(6);
    this.graphs[0] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.averageSpeed');
    this.graphs[1] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.meanStepDuration');
    this.graphs[2] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.swingMeanStrideDuration');
    this.graphs[3] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.doubleStance');
    this.graphs[4] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.cycleVariability');
    this.graphs[5] = new LongitudinalGraph(new Array<{ x: Date; y: number }>(), 'Checkup.walk.roliTronc');
    for (const bilan of bilans) {
      console.log('bilans    ', bilan);
      this.graphs[0].graph.push({ x: bilan.Created, y: bilan.AverageSpeed });
      this.graphs[1].graph.push({ x: bilan.Created, y: bilan.MeanStepDuration });
      this.graphs[2].graph.push({ x: bilan.Created, y: bilan.SwingMeanStrideDuration });
      this.graphs[3].graph.push({ x: bilan.Created, y: bilan.DoubleStance * 100 });
      this.graphs[4].graph.push({ x: bilan.Created, y: bilan.CycleVariability });
      this.graphs[5].graph.push({ x: bilan.Created, y: bilan.TrunkRoll });
    }
    console.log('BilanLongitudinal', this.graphs);
  }
  private transformBilanToGraphRomberg(bilans: RombergBilan[]) {
    this.graphsRomb = new Array<LongitudinalGraphRomberg>(5);
    this.graphsRomb[0] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.romberg.varianceLaterale',
    );
    this.graphsRomb[1] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Surface',
    );
    this.graphsRomb[2] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SwayDensity',
    );
    this.graphsRomb[3] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SpeedMoy',
    );
    this.graphsRomb[4] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Score',
    );
    for (const bilan of bilans) {
      this.graphsRomb[0].graphYF.push({ x: bilan.Created, y: bilan.EyesClosedLateralVariance });
      this.graphsRomb[0].graphYO.push({ x: bilan.Created, y: bilan.EyesOpenLateralVariance });
      this.graphsRomb[1].graphYF.push({ x: bilan.Created, y: bilan.EyesClosedSurface });
      this.graphsRomb[1].graphYO.push({ x: bilan.Created, y: bilan.EyesOpenSurface });
      this.graphsRomb[2].graphYF.push({ x: bilan.Created, y: bilan.EyesClosedSwayDensity });
      this.graphsRomb[2].graphYO.push({ x: bilan.Created, y: bilan.EyesOpenSwayDensity });
      this.graphsRomb[3].graphYF.push({ x: bilan.Created, y: bilan.EyesClosedSpeed });
      this.graphsRomb[3].graphYO.push({ x: bilan.Created, y: bilan.EyesOpenSpeed });
      this.graphsRomb[4].graphYF.push({ x: bilan.Created, y: bilan.EyesClosedScore });
      this.graphsRomb[4].graphYO.push({ x: bilan.Created, y: bilan.EyesOpenScore });
    }
  }
}
