import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { PatientIdentity, GenderArray, HandednessArray } from 'app/modules/smartflat-data-access';
import { Compute } from 'app/tools/compute';
import * as medicalConst from 'app/tools/medical.constants';
import { WeightAndHeightService } from 'app/modules/consultation/services/weightAndHeight.service';
import { Router } from '@angular/router';
import { CheckupStateService } from 'app/modules/checkup/modules/checkup-common/services/checkup-state.service';

@Component({
  selector: 'first-acquisition-banner',
  templateUrl: './first-acquisition-banner.component.html',
})
export class FirstAcquisitionBannerComponent implements OnInit {
  public readonly MIN_WEIGHT_KG: number = medicalConst.MIN_WEIGHT_KG;
  public readonly MAX_WEIGHT_KG: number = medicalConst.MAX_WEIGHT_KG;
  public readonly MIN_HEIGHT_CM: number = medicalConst.MIN_HEIGHT_CM;
  public readonly MAX_HEIGHT_CM: number = medicalConst.MAX_HEIGHT_CM;
  public patientIdentity: PatientIdentity = new PatientIdentity();

  @Output()
  private ready: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private checkupStateService: CheckupStateService,
    private weightAndHeightService: WeightAndHeightService,
    private router: Router,
  ) {}

  /**
   * Get patient's information.
   */
  public ngOnInit(): void {
    const patient = LocalStorageEx.currentPatient;
    console.log(this.constructor.name + ': current patient: ', patient);
    this.patientIdentity.firstName = patient.firstName;
    this.patientIdentity.lastName = patient.lastName;
    this.patientIdentity.phoneNumber = patient.phoneNumber;
    this.patientIdentity.address = patient.address;
    this.patientIdentity.email = patient.email;
    this.patientIdentity.birthdate = patient.birthdate;
    this.patientIdentity.age = Compute.age(new Date(patient.birthdate));
    this.patientIdentity.connected = false; // TODO
    const tablegender = GenderArray;
    this.patientIdentity.genderString = tablegender[patient.gender].completeName;
    const tableHand = HandednessArray;
    this.patientIdentity.handednessString = tableHand[patient.handedness].completeName;
    this.patientIdentity.Height = LocalStorageEx.Height;
    this.patientIdentity.Weight = LocalStorageEx.Weight;
    this.patientIdentity.MeasuredWeight = LocalStorageEx.MeasuredWeight;
  }

  public async saveAndNext(): Promise<void> {
    const isOk = await this.save();
    if (isOk) {
      this.checkupStateService.isInitialWeightMeasure = false;
      this.ready.emit(true);
    }
  }

  public async saveAndWeight(): Promise<void> {
    const isOk = await this.save();
    if (isOk) {
      this.checkupStateService.isInitialWeightMeasure = true;
      this.router.navigate(['/checkup/weight/installation']);
    }
  }

  /**
   * Save data in localStorage
   */
  public async save() {
    const heightCorrect: boolean = await this.weightAndHeightService.isHeightCorrect(this.patientIdentity.Height);
    const weightCorrect: boolean = await this.weightAndHeightService.isWeightCorrect(this.patientIdentity.Weight);

    if (weightCorrect && heightCorrect) {
      LocalStorageEx.Height = this.patientIdentity.Height;
      LocalStorageEx.Weight = this.patientIdentity.Weight;
      LocalStorageEx.MeasuredWeight = this.patientIdentity.MeasuredWeight;
      return true;
    }
    return false;
  }
}
