<div class="page-title">{{ name }} - {{ bilanSelected.Date | localdate: 'datetime' }}</div>

<div class="questionnaire_checkup">
  <div class="row">
    <div class="col-lg-12" *ngIf="answerRoot$ | async as root">
      <div *ngIf="ready">
        <h2 class="page-sub-title">{{ currentQuestionnary.nom }}</h2>
        <div *ngIf="root.score !== undefined && root.score !== null">
          {{ 'questionnary.score' | translate }} {{ root.score }}
        </div>
        <div class="row" style="padding-top: 30px !important; padding-bottom: 30px !important">
          <div style="text-align: center !important">
            <div class="col-lg-7">
              <div style="font-weight: bold !important; font-size: 2rem !important">
                {{ 'Test.Date' | translate }}
                :
                {{ currentDate }}
              </div>
            </div>

            <div class="col-lg-5">
              <div class="row">
                <div class="col-lg-5">
                  <div class="row">
                    <div class="col-lg-4" style="font-weight: bold !important; font-size: 2rem !important">
                      {{ 'Checkup.Compare' | translate }}:
                    </div>
                    <div class="col-lg-2" style="margin-top: 1.5%">
                      <input (change)="enableComparison(this)" [(ngModel)]="comparisonEnabled" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-3" style="margin-top: 1%">
                  <select
                    [disabled]="!comparisonEnabled"
                    (change)="onComparisonDateChanged($event)"
                    style="width: 180px !important"
                  >
                    <option
                      [selected]="
                        consultationDate === selectedDate || (comparisonEnabled === false && consultationDate === '')
                      "
                      *ngFor="let consultationDate of consultationsDate"
                      [ngValue]="consultationDate"
                    >
                      {{ consultationDate }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-3" style="margin-top: 0.75%" *ngIf="answerComparedRoot$ | async as comparedRoot">
                  <div
                    *ngIf="comparedRoot.score !== undefined && comparedRoot.score !== null"
                    style="font-weight: bold !important; font-size: 2rem !important"
                  >
                    {{ 'questionnary.score' | translate }} {{ comparedRoot.score }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="dateSelected; else comparaisonDisabled">
          <div class="questions_row">
            <div *ngFor="let questionSet of currentQuestionnary.listeQuestions" class="right questionSet">
              <p class="form-title">{{ questionSet.nom }}</p>
              <div *ngFor="let question of questionSet.listeItems" class="">
                <p class="form-sub-title">{{ question.question }}</p>

                <!--   begin    -->
                <div *ngFor="let option of question.options; let i = index">
                  <div class="row">
                    <div class="col-lg-6">
                      <ng-template #input1>
                        <input
                          readonly
                          *ngIf="question.typeQuestion === 'input'"
                          type="text"
                          placeholder="{{ preanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                        <input
                          readonly
                          *ngIf="question.typeQuestion === 'input-date'"
                          type="date"
                          value="{{ preanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                        <input
                          readonly
                          *ngIf="question.typeQuestion === 'inputNumber'"
                          type="number"
                          value="{{ preanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                      </ng-template>

                      <div
                        *ngIf="
                          question.typeQuestion !== 'input' &&
                            question.typeQuestion !== 'input-date' &&
                            question.typeQuestion !== 'inputNumber';
                          else input1
                        "
                      >
                        <div class="form_label">
                          <input
                            disabled
                            type="{{ question.typeQuestion }}"
                            [checked]="checkIfCheck(option.value, question.position, i)"
                            class="{{ question.typeQuestion }}"
                            name="Q{{ question.position }}"
                            id="Q{{ question.position }}A{{ i }}"
                          />
                          <label for="Q{{ question.position }}A{{ i }}">
                            <span class="{{ question.typeQuestion }}">{{ option.value }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- comparaison -->
                    <div class="col-lg-6" *ngIf="comparedPreanswers$ | async as comparedPreanswers">
                      <ng-template #input>
                        <input
                          readonly
                          style="margin-left: 150px !important"
                          *ngIf="question.typeQuestion === 'input'"
                          type="text"
                          placeholder="{{ comparedPreanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                        <input
                          readonly
                          style="margin-left: 150px !important"
                          *ngIf="question.typeQuestion === 'input-date'"
                          type="date"
                          value="{{ comparedPreanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                        <input
                          readonly
                          style="margin-left: 150px !important"
                          *ngIf="question.typeQuestion === 'inputNumber'"
                          type="number"
                          value="{{ comparedPreanswers[question.position] }}"
                          name="{{ question.position }}"
                          id="{{ question.position }}"
                        />
                      </ng-template>

                      <div
                        *ngIf="
                          question.typeQuestion !== 'input' &&
                            question.typeQuestion !== 'input-date' &&
                            question.typeQuestion !== 'inputNumber';
                          else input
                        "
                      >
                        <div class="row">
                          <div class="col-lg-2"></div>
                          <div class="col-lg-10 form_label">
                            <input
                              disabled
                              type="{{ question.typeQuestion }}"
                              [checked]="checkIfcompareDataChecked(option.value, question.position, i) | async"
                              class="{{ question.typeQuestion }}"
                              name="Compared-Q{{ question.position }}"
                              id="Compared-Q{{ question.position }}A{{ i }}"
                            />
                            <label for="Compared-Q{{ question.position }}A{{ i }}">
                              <span class="{{ question.typeQuestion }}">{{ option.value }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #comparaisonDisabled>
          <div *ngFor="let questionSet of currentQuestionnary.listeQuestions" class="questionSet">
            <p class="form-title">{{ questionSet.nom }}</p>
            <div *ngFor="let question of questionSet.listeItems">
              <p class="form-sub-title">{{ question.question }}</p>

              <div class="questions_row">
                <div *ngFor="let option of question.options; let i = index">
                  <ng-template #input2>
                    <input
                      readonly
                      *ngIf="question.typeQuestion === 'input'"
                      type="text"
                      placeholder="{{ preanswers[question.position] }}"
                      name="{{ question.position }}"
                      id="{{ question.position }}"
                    />
                    <input
                      readonly
                      *ngIf="question.typeQuestion === 'input-date'"
                      type="date"
                      value="{{ preanswers[question.position] }}"
                      name="{{ question.position }}"
                      id="{{ question.position }}"
                    />
                    <input
                      readonly
                      *ngIf="question.typeQuestion === 'inputNumber'"
                      type="number"
                      value="{{ preanswers[question.position] }}"
                      name="{{ question.position }}"
                      id="{{ question.position }}"
                    />
                  </ng-template>

                  <div
                    *ngIf="
                      question.typeQuestion !== 'input' &&
                        question.typeQuestion !== 'input-date' &&
                        question.typeQuestion !== 'inputNumber';
                      else input2
                    "
                  >
                    <div class="form_label">
                      <input
                        disabled
                        type="{{ question.typeQuestion }}"
                        [checked]="checkIfCheck(option.value, question.position, i)"
                        class="{{ question.typeQuestion }}"
                        name="Q{{ question.position }}"
                        id="Q{{ question.position }}A{{ i }}"
                      />
                      <label for="Q{{ question.position }}A{{ i }}">
                        <span class="{{ question.typeQuestion }}">{{ option.value }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button
          class="btn btn-primary btn-left"
          [routerLink]="['/PatientResults']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'Précédent' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
