import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceDalConfig } from './service-dal.config';

import {
  ConsultationService,
  PatientService,
  IdentityService,
  DiagnosticService,
  BilanService,
  QuestionnaireService,
  QuestionService,
  FormService,
  GripService,
  PatientDoctorRelationService,
  DoctorService,
  FootwearService,
  LocomotionBilanVisualAssessmentService,
  LocomotionBilanService,
  RombergBilanVisualAssessmentService,
  RombergBilanService,
  EstablishmentService,
  FlatService,
  PatientFlatRelationService,
  ExtractionService,
  WalkingAidService,
} from 'app/modules/smartflat-data-access/src/service-dal/api/api';

@Injectable()
export class ServiceDal {
  public consultationService = new ConsultationService(this.httpClient, this.configuration.uriBase, null);
  public patientService = new PatientService(this.httpClient, this.configuration.uriBase, null);
  public identityService = new IdentityService(this.httpClient, this.configuration.uriBase, null);
  public diagnosticService = new DiagnosticService(this.httpClient, this.configuration.uriBase, null);
  public bilanService = new BilanService(this.httpClient, this.configuration.uriBase, null);
  public questionnaryService = new QuestionnaireService(this.httpClient, this.configuration.uriBase, null);
  public questionService = new QuestionService(this.httpClient, this.configuration.uriBase, null);
  public formService = new FormService(this.httpClient, this.configuration.uriBase, null);
  public gripService = new GripService(this.httpClient, this.configuration.uriBase, null);

  public patientDoctorRelationService = new PatientDoctorRelationService(
    this.httpClient,
    this.configuration.uriBase,
    null,
  );
  public doctorService = new DoctorService(this.httpClient, this.configuration.uriBase, null);
  public shoesService = new FootwearService(this.httpClient, this.configuration.uriBase, null);
  public walkingAidServive = new WalkingAidService(this.httpClient, this.configuration.uriBase, null);
  public locomotionAssessmentService = new LocomotionBilanVisualAssessmentService(
    this.httpClient,
    this.configuration.uriBase,
    null,
  );
  public locomotionBilanService = new LocomotionBilanService(this.httpClient, this.configuration.uriBase, null);
  public rombergAssessmentService = new RombergBilanVisualAssessmentService(
    this.httpClient,
    this.configuration.uriBase,
    null,
  );
  public rombergBilanService = new RombergBilanService(this.httpClient, this.configuration.uriBase, null);
  public establishmentService = new EstablishmentService(this.httpClient, this.configuration.uriBase, null);
  public flatService = new FlatService(this.httpClient, this.configuration.uriBase, null);
  public patientFlatRelationService = new PatientFlatRelationService(this.httpClient, this.configuration.uriBase, null);
  public extractionService = new ExtractionService(this.httpClient, this.configuration.uriBase, null);
  // public weightService = new WeightService(this.httpClient, this.configuration.uriBase, null);

  // public rombergAssessmentService = new RombergBilanVisualAssessmentService(this.httpClient, this.configuration.uriBase, null);
  // public rombergBilanService = new RombergBilanService(this.httpClient, this.configuration.uriBase, null);

  constructor(private httpClient: HttpClient, private configuration: ServiceDalConfig) { }

  public get daluri() {
    return this.configuration.uriBase;
  }
}
