import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CheckupTitleService {
    public currentTitle$: Observable<string>;

    private readonly title: string = 'Nouvelle acquisition';
    private currentTitle = new BehaviorSubject(this.title);

    public constructor(protected translate: TranslateService) {
        this.currentTitle$ = this.currentTitle.asObservable();
        this.translate.onLangChange.subscribe((event) => {
            this.onLangChange();
        });
    }

    public setCurrentTitle(title: string): void {
        this.currentTitle.next(title);
    }

    public onLangChange() {
        this.currentTitle = new BehaviorSubject(this.translate.instant(this.title));
    }
}
