export const organizationPatient = 'Patients';

export const personAttributeHandedness = 'Handedness';
export const personAttributeHandednessLeft = 'Left';
export const personAttributeHandednessRight = 'Right';
export const personAttributeHandednessAmbidextrous = 'Ambidextrous';

export const personAttributeBirthdate = 'Birthdate';

export const personAttributePatientReference = 'PatientReference';

// Diagnostic part
export const firstSetId = 'firstSet';
export const secondSetId = 'secondSet';
export const temporalitySetId = 'temporalitySet';
export const etiologyIdQuestion = 'etiology';
export const typeIdQuestion = 'type';
export const localizationIdQuestion = 'localization';
export const lateralizationIdQuestion = 'lateralization';

export const durationIdQuestion = 'duration';
export const rhythmIdQuestion = 'rhythm';
export const currentIdQuestion = 'current';
