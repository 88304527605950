<div class="tor-histo">
  <div class="row">
    <div class="col-lg-4">
      <label>{{ 'tor.time.period' | translate }}</label>
      <select [(ngModel)]="periodSelected" name="periodSelected" (ngModelChange)="onViewChange($event)">
        <option value="day">{{ 'Date_Day' | translate }}</option>
        <option value="night">{{ 'Date_Night' | translate }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <h3>{{ 'tor.table.detail' | translate }}</h3>
      <canvas #torHisto></canvas>
    </div>
    <div class="col-lg-6">
      <h3>{{ 'tor.table.total' | translate }}</h3>
      <canvas #torHistoTotal></canvas>
    </div>
  </div>
</div>
