<div class="page-title">{{ 'Nouvelle acquisition' | translate }}</div>

<div class="questionnaire">
  <current-patient-banner [consultationStep]="1"> </current-patient-banner>

  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="listOfForms != null">
        <div *ngIf="listOfForms.length === 0" class="message">
          <p translate>noquestionnary.message</p>
        </div>
        <div *ngFor="let form of listOfForms" class="questionSet">
          <p class="form-sub-title">{{ form.nom }}</p>

          <div class="questions_row">
            <div *ngFor="let question of form.questionnaries" class="question">
              <div class="form_label  row">
                <span class="col-lg-3">
                  <input
                    type="checkbox"
                    [checked]="question.id <= idmax && preselectedId[question.id]"
                    class="checkbox"
                    name="question"
                    id="{{ question.nom }}"
                    (click)="AddOrRemoveQuestionnary(question.id)"
                  />
                  <label for="{{ question.nom }}">
                    <span class="checkbox">{{ question.nom }}</span>
                  </label>
                </span>
                <span class="col-lg-9 questionnaryCheck">
                  <span *ngIf="question.idBilan"
                    >{{ 'Questionnary.ckeck.already' | translate: { lastDate: question.lastDate | localdate } }}
                    <span *ngIf="question.lastScore || question.lastScore === 0">{{
                      'Questionnary.ckeck.lastscore' | translate: { score: question.lastScore }
                    }}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button
          class="btn btn-primary btn-left"
          [routerLink]="['/diagnosticList']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'Précédent' | translate }}
        </button>

        <button
          *ngIf="selectedId.length !== 0 || selectedQuestionnaries.length !== 0"
          class="btn btn-primary"
          (click)="addTableinLS()"
        >
          {{ 'SaveAndNext' | translate }}
        </button>

        <button
          *ngIf="selectedId.length === 0 && selectedQuestionnaries.length === 0"
          class="btn btn-primary"
          [routerLink]="['/checkup']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="addTableinLS()"
        >
          {{ 'PasserBilan' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
