/**
 * this class representes the data describing one or many graph(s).
 */
export class Data {
    public data: any[]; // values of y axe
    public labels: any[]; // values of x axe
    public patient_place: number; // value specific according the patient.
    // For example for histograms this value representes in which area the patient is
    // and for semiograms it representes the speed of the patient(in SD)
}
