/**
 * 3rd-party modules
 */
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { AuthConfig } from 'angular-oauth2-oidc';

/**
 * Our modules
 */

/**
 * Components
 */
import { NavigationActionsComponent } from './view/navigation-actions/navigation-actions.component';
import { NotificationComponent } from './view/notifications/notification/notification.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
/**
 * Services
 */
import { VersionService } from './index';
import { NavigationActionsService } from './index';
import { NotificationService } from './index';

import { AuthGuard } from './services/authentification/guard.service';
import { UUID } from './services/uuid/uuid.service';
import { ColorService } from 'app/modules/core/services/color.service';
import { AppConfigurationService } from 'app/app-configuration.service';
import { authConfigFactory } from 'app/modules/core/services/authentification/authConfig.factory';
import { AuthService } from 'app/modules/core/services/authentification/auth.service';
import { HttpErrorInterceptor } from 'app/modules/core/services/http/http-error.interceptor';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    NavigationActionsComponent,
    NotificationComponent,
    NotificationsComponent,
  ],
  providers: [
    NavigationActionsService,
    VersionService,
    NotificationService,
    ColorService,
    AuthGuard,
    UUID,
    AuthService,
    {
      provide: AuthConfig,
      useFactory: authConfigFactory,
      deps: [AppConfigurationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [NotificationService],
    },
  ],
  exports: [
    NavigationActionsComponent,
    NotificationsComponent,
  ],
})
export class CoreModule { }
