import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceDal } from '../service-dal/service-dal';
import { Doctor } from '../model/doctor.model';
import * as DoctorTransform from '../transformations/doctor.transform';

@Injectable()
/**
 * this service manages doctors, including the transformation of data to/from the DAL model.
 */
export class DoctorDataService {
  constructor(private serviceDal: ServiceDal) {}

  public async GetAllDoctors(): Promise<Doctor[]> {
    const doctorApi = this.serviceDal.doctorService;

    const lstDoctorsDal = await doctorApi.doctorFind().toPromise();
    const doctors = DoctorTransform.toDoctorDal(lstDoctorsDal);
    return doctors;
  }

  public getCurrentDoctorId(version?: string): Observable<{ id: number; lastVersion: string }> {
    return this.serviceDal.doctorService.doctorMe(version).pipe(
      map((value) => ({
        id: value.Id,
        lastVersion: value.lastConnection && value.lastConnection.version,
      })),
    );
  }
}
