import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ICheckupOption } from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import {
  NavigationActionsService,
  NavigationActionLink,
  NavigationActionClick,
  NotificationService,
} from 'app/modules/core';
import { CheckupTitleService } from 'app/modules/checkup/services/checkup-title.service';
import { PatientModalComponent } from './patient-modal/patient-modal.component';
import { AuthService } from 'app/modules/core/services/authentification/auth.service';
import { Roles } from 'app/modules/core/roles.enum';

@Component({
  templateUrl: './choixImpression.component.html',
})
export class ChoixImpressionComponent implements OnInit {
  @ViewChild(PatientModalComponent, { static: true })
  public patientModal: PatientModalComponent;

  public error: any;
  public checkupOptions: ICheckupOption[];
  public checkupType: string[];
  public checkupPassed: boolean[];
  public typeBoolean: boolean;
  public bothPassedBoolean: boolean;
  public errorMessage: string;

  constructor(
    private checkupTitleService: CheckupTitleService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationActionService: NavigationActionsService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {}

  public async ngOnInit() {
    this.navigationActionService.pushNavigationActions([
      new NavigationActionClick(this.translateService.instant('Suivant'), () => this.next()),
    ]);
    LocalStorageEx.setObject(LocalStorageObject.currentRapportType, 'None');

    this.checkupOptions = [
      {
        id: 1,
        type: this.translateService.instant('ChoixImpression.rapportPatient'),
        link: '/rapport',
        passed: LocalStorageEx.currentRapportPatient,
        disabled: !this.authService.hasRoleInstant(Roles.patientReport),
      },
      {
        id: 2,
        type: this.translateService.instant('ChoixImpression.rapportPraticien'),
        link: '/rapportPraticien',
        passed: LocalStorageEx.currentRapportDocteur,
        disabled: !this.authService.hasRoleInstant(Roles.doctorReport),
      },
    ];
    this.checkupTitleService.setCurrentTitle('ChoixImpression.selection');
  }

  public returnToPatientList(): void {
    this.router.navigate(['/PatientConsult']);
  }

  public next(): void {
    if (!LocalStorageEx.currentRapportDocteur && this.authService.hasRoleInstant(Roles.doctorReport)) {
      this.patientModal.show(true);
    } else {
      LocalStorageEx.currentRapportDocteur = false;
      LocalStorageEx.currentRapportPatient = false;
      this.returnToPatientList();
    }
  }
}
