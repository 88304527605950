import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { CommonModule } from 'app/modules/common';

import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/modules/core/core.module';
import { ConsultationRoutingModule } from './consultation-routing.module';
import { TimeModule } from 'app/modules/time/time.module';
import { SmartFlatDataAccessModule } from 'app/modules/smartflat-data-access';

import { CurrentPatientBannerComponent } from './views/current-patient-banner/current-patient-banner.component';
import { FirstAcquisitionBannerComponent } from './views/first-acquisition-banner/first-acquisition-banner.component';
import { WizardStepsComponent } from './views/wizard-steps/wizard-steps.component';
import { IdentityBannerComponent } from './views/identity-banner/identity-banner.component';
import { WeightAndHeightService } from 'app/modules/consultation/services/weightAndHeight.service';
import { HistoVersusConsultationService } from 'app/modules/consultation/services/histo-versus-consultation.service';
import { MathModule } from 'app/modules/math/math.module';

@NgModule({
  imports: [
    AngularCommon,
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    CoreModule,
    ConsultationRoutingModule,
    TimeModule,
    SmartFlatDataAccessModule,
    MathModule,
  ],
  declarations: [
    CurrentPatientBannerComponent,
    FirstAcquisitionBannerComponent,
    WizardStepsComponent,
    IdentityBannerComponent,
  ],
  exports: [CurrentPatientBannerComponent, FirstAcquisitionBannerComponent],
  providers: [WeightAndHeightService, HistoVersusConsultationService],
})
export class ConsultationModule {}
