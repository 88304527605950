export enum PatientViews {
  /**
   * List of patients modifiable and deletable.
   */
  Edit,
  /**
   * List of patients modifiable, deletable and addible ('+').
   */
  Plus,
  /**
   * Show delegation view
   */
  Delegate,
}
