// different application role that can be used
export enum Roles {
  /* can see all patient */
  listAllPatient = 'list_all_patient',
  /* can add, edit, remove patient */
  managePatient = 'manage_patient',
  /* select referrer for patient */
  selectPatientReferrer = 'select_patient_referrer',
  /* Add, edit delete, syndrome */
  editSyndrom = 'edit_syndrome',
  /* Make pass non medical questionnaire */
  passNonMedicalQuestionnaire = 'pass_non_medical_questionnaire',
  /* Make pass medical questionnaire */
  passMedicalQuestionnaire = 'pass_medical_questionnaire',
  /* Make pass checkup */
  runCheckup = 'run_checkup',
  /* Patient report */
  patientReport = 'patient_report',
  /* doctor report */
  doctorReport = 'doctor_report',
  /* export result of checup */
  exportResult = 'export_result',
  /* export raw data */
  exportRawData = 'export_raw_data',
  /* patient medical data */
  accessResults = 'access_result',
  /* Delegation for results */
  delegationResult = 'delegation_result',
  /* Delegation for checkup */
  delegationChekup = 'delegation_checkup',
  /* Doctor creation */
  userAdministration = 'user_administration',
  /* Edit of establishment */
  establishmentAdministration = 'establishment_administration',
}
