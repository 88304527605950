import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ServiceDal } from '../service-dal/service-dal';
import { InterceptorHttpParams } from 'app/modules/core/services/http/interceptor-http-params';

export enum ExtractionStatus {
  NO_EXPORT,
  OK,
  WAITING,
  ERROR,
}

@Injectable()
export class ExtractionService {
  constructor(private serviceDal: ServiceDal, private httpClient: HttpClient) {}

  public startExport(
    patientIds: number[],
    bilanIds: number[],
    includeRawData: boolean,
    anonymousData: boolean,
  ): Observable<void> {
    const api = this.serviceDal.extractionService;

    const patientfilter = JSON.stringify(patientIds);
    const bilanfilter = JSON.stringify(bilanIds);
    return api.extractionExport(patientfilter, bilanfilter, includeRawData, anonymousData);
  }

  public statusExport() {
    // To pass params to prevent login I need to manually make the request
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    // return this.http.get<WiiBoardState>(url, { params })

    return this.httpClient
      .get<string>(`${this.serviceDal.daluri}/Extractions/exportStatus`, { params })
      .pipe(
        catchError((error) => {
          console.warn('Error during get status of export', error);
          return 'ERROR';
        }),
        map((status) => {
          switch (status.split(' ')[0]) {
            case 'NO':
              return { status: ExtractionStatus.NO_EXPORT };
            case 'OK':
              const key = status.split(' ')[1];
              return { status: ExtractionStatus.OK, url: new URL('../export-download/' + key, this.serviceDal.daluri) };
            case 'RUNNING':
              return { status: ExtractionStatus.WAITING };
            case 'ERROR':
            default:
              return { status: ExtractionStatus.ERROR };
          }
        }),
      );
  }
}
