<div class="preamble-romberg">
  <div style=" border: 1px solid black; background-color: white;width: 60%;height: 115px;margin-top: 215px;margin-left: 325px;">

    <div class="row" style="margin-top: 10px !important; ">
      <div class="col-lg-2 main-title">
        {{ 'Indicator.Synthese' | translate }}
      </div>
      <div class="col-lg-8">
        <table style="width:  100%; border: 2px solid white; height: 30px; position: relative;">
          <tr class="scorescale-indicator">
            <td *ngFor="let i of  [0,1,2,3,4,5]">
              <img src="assets/images/indicator.png" [ngClass]="{ 'is-hidden': i!==score }" />
            </td>
          </tr>
          <tr class="scorescale">
            <th class="score0">
              <div>0</div>
            </th>
            <th class="score1">
              <div>1</div>
            </th>
            <th class="score2">
              <div>2</div>
            </th>
            <th class="score3">
              <div>3</div>
            </th>
            <th class="score4">
              <div>4</div>
            </th>
            <th class="score5">
              <div>5</div>
            </th>
          </tr>
        </table>
      </div>
      <div class="col-lg-2">
        <div *ngIf='score===null' class="non-evaluated" style="margin-top: 40px;">
          {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 80px;">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-8">
          <canvas #weight></canvas>
          <div class="indicator-title">
            {{ 'Indicator.Weight.Title' | translate }} </div>
        </div>

        <div class="col-lg-4 indicator-zone">
          <div *ngIf='weightCriteria !==null;else unmeasurableWeight'>
            <div class="indicator" [ngClass]="{ 'warn':weightCriteria  }"></div>
            <div class="about " (click)="disableWeightAboutModal.show()">
              <a> {{ 'About' | translate }}</a>
            </div>
          </div>
          <ng-template #unmeasurableWeight>
            <div class="non-evaluated">
              {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div *ngIf='weightCriteria!==null' class="criteria">
            {{ 'Indicator.Weight.Description' | translate: weightDescription }} </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-8">
          <canvas #energy></canvas>
          <div class="indicator-title">
            {{ 'Indicator.Energy.Title' | translate }}
          </div>
        </div>
        <div class="col-lg-4 indicator-zone">
          <div *ngIf='vigorCriteria !==null;else unmeasurableEnergy'>
            <div class="indicator" [ngClass]="{ 'warn':vigorCriteria  }"></div>
            <div class="about" (click)="disableEnergyAboutModal.show();">
              <a> {{ 'About' | translate }}</a>
            </div>
          </div>

          <ng-template #unmeasurableEnergy>
            <div class="non-evaluated">
              {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div *ngIf='vigorCriteria !==null' style="font-size: 15px ">
            {{ 'Indicator.Energy.Description1' | translate:vigorDescription }}<br>{{ 'Indicator.Energy.Description2' |
            translate:vigorDescription
            }}


          </div>
        </div>
      </div>
    </div>



  </div>
  <div class="row" style="margin-top: 50px;">

    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-8">
          <canvas #grip></canvas>
          <div class="indicator-title">
            {{ 'Indicator.Grip.Title' | translate }}
          </div>
        </div>
        <div class="col-lg-4 indicator-zone">
          <div *ngIf='strengthCriteria !==null;else unmeasurableGrip'>
            <div class="indicator" [ngClass]="{ 'warn':strengthCriteria  }"></div>
            <div class="about" (click)="disableGripAboutModal.show();">
              <a> {{ 'About' | translate }}</a>
            </div>
          </div>
          <ng-template #unmeasurableGrip>
            <div class="non-evaluated">
              {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div *ngIf='strengthCriteria !==null' class="criteria">
            {{ 'Indicator.Grip.Description' | translate:strengthDescription }}
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-8">
          <canvas #physical></canvas>
          <div class="indicator-title">
            {{ 'Indicator.Physical.Title' | translate }}
          </div>
        </div>


        <div class="col-lg-4 indicator-zone">
          <div *ngIf='physicalCriteria !==null;else unmeasurablePhysical'>
            <div class="indicator" [ngClass]="{ 'warn':physicalCriteria  }"></div>
            <div class="about" (click)="disablePhysicalAboutModal.show()">
              <a> {{ 'About' | translate }}</a>
            </div>
          </div>
          <ng-template #unmeasurablePhysical>
            <div class="non-evaluated">
              {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div *ngIf='physicalCriteria !==null' class="criteria">
            {{ 'Indicator.Physical.Description' | translate:physicalDescription }}
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row" style="margin-top: 50px;">

    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-8">
          <canvas #locomotion></canvas>
          <div class="indicator-title">
            {{ 'Indicator.Walk.Title' | translate }}
          </div>
        </div>
        <div class="col-lg-4 indicator-zone">
          <div *ngIf='speedCriteria !==null;else unmeasurableWalk'>
            <div class="indicator" [ngClass]="{ 'warn':speedCriteria  }"></div>
            <div class="about" (click)="disableWalkAboutModal.show()">
              <a> {{ 'About' | translate }}</a>
            </div>
          </div>

          <ng-template #unmeasurableWalk>
            <div class="non-evaluated">
              {{ 'Checkup.Walk.Assessment.NonEvaluated' | translate }} </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div *ngIf='speedCriteria !==null' class="criteria">
            {{ 'Indicator.Walk.Description' | translate:speedDescription }}
          </div>
        </div>
      </div>
    </div>




  </div>

</div>

<core-modal name='disableWeightAboutModal' #disableWeightAboutModal>
  <div class="modal-header">
    <img (click)="disableWeightAboutModal.hide()" src="assets/images/close.png" alt="X" class="close-button" />
    <label>{{'About'|translate}}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;"> {{'About.Description'|translate}}
    <br>
    <br> {{'About.Weight'|translate}}
  </div>
</core-modal>

<core-modal name='disableEnergyAboutModal' #disableEnergyAboutModal>
  <div class="modal-header">
    <img (click)="disableEnergyAboutModal.hide()" src="assets/images/close.png" alt="" class="close-button" />
    <label>{{'About'|translate}}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;"> {{'About.Description'|translate}}
    <br>
    <br> {{'About.Energy'|translate}}
  </div>
</core-modal>




<core-modal name='disableGripAboutModal' #disableGripAboutModal>
  <div class="modal-header">
    <img (click)="disableGripAboutModal.hide()" src="assets/images/close.png" alt="" class="close-button" />
    <label>{{'About'|translate}}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;"> {{'About.Description'|translate}}
    <br>
    <div [innerHTML]="'About.Grip' |translate"></div>
  </div>
</core-modal>


<core-modal name='disablePhysicalAboutModal' #disablePhysicalAboutModal>
  <div class="modal-header">
    <img (click)="disablePhysicalAboutModal.hide()" src="assets/images/close.png" alt="" class="close-button" />
    <label>{{'About'|translate}}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;">
    {{'About.Description'|translate}}
    <br>
    <br>{{'About.Physical'|translate}}
  </div>
</core-modal>



<core-modal name='disableWalkAboutModal' #disableWalkAboutModal>
  <div class="modal-header">
    <img (click)="disableWalkAboutModal.hide()" src="assets/images/close.png" alt="" class="close-button" />
    <label>{{'About'|translate}}</label>
  </div>
  <div class="modal-body" style=" text-align: left  !important;"> {{'About.Description'|translate}}
    <br>
    <br> {{'About.Walk'|translate}}
  </div>
</core-modal>
