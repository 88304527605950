import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'boolean' })
export class BooleanFormat implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    public transform(value: boolean): string {
        if (value !== null && value !== undefined && value === true) {
            return this.translateService.instant('boolean.true');
        } else {
            return this.translateService.instant('boolean.false');
        }
    }

}
