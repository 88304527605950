import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.css'],
})
export class PatientHeaderComponent {
  /* Tag attributes */
  @Input() public title: any;
  @Input() public value: any;
  @Input() public showValue: any;
  @Input() public inputValue = '';

  /* Tag events */
  @Output() public search = new EventEmitter<any>();

  public onSearch(event) {
    this.search.emit(event);
  }
}
