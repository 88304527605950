import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbService } from './breadcrumb.service';
import { LoggerService } from 'app/services/logger/logger.service';

@Injectable()
export class BreadcrumbServiceFactory {
  private defaultBcService: BreadcrumbService;
  private parametrizedBcServices: Map<string, BreadcrumbService>;

  public constructor(private logger: LoggerService, private router: Router) {
    this.defaultBcService = new BreadcrumbService(logger, router);
    this.parametrizedBcServices = new Map();
  }

  public getInstance(name?: string): BreadcrumbService {
    if (!name) {
      return this.defaultBcService;
    }

    let instance = this.parametrizedBcServices.get(name);
    if (instance === undefined) {
      instance = new BreadcrumbService(this.logger, this.router, name);
      this.parametrizedBcServices.set(name, instance);
    }

    return instance;
  }
}
