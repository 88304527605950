import { Injectable } from '@angular/core';
import { BehaviorSubject ,  from as fromPromise } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { BilanDataService } from 'app/modules/smartflat-data-access';
import { LocalDatePipe } from 'app/modules/time/pipes/localdate.pipe';
import { BilanResults } from 'app/modules/smartflat-data-access/src/model/bilan.model';

@Injectable()
export class HistoVersusConsultationService {
  public bilanResultSelected$ = new BehaviorSubject<BilanResults>(null);

  private _compareDate = '';
  private _comparaisonEnabled = false;
  private _history: boolean = false;
  private writeMode$: BehaviorSubject<boolean>;

  constructor(private bilanService: BilanDataService, private localDatePipe: LocalDatePipe) {
    console.log('Mode histo', LocalStorageEx.histoBoolean);
    if (LocalStorageEx.histoBoolean) {
      this.writeMode$ = new BehaviorSubject<boolean>(!LocalStorageEx.histoBoolean);
    } else {
      this.writeMode$ = new BehaviorSubject<boolean>(true);
    }
  }

  public setToReadOnly(value: boolean): void {
    console.log('Set to readonly', value);
    this.writeMode$.next(!value);
    LocalStorageEx.histoBoolean = value;
  }
  /**
   * If the value is at true it means that we are in a consultation.
   * At the opposité if the value is at fale, it means we are in a old bilan.
   */
  public get writeMode(): BehaviorSubject<boolean> {
    return this.writeMode$;
  }
  /**
   * If the value is at true it means that we are in a consultation.
   * At the opposité if the value is at fale, it means we are in a old bilan.
   */
  public get writeModeValue(): boolean {
    return this.writeMode$.value;
  }

  public goToHistory(history: boolean): void {
    this._history = history;
  }

  public get history(): boolean {
    return this._history;
  }

  public setPageInConsultation(route: string) {
    if (this.writeMode$.value) {
      LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, route);
    }
  }

  public get comparaisonEnabled() {
    return this._comparaisonEnabled;
  }

  public set comparaisonEnabled(value: boolean) {
    this._comparaisonEnabled = value;
  }

  public comparaisonReset() {
    this.compareDate = null;
    this.comparaisonEnabled = false;
  }

  /**
   * bilanDateForcomparaison
   */
  public bilanDateForcomparaison(patientId: number, bilanType: number) {
    return fromPromise(this.bilanService.getAssessments(patientId, bilanType, null)).pipe(
      map((listOfBilans) => {
        LocalStorageEx.bilanResults = listOfBilans;
        const listDate = listOfBilans.map((bilanResult) =>
          this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss'),
        );
        // add empty value
        listDate.unshift('');
        return listDate;
      }),
    );
  }

  public get compareDate() {
    return this._compareDate;
  }

  public set compareDate(newDate: string) {
    if (this._compareDate !== newDate && this._comparaisonEnabled) {
      const bilanResults = LocalStorageEx.bilanResults.find((bilanResult) => {
        return this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss') === newDate;
      });
      this.bilanResultSelected$.next(bilanResults);
    }
    this._compareDate = newDate;
  }
}
