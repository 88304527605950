import { Injectable } from '@angular/core';
import { ServiceDal } from '../service-dal/service-dal';
import { Grip } from '../model/grip.model';
import { Bilan as DalBilan } from '../service-dal/model/bilan';
import { Grip as DalGrip } from '../service-dal/model/grip';
import * as GripTransform from '../transformations/grip.transform';

@Injectable()
/**
 * this service manages grip acquisition, including the transformation of data to/from the DAL model.
 */
export class GripDataService {

    constructor(private serviceDal: ServiceDal) { }

    public async createGripAcquisition(grip: DalGrip): Promise<void> {
        const gripApi = this.serviceDal.gripService;
        await gripApi.gripCreate(grip).toPromise();
    }

    public async getGripAcquisitions(patientId: number): Promise<Grip[]> {
      const patientApi = this.serviceDal.patientService;
      const bilans = await patientApi.patientPrototypeGetGripBilans(patientId.toString()).toPromise();

      return GripTransform.DalGripsToGrips(bilans);
    }

    public async getGripAcquisitionByBilanId(bilanId: number): Promise<Grip> {
        const gripApi = this.serviceDal.gripService;

        const filterId = `{ "where": {"BilanId": ${bilanId} } }`;
        const dalGrip = await gripApi.gripFindOne(filterId).toPromise();
        const grip = GripTransform.DalGripToGrip(dalGrip);

        return grip;
    }
}
