import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbService } from './services/breadcrumb.service';
import { BreadcrumbServiceFactory } from './services/breadcrumb-factory.service';
import { BreadcrumbComponent } from './views/breadcrumb.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
    ],
    providers: [
        BreadcrumbServiceFactory,
    ],
    declarations: [BreadcrumbComponent],
    exports: [
        BreadcrumbComponent,
    ],
})
export class BreadcrumbModule { }
