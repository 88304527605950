import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/modules/core';

import { CheckupComponent } from './views/main/checkup.component';
import { CheckupSelectorComponent } from './views/selector/checkup-selector.component';
import { IndicatorComponent } from './views/indicator/indicator.component';
import { Roles } from '../core/roles.enum';

const checkupRoutes: Routes = [
  {
    path: 'checkup',
    component: CheckupComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CheckupSelectorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'grip',
        loadChildren: './modules/grip/grip.module#GripModule',
      },
      {
        path: 'walk',
        loadChildren: './modules/walk/walk.module#WalkModule',
      },
      {
        path: 'weight',
        loadChildren: './modules/weight/weight.module#WeightModule',
      },
      {
        path: 'romberg',
        loadChildren: './modules/romberg/romberg.module#RombergModule',
      },
      {
        path: 'indicator',
        component: IndicatorComponent,
        canActivate: [AuthGuard],
        data: {
          role: Roles.accessResults,
        },
      },
      {
        path: 'indicator/:patientId/:consultId',
        component: IndicatorComponent,
        canActivate: [AuthGuard],
        data: {
          role: Roles.accessResults,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(checkupRoutes)],
  exports: [RouterModule],
})
export class CheckupRoutingModule {}
