<div class="page-title">{{ 'Nouvelle acquisition' | translate }}</div>

<ng-template #firstAcq> <first-acquisition-banner (ready)="switch($event)"> </first-acquisition-banner> </ng-template>

<div *ngIf="!firstAcquisition; else firstAcq">
  <current-patient-banner [consultationStep]="0"> </current-patient-banner>

  <div class="row">
    <div class="col-lg-12">
      <div *ngIf="diagnostics != null">
        <div class="diagnostic_container">
          <div class="page-sub-title">{{ 'Liste des syndromes' | translate }}</div>

          <div *ngFor="let diagnostic of diagnostics; let i = index" class="diagnostic">
            <div class="row">
              <div class="col-lg-6">
                <div *ngFor="let answer of diagnostic.answerColumn1; let i = index">
                  <p>
                    {{ answer.question }}: <span> {{ answer.answer.question }}</span>
                    <span *ngIf="answer.option"> - {{ answer.option.value }}</span>
                  </p>
                </div>
              </div>

              <div class="col-lg-5">
                <p>
                  <span class="date">{{ 'Date création' | translate }} : {{ diagnostic.created | localdate }}</span>
                  <span class="date"
                    >{{ 'Dernière modification' | translate }} : {{ diagnostic.modified | localdate }}</span
                  >
                </p>
                <div *ngFor="let answer of diagnostic.answerColumn2; let i = index">
                  <p>
                    {{ answer.question }}: <span *ngIf="answer.answer"> {{ answer.answer.question }}</span>
                    <span *ngIf="answer.option"> - {{ answer.option.value }}</span>
                  </p>
                </div>
              </div>

              <div class="col-lg-1" *ngIf="rolesCheck.hasEditSyndrom | async">
                <div class="edit_row">
                  <img
                    src="assets/images/icon_edit.svg"
                    (click)="updateDiagnosticStatus(); edit(i)"
                    [routerLink]="['/diagnostic']"
                    [routerLinkActive]="['router-link-active']"
                    [routerLinkActiveOptions]="{ exact: true }"
                  />
                </div>
                <div class="delete_row">
                  <img src="assets/images/icon_delete.svg" (click)="selectDiagnostic(i); patientmodal.show(patient)" />

                  <patient-modal #patientmodal>
                    <div class="app-modal-header">
                      <label>{{ 'Suppression' | translate }}</label>
                    </div>
                    <div class="app-modal-body">{{ 'EraseDiag' | translate }}</div>
                    <div class="app-modal-footer">
                      <button type="button" class="btn btn-default" (click)="delete($event); patientmodal.hide()">
                        {{ 'Oui' | translate }}
                      </button>
                      <button type="button" class="btn btn-primary" (click)="patientmodal.hide()">
                        {{ 'Non' | translate }}
                      </button>
                    </div>
                  </patient-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="page-footer buttons-component">
        <button
          class="btn btn-primary btn-left"
          [routerLink]="['/PatientConsult']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'Précédent' | translate }}
        </button>
        <button
          *ngIf="rolesCheck.hasEditSyndrom | async"
          class="btn btn-primary"
          [routerLink]="['/diagnostic']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ 'newdiagnostic' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [routerLink]="['/FormsSelector']"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [disabled]="disabledButton"
        >
          {{ 'PasserQuestionnaires' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
