import { autoserialize, autoserializeAs } from 'cerialize';

import { StringMap } from 'app/tools/serialize-string-map';

import { Answer } from './answer.model';

/**
 * Object representing a set of answer
 */
export class AnswerSet {
    @autoserialize
    public set_id: string; // id of the set (same as the set question's id)
    @autoserializeAs(StringMap(Answer))
    public answers: Map<string, Answer>; // answers : the map is usful for the case
    // when the user changes the answer. (no duplication of answers)
}
