import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/modules/core/core.module';
import { BreadcrumbModule } from 'app/modules/breadcrumb/index';
import { TimeModule } from 'app/modules/time/time.module';
import { SmartFlatDataAccessModule } from 'app/modules/smartflat-data-access';
import { ConsultationModule } from 'app/modules/consultation';
import { RouterModule, Router } from '@angular/router';

import { OptiontdComponent } from './optiontd/optiontd.component';
import { PatientModalComponent } from './patient-modal/patient-modal.component';
import { CheckupTitleService } from 'app/modules/checkup/services/checkup-title.service';
import { ChoixImpressionComponent } from 'app/modules/rapports/choixImpression/choixImpression.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    CoreModule,
    BreadcrumbModule,
    TimeModule,
    ConsultationModule,
    SmartFlatDataAccessModule,
    RouterModule,
  ],
  declarations: [
    OptiontdComponent,
    ChoixImpressionComponent,
    PatientModalComponent,
  ],
  exports: [],
  providers: [CheckupTitleService],
})
export class ChoixImpressionModule {}
