<div class="patientsTable">
  <div class="row">
    <div class="col-lg-12">
      <patient-sub-header-result
        [selectedPatient]="selectedPatient"
        [patientSource]="patientSource"
        (search)="onSearchPatient($event)"
      >
      </patient-sub-header-result>

      <div class="row">
        <div class="patients col-lg-8 no-padding-right">
          <div class="viewcontent">
            <ng2-smart-table
              [settings]="patientSettings"
              [source]="patientSource"
              (rowSelect)="rowSelect($event)"
            ></ng2-smart-table>
            <patient-modal #patientmodal>
              <div class="app-modal-header">
                <label>{{ patientmodal.headerLabel | translate }}</label>
              </div>
              <div class="app-modal-body">
                {{ patientmodal.bodyText | translate }}
              </div>
              <div class="app-modal-footer">
                <button type="button" class="btn btn-primary" (click)="patientmodal.hide()">
                  {{ patientmodal.footerCancelText | translate }}
                </button>
              </div>
            </patient-modal>
          </div>
        </div>
        <patient-sub-results [selectedPatient]="selectedPatient"> </patient-sub-results>
      </div>
    </div>
  </div>
</div>
