import { IConsultationSaving } from 'app/services/tempSaving/consultationSaving.service';
import { Injectable } from '@angular/core';
import {
  Consultation,
  ConsultationDataService,
  Answer,
  HealthAnswer,
  DiagnosticDataService,
  QuestionnaryAnswer,
  QuestionnaryDataService,
  QuestionnaryAnswerQuestion,
  BilanDataService,
  Bilan,
  BilanType,
} from 'app/modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { QuestionnarySavingImp } from 'app/services/tempSaving/impl/questionnarySavingImp.service';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';
import { CheckupSavingImp } from 'app/services/tempSaving/impl/checkupSavingImp.service';
import { GripMeasure } from 'app/modules/checkup/modules/grip';

@Injectable()
export class ConsultationSavingImp implements IConsultationSaving {
  private consultation: Consultation;

  public constructor(
    private serviceBD: ConsultationDataService,
    private serviceDiagDB: DiagnosticDataService,
    private serviceQuestionnaryDB: QuestionnaryDataService,
    private questionnaryLocalService: QuestionnarySavingImp,
    private diagnosticLocalService: DiagnosticSavingImp,
    private checkupLocalService: CheckupSavingImp,
    private serviceBilanDB: BilanDataService,
  ) {}

  public async saveConsultation(newConsultation: Consultation): Promise<Consultation> {
    console.log('ConsultationSavingImp: saving consultation: ', newConsultation);
    const savedConsultation = await this.serviceBD.createConsultation(newConsultation);

    console.debug('ConsultationSavingImp: about to save diagnostics');
    try {
      await this.saveDiagnostics(savedConsultation.id);
      console.debug('ConsultationSavingImp: about to save questionnaries');
      await this.saveQuestionnaries(savedConsultation.id);
      console.debug('ConsultationSavingImp: about to save checkups');
      await this.saveCheckups(savedConsultation.id, newConsultation.date);
      await this.cleanLocalConsultation();
      try {
        await this.serviceBD.updateFragility(savedConsultation.id);
      } catch (errCalculation) {
        console.error('Fail to update fragility', errCalculation);
      }
      return this.serviceBD.getConsultationById(savedConsultation.id);
    } catch (error) {
      console.error('Save Consulation', error);
    }
  }

  public async cleanLocalConsultation(): Promise<void> {
    this.diagnosticLocalService.cleanMemory();
    this.questionnaryLocalService.cleanMemory();
    this.checkupLocalService.cleanMemory();
    LocalStorageEx.removeObject(LocalStorageObject.ConsultationInProgress);
    LocalStorageEx.removeObject(LocalStorageObject.Height);
    LocalStorageEx.removeObject(LocalStorageObject.Weight);
    LocalStorageEx.removeObject(LocalStorageObject.MeasuredWeight);
    LocalStorageEx.removeObject(LocalStorageObject.MeasurementWeight);
    LocalStorageEx.removeObject(LocalStorageObject.currentWalkAssessmentCheckup);
    LocalStorageEx.removeObject(LocalStorageObject.currentWalkPreambleCheckup);
    LocalStorageEx.removeObject(LocalStorageObject.currentWalkRecordingSession);
    LocalStorageEx.removeObject(LocalStorageObject.currentRombergAssessmentCheckup);
    LocalStorageEx.removeObject(LocalStorageObject.currentRombergPreambleCheckup);
    LocalStorageEx.removeObject(LocalStorageObject.currentRombergRecordingSession);
    LocalStorageEx.removeObject(LocalStorageObject.weightResultBilan);
    LocalStorageEx.removeObject(LocalStorageObject.currentRombergBilanStatus);
    LocalStorageEx.removeObject(LocalStorageObject.currentWalkBilanStatus);
    LocalStorageEx.removeObject(LocalStorageObject.currentGripBilanStatus);
    LocalStorageEx.removeObject(LocalStorageObject.currentWeightBilanStatus);
    LocalStorageEx.removeObject(LocalStorageObject.histoGripCheckup);
    LocalStorageEx.removeObject(LocalStorageObject.histoRombergRecordingSession);
    LocalStorageEx.removeObject(LocalStorageObject.histoWalkRecordingSession);
    LocalStorageEx.removeObject(LocalStorageObject.histoWeightResultBilan);
    LocalStorageEx.removeObject(LocalStorageObject.currentGripCheckup);
  }

  private async saveDiagnostics(consultationId: number): Promise<void> {
    if (LocalStorageEx.checkObject(LocalStorageObject.NewDiagnostics)) {
      const allNewDiag = LocalStorageEx.restoreArray(LocalStorageObject.NewDiagnostics, HealthAnswer);
      await Promise.all(
        allNewDiag.map(async (element) => {
          element.consultationId = consultationId;
          return this.serviceDiagDB.postDiagnosticForm(element).toPromise();
        }),
      );
    }
  }

  private async saveQuestionnaries(consultationId: number): Promise<void> {
    if (LocalStorageEx.checkObject(LocalStorageObject.Questionnaries)) {
      const questionnariesList = await this.questionnaryLocalService.getQuestionnaries();
      if (questionnariesList.length > 0) {
        const bilanQuestionnaries: Bilan = await this.serviceBilanDB.creationBilan(
          consultationId,
          BilanType.Questionnary,
        );
        for (const idQ of questionnariesList) {
          await this.pushQuestion(idQ, bilanQuestionnaries);
        }
      }
    }
  }

  private async pushQuestion(idQ: number, bilanQuestionnaries: Bilan): Promise<void> {
    const newQuestionnary: QuestionnaryAnswer = new QuestionnaryAnswer();
    // FIXME Use french questionnary for score.
    const form = await this.serviceQuestionnaryDB.getForm(idQ, 'fr');
    newQuestionnary.Type = idQ;
    newQuestionnary.BilanId = bilanQuestionnaries.Id;
    newQuestionnary.score = await this.questionnaryLocalService.getScore(form);
    const questionnaire = await this.serviceQuestionnaryDB.creationQuestionnaire(newQuestionnary);
    const answersTable: QuestionnaryAnswerQuestion[] = await this.questionnaryLocalService.getQuestions(idQ);
    answersTable.forEach(async (element) => {
      element.QuestionnaireId = questionnaire.Id;
      const res = await this.serviceQuestionnaryDB.createQuestion(element);
    });
  }

  private async saveCheckups(consultationId: number, date: Date): Promise<void> {
    if (LocalStorageEx.currentGripBilanStatus) {
      const bilan = await this.serviceBilanDB.creationBilan(consultationId, BilanType.CheckupGrip);
      const currentCheckup: GripMeasure = LocalStorageEx.currentGripCheckup;
      await this.checkupLocalService.saveGripCheckup(currentCheckup, bilan.Id, date);
    }
    if (LocalStorageEx.currentWalkBilanStatus) {
      const bilan: Bilan = await this.serviceBilanDB.creationBilan(consultationId, BilanType.CheckupWalk);
      await this.checkupLocalService.saveWalkCheckup(bilan.Id, date);
    }
    if (LocalStorageEx.currentRombergBilanStatus) {
      const bilan: Bilan = await this.serviceBilanDB.creationBilan(consultationId, BilanType.CheckupRomberg);
      await this.checkupLocalService.saveRombergCheckup(bilan.Id, date);
    }
    if (LocalStorageEx.weightResultBilan) {
      const bilan: Bilan = await this.serviceBilanDB.creationBilan(consultationId, BilanType.CheckupWeight);
    }
  }
}
