import { TorCalendarContent } from './torcalendarcontent.model';

export class TorCalendar {
    public id: string;
    public spaceId: number;
    public lastSuccesfullTorScriptLaunch: Date;
    public calendarContent: TorCalendarContent[];

    constructor() {
        this.calendarContent = new Array<TorCalendarContent>();
    }
}
