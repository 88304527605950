/**
 * Upper case a string.
 *
 * @param  {String} str
 * @return {String}
 */
export function upperCaseFirst(str: string): string {
  str = str === null ? '' : String(str);

  str = str.charAt(0).toLocaleUpperCase() + str.slice(1);

  const splitIdentifiers = new RegExp(/[\u0020\.\;\,\(\)\[\]\/\'\"\`\?\!\|\{\}\\\_\~\^\%\&\#\$\*\+\=\-]/g);

  if (splitIdentifiers.test(str)) {
    const spliters = str.match(splitIdentifiers);

    spliters.forEach((spliter, i) => {
      const split = str.split(spliter);

      const capitalized = split.map((elem) => {
        return elem.charAt(0).toUpperCase() + elem.slice(1);
      });
      str = capitalized.join(spliter);
    });
  }
  return str;
}
