import { QuestionnaryAnswerQuestion } from '../model/questionnary-answer-question.model';

import { QuestionnaryAnswer } from '../model/questionnary-answer.model';

import { Questionnaire } from '../service-dal/model/questionnaire';
import { Question } from '../service-dal/model/question';
import { LastQuestionnaireForPatient } from '../service-dal/model/lastQuestionnaireForPatient';

import { LastQuestionnary } from 'app/modules/smartflat-data-access/src/model/last-questionnary.model';

export function questionnaryToDalQuestionnary(questionnary: QuestionnaryAnswer): Questionnaire {
  const result: Questionnaire = {
    Id: questionnary.Id,
    Type: questionnary.Type,
    BilanId: questionnary.BilanId,
    score: questionnary.score,
  };
  return result;
}

export function dalQuestionnaryToQuestionnary(dalQuestionnaire: Questionnaire): QuestionnaryAnswer {
  const result: QuestionnaryAnswer = new QuestionnaryAnswer();
  result.Type = dalQuestionnaire.Type;
  result.BilanId = dalQuestionnaire.BilanId;
  result.Id = dalQuestionnaire.Id;
  result.score = dalQuestionnaire.score;

  // TODO something better ? about Answers
  return result;
}

export function questionToDalQuestion(question: QuestionnaryAnswerQuestion): Question {
  const result: Question = {
    Id: question.Id,
    QuestionNumber: question.QuestionNumber,
    QuestionAnswer: question.QuestionAnswer,
    QuestionnaireId: question.QuestionnaireId,
    AnswerNumber: question.AnswerNumber,
  };
  return result;
}

export function dalQuestionToQueston(question: Question): QuestionnaryAnswerQuestion {
  const result: QuestionnaryAnswerQuestion = {
    Id: question.Id,
    QuestionAnswer: question.QuestionAnswer,
    QuestionnaireId: question.QuestionnaireId,
    QuestionNumber: question.QuestionNumber,
    AnswerNumber: question.AnswerNumber,
  };

  return result;
}

export function dalLastQuestionnaryToLastQuestionnary(
  questionnaires: LastQuestionnaireForPatient[],
): Map<number, LastQuestionnary> {
  const map = new Map<number, LastQuestionnary>();
  for (const questionnaire of questionnaires) {
    const lastQuestionnary: LastQuestionnary = new LastQuestionnary();
    lastQuestionnary.BilanId = questionnaire.questionnaire.BilanId;
    lastQuestionnary.Id = questionnaire.questionnaire.Id;
    lastQuestionnary.Type = questionnaire.questionnaire.Type;
    lastQuestionnary.score = questionnaire.questionnaire.score;
    lastQuestionnary.date = questionnaire.executionDate;
    map.set(lastQuestionnary.Type, lastQuestionnary);
  }
  return map;
}
