import { Component, OnInit, Input } from '@angular/core';
import { TorTable } from '../../../models/tortable.model';

@Component({
  selector: 'tor-bilan-table',
  templateUrl: './tor-bilan-table.component.html',
})
export class TorBilanTableComponent implements OnInit {
  @Input()
  public torTable: TorTable;

  constructor() {
    // empty
  }

  public ngOnInit(): void {
    // empty
  }
}
