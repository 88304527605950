export interface EstablishmentConfig {
  boxes: boolean;
  cpp: boolean;
  disabled: boolean;
  minimumAge?: number;
}

/**
 * Object representing the establishment model
 */
export class Establishment {
  public Id?: number;
  public Name: string;
  public Logo?: string;
  public Address?: string;
  public Config: EstablishmentConfig;
}
