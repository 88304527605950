<div class="row">
  <div class="col-lg-12">
    <div>{{ 'choix Impression' | translate }}</div>

    <div class="viewcontent">
      <!-- TODO replace by standart modal-->
      <patient-modal #patientmodal>
        <div class="app-modal-header">
          <label>{{ 'choix Impression' | translate }}</label>
        </div>
        <div class="app-modal-body">
          {{ 'TextPopupConsultRapportDocteurEnCours' | translate }}
        </div>
        <div class="app-modal-footer">
          <button type="button" class="btn btn-primary" (click)="patientModal.hide(); returnToPatientList()">
            {{ 'Oui' | translate }}
          </button>
          <button type="button" class="btn btn-default" (click)="patientModal.hide()">{{ 'Non' | translate }}</button>
        </div>
      </patient-modal>
    </div>

    <p class="page-sub-title checkup_title">{{ 'ChoixImpression.selection' | translate }}</p>
    <div class="checkup_item" *ngFor="let option of checkupOptions">
      <checkup-option [option]="option"></checkup-option>
    </div>
  </div>
</div>
