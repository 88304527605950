/**
 * Object representing the rapport model
 */
export class Rapport {
  public Id?: number;
  public Type?: RapportType;
  public ConsultationId: BilanType;
}

export enum BilanType {
  Questionnary = 0,
  CheckupGrip = 1,
  CheckupWeight = 2,
  CheckupWalk = 3,
  CheckupRomberg = 4,
}

export enum RapportType {
  Rapport_Patient = 0,
  Rapport_Docteur = 1,
}
