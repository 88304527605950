import { Pipe, PipeTransform } from '@angular/core';
import { TorValue, TorIndicator } from 'app/modules/tor';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'tablecelldisplay' })
export class TableCellDisplay implements PipeTransform {
  private length: number = 14;

  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: any): string {
    const output = '' + value;
    if (output.length < this.length) {
      return value;
    }
    return output.slice(0, this.length) + '...';
  }

  // If needed => back to a new line and display all the value on several lines
  private addSpace(value: string): string {
    let spacesNumber = value.length / this.length;
    spacesNumber = Math.floor(spacesNumber);
    for (let i = 1; i <= spacesNumber; i++) {
      value = [value.slice(0, i * this.length), ' ', value.slice(i * this.length)].join('');
    }
    return value;
  }
}
