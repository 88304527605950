<!--Date bilan -->
<div class="consultations no-padding col-lg-1" style="padding-right: 40px !important;">
  <div class="col-lg-3" style="float: right;">
    <div *ngIf="this.selectedPatient" class="viewcontent" style="width: 250px !important;">
      <table>
        <tr>
          <td>
            <label>{{ 'Patient.DebutPeriod' | translate }}:</label>
            <my-date-picker
              [ngModel]="startDate"
              [options]="startDateOptions"
              (ngModelChange)="onStartDateChanged($event)"
            ></my-date-picker>
          </td>
        </tr>

        <tr>
          <td>
            <label>{{ 'Patient.finPeriod' | translate }}:</label>
            <my-date-picker
              [ngModel]="endDate"
              [options]="endDateOptions"
              (ngModelChange)="onEndDateChanged($event)"
            ></my-date-picker>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ 'Patient.Bilan' | translate }}:</label>
            <select
              (ngModelChange)="onBilanTypeChange($event)"
              [ngModel]="_staticBilan"
              style="width:160px !important; "
            >
              <option *ngFor="let bilan of bilanOptions; let i = index" [ngValue]="i">{{ bilan }}</option>
            </select>
          </td>
        </tr>

        <tr *ngIf="questionOptions.length > 0">
          <td>
            <label>{{ 'Patient.Questions' | translate }}:</label>
            <select
              (ngModelChange)="onQuestionOptionChange($event)"
              style="width:100px !important; "
              [ngModel]="_staticQuestion"
            >
              <option *ngFor="let question of questionOptions" [ngValue]="question.id">{{ question.nom }}</option>
            </select>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="bilanDate" class="viewcontent" style="width: 250px !important;">
      <ng2-smart-table
        [settings]="consultationSettings"
        [source]="consultationSource"
        (rowSelect)="rowSelect($event)"
      ></ng2-smart-table>
    </div>
  </div>
</div>
<!-- Type bilan -->
<div class="bilans col-lg-2" style="float: right;margin-right: 0px !important;">
  <div *ngIf="showBilanList" class="viewcontent">
    <ng2-smart-table
      [settings]="bilanSettings"
      [source]="bilanSource"
      (userRowSelect)="onSelectBilan($event)"
      (delete)="onDeleteBilan($event)"
    ></ng2-smart-table>
  </div>
</div>
