import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, of, from as fromPromise } from 'rxjs';
import { flatMap, publishReplay, refCount, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  Questionnary,
  QuestionnaryAnswer,
  QuestionnaryAnswers,
  BilanDataService,
} from 'app/modules/smartflat-data-access';
import { QuestionnaryDataService, QuestionnaryAnswerQuestion } from 'app/modules/smartflat-data-access';
import { BilanDetailed } from 'app/model/bilan-detailed.model';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { LocalDatePipe } from '../../modules/time/pipes/localdate.pipe';

@Component({
  selector: 'app-checkup-questionnary',
  templateUrl: './checkup-questionnary.component.html',
})
export class CheckupQuestionnaryComponent implements OnInit {
  public bilanSelected: BilanDetailed;
  public ready: boolean = false;
  public name: string;

  public compareQuestionaryId = new BehaviorSubject<number>(null);
  public answerRoot$: Observable<QuestionnaryAnswer>;
  public answerComparedRoot$: Observable<QuestionnaryAnswer>;

  public currentQuestionnary: Questionnary;
  public preanswers: string[][] = [];
  public comparedPreanswers$: Observable<string[][]>;

  public comparisonEnabled: boolean;
  public currentDate: string;
  public dateSelected: boolean;
  public selectedDate: string;
  public consultationsDate: string[] = [];

  private maxIndex: number;
  private answers: QuestionnaryAnswerQuestion[];
  private comparedAnswers$: Observable<QuestionnaryAnswerQuestion[]>;

  private comparedData: QuestionnaryAnswers[];

  private consultationDate: string;

  /**
   * constructor : initialization of service to get questionnaries and language service to get current language
   */
  constructor(
    private serviceQuestion: QuestionnaryDataService,
    private languageService: TranslateService,
    private localDatePipe: LocalDatePipe,
    private bilanService: BilanDataService,
  ) {}

  /**
   * Init : get current bilan and form corresponding and patient's name
   */
  public async ngOnInit(): Promise<void> {
    LocalStorageEx.comparisonEnabled = false;

    LocalStorageEx.selectedBilanResult = null;
    this.bilanSelected = LocalStorageEx.currentBilan;
    if (!LocalStorageEx.currentPatient) {
      LocalStorageEx.currentPatient = LocalStorageEx.currentPatientSelected;
    }
    this.currentQuestionnary = await this.serviceQuestion.getForm(
      this.bilanSelected.questionnaryType,
      this.languageService.currentLang,
    );
    this.name = LocalStorageEx.currentPatientSelected.firstName + ' ' + LocalStorageEx.currentPatientSelected.lastName;
    this.answerRoot$ = this.serviceQuestion.getQuestionnary(this.bilanSelected.QuestionnaryId);
    await this.getQuestions();

    this.dateSelected = false;
    this.currentDate = this.localDatePipe.parse(
      new Date(LocalStorageEx.currentConsultation.date),
      'dd/MM/yyyy HH:mm:ss',
    );

    const idQuestionnaries = [LocalStorageEx.currentBilan.questionnaryType];
    this.consultationsDate.push('');
    this.bilanService
      .getAssessments(LocalStorageEx.currentPatientSelected.patientId, 0, idQuestionnaries)
      .then((ListOfBilans) => {
        LocalStorageEx.bilanResults = ListOfBilans;
        ListOfBilans.forEach((bilanResult) => {
          this.consultationsDate.push(this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss'));
        });
      });

    this.answerComparedRoot$ = this.compareQuestionaryId.pipe(
      flatMap((id) => (id === null ? of(null) : this.serviceQuestion.getQuestionnary(id))),
      publishReplay(1),
      refCount(),
    );
    this.comparedAnswers$ = this.compareQuestionaryId.pipe(
      flatMap((id) => (id === null ? of([]) : fromPromise(this.serviceQuestion.getQuestions(id)))),
      publishReplay(1),
      refCount(),
    );
    this.comparedPreanswers$ = this.comparedAnswers$.pipe(map((answers) => this.getComparedQuestions(answers)));
  }

  public answerAtPosition(position: number) {
    // TODO observable ??
    if (this.answers) {
      return this.answers.filter((answer) => answer.QuestionNumber === position);
    }
  }

  /**
   * in case of prefilled form : check if the answer has to be checked or not !
   * @param option content of answer
   * @param position position of question in form
   */
  public checkIfCheck(option: string, position: number, answerIndex: number): boolean {
    const responses = this.answerAtPosition(position);
    return (
      responses.findIndex((answer) => answer.QuestionAnswer === option) !== -1 ||
      responses.findIndex((answer) => answer.AnswerNumber === answerIndex) !== -1
    );
  }

  public checkIfcompareDataChecked(option: string, position: number, answerIndex: number): Observable<boolean> {
    const responses = this.comparedAnswerAtPosition(position);

    return responses.pipe(
      map(
        (resp) =>
          resp.findIndex((answer) => answer.QuestionAnswer === option) !== -1 ||
          resp.findIndex((answer) => answer.AnswerNumber === answerIndex) !== -1,
      ),
    );
  }

  public comparedAnswerAtPosition(position: number) {
    return this.comparedAnswers$.pipe(map((answers) => answers.filter((answer) => answer.QuestionNumber === position)));
  }

  /**
   * get answers for a questionnary.
   */
  private async getQuestions(): Promise<void> {
    this.answers = await this.serviceQuestion.getQuestions(this.bilanSelected.QuestionnaryId);

    this.answers.forEach((answer) => {
      if (!this.preanswers[answer.QuestionNumber]) {
        this.preanswers[answer.QuestionNumber] = [];
      }
      this.preanswers[answer.QuestionNumber].push(answer.QuestionAnswer);
      if (answer.QuestionNumber > this.maxIndex) {
        this.maxIndex = answer.QuestionNumber;
      }
    });
    this.maxIndex = 0;
    this.ready = true;
  }

  private getComparedQuestions(answers: QuestionnaryAnswerQuestion[]) {
    const preanswers: string[][] = [];
    answers.forEach((answer) => {
      if (!preanswers[answer.QuestionNumber]) {
        preanswers[answer.QuestionNumber] = [];
      }
      preanswers[answer.QuestionNumber].push(answer.QuestionAnswer);
    });
    return preanswers;
  }

  private async onComparisonDateChanged(e: any) {
    this.dateSelected = false;
    if (e.target.value.toString() !== '') {
      this.dateSelected = this.comparisonEnabled && true;

      LocalStorageEx.bilanResults.forEach((bilanResult) => {
        if (this.localDatePipe.parse(bilanResult.consultation.Date, 'dd/MM/yyyy HH:mm:ss') === e.target.value) {
          console.log('change   ');
          LocalStorageEx.selectedBilanResult = bilanResult;
          this.comparedData = bilanResult.questionnaires;
          // FIXME
          this.compareQuestionaryId.next((this.comparedData[0] as any).Id);
        }
      });
    } else {
      this.compareQuestionaryId.next(null);
      LocalStorageEx.removeObject(LocalStorageObject.selectedBilanResult);
    }
    this.consultationDate = e.target.value;
  }

  private enableComparison(item: any) {
    if (this.comparisonEnabled === true && this.consultationDate !== undefined && this.consultationDate !== '') {
      this.dateSelected = this.comparisonEnabled && true;
    }
    if (this.dateSelected && this.consultationDate !== '') {
      this.dateSelected = this.comparisonEnabled && true;
    }
    if (!this.comparisonEnabled) {
      this.compareQuestionaryId.next(null);
      this.dateSelected = false;
    }

    LocalStorageEx.comparisonEnabled = this.comparisonEnabled;
  }
}
