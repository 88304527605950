import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from as fromPromise } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { IQuestionnarySaving } from '../questionnarySaving.service';
import {
  QuestionnaryAnswerQuestion,
  Questionnary,
  QuestionnaryDataService,
} from '../../../modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../tools/localstorageobject.enum';
import { QuestionnaryQuestion } from '../../../modules/smartflat-data-access/src/model/questionnary-question.model';

@Injectable()
export class QuestionnarySavingImp implements IQuestionnarySaving {
  private nbQuestionnaries: Observable<number>;

  public constructor(serviceQuestionnaryDB: QuestionnaryDataService, languageService: TranslateService) {
    // get max id of questionnaires

    this.nbQuestionnaries = fromPromise(serviceQuestionnaryDB.getQuestionnaryList(languageService.currentLang)).pipe(
      map((list) => {
        const ids = list.map((ql) => {
          const idlist = ql.questionnaries.map((q) => q.id);
          return Math.max(...idlist);
        });
        return Math.max(...ids);
      }),
      shareReplay(1),
    );
  }

  public async getQuestionnaries() {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    const res: number[] = [];
    for (const i in contentLS) {
      if (contentLS[+i].select) {
        res.push(+i + 1);
        // i+1 because i starts at 0 and questionnaireID at 1
      }
    }
    return res;
  }

  public async getScore(form: Questionnary) {
    const answers = await this.getQuestions(form.id);
    const questions = new Map<number, QuestionnaryQuestion>();

    form.listeQuestions.forEach((qs) => qs.listeItems.forEach((qq) => questions.set(qq.position, qq)));

    let havescore = false;
    let score = form.initialScore || 0;
    answers.forEach((qa) => {
      const qq = questions.get(qa.QuestionNumber);
      if (qq.typeQuestion === 'inputNumber' && qq.scoreRatio) {
        havescore = true;
        const value = parseFloat(qa.QuestionAnswer);
        if (!isNaN(value)) {
          score += qq.scoreRatio * value;
        }
      } else if (
        qq &&
        qq.options[qa.AnswerNumber] &&
        qq.options[qa.AnswerNumber].score !== undefined &&
        qq.options[qa.AnswerNumber].score !== null
      ) {
        havescore = true;
        score += qq.options[qa.AnswerNumber].score;
      }
    });
    if (havescore) return score;
    return undefined;
  }

  public async getQuestions(idQuestionnary: number) {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    const res: QuestionnaryAnswerQuestion[] = contentLS[idQuestionnary - 1].questions;
    return res;
  }

  public async deleteQuestionnary(idQuestionnary: number) {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    contentLS[idQuestionnary - 1] = { select: false, questions: [] };
    LocalStorageEx.setObject(LocalStorageObject.Questionnaries, contentLS);
  }

  public async addQuestionnary(idQuestionnary: number) {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    contentLS[idQuestionnary - 1].select = true;
    LocalStorageEx.setObject(LocalStorageObject.Questionnaries, contentLS);
  }

  public async addQuestion(question: QuestionnaryAnswerQuestion, idQuestionnary: number) {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    let find = false;
    for (const questionIndex in contentLS[idQuestionnary - 1].questions) {
      // TODO remove in future test on QuestionAnswer
      if (
        (contentLS[idQuestionnary - 1].questions[+questionIndex].QuestionAnswer === question.QuestionAnswer ||
          contentLS[idQuestionnary - 1].questions[+questionIndex].AnswerNumber === question.AnswerNumber) &&
        contentLS[idQuestionnary - 1].questions[+questionIndex].QuestionNumber === question.QuestionNumber
      ) {
        find = true;
        break;
      }
    }
    if (!find) {
      contentLS[idQuestionnary - 1].questions.push(question);
    }
    LocalStorageEx.setObject(LocalStorageObject.Questionnaries, contentLS);
  }

  public async removeQuestion(question: QuestionnaryAnswerQuestion, idQuestionnary: number) {
    await this.checkInitializeLS();
    const contentLS = LocalStorageEx.getObject(LocalStorageObject.Questionnaries);
    for (const questionIndex in contentLS[idQuestionnary - 1].questions) {
      if (
        contentLS[idQuestionnary - 1].questions[+questionIndex].QuestionAnswer === question.QuestionAnswer &&
        contentLS[idQuestionnary - 1].questions[+questionIndex].QuestionNumber === question.QuestionNumber
      ) {
        contentLS[idQuestionnary - 1].questions.splice(+questionIndex, 1);
      }
    }
    LocalStorageEx.setObject(LocalStorageObject.Questionnaries, contentLS);
  }

  public cleanMemory() {
    LocalStorageEx.removeObject(LocalStorageObject.Questionnaries);
  }

  /**
   * Initialize local storage with a list of answer empty
   */
  private async initializeQuestionnaries() {
    await this.nbQuestionnaries
      .pipe(
        map((nb) => {
          const questionnaries =
            nb > 0 ? Array.from(Array(nb).keys()).map(() => ({ select: false, questions: [] })) : [];
          LocalStorageEx.setObject(LocalStorageObject.Questionnaries, questionnaries);
        }),
      )
      .toPromise();
  }

  /**
   * checks if the object is in DB, if it is not it initialize it
   */
  private async checkInitializeLS() {
    if (!LocalStorageEx.checkObject(LocalStorageObject.Questionnaries)) {
      await this.initializeQuestionnaries();
    }
  }
}
