import { Component, OnInit, ViewChildren, TemplateRef, QueryList } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ICheckupOption, Bilan, DiagnosticDataService } from '../../../smartflat-data-access';
import { Consultation } from '../../../smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../../tools/localstorageobject.enum';
import { ConsultationSavingImp } from '../../../../services/tempSaving/impl/consultationSavingImp.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import {
  NavigationActionsService,
  NavigationActionLink,
  NavigationActionClick,
  NotificationService,
} from '../../../core';

import { WeightAndHeightService } from '../../../consultation/services/weightAndHeight.service';
import { CheckupTitleService } from '../../services/checkup-title.service';
import { BilanDataService } from '../../../smartflat-data-access/src/api/bilan-data.service';
import { DiagnosticSavingImp } from 'app/services/tempSaving/impl/diagnosticSavingImp.service';
import { Roles } from '../../../core/roles.enum';
import { AuthService } from '../../../core/services/authentification/auth.service';
import { map, take } from 'rxjs/operators';
import { RolesCheckService } from 'app/modules/core/services/authentification/roles-check.service';
import { CheckupStateService } from '../../modules/checkup-common/services/checkup-state.service';

@Component({
  templateUrl: './checkup-selector.component.html',
})
export class CheckupSelectorComponent implements OnInit {
  public error: any;
  public checkupOptions: ICheckupOption[] = [
    { id: 0, type: 'bilan_locomotion', link: 'walk/connexion', passed: false, disabled: false },
    { id: 1, type: 'bilan_grip', link: 'grip/protocol', passed: false, disabled: false },
    { id: 2, type: 'bilan_romberg', link: 'romberg/installation', passed: false, disabled: false },
    { id: 3, type: 'bilan_pesee', link: 'weight/installation', passed: false, disabled: false },
  ];
  public errorMessage: string;

  public notSaving = new BehaviorSubject(true);

  constructor(
    public rolesCheck: RolesCheckService,
    private checkupTitleService: CheckupTitleService,
    private saveService: ConsultationSavingImp,
    private router: Router,
    private route: ActivatedRoute,
    private navigationActionService: NavigationActionsService,
    private weightAndHeightService: WeightAndHeightService,
    private bilanService: BilanDataService,
    private listServiceBD: DiagnosticDataService,
    private serviceSave: DiagnosticSavingImp,
    private authService: AuthService,
    private checkupStateService: CheckupStateService,
  ) { }

  public async ngOnInit() {
    this.checkupStateService.isInitialWeightMeasure = false;
    this.checkupTitleService.setCurrentTitle('Checkup.choice');
    LocalStorageEx.setObject(LocalStorageObject.CurrentPageInConsult, 'checkup');

    LocalStorageEx.cocheGrip = false;
    LocalStorageEx.cocheWalk = false;
    LocalStorageEx.cocheRomberg = false;
    LocalStorageEx.cocheWeight = false;

    // remove check mark in next screen
    // Ugly to put this here
    LocalStorageEx.currentRapportDocteur = false;
    LocalStorageEx.currentRapportPatient = false;

    this.checkupOptions.forEach((element) => {
      element.passed = false;

      switch (element.type) {
        case 'bilan_grip':
          element.disabled = false;
          if (LocalStorageEx.currentGripBilanStatus) {
            element.passed = true;
            LocalStorageEx.cocheGrip = true;
          }
          break;
        case 'bilan_locomotion':
          if (LocalStorageEx.currentWalkBilanStatus) {
            element.passed = true;
            LocalStorageEx.cocheWalk = true;
          }
          element.disabled = false;
          break;
        case 'bilan_romberg':
          if (LocalStorageEx.currentRombergBilanStatus) {
            LocalStorageEx.cocheRomberg = true;
            element.passed = true;
          }
          element.disabled = false;
          break;
        case 'bilan_pesee':
          if (LocalStorageEx.weightResultBilan) {
            // If bilan_pesee and walktest or static test then disable it!
            if (
              (LocalStorageEx.currentRombergAssessmentCheckup &&
                LocalStorageEx.currentRombergPreambleCheckup &&
                LocalStorageEx.currentRombergRecordingSession) ||
              (LocalStorageEx.currentWalkAssessmentCheckup &&
                LocalStorageEx.currentWalkPreambleCheckup &&
                LocalStorageEx.currentWalkRecordingSession)
            ) {
              element.disabled = true;
            }
          } else {
            element.disabled = false;
          }
          if (LocalStorageEx.currentWeightBilanStatus) {
            element.passed = true;
            LocalStorageEx.cocheWeight = true;
          }
          break;
      }
    });

    const action1 = new NavigationActionLink('Précédent', '/FormsSelector');
    const action2 = new NavigationActionClick('Terminer la consultation', () => this.createConsultation(), {
      active: this.notSaving,
      classes: this.notSaving.pipe(map((isNotSaving) => (!isNotSaving ? ['checkup_btn_loading'] : []))),
    });

    const actions = [action1, action2];
    this.navigationActionService.pushNavigationActions(actions);
  }

  private async createConsultation(): Promise<void> {
    // make user wait
    this.notSaving.next(false);

    if (LocalStorageEx.hiddenDiagnostics) {
      LocalStorageEx.hiddenDiagnostics.forEach((diagnostic) => {
        if (diagnostic.id_diagnostic) {
          this.listServiceBD.removeOneDiagnostic(diagnostic.id_diagnostic);
        } else {
          this.serviceSave.removeNewDiagnostic(diagnostic.date);
        }
      });
    }
    LocalStorageEx.removeObject(LocalStorageObject.hiddenDiagnostics);
    LocalStorageEx.removeObject(LocalStorageObject.savedDiagnostics);

    const newConsultation: Consultation = new Consultation();
    newConsultation.date = new Date();
    newConsultation.patientId = LocalStorageEx.currentPatient.patientId;
    // TODO
    newConsultation.Height = LocalStorageEx.Height;
    newConsultation.Weight = LocalStorageEx.Weight;
    newConsultation.MeasuredWeight = LocalStorageEx.MeasuredWeight;
    const heightCorrect: boolean = await this.weightAndHeightService.isHeightCorrect(newConsultation.Height);
    const weightCorrect: boolean = await this.weightAndHeightService.isWeightCorrect(newConsultation.Weight);

    if (heightCorrect && weightCorrect) {
      const savedConsult = await this.saveService.saveConsultation(newConsultation);
      LocalStorageEx.currentConsultRapport = savedConsult;

      const route = this.nextRoute(savedConsult);
      this.router.navigate(route, { relativeTo: this.route });
    }

    // finish
    this.notSaving.next(true);
  }

  /**
   * Select route depending of consultation and user role
   * @param savedConsult Currect saved consultation
   */
  private nextRoute(savedConsult: Consultation) {
    const roleAccessResult = this.authService.hasRoleInstant(Roles.accessResults);
    const roleReport =
      this.authService.hasRoleInstant(Roles.doctorReport) || this.authService.hasRoleInstant(Roles.patientReport);

    if (roleAccessResult && savedConsult && savedConsult.fragilityState) {
      return ['/checkup/indicator'];
    }

    if ((LocalStorageEx.cocheRomberg || LocalStorageEx.cocheWalk) && roleReport) {
      return ['/choixImpression'];
    }

    // default back to patien selection
    return ['/PatientConsult'];
  }

  /**
   *
   * @param idConsulation retrieve the bilan[]
   */
  private async getBilanType(idConsulation: number): Promise<Bilan[]> {
    console.log('méthode getBilanType');
    try {
      return this.bilanService.getBilans(idConsulation).then((bilans) => bilans);
    } catch (e) {
      console.error('error getBilanType', e);
    }
  }
}
