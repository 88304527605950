import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GripDataService } from './api/grip-data.service';
import { PatientDataService } from './api/patient-data.service';
import { DiagnosticDataService } from './api/diagnostic-data.service';
import { ConsultationDataService } from './api/consultation-data.service';
import { FootWearDataService } from './api/footwears-data.service';
import { QuestionnaryDataService } from './api/questionnary-data.service';
import { BilanDataService } from './api/bilan-data.service';
import { FlatDataService } from './api/flat-data.service';
import { TorDataService } from './api/tor.service';
import { HttpTempService } from './api/httptor.service';
import { HttpClientModule } from '@angular/common/http';
import { LocomotionDataService } from './api/locomotion-walk.service';
import { RombergDataService } from './api/romberg.service';
import { DoctorDataService } from './api/doctor-data.service';
import { ServiceDal } from './service-dal/service-dal';
import { EstablishmentDataService } from './api/establishment-data.service';
import { ExtractionService } from './api/extraction.service';
@NgModule({
  imports: [HttpClientModule, TranslateModule.forChild()],
  declarations: [],
  providers: [
    ServiceDal,
    BilanDataService,
    GripDataService,
    PatientDataService,
    DiagnosticDataService,
    ConsultationDataService,
    FootWearDataService,
    QuestionnaryDataService,
    FlatDataService,
    TorDataService,
    HttpTempService,
    LocomotionDataService,
    RombergDataService,
    DoctorDataService,
    EstablishmentDataService,
    ExtractionService,
  ],
  exports: [],
})
export class SmartFlatDataAccessModule {}
