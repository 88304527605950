import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TorBilanComponent } from '.';
import { TorRoutingModule } from './tor-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TimeModule } from '../time/time.module';
import { CoreModule } from '../core/core.module';
import { CheckupModule } from '../checkup';
import { ConsultationModule } from '../consultation';

import { TorWeekTableService } from './services/torweektable.service';
import { TorYearTableService } from './services/toryeartable.service';
import { TorMonthTableService } from './services/tormonthtable.service';
import { SmartFlatDataAccessModule } from '../smartflat-data-access';
import { TorService } from './services/tor.service';
import { TorBilanTableComponent, TorBilanHistoComponent } from '.';
import { ChartsModule } from 'ng2-charts';
import { MathModule } from '../math/math.module';
import { MyDatePickerModule } from 'mydatepicker';
import { FlatDataService } from '../smartflat-data-access/src/api/flat-data.service';

@NgModule({
  imports: [
    BrowserModule,
    TranslateModule.forChild(),
    TorRoutingModule,
    FormsModule,
    TimeModule,
    CoreModule,
    CheckupModule,
    ConsultationModule,
    SmartFlatDataAccessModule,
    ChartsModule,
    MathModule,
    MyDatePickerModule,
  ],
  declarations: [TorBilanComponent, TorBilanTableComponent, TorBilanHistoComponent],
  providers: [TorWeekTableService, TorYearTableService, TorMonthTableService, TorService, FlatDataService],
  exports: [TorBilanComponent],
})
export class TorModule {}
