import { catchError, map, switchMap, share, startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, interval } from 'rxjs';

import { EtatYeux } from 'app/modules/smartflat-data-access/src/model/romberg-test.model';
import { AppConfigurationService } from 'app/app-configuration.service';
import { NotificationService } from 'app/modules/core';
import { LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum';
import { WiiBoardState } from '../models/wiiboard-state.model';
import { InterceptorHttpParams } from '../../core/services/http/interceptor-http-params';

interface IGetWiiBoardState {
  GetWiiBoardStateResult: string;
}

@Injectable()
export class WiiBoardService {
  public readonly isConnected: Observable<boolean>;
  public readonly wiiState: Observable<WiiBoardState>;

  private windowsServiceUrl: string;
  private connected: string = '/CreateServiceBTWiiBoard';
  private getWeight: string = '/GetWeigth?duration=5';
  private forcelogouturl: string = '/Disconnect?force=99';
  private softLogouturl: string = '/Disconnect?force=1';
  private getFullState = '/GetState';
  private domesure: string = '/domesure?guid={guid}&date={date}&yeux={yeux}&duration={duration}';
  private pushPathMeasure: string = '/pushmesure?guid={guid}&date={date}&yeux={yeux}';
  private stopMesureSOS: string = '/stopMesure';
  private getState: string = '/GetWiiBoardState ';

  constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.windowsServiceUrl = this.appConfigurationService.forcePlatformBaseUri;

    this.isConnected = this.configureIsConnected();
    this.wiiState = this.configureWiiState();
  }

  public async wiiBoardConnect(): Promise<boolean> {
    const url: string = this.windowsServiceUrl + this.connected;
    try {
      const resp = await this.http.post(url, null).toPromise();
      console.log(resp);
      const body = resp as any;
      if (body.CreateServiceBTforWiiBoardResult === 'Erreur lors de la création du service Bluetooth.') {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async wiiBoardGetWeight(): Promise<number> {
    const url: string = this.windowsServiceUrl + this.getWeight;
    try {
      const resp = await this.http.get<any>(url).toPromise();
      console.log(resp);
      console.log(resp.GetWeigthResult.Weight);
      return resp.GetWeigthResult.weightKg;
    } catch (error) {
      console.log(error);
      return NaN;
    }
  }

  public async logout(): Promise<boolean> {
    const url: string = this.windowsServiceUrl + this.forcelogouturl;
    try {
      const resp = await this.http.post(url, null).toPromise();
      console.log(resp);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async softLogout(): Promise<boolean> {
    const url: string = this.windowsServiceUrl + this.softLogouturl;
    try {
      const resp = await this.http.post(url, null).toPromise();
      console.log(resp);
      return true;
    } catch (error) {
      return false;
    }
  }

  public doRecording(currentRecordingSessionId: string, date: string, yeux: EtatYeux, duration: number) {
    let url = this.windowsServiceUrl + this.domesure;
    url = url.replace('{guid}', currentRecordingSessionId);
    url = url.replace('{date}', date);
    url = url.replace('{yeux}', EtatYeux[yeux].toString());
    url = url.replace('{duration}', duration.toString());
    // Disable notification of error, handled in gui
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post<string>(url, null, { params });
  }

  public pushMeasure(currentRecordingSessionId: string, date: string, yeux: EtatYeux): Observable<any> {
    let url = this.windowsServiceUrl + this.pushPathMeasure;
    url = url.replace('{guid}', currentRecordingSessionId);
    url = url.replace('{date}', date);
    url = url.replace('{yeux}', EtatYeux[yeux].toString());
    console.log(this.constructor.name + ': Start saving: ', EtatYeux[yeux].toString(), 'date', date);
    // Disable notification of error, handled in gui
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post(url, null, { params });
  }

  public async stopRecordingSOS(): Promise<boolean> {
    console.log(this.constructor.name + ': stop recording static SOS: ');
    const url = this.windowsServiceUrl + this.stopMesureSOS;
    // Disable notification of error, handled in gui
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    try {
      const resp = await this.http.post(url, null, { params }).toPromise();
      console.log(resp);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Get state object. Do not throw if error.
   */
  public wiiBoardGetFullStatus(): Observable<WiiBoardState> {
    const url: string = this.windowsServiceUrl + this.getFullState;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http
      .get<WiiBoardState>(url, { params })
      .pipe(
        catchError(() =>
          of({
            acquisitionActive: false,
            nbPoints: 0,
            wiiBoardConnected: false,
            yeuxDone: [],
            niveauBatterie: null,
          } as WiiBoardState),
        ),
      );
  }

  public async wiiBoardGetStatus(): Promise<boolean> {
    const url: string = this.windowsServiceUrl + this.getState;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    try {
      const resp = await this.http
        .get<IGetWiiBoardState>(url, { params })
        .toPromise();
      console.log(resp);
      switch (resp.GetWiiBoardStateResult) {
        case 'WiiBoard connected':
          LocalStorageEx.setObject(LocalStorageObject.boardConnected, true);
          return true;
        case 'Bluetooth not enabled':
          LocalStorageEx.setObject(LocalStorageObject.boardConnected, false);
          this.notificationService.pushErrorNotifications(this.translateService.instant('wiiBoardBluetoothError'));
          return false;
        default:
          LocalStorageEx.setObject(LocalStorageObject.boardConnected, false);
          this.notificationService.pushErrorNotifications(this.translateService.instant('wiiBoardConnectError'));
          return false;
      }
    } catch (error) {
      console.log(error);
      this.notificationService.pushErrorNotifications(this.translateService.instant('Checkup.Walk.error.service'));
      return false;
    }
  }

  // TODO REFACTORING TBY
  public async wiiBoardGetStatusWithOutNotification(): Promise<boolean> {
    const url: string = this.windowsServiceUrl + this.getState;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    try {
      const resp = await this.http
        .get<IGetWiiBoardState>(url, { params })
        .toPromise();
      console.log(resp);
      switch (resp.GetWiiBoardStateResult) {
        case 'WiiBoard connected':
          return true;
        case 'Bluetooth not enabled':
          LocalStorageEx.setObject(LocalStorageObject.boardConnected, false);
          return false;
        default:
          LocalStorageEx.setObject(LocalStorageObject.boardConnected, false);
          return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  private configureIsConnected() {
    const url: string = this.windowsServiceUrl + this.getState;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return interval(2000).pipe(
      startWith(0),
      switchMap(() =>
        this.http
          .get<IGetWiiBoardState>(url, { params })
          .pipe(
            map((resp) => {
              switch (resp.GetWiiBoardStateResult) {
                case 'WiiBoard connected':
                  return true;
                case 'Bluetooth not enabled':
                  return false;
                default:
                  return false;
              }
            }),
            catchError(() => of(false)),
          ),
      ),
      share(),
    );
  }

  private configureWiiState() {
    const url: string = this.windowsServiceUrl + this.getFullState;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return interval(2000).pipe(
      startWith(0),
      switchMap(() =>
        this.http
          .get<WiiBoardState>(url, { params })
          .pipe(
            catchError(() =>
              of({
                acquisitionActive: false,
                nbPoints: 0,
                wiiBoardConnected: false,
                yeuxDone: [],
                niveauBatterie: null,
              }),
            ),
          ),
      ),
      share(),
    );
  }
}
