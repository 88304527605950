<div class="loginPage">
  <div class="row">
    <div class="col-lg-12">
      <form class="form-signin">
        <h1 class="login-title">
          <br />
          <!-- <img class="logo" src="assets/images/logo_smartflat.svg" alt="Smartflat"> -->
        </h1>

        <div class="alert alert-danger" *ngIf="errorMessage">
          <div>{{ errorMessage | translate }}</div>
        </div>

        <input
          *ngIf="!isImplicit"
          [(ngModel)]="login"
          type="text"
          class="form-control"
          placeholder="{{'Login.Username' | translate }}"
          name="login"
          required
          autofocus
        />

        <input
          *ngIf="!isImplicit"
          [(ngModel)]="password"
          type="password"
          class="form-control"
          name="password"
          placeholder="{{ 'Login.Password' | translate }}"
          required
        />

        <button *ngIf="!loading" (click)="authentication()" class="btn btn-lg btn-primary btn-block" type="submit">
          {{ 'Login.Connect' | translate }}
        </button>

        <div class="loading"><img *ngIf="loading" src="assets/images/loading.gif" /></div>
      </form>
    </div>
  </div>
</div>
