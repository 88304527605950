import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubOption } from '../../../model/sub-options';

@Component({
  selector: 'app-health-form-options',
  templateUrl: './health-form-options.component.html',
})

/**
 * this component representes the options of an answer in a diagnostic form.
 * It exchanges data with health-form-question component (it gets the options and sends the selected option)
 */
export class HealthFormOptionsComponent {
  @Input()
  public title: string;
  @Input()
  public options: SubOption;
  @Input()
  public name: string;
  @Input()
  public preSelectedItem: number;
  @Output()
  private value = new EventEmitter<number>();
  @Output()
  private close = new EventEmitter<any>();

  /**
   * sends instruction to close the popup
   */
  public hide() {
    this.close.emit();
  }

  /**
   * this funtion is called when the user chooses an option. It sends data to the health-form-question component.
   * @param val place of answer (0 for the first answer, 1 for the second...)
   */
  private setValueOption(val) {
    console.log(val);
    this.value.emit(val);
  }
}
