<div class="page-title">
  {{ 'Gestion patients' | translate }}
</div>

<div class="row">
  <div class="col-lg-9">
    <div class="page-sub-title searchBar">
      <patient-header
        [showValue]="false"
        value="{{ patientName }}"
        (search)="onSearchPatient($event)"
        [inputValue]="initSearch"
      >
      </patient-header>
    </div>
  </div>
</div>
