<div class="row">
  <div class="col-lg-12">
    <div id="long-list" class="popup">
      <div class="popup-header">
        <span class="liste">{{title}} </span>


        <a type="button" class="exit-button" (click)='hide()'>&times;</a>
      </div>
      <div class="popup-body">
        <div *ngFor="let rep of options; let i = index">
          <input type="radio" [checked]="rep.id===preSelectedItem" name={{name}} id={{rep.value}} class="radio" (click)="setValueOption(rep.id)"
          />
          <label for={{rep.value}}>
            <span class="radio">{{rep.value}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
