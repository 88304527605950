export class Utilities {
  /**
   * deepCopy
   * @param o: any
   */
  public static deepCopy(o) {
    let copy = o;
    if (o && typeof o === 'object') {
      copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {};
      for (const k in o) {
        if (o.hasOwnProperty(k)) {
          copy[k] = this.deepCopy(o[k]);
        }
      }
    }

    return copy;
  }
}
