import { Bilan as BilanDal } from '../service-dal/model/bilan';
import { Bilan } from '../model/bilan.model';

/**
 * This function convert a Bilan (checkup) from dataBase type
 * to bilan type for web-ui
 * @param dalBilan bilan in dataBase type
 */
export function DalBilanToBilan(dalBilan: BilanDal): Bilan {
    const result: Bilan = new Bilan();
    result.Id = dalBilan.Id;
    result.ConsultationId = dalBilan.ConsultationId;
    result.Type = dalBilan.Type;

    return result;
}
