import { Component, HostListener, OnInit, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from 'app/tools/divers.const';
import { VersionService } from 'app/modules/core/index';
import { Idle, IdleExpiry, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { LocalStorageEx, LocalStorageObject } from 'app/tools/localstorageobject.enum';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent } from 'app/modules/common';
import { AuthService } from 'app/modules/core/services/authentification/auth.service';
import { ICheckupOption } from './modules/smartflat-data-access';
import { LoggerService } from './services/logger/logger.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChildren(ModalComponent)
  public modals: QueryList<ModalComponent>;

  public version = '1.0.0';
  private idleSeconds: number = 3600;
  private idleWatched: boolean = false;

  private readonly redirectUrlAfterFirstLogin: string = '/Patient';
  constructor(
    private logger: LoggerService,
    private idle: Idle,
    private translate: TranslateService,
    private versionService: VersionService,
    private router: Router,
    private authService: AuthService,
  ) {
    authService.init().subscribe(() => {
      // auth init done

      // if login fail redirect to login
      authService.isConnected.subscribe((isLogged) => {
        if (!isLogged) {
          this.router.navigate(['/login']);
        }
      });
      // only redirect for first login.
      authService.isConnected
        .pipe(
          filter((isLogged) => isLogged),
          first(),
        )
        .subscribe(() => {
          if (this.authService.stateUrl) {
            this.router.navigate([this.authService.stateUrl]);
          } else {
            const wasUrl = this.router.url;
            // only redirect from default route (need improvement)
            if (wasUrl === '/' || wasUrl === '/login' || wasUrl === '') {
              this.router.navigate([this.redirectUrlAfterFirstLogin]);
              this.logger.info(` redirectUrlAfterFirstLogin=${this.redirectUrlAfterFirstLogin} was ${wasUrl}`);
            }
          }
        });
    });

    this.version = versionService.getAppVersion();
    translate.addLangs(LANGUAGES);
    translate.setDefaultLang(LANGUAGES[2]);
    translate.use(translate.getBrowserLang());
    this.idleWatch(this.idleSeconds);
  }

  public idleWatch(idleSeconds: number) {
    if (this.idleWatched === true) return;
    this.idle.setIdle(5);
    this.idle.setTimeout(idleSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.logger.warn('Idle timeouted !');
      this.authService.logOut();
      this.idle.watch();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.logger.info('Idle begins at ' + new Date().toISOString());
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.logger.info('Idle ends at ' + new Date().toISOString());
    });
    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      if (countdown < 5) {
        this.logger.warn('Idle counts down: ' + countdown + ' seconds');
      }
    });

    this.idle.watch();
    this.idleWatched = true;
  }

  public get name(): string {
    // TODO rework to get more specific data.
    const claims: any = this.authService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.name;
  }

  public logOut() {
    localStorage.clear();
    this.authService.logOut();
    this.router.navigate(['/login']);
  }

  public cancelLogOut() {
    this.authService.cancelLogOut();
  }

  public ngOnInit(): void {
    this.authService.logoutDisplayModal.subscribe((value) => {
      if (value && this.modals) {
        this.modals.first.show();
      } else if (!value && this.modals) {
        this.modals.first.hide();
      }
    });
    this.authService.loginErrorDisplayModal.subscribe((value) => {
      if (value && this.modals) {
        this.modals.filter((e) => e.name === 'disabledEstablishmentModal').forEach((e) => e.show());
      } else if (!value && this.modals) {
        this.modals.filter((e) => e.name === 'disabledEstablishmentModal').forEach((e) => e.hide());
      }
    });

    this.authService.appVersionDisplayModal.subscribe((value) => {
      if (value && this.modals) {
        this.showVersionModal();
      }
    });
  }

  public showCheckupModal(option: string) {
    this.modals.filter((e) => e.name === option.replace('_', '') + 'Modal').forEach((e) => e.show());
  }

  public doBilan(option: string) {
    switch (option) {
      case 'bilan_grip':
        this.router.navigate(['/checkup/grip/protocol']);
        this.modals.filter((e) => e.name === 'bilangripModal').forEach((e) => e.hide());
        LocalStorageEx.removeObject(LocalStorageObject.currentGripBilanStatus);
        LocalStorageEx.removeObject(LocalStorageObject.histoGripCheckup);
        LocalStorageEx.removeObject(LocalStorageObject.currentGripCheckup);

        break;
      case 'bilan_locomotion':
        this.router.navigate(['/checkup/walk/connexion']);
        this.modals.filter((e) => e.name === 'bilanlocomotionModal').forEach((e) => e.hide());
        LocalStorageEx.removeObject(LocalStorageObject.currentWalkBilanStatus);
        LocalStorageEx.removeObject(LocalStorageObject.histoWalkRecordingSession);
        LocalStorageEx.removeObject(LocalStorageObject.currentWalkRecordingSession);
        LocalStorageEx.removeObject(LocalStorageObject.currentWalkAssessmentCheckup);

        break;
      case 'bilan_romberg':
        this.router.navigate(['/checkup/romberg/installation']);
        this.modals.filter((e) => e.name === 'bilanrombergModal').forEach((e) => e.hide());
        LocalStorageEx.removeObject(LocalStorageObject.currentRombergBilanStatus);
        LocalStorageEx.removeObject(LocalStorageObject.histoRombergRecordingSession);
        LocalStorageEx.removeObject(LocalStorageObject.currentRombergRecordingSession);
        LocalStorageEx.removeObject(LocalStorageObject.currentRombergAssessmentCheckup);

        break;

      case 'bilan_pesee':
        this.router.navigate(['/checkup/weight/installation']);
        this.modals.filter((e) => e.name === 'bilanpeseeModal').forEach((e) => e.hide());
        LocalStorageEx.removeObject(LocalStorageObject.currentWeightBilanStatus);
        LocalStorageEx.removeObject(LocalStorageObject.histoWeightResultBilan);
        LocalStorageEx.removeObject(LocalStorageObject.weightResultBilan);

        break;
    }
  }

  public cancelBilan(option: string) {
    this.modals.filter((e) => e.name === option.replace('_', '') + 'Modal').forEach((e) => e.hide());
  }

  public cancelDisbaleEstablishment() {
    this.modals.filter((e) => e.name === 'disabledEstablishmentModal').forEach((e) => e.hide());
  }

  public showVersionModal() {
    this.modals.filter((e) => e.name === 'versionModal').forEach((e) => e.show());
  }

  public hideVersionModal() {
    this.modals.filter((e) => e.name === 'versionModal').forEach((e) => e.hide());
  }

  public showCguModal() {
    this.modals.filter((e) => e.name === 'cguModal').forEach((e) => e.show());
  }

  public hideCguModal() {
    this.modals.filter((e) => e.name === 'cguModal').forEach((e) => e.hide());
  }

  public showWarningPatientModal() {
    this.modals.filter((e) => e.name === 'warningPatientModal').forEach((e) => e.show());
  }

  public hideWarningPatientModal() {
    this.modals.filter((e) => e.name === 'warningPatientModal').forEach((e) => e.hide());
  }

  public showWarningWeightModal() {
    this.modals.filter((e) => e.name === 'warningWeightModal').forEach((e) => e.show());
  }

  public hideWarningWeightModal() {
    this.modals.filter((e) => e.name === 'warningWeightModal').forEach((e) => e.hide());
  }
  /**
   * load or reload event
   */
  @HostListener('window:load', ['$event'])
  private load($event) {
    this.idleWatch(this.idleSeconds);
  }

  /**
   * refresh, unload and close event
   */
  @HostListener('window:beforeunload', ['$event'])
  private beforeUnloadHander($event) {
    // this.oauthService.stopRefreshTimer();
    this.idle.stop();
    this.idleWatched = false;
    /**
     * This line will show a dialogue windows as does alert();
     */
    // $event.returnValue = 'beforeUnloadHander';
  }
}
