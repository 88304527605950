import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InputValuePipe } from './inputvalue.pipe';
import { InputValueDirective } from './inputvalue.directive';
import { IInputOption } from './inputoption.interface';

import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import { LocaleService } from 'mydatepicker/dist/services/my-date-picker.locale.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEx, LocalStorageObject } from '../../../../tools/localstorageobject.enum';
import { LocalDatePipe } from '../../../../modules/time/pipes/localdate.pipe';
import { readOnlyViews } from '../../patient.readonlyviews.constant';

@Component({
  selector: 'field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.css'],
  providers: [InputValuePipe, InputValueDirective],
})
export class FieldInputComponent implements OnInit, OnChanges {
  /* Tag attributes */
  @Input()
  public value: any; /** object or single value */
  @Input()
  public field: string; /** field of object */
  @Input()
  public type: string; /** type of field */
  @Input()
  public step: string; /** numeric precision */
  @Input()
  public readonly: boolean | number = readOnlyViews.EDITABLE;
  public options: IInputOption[] | any[];
  @Input()
  public placeholder: string = '';
  public test: boolean = false;
  /* Tag events */
  @Output()
  public input = new EventEmitter<any>();

  public localDateOptions: IMyDpOptions;
  public optionsRearranged: IInputOption[];

  private localeService: LocaleService = new LocaleService();

  private previousValue: any = undefined;

  public constructor(private translateService: TranslateService, private localDatePipe: LocalDatePipe) {}

  /**
   * get default date for my-date-picker component
   */
  public get defaultDate(): string {
    const today = new Date();
    const year = today.getFullYear() - 60; // TODO: configurable

    let selDate: string = this.fieldValue;
    if (this.fieldValue) {
      selDate = this.localDatePipe.parse(selDate, this.localDateOptions.dateFormat.replace(/[m]/g, 'M'));
    }
    return selDate;
  }

  @Input()
  set option(options: IInputOption[] | any[]) {
    this.options = options;
    this.createOptionsRearranged();
  }

  public get fieldView(): any {
    return {
      IsField: this.field && this.field.length && this.value,
      ReadOnly: this.readonly,
      Select: !this.readonly && this.options,
      Input: !this.readonly && !this.options && this.type !== 'date',
      Mydatepicker: !this.readonly && this.type === 'date',
      TextArea: !this.readonly && this.type === 'textarea',
    };
  }

  public get fieldValue(): any {
    const value = this.fieldView.IsField ? this.value[this.field] : this.value;
    return value || this.type === 'boolean' ? value : null;
  }

  public set fieldValue(value: any) {
    if (this.fieldView.IsField) {
      if (this.previousValue === undefined) this.previousValue = this.value[this.field] ? this.value[this.field] : null;
      this.value[this.field] = value;
    } else {
      if (this.previousValue === undefined) this.previousValue = this.value ? this.value : null;
      this.value = value;
    }
  }

  public isInputOption(option: any): option is IInputOption {
    return (option as IInputOption).value !== undefined;
  }

  public get fieldText(): string {
    const value = this.fieldValue;
    let text = null;

    /**
     * Translate text here
     */
    if (value || (this.type === 'boolean' && value === false)) {
      text = value;
      if (this.options && this.options.length > 0 && this.isInputOption(this.options[0])) {
        const option = (this.options as IInputOption[]).find((item) => item.value === value);
        if (!option) {
          console.log(value + ' not found in ' + JSON.stringify(this.options));
        } else {
          text = this.translateService.instant(option.completeName);
        }
      } else {
        if (this.type === 'date') text = this.localDatePipe.parse(value);
      }
    }

    return text;
  }

  public optionText(option: any): string {
    let text = option;
    if (this.isInputOption(option)) {
      /**
       * Translate text here
       */

      text = this.translateService.instant(option.completeName);
    }
    return text;
  }

  public optionValue(option: any) {
    return this.isInputOption(option) ? option.value : option;
  }

  public isSelected(option: any): boolean {
    const value = this.isInputOption(option) ? (option as IInputOption).value : option;
    return this.fieldValue === value;
  }

  public onInput(event) {
    const value = event.target.value;
    /**
     * Check type here
     */
    switch (this.type) {
      case 'enum':
      case 'number':
        this.fieldValue = Number(value);
        break;
      case 'boolean':
        this.fieldValue = value === 'true';
        break;
      case 'date':
        this.fieldValue = new Date(value);
        break;
      default:
        this.fieldValue = value;
        break;
    }

    this.input.emit(this.value);
  }

  public onDateChanged(event: IMyDateModel) {
    /**
     * ISO Date format is 'yyyy-mm-dd' in Javascript.
     */
    this.fieldValue = event.jsdate;
    this.input.emit(this.value);
  }

  public onLangChange() {
    const today = new Date();
    const currentLang = this.translateService.currentLang.replace(/cn/g, 'zh-cn');
    const localeOptions = this.localeService.getLocaleOptions(currentLang);

    localeOptions.editableDateField = false;
    localeOptions.showTodayBtn = false;
    localeOptions.inline = false;
    localeOptions.minYear = today.getFullYear() - 150; // TODO: configurable
    localeOptions.disableSince = {
      year: today.getFullYear(),
      month: today.getMonth() + 1, // (from 0 to 11)
      day: today.getDate() + 1,
    };

    this.localDateOptions = localeOptions;
  }

  public ngOnInit() {
    console.assert('test Field Input');
    this.onLangChange();
    /**
     * Note:
     * Both this.value and this.value[this.field] are possiblly undefined, so don't check here.
     */
    this.translateService.onLangChange.subscribe((event) => {
      this.onLangChange();
    });
    this.createOptionsRearranged();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly) {
      if (changes.readonly.currentValue !== changes.readonly.previousValue) {
        if (changes.readonly.currentValue === readOnlyViews.CANCELED) {
          if (this.previousValue !== undefined && this.previousValue !== this.fieldValue) {
            this.fieldValue = this.previousValue;
            this.previousValue = undefined;
          }
        }
      }
    }
  }
  private checkIsInputOption(): boolean {
    if (this.options && this.options.length) {
      const optn = this.options as IInputOption[];
      return optn[0].show !== undefined;
    } else {
      return false;
    }
  }

  private createOptionsRearranged(): void {
    if (this.checkIsInputOption()) {
      this.optionsRearranged = [];
      for (const elmt of this.options) {
        let idRoom: number;
        if (LocalStorageEx.currentPatientSelected) {
          const pat = LocalStorageEx.currentPatientSelected;
          // idRoom = pat.spaceId;
        } else {
          idRoom = null;
        }
        if (elmt.show || idRoom === Number(elmt.value)) {
          this.optionsRearranged.push(elmt);
        }
      }
      this.test = true;
    }
  }
}
