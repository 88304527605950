
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Answer, AnswerSet, QuestionSet } from '../../../modules/smartflat-data-access';
import { LocalStorageEx, LocalStorageObject } from '../../../tools/localstorageobject.enum';

@Component({
  selector: 'app-health-form-question-set',
  templateUrl: './health-form-question-set.component.html',
})

/**
 * This component representes a set of questions (and answers) of a diagnostic form.
 * It exchanges data from health-form component (it gets questions sets and sends answers set)
 * It also send questions to the component health-form-question
 */
export class HealthFormQuestionSetComponent implements OnInit {

  @Input()
  public questionSet: QuestionSet;
  @Output()
  private AnswerSetOut = new EventEmitter<{ set: AnswerSet, id: string }>();

  private answerSet: AnswerSet = new AnswerSet();
  private validAnswers: Map<string, boolean> = new Map<string, boolean>();
  private setValid: boolean = false;
  /**
   * constructor initializes the set of answers.
   */
  constructor() {
    this.answerSet.answers = new Map<string, Answer>();
  }

  public ngOnInit(): void {
    this.questionSet.listeItems.forEach((element) => {
      this.validAnswers.set(element.id, false);
    });
  }

  /**
   * Add an answer in the map and if the answer is the last answer, this function sends the answers set to health-form component
   * @param answer the answer to add
   */
  private addQuestionOnSet(response: { answer: Answer, id: string }) {
    if (response.answer !== null) {
      this.validAnswers.set(response.id, true);
      this.setValid = true;
      this.validAnswers.forEach((element) => {
        if (!(element)) {
          this.setValid = false;
        }
      });
      this.answerSet.set_id = this.questionSet.id;
      this.answerSet.answers.set(response.id, response.answer);
      if (LocalStorageEx.checkObject(LocalStorageObject.diagnostic_patient)) {
        // modification of a diagnostic
        this.AnswerSetOut.emit({ set: this.answerSet, id: this.questionSet.id });
      } else if (this.setValid) {
        // last question of set
        // Output
        this.AnswerSetOut.emit({ set: this.answerSet, id: this.questionSet.id });
      } else {
        this.AnswerSetOut.emit({ set: null, id: this.questionSet.id });
      }
    } else {
      this.validAnswers.set(response.id, false);
      this.AnswerSetOut.emit({ set: null, id: this.questionSet.id });
    }
  }
}
