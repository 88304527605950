<div>
  <div class="col-lg-6 col-md-6 col-xs-12 container_buttons">
    <button
      (click)="checkGUID(option)"
      class="btn btn-primary"
      class="{{ option.type }}"
      [disabled]="option.disabled || globalDisabled"
    >
      <span class="option_passed" *ngIf="option.passed === true">&#10004;</span>
      <span class="option_type">
        {{ option.type | translate }}
      </span>
    </button>
  </div>
</div>
<core-modal name="{{ waitAnalyseName }}" #measureWaitAnalyse>
  <div class="modal-header"></div>
  <div class="modal-body"></div>
</core-modal>
