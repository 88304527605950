import { AuthConfig } from 'angular-oauth2-oidc';
import { AppConfigurationService } from 'app/app-configuration.service';

export function authConfigFactory(
  appConfigurationService: AppConfigurationService,
): AuthConfig {
  const config: AuthConfig = {
    issuer: appConfigurationService.wso2Parameters.issuer,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: appConfigurationService.wso2Parameters.clientId,

    // Set a dummy secret
    // Please note that the auth-server used here demand the client to transmit a client secret, although
    // the standard explicitly cites that the password flow can also be used without it. Using a client secret
    // does not make sense for a SPA that runs in the browser. That's why the property is called dummyClientSecret
    // Using such a dummy secreat is as safe as using no secret.
    dummyClientSecret: appConfigurationService.wso2Parameters.dummyClientSecret,

    scope: appConfigurationService.wso2Parameters.scope,
    // set to true, to receive also an id_token via OpenId Connect (OIDC) in addition to the
    // OAuth2-based access_token
    // Do not work in password config
    oidc: true,
    // oidc: false,
    requireHttps: appConfigurationService.wso2Parameters.bypassHttps ? false : true,

    redirectUri: window.location.origin,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  };
  return config;
}
