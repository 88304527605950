import { Component, OnInit, Input } from '@angular/core';
import { PatientIdentity } from 'app/modules/smartflat-data-access';
import { LocalStorageEx } from '../../../../tools/localstorageobject.enum';

@Component({
  selector: 'consultation-identity-banner',
  templateUrl: './identity-banner.component.html',
})
export class IdentityBannerComponent implements OnInit {
  @Input()
  public patient: PatientIdentity;
  public patientSpace: string;

  public async ngOnInit() {
    /* */
  }
}
