import { DatePipe } from '@angular/common';
import { Injectable, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
@Pipe({
  name: 'localdate',
  pure: false,
})
export class LocalDatePipe extends DatePipe {
  /** @internal */
  public LOCALDATEFORMAT = {
    fr: 'dd/MM/yyyy',
    cn: 'yyyy-MM-dd',
    en: 'MM/dd/yyyy',
    zh: 'yyyy-MM-dd',
  };

  public LOCALDATETIMEFORMAT = {
    fr: 'dd/MM/yyyy HH:mm:ss',
    cn: 'yyyy-MM-dd HH:mm:ss',
    en: 'MM/dd/yyyy hh:mm:ss a',
    zh: 'yyyy-MM-dd HH:mm:ss',
  };

  public LOCALDATEHMFORMAT = {
    fr: 'dd/MM/yyyy HH:mm',
    cn: 'yyyy-MM-dd HH:mm',
    en: 'MM/dd/yyyy hh:mm a',
    zh: 'yyyy-MM-dd HH:mm',
  };

  // use navigator lang if available
  public currentLang: string = navigator.language.split('-')[0];

  private lastValue: any = null;
  private lastPatern: string = null;
  private lastResult: string = null;

  public constructor(private translateService: TranslateService) {
    super('en-US');
  }

  public parse(value: any, pattern: string = null): string | null {
    const localDate = new LocalDatePipe(this.translateService);
    return localDate.transform(value, pattern);
  }

  public transform(value: any, pattern: string = null): string | null {
    // use cache if possible
    if (value === this.lastValue && pattern === this.lastPatern) {
      return this.lastResult;
    }

    const dateISOString = value && value instanceof Date ? value.toISOString() : moment(value).toISOString();
    let dateFormat: string = null;

    if (this.translateService) this.currentLang = this.translateService.currentLang;

    if (!pattern) dateFormat = this.LOCALDATEFORMAT[this.currentLang];
    if (pattern === 'datetime') dateFormat = this.LOCALDATETIMEFORMAT[this.currentLang];
    if (pattern === 'datehm') dateFormat = this.LOCALDATEHMFORMAT[this.currentLang];

    // cache result
    this.lastResult = super.transform(dateISOString, dateFormat || pattern);
    this.lastValue = value;
    this.lastPatern = pattern;
    return this.lastResult;
  }
}
