import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'patient-celldate',
  templateUrl: './patient-celldate.component.html',
  styleUrls: ['./patient-celldate.component.css'],
})
export class PatientCellDateComponent implements ViewCell, OnInit {

  @Input() public value: any;
  @Input() public rowData: any;

  public ngOnInit() {
    // Just let TSLint happy
  }
}
