import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

import { ShoesSelectorValues } from '../../../force-platform';
import { AnalysisResults } from '../../../checkup/modules/romberg';

@Component({
  selector: 'app-rapport-romberg-part',
  templateUrl: './rapport-romberg-part.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-romberg-part.component.scss'],
})
export class RapportRombergPartComponent implements OnInit, OnChanges {
  @Input()
  public shoes: ShoesSelectorValues;

  @Input()
  public analysisResult: AnalysisResults;

  public scoreYO: number;
  public scoreYF: number;

  public surfaceYO: number;
  public surfaceYF: number;

  public swayDensityYO: number;
  public swayDensityYF: number;

  @ViewChild('graphSurfaceYO', { static: false })
  public graphSurfaceYO: ElementRef;

  @ViewChild('graphSurfaceYF', { static: false })
  public graphSurfaceYF: ElementRef;

  public ngOnInit() {
    if (this.analysisResult) {
      this.updateSurfaceGraphs();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if not all the needed value here, return;
    if (!this.analysisResult) {
      return;
    }

    this.scoreYO = Math.floor(this.analysisResult.ScoreYO * 100) / 100;
    this.scoreYF = Math.floor(this.analysisResult.ScoreYF * 100) / 100;

    this.surfaceYO = Math.floor(this.analysisResult.SurfaceYO * 100) / 100;
    this.surfaceYF = Math.floor(this.analysisResult.SurfaceYF * 100) / 100;

    this.swayDensityYO = Math.floor(this.analysisResult.SwayDensityYO * 100) / 100;
    this.swayDensityYF = Math.floor(this.analysisResult.SwayDensityYF * 100) / 100;

    this.updateSurfaceGraphs();
  }

  public updateSurfaceGraphs() {
    const surfaceMax = 100;
    const graphsurfaceYOValue = Math.min(...[100, (this.surfaceYO * 100) / surfaceMax]);
    const graphsurfaceYFValue = Math.min(...[100, (this.surfaceYF * 100) / surfaceMax]);
    if (this.graphSurfaceYO && this.graphSurfaceYF) {
      this.drawCircle(graphsurfaceYOValue, this.graphSurfaceYO);
      this.drawCircle(graphsurfaceYFValue, this.graphSurfaceYF);
    }
  }

  // Circle surface YO et YF dans be a custom component.
  private drawCircle(radius: number, canvaRef: ElementRef) {
    const canvasCircle = canvaRef.nativeElement as HTMLCanvasElement;
    const context = canvasCircle.getContext('2d');
    const centerX = canvasCircle.width / 2;
    const centerY = canvasCircle.height / 2;

    context.beginPath();
    context.arc(centerX, centerY, radius * 1.13, 0, 2 * Math.PI, false);
    context.fillStyle = '#FB607F';
    context.fill();
    context.lineWidth = 2;
    context.strokeStyle = '#FB607F';
    context.stroke();

    context.beginPath();
    context.arc(centerX, centerY, 100 * 1.13, 0, 2 * Math.PI, false);
    context.lineWidth = 2;
    context.strokeStyle = '#003300';
    context.stroke();
  }
}
