import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { ServiceDal } from '../service-dal/service-dal';

import { HttpTempService } from 'app/modules/smartflat-data-access/src/api/httptor.service';
import { GatewayState } from '../model/gateway.model';
import { TorCalendarQuery } from '../model/torcalendarquery.model';
import { TorCalendar } from '../model/torcalendar.model';

@Injectable()
/**
 * this service manages tor acquisition, including the transformation of data to/from the DAL model.
 */
export class TorDataService {
  constructor(private torhttpService: HttpTempService, private serviceDal: ServiceDal) {}

  public areCaptorsActivatedAll(): Observable<GatewayState> {
    return this.torhttpService.areCaptorsActivatedAll();
  }

  public setCaptorsActivation(spaceId: number, mute: boolean): Observable<boolean> {
    return of(true);

    /* return this.serviceDal.spaceService
      .spacePrototypeGetAssets(spaceId.toString())
      .flatMap((asset) => asset)
      .take(1)
      .flatMap((asset) => this.torhttpService.setCaptorsActivation(asset.ElectronicAddress, mute));
      */
  }

  public areCaptorsActivated(spaceId: number): Observable<GatewayState> {
    return of(new GatewayState());
    /*
    return this.serviceDal.spaceService
      .spacePrototypeGetAssets(spaceId.toString())
      .flatMap((asset) => asset)
      .take(1)
      .flatMap((asset) => this.torhttpService.areCaptorsActivated(asset.ElectronicAddress));
      */
  }

  public async getSubspacesBySpace(spaceId: number) {
    const subSpaces = []; // = await this.serviceDal.spaceService.spacePrototypeGetSubSpaces(spaceId.toString()).toPromise();
    return subSpaces;
  }

  public calendarQuery(torCalendarQuey: TorCalendarQuery): Observable<TorCalendar> {
    return this.torhttpService.torCalendarQuery(torCalendarQuey);
  }
}
