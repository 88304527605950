import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MathService } from 'app/modules/math/services/math.service';
import { FloatDisplayed } from 'app/modules/math/pipes/float-displayed.pipe';
import { PercentageDisplayed } from 'app/modules/math/pipes/float-percentage.pipe';

@NgModule({
  imports: [TranslateModule.forChild()],
  declarations: [FloatDisplayed, PercentageDisplayed],
  providers: [MathService, FloatDisplayed, PercentageDisplayed],
  exports: [FloatDisplayed, PercentageDisplayed],
})
export class MathModule {
  public static forRoot() {
    return {
      NgModule: MathModule,
      providers: [],
    };
  }
}
