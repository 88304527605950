import { Injectable } from '@angular/core';
import { Grip, GripDataService } from 'app/modules/smartflat-data-access';
import { GripMeasure } from '../models/grip-measure.model';
import { GripMeasureDatasets } from '../models/grip-measures-dataset.model';
import { BilanDetailed } from 'app/model/bilan-detailed.model';
import { GripMathService } from './grip-math.service';
import { LoggerService } from 'app/services/logger/logger.service';

@Injectable()
export class GripDataHistoryService {
  public constructor(
    private logger: LoggerService,
    private gripDataService: GripDataService,
    private gripMathService: GripMathService,
  ) {}

  public async fetchGripMeasureFromBilan(bilan: BilanDetailed): Promise<GripMeasure> {
    const grip = await this.gripDataService.getGripAcquisitionByBilanId(bilan.Id);
    const gripMeasure = new GripMeasure();
    gripMeasure.left1 = grip.LeftHandV1;
    gripMeasure.left2 = grip.LeftHandV2;
    gripMeasure.left3 = grip.LeftHandV1;
    gripMeasure.right1 = grip.RightHandV1;
    gripMeasure.right2 = grip.RightHandV2;
    gripMeasure.right3 = grip.RightHandV3;
    gripMeasure.when = new Date(grip.Horodate);
    this.logger.info(this.constructor.name + ': fetchGripMeasureFromBilan(bilanId=' + bilan.Id + ')=', gripMeasure);
    return gripMeasure;
  }

  public async fetchGripMeasureHistory(patientId: number): Promise<GripMeasureDatasets> {
    const gripHistory = await this.gripDataService.getGripAcquisitions(patientId);
    this.logger.info(this.constructor.name + ': fetchGripMeasureHistory(patientId=' + patientId + ')', gripHistory);
    return this.buildGripDataset(gripHistory);
  }

  private buildGripDataset(gripHistory: Grip[]): GripMeasureDatasets {
    const out = new GripMeasureDatasets();
    gripHistory.forEach((grip: Grip) => this.pushGripDataPoint(out, grip));
    return out;
  }

  private pushGripDataPoint(output: GripMeasureDatasets, grip: Grip): void {
    this.logger.info(this.constructor.name + ': building grip data for day=' + grip.Horodate);
    const left = this.calculateLeftMean(grip);
    const right = this.calculateRightMean(grip);
    const date = new Date(grip.Horodate);

    output.leftHand.data.push({ x: date, y: left });
    output.rightHand.data.push({ x: date, y: right });
  }

  private calculateLeftMean(grip: Grip): number | null {
    return this.gripMathService.calculateMean(grip.LeftHandV1, grip.LeftHandV2, grip.LeftHandV3);
  }

  private calculateRightMean(grip: Grip): number | null {
    return this.gripMathService.calculateMean(grip.RightHandV1, grip.RightHandV2, grip.RightHandV3);
  }
}
