<div class="support-3">

    <div class="row">
        <div class="col-lg-12">
            <div class="page-title">{{ 'Settings.WiiBoadScreen.Connexion.Title' | translate}}</div>
        </div>
    </div>

  <div class="box">
        <div class="row">
            <div class="col-lg-12">
                <p>{{ 'Settings.WiiBoadScreen.Connexion.Text' | translate }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 explanations">
                <div class="number">1</div>
                <p class="description">{{'Settings.WiiBoadScreen.Connexion.Action' | translate}}</p>
                <img src="assets/images/bluetoothsettings.png" alt="connexion">
            </div>    
        </div>
    </div>

</div>