import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';
import { BreadcrumbModule } from '../breadcrumb';
import { CheckupRoutingModule } from './checkup-routing.module';
import { TimeModule } from '../time/time.module';
import { SmartFlatDataAccessModule } from '../smartflat-data-access';
import { ConsultationModule } from '../consultation';
import { CheckupComponent } from './views/main/checkup.component';
import { CheckupSelectorComponent } from './views/selector/checkup-selector.component';
import { OptiontdComponent } from './views/selector/optiontd/optiontd.component';
import { CheckupTitleService } from './services/checkup-title.service';
import { CommonModule, ModalComponent } from '../common';
import { MyDatePickerModule } from 'mydatepicker';
import { IndicatorComponent } from './views/indicator/indicator.component';
import { GripDataHistoryService } from './modules/grip/services/grip-data-history.service';
import { GripMathService } from './modules/grip/services/grip-math.service';
import { PatientService } from 'app/modules/smartflat-data-access/src/service-dal';

@NgModule({
  imports: [
    AngularCommon,
    FormsModule,
    TranslateModule.forChild(),
    CoreModule,
    CheckupRoutingModule,
    BreadcrumbModule,
    TimeModule,
    ConsultationModule,
    SmartFlatDataAccessModule,
    CommonModule,
    MyDatePickerModule,
  ],
  declarations: [CheckupComponent, CheckupSelectorComponent, IndicatorComponent, OptiontdComponent],
  exports: [],
  providers: [CheckupTitleService, ModalComponent, GripDataHistoryService, GripMathService, PatientService],
})
export class CheckupModule {}
