import { Injectable } from '@angular/core';

import { Consultation } from '../model/consultation.model';

import { ServiceDal } from '../service-dal/service-dal';

import { Consultation as DalConsultation } from '../service-dal/model/consultation';

import * as ConsultationTransforms from '../transformations/consultation.transform';

const includeAttributes = '{"include": []}'; // TODO: add "bilan"?
const getLastFilter = '{ "order": ["Date DESC"], "limit": 1 }'; // order by date, get last

@Injectable()
/**
 * this service manages consultations, including the transformation of data to/from the DAL model.
 */
export class ConsultationDataService {
  private patientOrganizationId?: string;

  constructor(private serviceDal: ServiceDal) {}

  public async getConsultationsForPatient(patientId: number): Promise<Consultation[]> {
    const api = this.serviceDal.patientService;
    const consultations = await api.patientPrototypeGetConsultations(patientId.toString()).toPromise();
    return consultations.map((consultation) => ConsultationTransforms.DalConsultationToConsultation(consultation));
  }

  public async getSearchedConsultationsForPatient(
    patientId: number,
    startDate: string,
    endDate: string,
    typeBilan: string,
    typeQuestionnaire: string,
  ): Promise<Consultation[]> {
    const api = this.serviceDal.consultationService;
    const consultations = await api
      .consultationGetConsultationsFilteredByBilan(
        '{"patientId":"' +
          patientId +
          '","typeBilan":"' +
          typeBilan +
          '","typeQuestionnaire":"' +
          typeQuestionnaire +
          '","startsDate":"' +
          startDate +
          '","endsDate":"' +
          endDate +
          '" }',
      )
      .toPromise();
    return consultations.map((consultation) => ConsultationTransforms.DalConsultationToConsultation(consultation));
  }

  public getConsultationById(id: number): Promise<Consultation> {
    const api = this.serviceDal.consultationService;
    return api
      .consultationFindById(id.toString())
      .toPromise()
      .then((c) => ConsultationTransforms.DalConsultationToConsultation(c));
  }

  public async getLastConsultationForPatient(patientId: number): Promise<Consultation> {
    const api = this.serviceDal.patientService;
    const consultations = await api.patientPrototypeGetConsultations(patientId.toString(), getLastFilter).toPromise();

    if (consultations.length > 0) return ConsultationTransforms.DalConsultationToConsultation(consultations[0]);
    else return null;
  }

  public async createConsultation(consultation: Consultation): Promise<Consultation> {
    let dalConsultation = ConsultationTransforms.ConsultationToDalConsultation(consultation);
    const api = this.serviceDal.patientService;

    dalConsultation = await api
      .patientPrototypeCreateConsultations(consultation.patientId.toString(), dalConsultation)
      .toPromise();

    return ConsultationTransforms.DalConsultationToConsultation(dalConsultation);
  }

  public async getMeasuredWeight(patientId: number): Promise<Consultation[]> {
    const api = this.serviceDal.patientService;
    const consultations = await api.patientPrototypeGetMeasuredWeight(patientId.toString()).toPromise();
    return consultations.map((consultation) => ConsultationTransforms.DalConsultationToConsultation(consultation));
  }

  public async updateFragility(consultationId: number) {
    const api = this.serviceDal.consultationService;
    await api.consultationPrototypeUpdateFragility(consultationId.toString()).toPromise();
  }
}
