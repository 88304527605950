import { Component, Input } from '@angular/core';
import { IPatient } from '../patient.interface';

@Component({
  selector: 'patient-modal',
  templateUrl: './patient-modal.component.html',
  styleUrls: ['./patient-modal.component.css'],
})
export class PatientModalComponent {
  @Input() public visible = false;
  @Input() public visibleAnimate = false;
  @Input() public autorizeOnContainerClicked: boolean = true;

  // @TODO ? déclaration sert à rien ici
  public headerLabel: string;
  public bodyText: string;
  public footerConfirmText: string;
  public footerCancelText: string;
  //

  public show(event: any): void {
    this.visible = true;
    setTimeout(() => (this.visibleAnimate = true), 100);
  }

  public hide(event: any): void {
    this.visibleAnimate = false;
    setTimeout(() => (this.visible = false), 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal') && this.autorizeOnContainerClicked) {
      this.hide(null);
    }
  }
}
