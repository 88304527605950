<div class="support">
  <div class="row">
    <div class="col-lg-12">
      <div class="page-title">{{ 'param' | translate }}</div>
    </div>
  </div>

  <div class="row page-sub-title">
    <div class="col-lg-12 no-padding">
      <p>{{ 'Langue' | translate }}</p>
    </div>
  </div>

  <div class="row selectlanguages">
    <div class="col-lg-2">
      <select (change)="setLanguage($event)" [(ngModel)]="selectLanguage">
        <option *ngFor="let locale of locales" value="{{ locale.code }}">{{ locale.label }}</option>
      </select>
    </div>
  </div>
</div>
