<!-- section bloc -->
<div class="infoPatient">

  <div class="col-md-2 col-xs-12">
    <div class="logo">
      <img src={{this.establishmentLogo}} alt="">
    </div>
  </div>

  <div class="col-md-10 col-xs-12 infos-titre">
    <div class="row" id="rapportMarche">
      <div class="col-md-12">
        <h1>{{title}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xs-12" *ngIf="this.establishmentName">
        <div class="info-1" id="etablissement"> {{'Rapport.Etablissement' | translate}} </div>
        <div class="info-2">
          {{this.establishmentName}} </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="info-1"> {{'Patient.Lastname' |translate }} </div>
        <div class="info-2" id="NomPatient"> {{patientIdentity.firstName }}
          {{patientIdentity.lastName}} </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="info-1"> {{'Date' |translate }} </div>
        <div class="info-2"> {{dateConsultation}} </div>
      </div>
    </div>
  </div>

</div>
