<div class="row">
    <div class="col-lg-3">
        {{ 'Sexe' | translate }}: <span>{{ patient.genderString | translate}}</span>
    </div>
    <div class="col-lg-3">
        {{ 'Naissance' | translate }}: <span>{{ patient.birthdate | localdate }} - {{ patient.age }} {{ 'ans' | translate }}</span>
    </div>
    <div class="col-lg-3">
        {{ 'Latéralisation' | translate }}: <span>{{ patient.handednessString | translate }}</span>
    </div> 
    <div class="col-lg-3">
        {{ 'Appartement' | translate }} : <span>{{ patientSpace }}</span>
    </div>
</div>