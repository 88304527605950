import { Gender } from './gender.enum';
import { Handedness } from './handedness.enum';
import { Consultation } from './consultation.model';
import { FragilityState, Flat } from '../service-dal';

export class Patient {
  public guid: string; // unique guid for this patient
  public userId: number; // id of user in the walktest database. Client code should not modify.
  public patientId: number; // id of patient in the walktest database. Client code should not modify
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public address: string;
  public email: string;
  public birthdate: Date;
  public gender: Gender;
  public handedness: Handedness;
  public created: Date;
  public modified: Date;
  public folderNumber: string;
  public doctorId?: number;
  public relationDoctorId?: number; // évite un aller-retour au service DAL
  public flatId?: number;

  public lastConsultation?: Consultation;
  public lastConsultationDate?: Date;
  public hasHomonyme?: boolean;
  public lastMeasuredWeight?: number;
  public fragilityState: FragilityState;
  public connected: boolean;
  public currentFlat?: Flat;
  public flatName?: string;
  public consent: boolean;
}
