import { Injectable } from '@angular/core';
import { ServiceDal } from '../service-dal/service-dal';
import { FootWear } from '../model/footwears.model';
import * as FootWearTransform from '../transformations/footwear.transform';
import { WalkingAid } from '../service-dal';

@Injectable()
/**
 * this service manages grip acquisition, including the transformation of data to/from the DAL model.
 */
export class FootWearDataService {
  constructor(private serviceDal: ServiceDal) { }

  public async GetAllFootWears(local: string): Promise<FootWear[]> {
    const footWears: FootWear[] = [];
    const filter: any = { where: { Culture: local } };

    const footWearsDal = await this.serviceDal.shoesService.footwearFind(JSON.stringify(filter)).toPromise();
    footWearsDal.forEach((footWearDal) => {
      footWears.push(FootWearTransform.DalFootWearsToFootWears(footWearDal));
    });
    return footWears;
  }

  public async GetFootWearById(local: string, id: number): Promise<FootWear> {
    const filter: string = '{ "where": { "Culture":"' + local + '" } }';
    const footWear = await this.serviceDal.shoesService.footwearFindById(id.toString(), filter).toPromise();
    return FootWearTransform.DalFootWearsToFootWears(footWear);
  }

  public async GetWalkingAidById(local: string, id: number): Promise<WalkingAid> {
    const filter: string = '{ "where": { "id":' + id + ', "culture":"' + local + '" } }';
    const walkingAid = await this.serviceDal.walkingAidServive.walkingAidFindOne(filter).toPromise();
    return walkingAid;
  }

  public async GetAllWalkingAid(local: string): Promise<WalkingAid[]> {
    const walkingAid: WalkingAid[] = [];
    const filter: any = { where: { culture: local } };
    const walkingAidsDal = await this.serviceDal.walkingAidServive.walkingAidFind(JSON.stringify(filter)).toPromise();
    walkingAidsDal.forEach((walkingAidDal) => {
      walkingAid.push({ id: walkingAidDal.id, culture: walkingAidDal.culture, translate: walkingAidDal.translate });
    });
    return walkingAid;
  }
}
