import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

export enum PeriodType {
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export const TIME_FREQUENCY_ARRAY = [
  { value: PeriodType.Week, label: i18nKey('Date.Week') },
  { value: PeriodType.Month, label: i18nKey('Date.Month') },
  { value: PeriodType.Year, label: i18nKey('Date.Year') },
];
