<div class="row">
  <div class="col-lg-12">
    <table class="tor-table">
      <thead>
        <tr>
          <th rowspan="2" class="dark">
            <span>{{ 'Date.duration' | translate }}</span>
          </th>
          <th colspan="2" *ngFor="let weekday of torTable.torTableHead.headValues">
            <span>{{ weekday }}</span>
          </th>
          <th colspan="2" class="dark">
            <span>{{ 'tor.table.total' | translate }}</span>
          </th>
        </tr>
        <tr>
          <th *ngFor="let dayMoment of torTable.torTableHead.subHeadValues" class="day">
            <span class="{{ dayMoment }}"></span>
          </th>
          <th class="day"><span class="Date_Day"></span></th>
          <th class="day"><span class="Date_Night"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of torTable.rows">
          <td>
            <span
              >{{ 'Date.From' | translate }} {{ row.startDay | localdate }} {{ 'Date.To' | translate }}
              {{ row.endDay | localdate }}</span
            >
          </td>
          <td *ngFor="let value of row.values">
            <span>{{ value.totalTorIndicator }}</span>
          </td>
          <td>
            <span> {{ row.dayTorIndicator }}</span>
          </td>
          <td>
            <span>{{ row.nightTorIndicator }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
