import { Component } from '@angular/core';
import { VersionService } from 'app/modules/core/index';
import { AppComponent } from 'app/app.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  public version: string = '0.6.1';

  constructor(private versionService: VersionService, private app: AppComponent) {
    this.version = versionService.getAppVersion();
  }

  public showVersionModal() {
    this.app.showVersionModal();
  }

  public showCguModal() {
    this.app.showCguModal();
  }
}
