import { Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';

import { IPatient } from '../../patient.interface';
import { PatientDataSource } from '../../patient-data-source';
import { of } from 'rxjs';
import { filter, delay } from 'rxjs/operators';

@Component({
  selector: 'patient-sub-header-result',
  templateUrl: './patient-sub-header-result.component.html',
})
export class PatientSubHeaderResultComponent {
  @Input() public selectedPatient: IPatient;
  @Input() public patientSource: PatientDataSource;
  @Output() public search = new EventEmitter<boolean>();

  public initSearch = '';

  public ngOnChanges(change: SimpleChanges) {
    if (change.patientSource) {
      of(change.patientSource.currentValue as PatientDataSource)
        .pipe(
          filter((o) => !!o),
          delay(100),
        )
        .subscribe((v) => (this.initSearch = v.globalFilter));
    }
  }

  public get patientName(): string {
    let patientName: string = '';
    if (this.selectedPatient) {
      // TODO: we need to have a localizable string here. i.e. something like
      // patientName = format('{lastName} {firstName}' | Translate);
      if (this.selectedPatient.lastName) patientName = this.selectedPatient.lastName;
      if (this.selectedPatient.firstName) {
        if (patientName.length > 0) patientName += ' ';
        patientName += this.selectedPatient.firstName;
      }
    }
    return patientName;
  }

  /* Event handlers */
  public onSearchPatient(query: string = '') {
    this.patientSource.globalFilter = query;
    this.search.emit(true);
  }
}
